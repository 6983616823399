import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {
  convertImageToBase64,
  getFormattedNumber,
} from "../../utils/globalFunctions";
import ReactToPrint from "react-to-print";
import { useLazyGetMatchingCardQuery } from "../../servicesRtkQuery/publicApi";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";

export const LumpMatchingCardPreview = (props: any) => {
  const exportFrontRef: any = useRef(null);
  const exportBackRef: any = useRef(null);
  const exportChallanRef: any = useRef(null);
  const exportCheking: any = useRef(null);

  const [image, setImage]: any = useState();
  const [toggleState, setToggleState] = useState(1);
  var userData: any = localStorage.getItem("user");
  userData = JSON.parse(userData);

  const toggleTab = (index: any) => {
    setToggleState(index);
  };

  const [matchingCard, setMatchingCard]: any = useState();

  const id = props.id;
  const [trigger, result] = useLazyGetMatchingCardQuery();
  const { isSuccess, isFetching } = result;
  const [loadingImage, setLoadingImage]: any = useState(false);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setMatchingCard(result?.data?.data?.matchingCard?.[0]);
    }
  }, [isSuccess, isFetching]);
  useEffect(() => {
    let payload = {
      mcId: id,
      query: {
        isSingle: true,
      },
    };
    trigger(payload);
  }, []);
  useEffect(() => {
    setLoadingImage(true);
    if (matchingCard) {
      if (matchingCard?.image?.URL?.startsWith("https:")) {
        convertImageToBase64(matchingCard?.image?.URL, setImage);
      } else {
        Swal.fire({
          toast: true,
          icon: "warning",
          title: "You have not uploaded any image\nPlease upload a new image.",
          position: "top-end",
          showConfirmButton: false,
          timer: ERROR_MESSAGE_TIMEOUT,
          timerProgressBar: true,
        });
        setLoadingImage(false);
      }
    }
  }, [matchingCard]);

  useEffect(() => {
    if (image) {
      setLoadingImage(false);
    }
  }, [image]);

  let isFrame = true;
  if (matchingCard?.type === "dress" || matchingCard?.type === "lump") {
    matchingCard?.fabricColors?.forEach((item: any) => {
      if (matchingCard?.needlesHead?.length > 9) {
        if (item?.niddles?.[17]) {
          isFrame = false;
        }
      } else {
        if (item?.niddles?.[8]) {
          isFrame = false;
        }
      }
    });
  } else {
    isFrame = false;
  }

  function FrontPage() {
    return (
      <div
        ref={exportFrontRef}
        className={`${matchingCard?.needlesHead?.length > 9 ? "a4-landscape" : "a4-portrait"
          } overflow-hidden`}
      >
        <div className="text-lg text-center underline capitalize font-bold">
          Matching Card -&nbsp;&nbsp;{matchingCard?.type}
        </div>
        <div className="overflow-hidden text-xs border border-black">
          {matchingCard?.needlesHead?.length > 9 ? (
            <>
              <table className="w-full border-collapse bg-white text-center">
                <thead className="capitalize border border-black">
                  <tr className="border border-black text-base">
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      party name
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      design no.
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      date
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      Challan No.
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold"
                    >
                      card no.
                    </th>
                  </tr>
                  <tr className="text-base">
                    <th className="text-center border-x border-black font-bold">
                      {matchingCard?.party?.name}
                    </th>
                    <th className="text-center border-r border-b border-black font-bold">
                      {matchingCard?.designNo}
                    </th>
                    <th className="text-center border-r border-black font-bold">
                      {moment(matchingCard?.date).format("DD/MM/YYYY")}
                    </th>
                    <th className="text-cente border-r border-black font-bold">
                      {matchingCard?.challanNo2?.length
                        ? matchingCard?.challanNo + " + " + matchingCard?.challanNo2
                        : matchingCard?.challanNo}
                    </th>
                    <th className="text-center border-b border-r border-black font-bold">
                      {matchingCard?.invoiceNo}
                    </th>
                  </tr>
                </thead>
              </table>
              <table className="w-full border-collapse bg-white text-center">
                <tbody className="border-b border-black">
                  <tr>
                    <td
                      rowSpan={2}
                      className="text-center border-y border-r font-bold border-black w-20 text-lg"
                    >
                      Fabric Color
                    </td>
                    <td className="text-center border-y font-bold border-black w-12">
                      Meater
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N1
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N2
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N3
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N4
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N5
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N6
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N7
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N8
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N9
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N10
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N11
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N12
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N13
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N14
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N15
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N16
                    </td>
                    <td className="text-center border-l font-bold border-y border-black w-20">
                      N17
                    </td>
                    {isFrame ? (
                      <td
                        rowSpan={2}
                        className="text-center border-l font-bold border-y border-black w-20"
                      >
                        फ्रेम / हेड
                      </td>
                    ) : (
                      <td className="text-center border-l font-bold border-y border-black w-20">
                        N18
                      </td>
                    )}
                  </tr>
                  <tr className="text-sm leading-5">
                    <td className="text-center border-b border-black text-base">
                      {matchingCard?.TPPM}
                    </td>
                    {matchingCard?.needlesHead?.map((head: any, index: any) => {
                      return (
                        <td
                          key={index}
                          className="text-base text-center border-b border-l border-black"
                        >
                          <div className="border-b border-black" id="pattern">
                            {head?.designPattern}
                          </div>
                          <div>{head?.threadType}</div>
                        </td>
                      );
                    })}
                    {isFrame
                      ? matchingCard?.needlesHead?.length > 0
                        ? Array(Math.max(0, 17 - Number(matchingCard?.needlesHead?.length)))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-black font-bold"
                              >
                                <div className="border-b border-black">
                                  &nbsp;
                                </div>
                                <div>&nbsp;</div>
                              </td>
                            );
                          })
                        : null
                      : matchingCard?.needlesHead?.length > 0
                        ? Array(Math.max(0, 18 - Number(matchingCard?.needlesHead?.length)))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-black font-bold"
                              >
                                <div className="border-b border-black">
                                  &nbsp;
                                </div>
                                <div>&nbsp;</div>
                              </td>
                            );
                          })
                        : null}
                  </tr>
                  {matchingCard?.fabricColors?.map(
                    (fabric: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td
                            className={`text-right text-sm border-r border-b border-black font-bold capitalize pr-2`}
                            id="color"
                          >
                            {fabric?.fabricColorId?.colorName}
                          </td>
                          <td
                            className={`text-center border-b border-black capitalize text-base`}
                          >
                            {fabric?.PM}
                          </td>
                          {fabric?.niddles?.map((niddle: any, index: any) => {
                            return niddle?.designCodeId2?.designCode ? (
                              <td className="text-center uppercase border-b border-l border-black font-bold">
                                <tr className="border-b border-black">
                                  {niddle?.designCodeId1?.designCode}
                                </tr>
                                <tr>{niddle?.designCodeId2?.designCode}</tr>
                              </td>
                            ) : (
                              <td
                                key={index}
                                className="text-center uppercase h-13 border-b border-l border-black font-bold"
                              >
                                <div>{niddle?.designCodeId1?.designCode}</div>
                              </td>
                            );
                          })}
                          {isFrame
                            ? fabric?.niddles?.length > 0
                              ? Array(Math.max(0, 17 - Number(fabric?.niddles?.length)))
                                ?.fill("-")
                                ?.map((row: any, index: number) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center h-13 border-b border-l border-black font-bold"
                                    >
                                      &nbsp;
                                    </td>
                                  );
                                })
                              : null
                            : fabric?.niddles?.length > 0
                              ? Array(Math.max(0, 18 - Number(fabric?.niddles?.length)))
                                ?.fill("-")
                                ?.map((row: any, index: number) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center h-13 border-b border-l border-black font-bold"
                                    >
                                      &nbsp;
                                    </td>
                                  );
                                })
                              : null}
                          {isFrame ? (
                            <td className="text-center border-b h-13 border-l border-black font-bold text-lg">
                              {fabric?.frame ? fabric?.frame + "F /" : null}
                              {fabric?.head ? fabric?.head + "H" : null}
                            </td>
                          ) : null}
                        </tr>
                      );
                    }
                  )}
                  {matchingCard?.fabricColors?.length > 0 &&
                    Number(matchingCard?.fabricColors?.length) < 5
                    ? Array(Math.max(0, 5 - Number(matchingCard?.fabricColors?.length)))
                      ?.fill("-")
                      ?.map((fabric: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td className="border-r border-black font-bold">
                              &nbsp;
                            </td>
                            <td className={`border-black capitalize`}>
                              &nbsp;
                            </td>
                            {Array(18)
                              ?.fill("-")
                              ?.map(() => {
                                return (
                                  <td className="border-l border-black"></td>
                                );
                              })}
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table className="w-full border-collapse bg-white text-center">
                <thead className="capitalize border border-black text-base">
                  <tr className="border border-black">
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold py-1"
                    >
                      party name
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold py-1"
                    >
                      design no.
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold py-1"
                    >
                      date
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold py-1"
                    >
                      Challan No.
                    </th>
                    <th
                      style={{ width: "20%" }}
                      className="text-center border-r border-black font-bold py-1"
                    >
                      card no.
                    </th>
                  </tr>
                  <tr className="text-base">
                    <th className="text-center border-x border-black font-bold py-1">
                      {matchingCard?.party?.name}
                    </th>
                    <th className="text-center border-r border-b border-black font-bold py-1">
                      {matchingCard?.designNo}
                    </th>
                    <th className="text-center border-r border-black font-bold py-1">
                      {moment(matchingCard?.date).format("DD/MM/YYYY")}
                    </th>
                    <th className="text-cente border-r border-black font-bold py-1">
                      {matchingCard?.challanNo2?.length
                        ? matchingCard?.challanNo + " + " + matchingCard?.challanNo2
                        : matchingCard?.challanNo}
                    </th>
                    <th className="text-center border-b border-r border-black font-bold py-1">
                      {matchingCard?.invoiceNo}
                    </th>
                  </tr>
                </thead>
              </table>
              <table className="w-full border-collapse bg-white text-center">
                <tbody className="text-xs leading-5 border-b border-black">
                  <tr>
                    <td
                      rowSpan={2}
                      className="text-center border-y border-r font-bold border-black py-1 w-20 text-lg"
                    >
                      Fabric Color
                    </td>
                    <td className="text-center border-y font-bold border-black py-1 w-12">
                      Meater
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N1
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N2
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N3
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N4
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N5
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N6
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N7
                    </td>
                    <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                      N8
                    </td>
                    {isFrame ? (
                      <td
                        rowSpan={2}
                        className="text-center border-l font-bold border-y border-black py-1 w-20"
                      >
                        फ्रेम / हेड
                      </td>
                    ) : (
                      <td className="text-center border-l font-bold border-y border-black py-1 w-20">
                        N9
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="text-center border-b border-black py-1 text-base">
                      {matchingCard?.TPPM}
                    </td>
                    {matchingCard?.needlesHead?.map((head: any, index: any) => {
                      return (
                        <td
                          key={index}
                          className="text-base text-center border-b border-l border-black"
                        >
                          <div className="border-b border-black" id="pattern">
                            {head?.designPattern}
                          </div>
                          <div>{head?.threadType}</div>
                        </td>
                      );
                    })}
                    {isFrame
                      ? matchingCard?.needlesHead?.length > 0
                        ? Array(Math.max(0, 8 - Number(matchingCard?.needlesHead?.length)))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-black font-bold"
                              >
                                <div className="border-b border-black">
                                  &nbsp;
                                </div>
                                <div>&nbsp;</div>
                              </td>
                            );
                          })
                        : null
                      : matchingCard?.needlesHead?.length > 0 &&
                        matchingCard?.needlesHead?.length < 9
                        ? Array(Math.max(0, 9 - Number(matchingCard?.needlesHead?.length)))
                          ?.fill("-")
                          ?.map((row: any, index: number) => {
                            return (
                              <td
                                key={index}
                                className="text-center border-b border-l border-black font-bold"
                              >
                                <div className="border-b border-black">
                                  &nbsp;
                                </div>
                                <div>&nbsp;</div>
                              </td>
                            );
                          })
                        : null}
                  </tr>
                  {matchingCard?.fabricColors?.map(
                    (fabric: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td
                            className={`text-right text-sm pr-2 border-r border-b border-black font-bold capitalize py-4.5`}
                            id="color"
                          >
                            {fabric?.fabricColorId?.colorName}
                          </td>
                          <td
                            className={`text-center border-b border-black capitalize text-base`}
                          >
                            {fabric?.PM}
                          </td>
                          {fabric?.niddles?.map((niddle: any, index: any) => {
                            return niddle?.designCodeId2?.designCode ? (
                              <td className="text-center uppercase border-b border-l border-black font-bold">
                                <div className="border-b border-black">
                                  {niddle?.designCodeId1?.designCode}
                                </div>
                                <div>{niddle?.designCodeId2?.designCode}</div>
                              </td>
                            ) : (
                              <td
                                key={index}
                                className="text-center uppercase h-13 border-b border-l border-black font-bold"
                              >
                                {niddle?.designCodeId1?.designCode}
                              </td>
                            );
                          })}
                          {isFrame
                            ? fabric?.niddles?.length > 0
                              ? Array(Math.max(0, 8 - Number(fabric?.niddles?.length)))
                                ?.fill("-")
                                ?.map((row: any, index: number) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center border-b h-13 border-l border-black font-bold"
                                    >
                                      &nbsp;
                                    </td>
                                  );
                                })
                              : null
                            : fabric?.niddles?.length > 0
                              ? Array(Math.max(0, 9 - Number(fabric?.niddles?.length)))
                                ?.fill("-")
                                ?.map((row: any, index: number) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center border-b h-13 border-l border-black font-bold"
                                    >
                                      &nbsp;
                                    </td>
                                  );
                                })
                              : null}
                          {isFrame ? (
                            <td className="text-center border-b h-13 border-l border-black font-bold text-lg">
                              {fabric?.frame ? fabric?.frame + " F /" : null}
                              {fabric?.head ? fabric?.head + "H" : null}
                            </td>
                          ) : null}
                        </tr>
                      );
                    }
                  )}
                  {matchingCard?.fabricColors?.length > 0 &&
                    Number(matchingCard?.fabricColors?.length) < 10
                    ? Array(Math.max(0, 10 - Number(matchingCard?.fabricColors?.length)))
                      ?.fill("-")
                      ?.map((fabric: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td className="border-r border-black font-bold">
                              &nbsp;
                            </td>
                            <td className={`border-black capitalize`}>
                              &nbsp;
                            </td>
                            {Array(9)
                              ?.fill("-")
                              ?.map(() => {
                                return (
                                  <td className="border-l h-12 border-black"></td>
                                );
                              })}
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </>
          )}
          <div className={`text-xs p-1 grid grid-cols-8 mt-1`}>
            <div className="col-span-6">
              <table className="w-full border border-collapse bg-white text-center">
                <thead className=" divide-black border-t border-x border-black text-sm">
                  <tr>
                    <th
                      scope="col"
                      className="border-r border-y-2 border-l-2 w-16 border-black capitalize"
                    >
                      MC No.
                    </th>
                    <th
                      scope="col"
                      className="border-r border-y-2 border-black capitalize"
                    >
                      Area
                    </th>
                    <th
                      scope="col"
                      className="border-r border-y-2 w-14 border-black capitalize"
                    >
                      Head
                    </th>
                    <th
                      scope="col"
                      className="border-r border-y-2 border-black capitalize w-[12%]"
                    >
                      Item
                    </th>
                    <th
                      scope="col"
                      className="border-r border-y-2 w-18 border-black capitalize"
                    >
                      Mc. Design
                    </th>
                    <th
                      scope="col"
                      className="border-r border-y-2 w-14 border-black capitalize"
                    >
                      stitch
                    </th>
                    <th
                      scope="col"
                      className="border-r border-y-2 w-14 border-black capitalize"
                    >
                      frame
                    </th>
                    <th
                      scope="col"
                      className="border-r border-y-2 border-black w-20 capitalize"
                    >
                      prod.
                    </th>
                    <th
                      scope="col"
                      className="border-r-2 border-y-2 w-14 border-black capitalize"
                    >
                      days
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y text-center divide-black border border-black text-sm">
                  {matchingCard?.clothes?.map((item: any, index: number) => {
                    return (
                      <tr key={index} className="text-center">
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize"></td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize">
                          {item?.area}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize">
                          {item?.head}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize">
                          {item?.item?.name}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-left capitalize">
                          #
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize">
                          {item?.stitch}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize">
                          {item?.frame}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize">
                          {item?.production}
                        </td>
                        <td className="border-r border-black font-normal text-gray-900 text-center capitalize">
                          {item?.day}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      colSpan={2}
                      className="px-2 font-bold text-gray-900 text-right capitalize"
                    >
                      {props?.remark}
                    </td>
                    <td
                      colSpan={3}
                      className="px-2 font-bold text-gray-900 text-right capitalize"
                    >
                      Days : {matchingCard?.totalDays}
                    </td>
                    <td
                      colSpan={4}
                      className=" border-black px-2 font-bold text-gray-900 text-center capitalize"
                    >
                      Total Production : {matchingCard?.totalProduction}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="grid grid-cols-7 col-span-2 row-span-2">
              <div className="col-span-7 text-center z-30">
                <span className="bg-white px-1">
                  {matchingCard?.image?.top}
                </span>
              </div>
              <div className="flex items-center justify-center mr-2 z-30"></div>
              <div className="col-span-5">
                <div className="flex justify-center items-center">
                  {image ? (
                    <img
                      src={image}
                      className="object-contain max-h-48 min-h-48 min-w-44 max-w-44"
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex items-center justify-center ml-2 z-30">
                <span className="bg-white px-1">
                  {matchingCard?.image?.right}
                </span>
              </div>
              <div className="col-span-7 text-center z-30">
                <span className="bg-white px-1">
                  {matchingCard?.image?.bottom}
                </span>
              </div>
            </div>
            <div className="col-span-6 grid grid-cols-6 gap-4 mt-1">
              <div className="col-span-6">
                <table className="border w-full border-collapse bg-white text-center">
                  <thead className="divide-black border-y-2 border-x border-black">
                    <tr>
                      <th
                        style={{ width: "25%" }}
                        scope="col"
                        className=" border-r border-l-2 border-t-2 border-b border-black capitalize"
                      >
                        Fabric
                      </th>
                      <th
                        style={{ width: "25%" }}
                        scope="col"
                        className="border-r border-t-2 border-b border-black capitalize"
                      >
                        Fabric Party
                      </th>

                      <th
                        style={{ width: "15%" }}
                        scope="col"
                        className="border-r border-t-2 border-b border-black capitalize"
                      >
                        Cut/Mtr
                      </th>
                      <th
                        style={{ width: "15%" }}
                        scope="col"
                        className="border-r border-t-2 border-b border-black capitalize"
                      >
                        Height
                      </th>
                      <th
                        // style={{ width: "20%" }}
                        scope="col"
                        className="pr-2 capitalize border-l border-r-2 border-black"
                        rowSpan={2}
                      >
                        <div className="m-auto">
                          <div className="text-center font-bold">
                            {matchingCard?.spacing?.top}
                          </div>
                          <div></div>
                          <div className="ml-[18px] flex">
                            <div className="py-2 px-7 border-2 border-black mr-2"></div>
                            <div>{matchingCard?.spacing?.right}</div>
                          </div>
                          <div className="text-center font-bold">
                            {matchingCard?.spacing?.bottom}
                          </div>
                          <div></div>
                        </div>
                      </th>
                    </tr>

                    <tr>
                      <td className="gap-3 border-r border-l-2 border-black px-2 font-normal text-gray-900 capitalize">
                        {matchingCard?.clothe?.name}
                      </td>
                      <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize">
                        {matchingCard?.clotheParty?.name}
                      </td>
                      <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize">
                        {getFormattedNumber(
                          (Number(matchingCard?.CM) + 0).toFixed(2)
                        )}
                      </td>
                      <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize">
                        {matchingCard?.height}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
          <div className={`text-sm p-1`}>
            <div className="col-span-3 flex">
              <table className="w-full border border-collapse bg-white text-left">
                <thead className="divide-black border-t border-x border-black">
                  <tr>
                    <th
                      style={{ width: "25%" }}
                      scope="col"
                      className="px-6 text-center capitalize border-r border-l-2 border-y-2 border-black"
                    >
                      {matchingCard?.work?.foilColor ? "foil" : "Deco"}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      scope="col"
                      className="px-6 text-center capitalize border-r border-y-2 border-black"
                    >
                      hand work
                    </th>
                    <th
                      style={{ width: "25%" }}
                      scope="col"
                      className="px-6 text-center capitalize border-r border-y-2 border-black"
                    >
                      {matchingCard?.work?.extra?.stitching
                        ? "stitching"
                        : matchingCard?.work?.extra?.cutwork
                          ? "cutwork"
                          : ""}
                    </th>
                    <th
                      style={{ width: "25%" }}
                      scope="col"
                      className="px-6 text-center capitalize border-r-2 border-y-2 border-black"
                    >
                      bill no.
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y text-center text-sm divide-black border border-black">
                  <tr>
                    <td className="gap-3 px-3 font-normal text-gray-900 text-center capitalize border-r border-black">
                      {matchingCard?.work?.foilColor
                        ? matchingCard?.work?.foilColor?.name
                        : matchingCard?.work?.decoType}
                      &nbsp;
                    </td>
                    <td className="gap-3 font-normal text-gray-900 text-center capitalize grid grid-cols-3">
                      <div className="gap-3 font-normal text-gray-900 text-center capitalize border-r border-black">
                        {matchingCard?.work?.handWork?.type}
                      </div>
                      <div className="gap-3 font-normal text-gray-900 text-center capitalize border-r border-black">
                        {matchingCard?.work?.handWork?.qty}
                      </div>
                      <div className="gap-3 font-normal text-gray-900 text-center capitalize">
                        {matchingCard?.work?.handWork?.rate}
                      </div>
                    </td>
                    <td className="gap-3 font-normal text-gray-900 text-center capitalize border-l border-black">
                      {matchingCard?.work?.extra?.cutwork}
                      {matchingCard?.work?.extra?.stitching}
                    </td>
                    <td className="gap-3 font-normal text-gray-900 text-center capitalize border-l border-black">
                      {matchingCard?.work?.billNo}
                    </td>
                  </tr>
                  <tr>
                    <td className="gap-3 font-normal text-gray-900 text-center capitalize border-r border-black">
                      &nbsp;
                    </td>
                    <td className="gap-3 font-normal text-gray-900 text-center capitalize border-r border-black">
                      &nbsp;
                    </td>
                    <td className="gap-3 font-normal text-gray-900 text-center capitalize border-r border-black">
                      &nbsp;
                    </td>
                    <td className="gap-3 font-normal text-gray-900 text-center capitalize border-r border-black">
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="text-md mt-3 w-3/4">
            Note : प्रथम फ्रेम सुपरवाइज़र को दिखाकर ही चालु करना |
          </div>
          <div className="text-sm flex justify-center w-1/4 mt-2">Sign.</div>
        </div>
        <div className="text-lg mr-25">{matchingCard?.notes}</div>
      </div>
    );
  }

  function BackPage() {
    return (
      <div
        ref={exportBackRef}
        className={`${matchingCard?.needlesHead?.length > 9 ? "a4-landscape" : "a4-portrait"
          } overflow-hidden`}
      >
        <div className="h-full">
          <div className={`text-sm h-full max-h-max`}>
            <table
              className="w-full border border-collapse bg-white text-left"
              width="100%"
            >
              <thead className="divide-black border border-black">
                <tr>
                  <th
                    scope="col"
                    className="px-6 w-34 capitalize border-r border-black"
                  >
                    fabric color
                  </th>
                  <th scope="col" className="px-6 capitalize text-center">
                    number of frames
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y text-center divide-black border border-black">
                {matchingCard?.fabricColors?.map((fabric: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td className="gap-3 px-6 font-normal text-base text-gray-900 text-left capitalize border-r border-black">
                        {" "}
                        {fabric.fabricColorId.colorName}
                      </td>
                      <td className="gap-3 font-normal text-gray-900 text-left capitalize">
                        <div className="flex p-1 flex-wrap">
                          {fabric?.frame &&
                            Array(fabric.frame)
                              .fill(" ")
                              .map((row: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className="border border-black items-center font-bold px-3 py-1 mr-2 mb-2 rounded-md"
                                  >
                                    {" "}
                                    {index + 1}
                                  </div>
                                );
                              })}
                          {fabric.head ? (
                            <div className="border border-black items-center font-bold px-3 py-1 mr-2 mb-2 rounded-md">
                              {fabric.head} H
                            </div>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-2 text-sm">
            <table className="border w-full border-collapse bg-white text-center">
              <thead className="divide-black border-t border-x border-black">
                <tr>
                  <th
                    style={{ width: "20%" }}
                    scope="col"
                    className="px-6 border-r border-l-2 border-y-2 border-black capitalize"
                  >
                    material
                  </th>
                  <th
                    style={{ width: "20%" }}
                    scope="col"
                    className="px-3 border-r border-y-2 border-black capitalize"
                  >
                    Fabric
                  </th>
                  <th
                    style={{ width: "20%" }}
                    scope="col"
                    className="px-6 border-r border-y-2 border-black capitalize"
                  >
                    production
                  </th>
                  <th
                    style={{ width: "20%" }}
                    scope="col"
                    className="px-6 capitalize border-y-2 border-r border-black"
                  >
                    alter
                  </th>
                  <th
                    style={{ width: "20%" }}
                    scope="col"
                    className="px-6 capitalize border-y-2 border-r-2 border-black"
                  >
                    delivery
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y text-center divide-black border border-black text-sm">
                {Array(2)
                  .fill(" ")
                  .map((row: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize">
                          &nbsp;
                        </td>
                        <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize"></td>
                        <td className="gap-3 px-2 font-normal text-gray-900 capitalize"></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="my-3">
            <table style={{ "borderSpacing": "2px 10px" }}>
              <tr>
                <td className="px-2">Plain</td>
                <td className="py-3 px-9  border-black border-2"></td>
                <td className="px-2">Production</td>
                <td className="py-3 px-9  border-black border-2"></td>
                <td className="px-2">Checking</td>
                <td className="py-3 px-9  border-black border-2"></td>
                <td className="px-2">Handwork</td>
                <td className="py-3 px-9  border-black border-2"></td>
                <td className="px-2">Delivery</td>
                <td className="py-3 px-9  border-black border-2"></td>
              </tr>
              {/* <tr>
                <td className="px-2">Day</td>
                <td className="py-3 px-9  border-black border-2"></td>
                <td className="px-2">Night</td>
                <td className="py-3 px-9  border-black border-2"></td>
              </tr> */}
            </table>
          </div>

          <div className="mt-2 text-sm w-[58%]">
            <table className="border w-full border-collapse bg-white text-center">
              <thead className="divide-black border-t border-x border-black">
                <tr>
                  <th
                    style={{ width: "20%" }}
                    scope="col"
                    className="px-6 border-r h-8 border-l-2 border-y-2 border-black capitalize text-md"
                  >
                    Day
                  </th>
                  <th
                    style={{ width: "20%" }}
                    scope="col"
                    className="px-3 border-r h-8 border-y-2 border-black capitalize text-md"
                  >
                    Night
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y text-center divide-black border border-black text-sm">
                {Array(2)
                  .fill(" ")
                  .map((row: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize">
                          &nbsp;
                        </td>
                        <td className="gap-3 border-r border-black px-2 font-normal text-gray-900 capitalize"></td>
                      </tr>
                    );
                  })}
                <tr>
                  <td className="gap-3 border-r border-black px-2  text-gray-900 font-bold capitalize text-left">
                    Mc No =
                  </td>
                  <td className="gap-3 border-r border-black px-2  text-gray-900 font-bold capitalize text-left">MC No =</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  function Challan() {
    let colors: any = [];
    matchingCard?.fabricColors?.forEach((item: any) => {
      colors.push(item?.fabricColorId?.colorName);
    });
    return (
      <div ref={exportChallanRef} className={`a4-portrait overflow-hidden`}>
        <div className="h-full">
          <div className="mt-2 text-xs">
            <table className="w-full border-collapse bg-white text-center border border-black">
              <thead className="capitalize">
                <tr className="border border-black text-sm">
                  <td
                    style={{ width: "20%" }}
                    className="text-center border-r border-black "
                  >
                    party name
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    Challan No.
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    design no.
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    Job rate
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    date
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    card no.
                  </td>
                </tr>
                <tr className="text-sm">
                  <td className="text-center border-x border-black">
                    {matchingCard?.party?.name}
                  </td>
                  <td className="text-cente border-r border-black">
                    {matchingCard?.challanNo}
                  </td>
                  <td className="text-center border-r border-b border-black">
                    {matchingCard?.designNo}
                  </td>
                  <td className="text-center border-r border-b border-black">
                    {matchingCard?.jobRate}
                  </td>
                  <td className="text-center border-r border-black">
                    {moment(matchingCard?.date).format("DD/MM/YYYY")}
                  </td>
                  <td className="text-center border-b border-r border-black">
                    {matchingCard?.invoiceNo}
                  </td>
                </tr>
              </thead>
            </table>
            <table className="w-full border-collapse bg-white text-center mt-5">
              <thead className="capitalize border border-black">
                <tr className="border border-black text-sm">
                  <td className="text-center w-20 border-r-2 border-t-2 border-l-2 border-black">
                    Color
                  </td>
                  {matchingCard?.fabricColors?.map((item: any, index: any) => {
                    return (
                      <td className="text-center w-20 border-r border-black">
                        {item?.fabricColorId?.colorName}
                      </td>
                    );
                  })}
                </tr>
                <tr className="border border-black text-sm">
                  <td className="text-center border-r-2 border-l-2 border-b-2 w-20 border-black">
                    Pcs
                  </td>
                  {matchingCard?.fabricColors?.map((item: any, index: any) => {
                    return (
                      <td className="text-center w-20 border-r border-black">
                        {item?.PM}
                      </td>
                    );
                  })}
                </tr>
              </thead>
            </table>
            <table className="w-full border-collapse bg-white text-center mt-5">
              <thead className="capitalize border border-black">
                <tr className="border border-black text-sm">
                  <td
                    style={{ width: "33.33%" }}
                    className="text-center border-r border-black"
                  >
                    Fabrics
                  </td>
                  <td
                    style={{ width: "33.33%" }}
                    className="text-center border-r border-black"
                  >
                    Fabrics Party
                  </td>
                  <td
                    style={{ width: "33.33%" }}
                    className="text-center border-r border-black"
                  >
                    Total Pcs
                  </td>
                </tr>
                <tr className="text-sm">
                  <td className="text-center border-x border-black">
                    {matchingCard?.clothe?.name}
                  </td>
                  <td className="text-cente border-r border-black">
                    {matchingCard?.clotheParty?.name}
                  </td>
                  <td className="text-center border-r border-b border-black">
                    {matchingCard?.TPPM}
                  </td>
                </tr>
              </thead>
            </table>

            {matchingCard?.type == "saree" ? (
              <table className="w-full border-collapse bg-white text-center mt-5 ">
                <thead className="capitalize border border-black">
                  <tr className="border border-black text-sm">
                    <td
                      style={{ width: "33.33%" }}
                      className="text-center border-r border-black"
                    >
                      Cut
                    </td>
                    <td
                      style={{ width: "33.33%" }}
                      className="text-center border-r border-black"
                    >
                      Mtr Rate
                    </td>
                    <td
                      style={{ width: "33.33%" }}
                      className="text-center border-r border-black"
                    >
                      Fabric Saree Rate
                    </td>
                  </tr>
                  <tr className="text-sm">
                    <td className="text-center border-x border-black">
                      &nbsp;
                    </td>
                    <td className="text-cente border-r border-black">&nbsp;</td>
                    <td className="text-center border-r border-b border-black">
                      &nbsp;
                    </td>
                  </tr>
                </thead>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    );
  }


  function Cheking() {
    let colors: any = [];
    matchingCard?.fabricColors?.forEach((item: any) => {
      colors.push(item?.fabricColorId?.colorName);
    });
    return (
      <div ref={exportCheking} className={`a4-portrait overflow-hidden`}>
        <div className="h-full">
          <div className="mt-2 text-xs">

            <table className="w-full border-collapse bg-white text-center border border-black mb-3">
              <thead className="capitalize">
                <tr className="border border-black text-base h-10">
                  <td
                    style={{ width: "20%" }}
                    className="text-center border-r border-black "
                  >
                    party name
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    design no.
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    date
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    Challan No.
                  </td>
                  <td
                    style={{ width: "16%" }}
                    className="text-center border-r border-black "
                  >
                    card no.
                  </td>
                </tr>
                <tr className="text-base h-10">
                  <td className="text-center border-x border-black font-bold">
                    {matchingCard?.party?.name}
                  </td>
                  <td className="text-center border-r border-b border-black font-bold">
                    {matchingCard?.designNo}
                  </td>
                  <td className="text-center border-r border-black font-bold">
                    {moment(matchingCard?.date).format("DD/MM/YYYY")}
                  </td>
                  <td className="text-cente border-r border-black font-bold">
                    {matchingCard?.challanNo}
                  </td>
                  <td className="text-center border-b border-r border-black font-bold">
                    {matchingCard?.invoiceNo}
                  </td>
                </tr>
              </thead>
            </table>

            <table className="w-full border-collapse bg-white text-center">
              <tbody className="border border-black">
                <tr className="h-10">
                  <td
                    rowSpan={2}
                    className="text-center border-y border-r font-bold border-black w-16 text-lg"
                  >
                    Color
                  </td>
                  <td rowSpan={2} className="text-center border-y text-base border-r font-bold border-black w-12">
                    Pcs
                  </td>
                  <td colSpan={3} className="text-center border-l border-y border-black w-20 h-10">
                    {`Sign--------->`}
                  </td>
                  <td className="text-center border-l font-bold border-y border-black w-20">

                  </td>
                  <td className="text-center border-l font-bold border-y border-black w-20">

                  </td>
                  <td className="text-center border-l font-bold border-y border-black w-20">

                  </td>
                  <td className="text-center border-l font-bold border-y border-black w-20">

                  </td>
                  <td className="text-center border-l font-bold border-y border-black w-20">

                  </td>
                </tr>
                <tr className="text-base  h-10">
                  <td className="text-center border border-black text-base w-12">
                    Work
                  </td>
                  <td className="text-center border border-black text-base  w-12">
                    Plain
                  </td>
                  <td className="text-center border border-black text-base  w-12">
                    Short
                  </td>
                  <td className="text-center border border-black text-base">
                    धागा कटिंग
                  </td>
                  <td className="text-center border border-black text-base">
                    कच्छा चेकिग
                  </td>
                  <td className="text-center border border-black text-base">
                    अल्टर चेकिग
                  </td>
                  <td className="text-center border border-black text-base">
                    फाइनल चेकिग
                  </td>
                  <td className="text-center border border-black text-base">
                    हेंडवकॅ चेकिग
                  </td>
                </tr>
                {matchingCard?.fabricColors?.map(
                  (fabric: any, index: any) => {
                    return (
                      <tr key={index} className="h-[60px]">
                        <td
                          className={`text-right text-lg border-r border-b border-black font-bold capitalize pr-2 py-4.5`}
                          id="color"
                        >
                          {fabric?.fabricColorId?.colorName}
                        </td>
                        <td
                          className={`text-center border-b border-black capitalize text-lg`}
                        >
                          {fabric?.PM}
                        </td>
                        {fabric?.niddles?.map((niddle: any, index: any) => {
                          return niddle?.designCodeId2?.designCode ? (
                            <td className="text-center uppercase border-b border-l border-black font-bold">
                            </td>
                          ) : (
                            <td
                              key={index}
                              className="text-center uppercase h-12 border-b border-l border-black font-bold"
                            >
                            </td>
                          );
                        })}
                        {isFrame
                          ? fabric?.niddles?.length > 0
                            ? Array(Math.max(0, 7 - Number(fabric?.niddles?.length)))
                              ?.fill("-")
                              ?.map((row: any, index: number) => {
                                return (
                                  <td
                                    key={index}
                                    className="text-center h-12 border-b border-l border-black font-bold"
                                  >
                                    &nbsp;
                                  </td>
                                );
                              })
                            : null
                          : fabric?.niddles?.length > 0
                            ? Array(Math.max(0, 7 - Number(fabric?.niddles?.length)))
                              ?.fill("-")
                              ?.map((row: any, index: number) => {
                                return (
                                  <td
                                    key={index}
                                    className="text-center h-12 border-b border-l border-black font-bold"
                                  >
                                    &nbsp;
                                  </td>
                                );
                              })
                            : null}
                        {isFrame ? (
                          <td className="text-center border-b h-12 border-l border-black font-bold">
                          </td>
                        ) : null}
                      </tr>
                    );
                  }
                )}
                {matchingCard?.fabricColors?.length > 0 &&
                  Number(matchingCard?.fabricColors?.length) < 5
                  ? Array(Math.max(0, 12 - Number(matchingCard?.fabricColors?.length)))
                    ?.fill("-")
                    ?.map((fabric: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td className="border-r border-black font-bold">
                            &nbsp;
                          </td>
                          <td className={`border-black capitalize`}>
                            &nbsp;
                          </td>
                          {Array(8)
                            ?.fill("-")
                            ?.map(() => {
                              return (
                                <td className="border-l border-black"></td>
                              );
                            })}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>

            <div className={`text-xs grid grid-cols-12`}>
              <div className="col-span-8">
                <table className="w-full border-collapse bg-white text-center my-3">
                  <tbody>
                    <tr className="h-10">
                      <td className="text-center text-lg border-2 font-bold border-black w-[78px]">
                        टोटल
                      </td>
                      <td className="text-center text-lg border-2 font-bold border-black w-14">
                        {matchingCard?.fabricColors.reduce((total: any, item: any) => total + item.PM, 0)}
                      </td>
                      <td className="text-center text-lg border-2 font-bold border-black w-[60px]">

                      </td>
                      <td className="text-center text-lg border-2 font-bold border-black w-[58px]">

                      </td>
                      <td className="text-center text-lg border-2 font-bold border-black w-[58px]">

                      </td>
                      <td colSpan={4} className="text-center text-lg font-bold w-12">

                      </td>

                    </tr>
                  </tbody>
                </table>
                <table className="w-full border-collapse bg-white text-center my-2">
                  <tbody>
                    <tr className="h-10">
                      <td colSpan={12} className="text-center font-bold w-12 border border-black">
                      </td>
                    </tr>
                    <tr className="h-10">
                      <td colSpan={12} className="text-center font-bold w-12 border border-black">
                      </td>
                    </tr>
                    <tr className="h-10">
                      <td colSpan={12} className="text-center font-bold w-12 border border-black">
                      </td>
                    </tr>
                    <tr className="h-10">
                      <td colSpan={12} className="text-center font-bold w-12 border border-black">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-span-4 grid grid-cols-6 gap-4 ml-2">
                <div className="col-span-6">
                  <div className="grid grid-cols-5">
                    <div className="col-span-5 text-center z-30">
                      <span className="bg-white px-1">
                        {matchingCard?.image?.top}
                      </span>
                    </div>
                    <div className="flex items-center justify-center mr-2 z-30"></div>
                    <div className="col-span-3">
                      <div className="flex justify-center items-center">
                        {image ? (
                          <img
                            src={image}
                            className="object-contain max-h-50 min-h-50 w-52"
                            alt=""
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="flex items-center justify-start ml-2 z-30">
                      <span className="bg-white px-1">
                        {matchingCard?.image?.right}
                      </span>
                    </div>
                    <div className="col-span-5 text-center z-30">
                      <span className="bg-white px-1">
                        {matchingCard?.image?.bottom}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    );
  }


  return (
    <div>
      <Modal
        open={props.showMatchingPreview}
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{
          modal: "rounded-xl",
        }}
        onEscKeyDown={() => props.setShowMatchingPreview(false)}
        onOverlayClick={() => props.setShowMatchingPreview(false)}
        styles={{
          overlay: {
            height: "auto",
            width: "auto",
          },
          modalContainer: {
            overflow: "auto",
          },
        }}
        aria-labelledby="matching-card-pdf"
        aria-describedby="matching-card-pdf-description"
        animationDuration={400}
        onClose={() => props.setShowMatchingPreview(false)}
      >
        <div className="overflow-auto">
          <div className="text-center">
            <div className="bg-white border text-left shadow-xl">
              <div className="w-full rounded-xl overflow-hidden p-1">
                <div className="border rounded-xl md:rounded-full p-1.5 bg-gray-50 shadow-sm">
                  <ul
                    className="flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row px-2"
                    role="tablist"
                  >
                    <li
                      className={`${toggleState === 1
                        ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                        : "hover:bg-themeColor/5"
                        } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(1)}
                      >
                        front page
                      </button>
                    </li>
                    <li
                      className={`${toggleState === 2
                        ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                        : "hover:bg-themeColor/5"
                        } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(2)}
                      >
                        back page
                      </button>
                    </li>
                    {
                      userData?.role !== "designer" &&
                      <li
                        className={`${toggleState === 3
                          ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                          : "hover:bg-themeColor/5"
                          } flex-auto text-center rounded-full`}
                      >
                        <button
                          className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                          onClick={() => toggleTab(3)}
                        >
                          challan
                        </button>
                      </li>
                    }
                    <li
                      className={`${toggleState === 4
                        ? "border-b-2 border-themeColor bg-themeColor/13 font-bold"
                        : "hover:bg-themeColor/5"
                        } flex-auto text-center rounded-full`}
                    >
                      <button
                        className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`}
                        onClick={() => toggleTab(4)}
                      >
                        Cheking
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full h-full rounded-xl overflow-hidden bg-white">
                <div
                  className={`transition-opacity duration-150 ease-linear ${toggleState === 1 ? "opacity-100" : "opacity-0 hidden"
                    }`}
                >
                  <FrontPage />
                </div>
                <div
                  className={`transition-opacity duration-150 ease-linear ${toggleState === 2 ? "opacity-100" : "opacity-0 hidden"
                    }`}
                >
                  <BackPage />
                </div>
                <div
                  className={`transition-opacity duration-150 ease-linear ${toggleState === 3 ? "opacity-100" : "opacity-0 hidden"
                    }`}
                >
                  <Challan />
                </div>
                <div
                  className={`transition-opacity duration-150 ease-linear ${toggleState === 4 ? "opacity-100" : "opacity-0 hidden"
                    }`}
                >
                  <Cheking />
                </div>
                <div className="m-3 flex flex-col lg:flex-row gap-2">
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2"
                          disabled={loadingImage}
                        >
                          {loadingImage ? (
                            <>
                              <span>Loading</span>
                              <i className="fas fa-spinner animate-spin ml-2"></i>
                            </>
                          ) : (
                            <>
                              <span>Front Print</span>
                              <i className="fas fa-file-download ml-2"></i>
                            </>
                          )}
                        </button>
                      )}
                      content={() => exportFrontRef.current}
                      documentTitle={props.pageName + "-Front"}
                    />
                  </div>
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                          Back Print <i className="fas fa-file-download"></i>
                        </button>
                      )}
                      content={() => exportBackRef.current}
                      documentTitle={props.pageName + "-Back"}
                    />
                  </div>
                  {
                    userData?.role !== "designer" &&
                    <div>
                      <ReactToPrint
                        trigger={() => (
                          <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                            Challan Print <i className="fas fa-file-download"></i>
                          </button>
                        )}
                        content={() => exportChallanRef.current}
                        documentTitle={props.pageName + "-Back"}
                      />
                    </div>
                  }
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                          Cheking <i className="fas fa-file-download"></i>
                        </button>
                      )}
                      content={() => exportCheking.current}
                      documentTitle={props.pageName + "-Cheking"}
                    />
                  </div>
                  <div>
                    <button
                      className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                      onClick={() => props.setShowMatchingPreview(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
