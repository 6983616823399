import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../components/form/Select";
import TextBox from "../../components/form/TextBox";
import CreatableSelect from "react-select/creatable";
import { PartyAdd } from "../../components/modal/PartyAdd";
import { ClothAdd } from "../../components/modal/ClothAdd";
import { ItemAdd } from "../../components/modal/ItemAdd";
import {
  useLazyPartyQuery,
  useLazyClothQuery,
  useLazyItemQuery,
  useLazyFabricColorQuery,
  useLazyGetFabricColorQuery,
  useLazyGetClothQuery,
  useLazyGetItemQuery,
  useLazyGetPartyNameQuery,
  useLazyGetJobWorkNameQuery,
  useLazyNewMatchingInvoiceNumberQuery,
  useLazyGetMatchingCardQuery,
  useLazyGetDescQuery,
  useLazyDescQuery,
  useLazyChallanRemarksQuery,
  useLazyNewPartyChallanInvoiceNumberQuery,
  useAddPartyChallanMutation,
  useLazyPartyChallanQuery,
  useLazyGetPartyChallanQuery,
  useUpdatePartyChallanMutation,
} from "../../servicesRtkQuery/publicApi";
import { useSelector } from "react-redux";
import { FabricAdd } from "../../components/modal/FabricAdd";
import ObjectID from "bson-objectid";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import { NameAdd } from "../../components/modal/NameAdd";
import { createFilter } from "react-select";
import { DescAdd } from "../../components/modal/DescAdd";
import { ChallanRemarksAdd } from "../../components/modal/ChallanRemarksAdd";
import { selectFilterConfig } from "../../utils/globalFunctions";
import moment from "moment";
import { PartyChallanPreview } from "../../components/pdf/challan/partyChallanPreview";

export default function PartyChallan() {
  const [isChallanUpdate, setIsChallanUpdate] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsChallanUpdate(true);
    } else {
      setIsChallanUpdate(false);
    }
  }, [id]);
  // Redux Data State
  const partyChallansData = useSelector(
    (state: any) => state.partyChallanSlice.partyChallan
  );



  // Global Data State
  const [cardData, setCardData]: any = useState();
  const [partyChallanData, setPartyChallanData]: any = useState();


  const [partyChallanTableData, setPartyChallanTableData] = useState<any>([]);


  const [selectedJobRow, setSelecetdJobRow]: any = useState("");

  const [trigger, result] = useLazyGetJobWorkNameQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [clothTrigger, clothResult] = useLazyClothQuery();
  const {
    isSuccess: isClothSuccess,
    isFetching: isClothFetching,
    isError: isClothError,
    error: clothError,
  } = clothResult;



  const [descTrigger, descResult] = useLazyDescQuery();
  const {
    isSuccess: isDescSuccess,
    isFetching: isDescFetching,
    isError: isDescError,
    error: descError,
  } = descResult;

  const [matchingInvoiceTrigger, matchingInvoiceResult] =
    useLazyNewMatchingInvoiceNumberQuery();
  const {
    isSuccess: isMatchingInvoiceSuccess,
    isFetching: isMatchingInvoiceFetching,
    isError: isMatchingInvoiceError,
    error: matchingInvoiceerror,
  } = matchingInvoiceResult;

  const [matchingTrigger, matchingResult] = useLazyGetMatchingCardQuery();
  const {
    isSuccess: isMatchingSuccess,
    isFetching: isMatchingFetching,
    isError: isMatchingError,
    error: matchingError,
  } = matchingResult;

  const [nameTrigger, nameResult] = useLazyGetJobWorkNameQuery();
  const {
    isSuccess: isNameSuccess,
    isFetching: isNameFetching,
    isError: isNameError,
    error: nameError,
  } = nameResult;

  const [fabricTrigger, fabricResult] = useLazyFabricColorQuery();
  const {
    isSuccess: isFabricSuccess,
    isFetching: isFabricFetching,
    isError: isFabricError,
    error: fabricError,
  } = fabricResult;

  const [getClothTrigger, getClothResult] = useLazyGetClothQuery();
  const {
    isSuccess: isgetClothSuccess,
    isFetching: isgetClothFetching,
    isError: isgetClothError,
    error: getClothError,
  } = getClothResult;

  const [getFabricColorTrigger, getFabricColorResult] =
    useLazyGetFabricColorQuery();
  const {
    isSuccess: isgetFabricColorSuccess,
    isFetching: isgetFabricColorFetching,
    isError: isgetFabricColorError,
    error: getFabricColorError,
  } = getFabricColorResult;

  const [invoiceTrigger, invoiceResult] = useLazyNewPartyChallanInvoiceNumberQuery();
  const {
    isSuccess: isInvoiceSuccess,
    isFetching: isInvoiceFetching,
    isError: isInvoiceError,
    error: invoiceError,
  } = invoiceResult;

  const [challanTrigger, challanResult] = useLazyGetPartyChallanQuery();
  const {
    isSuccess: isChallanSuccess,
    isFetching: isChallanFetching,
    isError: isChallanError,
    error: challanError,
  } = challanResult;

  const [getNameTrigger, getNameResult] = useLazyGetJobWorkNameQuery();
  const {
    isSuccess: isgetNameSuccess,
    isFetching: isgetNameFetching,
    isError: isgetNameError,
    error: getNameError,
  } = getNameResult;

  const [showPreview, setShowPreview] = useState(false);
  const [savedId, setSavedId]: any = useState("");

  const [allTrigger, allResult] = useLazyPartyChallanQuery();
  const {
    isSuccess: isAllSuccess,
    isFetching: isAllFetching,
    isError: isAllError,
    error: allError,
  } = allResult;




  useEffect(() => {
    clothTrigger("");
    fabricTrigger("");
    invoiceTrigger("");
    getNameTrigger("");
    nameTrigger("");
    if (id) {
      challanTrigger(id);
    }

  }, []);
  const navigate = useNavigate();


  // Party Creatable State
  const [showAddParty, setShowAddParty] = useState(false);
  const [partyNameAdd, setPartyNameAdd] = useState("");
  const [Party, setParty]: any = useState();
  const [selectedParty, setSelectedParty]: any = useState({});

  // Cloth Creatable State
  const [showAddCloth, setShowAddCloth] = useState(false);
  const [clothAdd, setClothAdd] = useState("");
  const [Cloth, setCloth]: any = useState<any>([]);
  const [selectedCloth, setSelectedCloth]: any = useState({});
  const [clothState, setClothState]: any = useState({});

  // Reamrks Creatable State
  const [showAddRemarks, setShowAddRemarks] = useState(false);
  const [remarksAdd, setRemarksAdd] = useState("");
  const [Remarks, setRemarks]: any = useState([]);
  const [selectedRemarks, setSelectedRemarks]: any = useState({});
  const [remarksState, setRemarksState]: any = useState({});

  // Item Creatable State
  const [showAddItem, setShowAddItem] = useState(false);
  const [itemAdd, setItemAdd] = useState("");
  const [Item, setItem]: any = useState<any>([]);
  const [selectedItem, setSelectedItem]: any = useState({});
  const [itemState, setItemState]: any = useState({});

  // Item Creatable State
  const [showAddDesc, setShowAddDesc] = useState(false);
  const [descAdd, setDescAdd] = useState("");
  const [Desc, setDesc]: any = useState<any>([]);
  const [selectedDesc, setSelectedDesc]: any = useState({});
  const [descState, setDescState]: any = useState({});

  // Name Creatable State
  const [showAddName, setShowAddName] = useState(false);
  const [nameAdd, setNameAdd] = useState("");
  const [Name, setName]: any = useState<any>([]);
  const [addressState, setAddressState]: any = useState<any>([]);
  const [address, setAddress]: any = useState<any>();
  const [selectedName, setSelectedName]: any = useState({});
  const [nameState, setNameState]: any = useState({});
  const [selectedCardNo, setSelectedCardNo]: any = useState({});
  const [options, setOptions]: any = useState([]);

  // Fabric Creatable State
  const [showAddFabric, setShowAddFabric] = useState(false);
  const [fabricAdd, setFabricAdd] = useState("");
  const [Fabric, setFabric]: any = useState([]);
  const [selectedFabric, setSelectedFabric]: any = useState({});
  const [fabricState, setFabricState]: any = useState({});
  const [partyChallanNo, setPartyChallanNo]: any = useState<any>([]);


  const [images, setImages]: any = useState();
  const [selectedPartyChallanNo, setSelectedPartyChallanNo]: any = useState("");


  // Is SampleCard Update State
  const [isPartyUpdate, setIsPartyUpdate] = useState(false);


  //Total States
  const [pcsTotal, setPcsTotal]: any = useState(0);

  // error message state
  const [errorMessage, setErrorMessage]: any = useState({});

  const [updateChallan, updateResult] = useUpdatePartyChallanMutation();
  const [addChallan, addResult] = useAddPartyChallanMutation();
  // reference props
  const jobWorkRef: any = useRef(null);


  useEffect(() => {
    let date = document.getElementById("date") as HTMLInputElement | any;
    let today = new Date() as any;
    let month = today.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

    date.value = today.getFullYear() + "-" + month + "-" + day;
    if (isChallanUpdate) {
      if (partyChallansData?.partyChallan) {
        let apiData = partyChallansData?.partyChallan?.filter((item: any) => {
          return item._id === id;
        })[0];
        if (apiData) {
          setCardData({
            ...apiData,
            date: apiData?.date
              ? apiData?.date
              : today.getFullYear() + "-" + month + "-" + day,
          });
          setAddress(apiData?.cName?.address);
          setSelectedName({
            label: apiData?.challanName?.name,
            value: apiData?.challanName?._id,
          });
                    setSelectedCardNo({
            label: apiData?.card?.number,
            value: apiData?.card?._id,
          });
          setType(apiData?.type);
          matchingInvoiceTrigger({ type: apiData?.type?.toLowerCase() });
          setSelectedItem({
            label: apiData?.item?.name,
            value: apiData?.item?._id,
          });
          setSelectedParty({
            label: apiData?.party?.name,
            value: apiData?.party?._id,
          });
          let jobData: any = [];
          apiData?.work?.forEach((item: any) => {
            let object = {
              _id: item?._id,
              clotheId: item?.clothe?._id,
              fabricColor: item?.fabricColor?._id,
              designNo: item?.designNo || null,
              pcs: item?.pcs,
              remark: item?.remark || null,
              rate: item?.rate || null

            };
            jobData.push(object);
          });

          setPartyChallanTableData(jobData);
        } else {
          challanTrigger(id);
        }

      } else {
        challanTrigger(id);
      }
    } else {
      setCardData({});
      setCardData({
        ...cardData,
        date: today.getFullYear() + "-" + month + "-" + day,
      });
    }
  }, [fabricState]);

  useEffect(() => {
    if (isChallanUpdate) {
      if (isChallanSuccess && !isChallanFetching) {
        let apiData = challanResult?.data?.data?.partyChallan?.[0];
        setAddress(apiData?.cName?.address);
        setCardData(apiData);
        
        setSelectedCardNo({
          label: apiData?.card?.number,
          value: apiData?.card?._id,
        });
        setType(apiData?.type);
        matchingInvoiceTrigger({ type: apiData?.type?.toLowerCase() });
        setSelectedItem({
          label: apiData?.item?.name,
          value: apiData?.item?._id,
        });
        setSelectedName({
          label: apiData?.challanName?.name,
          value: apiData?.challanName?._id,
        });
        let jobData: any = [];
        apiData?.work?.forEach((item: any) => {
          let object = {
            _id: item?._id,
            clotheId: item?.clothe?._id,
            fabricColorId: item?.fabricColor?._id,
            designNo: item?.designNo || null,
            pcs: item?.pcs,
            remark: item?.remark || null,
            rate: item?.rate || null
          };
          jobData.push(object);
        });

        setPartyChallanTableData(jobData);
      }
    }
  }, [isChallanSuccess, isChallanFetching]);


  useEffect(() => {
    if (isSuccess && !isFetching) {


      let getAllParty = result.data?.data?.party.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });

      setParty(getAllParty ? getAllParty : []);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isAllSuccess && !isAllFetching) {
      if (!isChallanUpdate) {
        navigate(`/jobwork/partychallan/${savedId}`);
      }
    }
  }, [isAllSuccess, isAllFetching]);


  useEffect(() => {
    if (isgetNameSuccess && !isgetNameFetching) {
      let name = getNameResult.data?.data?.challanName.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (name) {
        setName(name);
      }
    }
  }, [isgetNameSuccess, isgetNameFetching]);

  useEffect(() => {
    if (isInvoiceSuccess && !isInvoiceFetching) {
      var data: any = [];
      invoiceResult &&
        invoiceResult?.data?.data?.map((invoice: any) => {
          if (id) {
            // If Updating Challan
            if (invoice._id) {
              data.push(invoice.pcNo);
              if (id === invoice?._id) {
                setSelectedPartyChallanNo(invoice.pcNo);
              }

            }
          } else {
            // If Adding Challan
            data.push(invoice.pcNo);
            setSelectedPartyChallanNo(data[0]);

          }
        });
      setPartyChallanNo(data);
    }
  }, [isInvoiceSuccess, isInvoiceFetching]);


  useEffect(() => {
    if (isClothSuccess && !isClothFetching) {
      let getAllCloth = clothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setCloth(getAllCloth ? getAllCloth : []);
      let clothes: any = [];
      getAllCloth?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        clothes[id] = label;
      });

      setClothState(clothes);
    }
  }, [isClothSuccess, isClothFetching]);

  useEffect(() => {
    if (isFabricSuccess && !isFabricFetching) {
      let getAllFabric = fabricResult.data?.data?.fabricColor?.map(
        ({ _id, colorName }: any) => {
          return {
            label: colorName,
            value: _id,
          };
        }
      );

      setFabric(getAllFabric ? getAllFabric : []);

      let fabricColors: any = {};

      getAllFabric?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        fabricColors[id] = label;
      });

      setFabricState(fabricColors);
    }
  }, [isFabricSuccess, isFabricFetching]);


  useEffect(() => {
    if (isDescSuccess && !isDescFetching) {
      let getAllDesc = descResult.data?.data?.description.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setDesc(getAllDesc ? getAllDesc : []);
      let items: any = {};

      getAllDesc?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        items[id] = label;
      });

      setDescState(items);
    }
  }, [isDescSuccess, isDescFetching]);

  useEffect(() => {
    if (isNameSuccess && !isNameFetching) {
      let getAllname: any = [];
      let allAddress: any = {};
      nameResult.data?.data?.challanName.forEach((item: any) => {
        let object: any = {
          label: item?.name,
          value: item?._id,
        };
        getAllname.push(object);
        allAddress[item?.name] = item?.address;
      });
      setName(getAllname ? getAllname : []);
      setAddressState(allAddress);
      let names: any = {};

      getAllname?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        names[id] = label;
      });

      setNameState(names);
    }
  }, [isNameSuccess, isNameFetching]);

  useEffect(() => {
    if (isgetClothSuccess && !isgetClothFetching) {
      let cloth = getClothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (cloth) {
        setCloth(cloth);
      }
    }
  }, [isgetClothSuccess, isgetClothFetching]);

  const [type, setType]: any = useState<any>(null);

  useEffect(() => {
    if (isMatchingInvoiceSuccess && !isMatchingInvoiceFetching) {
      var invoices: any = [];
      matchingInvoiceResult &&
        matchingInvoiceResult.data?.data?.map((invoice: any) => {
          if (invoice?._id) {
            let option: any = {
              label: invoice?.invoiceNo + "-" + invoice?.cardNo,
              value: invoice?._id,
            };
            invoices.push(option);
          }
        });

      // Move setOptions and console.log inside the if block
      setOptions(invoices);
    }
  }, [
    isMatchingInvoiceSuccess,
    isMatchingInvoiceFetching,
    matchingInvoiceResult,
  ]);
  useEffect(() => {
    if (isMatchingSuccess && !isMatchingFetching) {
      let matchingData = matchingResult?.data?.data?.matchingCard?.[0];

      setCardData({
        ...cardData,
        designNo: matchingData?.designNo,
        PCNo: matchingData?.challanNo,
      });

      setSelectedParty({
        label: matchingData?.party?.name,
        value: matchingData?.party?._id,
      });

      let jobData: any = [];
      matchingData?.fabricColors?.forEach((item: any) => {
        let object = {
          _id: item?._id,
          clotheId: matchingData?.clothe?._id,
          fabricColorId: item?.fabricColorId?._id,
          PM: "",
        };
        jobData.push(object);
      });
      setPartyChallanTableData(jobData);
    }
  }, [isMatchingSuccess, isMatchingFetching]);


  useEffect(() => {
    let total = 0;
    partyChallanTableData.forEach((item: any) => {
      total = total + item?.pcs;
    });
    setPcsTotal(total);
  }, [partyChallanTableData]);


  const getData = (data: any) => {
    setSelectedName({
      label: data.name,
      value: data._id
    });
    setName([...Name, {
      label: data.name,
      value: data._id
    }]);
    setShowAddParty(false);
    setErrorMessage({
      ...errorMessage,
      name: ""
    });
  };

  const getClothData = (data: any) => {
    setSelectedCloth(data);
    setCloth([...Cloth, data]);
    setShowAddCloth(false);
  };

  const getItemData = (data: any, type: any) => {
    setSelectedItem(data);
    setItem([...Item, data]);
    setShowAddItem(false);
  };
  const getDescData = (data: any, type: any) => {
    setSelectedDesc(data);
    setDesc([...Desc, data]);
    setShowAddDesc(false);
  };

  const getFabricData = (data: any) => {
    setSelectedFabric(data);
    setFabric([...Fabric, data]);
    setShowAddFabric(false);
  };

  const getNameData = (data: any) => {
    setSelectedName(data);
    setName([...Name, data]);
    setShowAddName(false);
  };

  const getRemarksData = (data: any, type: any) => {
    setSelectedRemarks(data);
    setRemarks([...Remarks, data]);
    setShowAddRemarks(false);
  };

  const handleChange = (e: any, type: any = null) => {
    switch (type) {
      case "party":
        let selectParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedParty(selectParty);
        break;
      case "cloth":
        let selectedCloth = {
          label: e.label,
          value: e.value,
        };
        setSelectedCloth(selectedCloth);
        break;
      case "description":
        let selectedDesc = {
          label: e.label,
          value: e.value,
        };
        setSelectedDesc(selectedDesc);
        break;
      case "remarks":
        let remarks = {
          label: e.label,
          value: e.value,
        };
        setSelectedRemarks(remarks);
        break;
      case "item":
        let selectedItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedItem(selectedItem);
        break;
      case "name":
        let selectedName = {
          label: e.label,
          value: e.value,
        };
        setSelectedName(selectedName);
        setAddress(addressState[e.label]);
        break;
      case "fabricColor":
        let selectedFabric = {
          label: e.label,
          value: e.value,
        };
        setSelectedFabric(selectedFabric);
        break;
      default:
    }
  };

  const handleChanges = (e: any) => {
    setCardData({
      ...cardData,
      [e.target.name]: e.target.value,
    });
  };


  const handleJobChanges = (e: any) => {
    setPartyChallanData({
      ...partyChallanData,
      [e.target.name]: e.target.value,
    });
  };

  const [isUpdating, setIsUpdating]: any = useState(false);
  const updateData = () => {
    let status = false;
    if (Object?.keys(selectedName)?.length === 0) {
      errorData["name"] = "Name is Required";
      status = true;
    } else {
      errorData["name"] = "";
    }
    if (
      moment(cardData?.date).format("YYYY-MM-DD") >
      moment(cardData?.receiveDate).format("YYYY-MM-DD")
    ) {
      errorData["receiveDate"] = "receiveDate is Invalid";
      status = true;
    } else {
      errorData["receiveDate"] = "";
    }
    if (partyChallanTableData?.length === 0) {
      errorData["jobwork"] = "Party Challan is Required";
      status = true;


    } else {
      errorData["jobwork"] = "";
    }
    setErrorMessage(errorData);

    if (!status) {
      setIsUpdating(true);

      // Transform partyChallanTableData to match required schema
      const workData = partyChallanTableData.map((item: any) => ({
        _id: item._id,
        remark: item.remark || null,
        designNo: item.designNo,
        clotheId: item.clotheId,
        fabricColorId: item.fabricColorId,

        pcs: Number(item.pcs),
        rate: item.rate ? Number(item.rate) : null
      }));

      let finalData = {
        pcNo: selectedPartyChallanNo,
        nameId: selectedName.value,
        date: cardData?.date,
        receiveDate: cardData?.receiveDate || null,
        work: workData,
        receivePcs: cardData?.receivePcs ? Number(cardData?.receivePcs) : null
      };


      updateChallan({
        payload: finalData,
        query: {
          pcId: id,
        },
      }).then((data: any) => {
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Challan Successfully Updated !!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          allTrigger();
        }
      });
    }
  };
  let errorData = {
    name: "",
    cardNo: "",
    PCNo: "",
    designNo: "",
    rate: "",
    jobwork: "",
    cloth: "",
    fabricColor: "",
    pcs: "",
    receiveDate: "",
    receivePcs: "",
  };


  const addData = () => {
    let status = false;
    if (!selectedName?.value) {
      errorData["name"] = "Name is Required";
      status = true;
    } else {
      errorData["name"] = "";
    }
    if (
      moment(cardData?.date).format("YYYY-MM-DD") >
      moment(cardData?.receiveDate).format("YYYY-MM-DD")
    ) {
      errorData["receiveDate"] = "receiveDate is Invalid";
      status = true;
    } else {
      errorData["receiveDate"] = "";
    }
    if (partyChallanTableData?.length === 0) {
      errorData["jobwork"] = "Party Challan is Required";
      status = true;
    } else {
      errorData["jobwork"] = "";
    }

    setErrorMessage(errorData);

    if (!status) {
      setIsUpdating(true);

      // Transform partyChallanTableData to match required schema
      const workData = partyChallanTableData.map((item: any) => ({
        _id: item._id,
        remark: item.remark || null,
        designNo: item.designNo,
        clotheId: item.clotheId,
        fabricColorId: item.fabricColorId,
        pcs: Number(item.pcs),
        rate: item.rate ? Number(item.rate) : null
      }));

      let finalData = {
        pcNo: selectedPartyChallanNo,
        nameId: selectedName.value,
        date: cardData?.date,
        receiveDate: cardData?.receiveDate || null,
        work: workData,
        receivePcs: cardData?.receivePcs ? Number(cardData?.receivePcs) : null

      };


      addChallan(finalData).then((data: any) => {
        setSavedId(data?.data?.data?.insertedIds[0]);
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Challan Successfully Added!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          // allTrigger();
        }
      });
    }
  };

  const openInvoicePreview = () => {
    setShowPreview(true);
  };

  return (
    <div>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="invisible mr-6 lg:visible xl:visible sticky top-5 z-90">
          <nav className="absolute ml-0 sm:ml-0 md:ml-0 lg:ml-0 sm:top-0 md:top-0 lg:top-0 left-6 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
            <div className="flex gap-5 w-full justify-between items-center">
              <div className="px-3 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                <span className="text-xl">Party Challan</span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 w-2/5">
                <div className="w-1/2">
                  <Select
                    id="pcNo"
                    name="pcNo"
                    htmlFor="pcNo"
                    label="Party Challan No."
                    disabled
                    options={partyChallanNo}
                    value={selectedPartyChallanNo}
                  />
                </div>
              </div>
              <div className="px-3 w-2/5 flex justify-end">
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={showPreview}
                  onClick={openInvoicePreview}
                >
                  <i className="fa fa-download"></i>
                  <span className="ml-2">Print</span>
                </button>
                <button
                  className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={isUpdating}
                  onClick={isChallanUpdate ? updateData : addData}
                >
                  {isUpdating ? (
                    <i className="fa fa-spinner animate-spin"></i>
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  <span className="ml-2">
                    {isChallanUpdate ? "Update" : "Save"}
                  </span>
                </button>
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    navigate({
                      pathname: `/partychallandashboard`,
                    });
                  }}
                >
                  <i className="fa fa-close"></i>
                  <span className="ml-2">Cancel</span>
                </button>
              </div>
            </div>
          </nav>
        </div>

        <div className="flex mt-0 bg-themeBgColor lg-mt-19 xl:mt-19 items-center p-0 overflow-hidden bg-cover">
          <div className="w-full mx-6">
            <div className="w-full h-full rounded-xl bg-white px-2">
              <div className="p-2 lg:p-0 xl:p-0 lg:hidden visible xl:hidden lg:invisible xl:invisible">
                <div className="mt-2 md:mt-0">
                  <Select
                    id="pcNo"
                    name="pcNo"
                    htmlFor="pcNo"
                    label="Party Challan No."
                    disabled
                    options={partyChallanNo}
                    value={selectedPartyChallanNo}

                  />
                </div>
              </div>

              <div className="p-2 pb-0">
                <div>
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-4 md:pt-3 pb-0">
                    <div>
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="name"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block z-40 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 "
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "name");
                              setErrorMessage({
                                ...errorMessage,
                                name: "",
                              });
                            } else {
                              setSelectedName({});
                            }
                          }}
                          classNames={{
                            control: () =>
                              errorMessage?.name
                                ? "border-red-600"
                                : "border-gray-500 ",
                          }}

                          onCreateOption={(data: any) => {
                            setNameAdd(data);
                            setShowAddName(true);
                          }}
                          options={Name && Name}
                          value={selectedName}
                          menuPosition="fixed"
                        />
                        <label
                          htmlFor="name"
                          className={`absolute z-40 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.name
                            ? "text-red-500"
                            : "text-gray-500"
                            }`}
                        >
                          Name
                        </label>
                      </div>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.name}
                      </span>
                    </div>

                    <TextBox
                      id="date"
                      type="date"
                      name="date"
                      htmlFor="date"
                      label="Date"
                      tabIndex={-1}
                      placeholder=" "
                      value={cardData?.date}
                      onChange={(e: any) => handleChanges(e)}
                    />

                    <div>
                      <TextBox
                        id="receiveDate"
                        type="date"
                        name="receiveDate"
                        htmlFor="receiveDate"
                        label="Receive Date"
                        placeholder=" "
                        value={cardData?.receiveDate}
                        onChange={(e: any) => handleChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.receiveDate}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="receivePcs"
                        name="receivePcs"
                        htmlFor="receivePcs"
                        label="Receive Piece"
                        type="number"
                        placeholder=" "
                        value={cardData?.receivePcs}

                        onChange={(e: any) => handleChanges(e)}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.receivePcs}
                      </span>
                    </div>
                  </div>

                  <div>
                    <fieldset
                      className={`border-2 mt-2 rounded-2 border-gray-400 p-2 ${errorMessage?.jobwork
                        ? "border-red-500"
                        : "border-gray-400"
                        }`}
                    >
                      <legend
                        className={`capitalize px-2 ${errorMessage?.jobwork ? "text-red-500" : ""
                          }`}
                      >
                        Job Work
                      </legend>
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-7">
                        <div>
                          <TextBox
                            htmlFor="remark"
                            id="remark"
                            name="remark"
                            label="Remark"
                            type="text"
                            placeholder=" "
                            value={partyChallanData?.remark}
                            onChange={handleJobChanges}
                          />
                        </div>


                        <div>
                          <TextBox
                            htmlFor="designNo"
                            id="designNo"
                            name="designNo"
                            label="Design No"
                            type="text"
                            placeholder=" "
                            required
                            value={partyChallanData?.designNo}
                            onChange={handleJobChanges}
                          />

                        </div>
                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="cloth"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-themeColor"
                            classNames={{
                              control: () =>
                                errorMessage?.cloth
                                  ? "border-red-600"
                                  : "border-gray-500",
                            }}
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "cloth");
                                setErrorMessage({
                                  ...errorMessage,
                                  cloth: "",
                                });
                              } else {
                                setSelectedCloth({});
                              }
                            }}
                            onCreateOption={(data: any) => {
                              setClothAdd(data);
                              setShowAddCloth(true);
                            }}
                            options={Cloth}
                            value={selectedCloth}
                            menuPosition="fixed"
                          />
                          <label
                            htmlFor="cloth"
                            className={`absolute text-md text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.cloth
                              ? "text-red-500"
                              : "text-gray-500"
                              }`}
                          >
                            Fabric
                          </label>
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.cloth}
                          </span>
                        </div>
                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="fabricColor"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            classNames={{
                              control: () =>
                                errorMessage?.fabricColor
                                  ? "border-red-600"
                                  : "border-grey-300",
                            }}
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "fabricColor");
                                setErrorMessage({
                                  ...errorMessage,
                                  fabricColor: "",
                                });
                              } else {
                                setSelectedFabric({});
                              }
                            }}
                            onCreateOption={(data: any) => {
                              setFabricAdd(data);
                              setShowAddFabric(true);
                            }}
                            options={Fabric}
                            value={selectedFabric}
                            menuPosition="fixed"
                          />
                          <label
                            htmlFor="item"
                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.fabricColor
                              ? "text-red-500"
                              : "text-gray-500"
                              }`}
                          >
                            Fabric Color
                          </label>
                          <span className="text-sm text-red-500 flex justify-start">
                            {errorMessage?.fabricColor}
                          </span>
                        </div>


                        <div>
                          <TextBox
                            htmlFor="pcs"
                            id="pcs"
                            name="pcs"
                            label="Pieces"
                            type="number"
                            placeholder=" "
                            required
                            value={partyChallanData?.pcs}
                            onChange={handleJobChanges}


                          />
                        </div>
                        <div>
                          <TextBox
                            htmlFor="rate"
                            id="rate"
                            name="rate"
                            label="Rate"
                            type="number"
                            placeholder=" "
                            value={partyChallanData?.rate}
                            onChange={handleJobChanges}
                          />

                        </div>



                        <button
                          className="w-full px-4 py-2 text-sm leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                          onClick={() => {
                            let errorData = {
                              cloth: "",
                              fabricColor: "",
                              pcs: "",

                            };

                            // Validate required fields
                            if (Object.keys(selectedCloth)?.length === 0) {
                              errorData.cloth = "Fabric is Required";
                            }
                            if (Object.keys(selectedFabric)?.length === 0) {
                              errorData.fabricColor = "Fabric Color is Required";
                            }
                            if (!partyChallanData?.pcs) {
                              errorData.pcs = "Pcs is Required";
                            }



                            setErrorMessage(errorData);

                            // Only proceed if no validation errors
                            if (!errorData.cloth && !errorData.fabricColor && !errorData.pcs) {
                              if (isPartyUpdate) {
                                // Handle update
                                const record = {
                                  _id: selectedJobRow,
                                  clotheId: selectedCloth.value,
                                  fabricColorId: selectedFabric.value,
                                  pcs: Number(partyChallanData.pcs),
                                  remark: partyChallanData?.remark || null,
                                  designNo: partyChallanData?.designNo || null,
                                  rate: partyChallanData?.rate ? Number(partyChallanData.rate) : null,



                                };

                                const elementsIndex = partyChallanTableData.findIndex(
                                  (element: any) => element._id === selectedJobRow
                                );


                                let newArray = [...partyChallanTableData];
                                newArray[elementsIndex] = record;
                                setPartyChallanTableData(newArray);


                              } else {
                                // Handle add new
                                const record = {
                                  _id: ObjectID().toHexString(),
                                  clotheId: selectedCloth.value,
                                  fabricColorId: selectedFabric.value,
                                  pcs: Number(partyChallanData.pcs),
                                  remark: partyChallanData?.remark || null,
                                  designNo: partyChallanData?.designNo || null,
                                  rate: partyChallanData?.rate ? Number(partyChallanData.rate) : null,



                                };

                                setPartyChallanTableData([...partyChallanTableData, record]);
                              }


                              // Reset form after successful add/update
                              setPartyChallanData({
                                pcs: "",
                                designNo: "",
                                rate: "",
                                remark: "",
                              });


                              setSelectedFabric({});
                              setSelectedCloth({});
                              setSelectedRemarks({});
                              setIsPartyUpdate(false);


                            }
                          }}
                        >
                          {isPartyUpdate ? "Update Party Challan" : "Add Party Challan"}
                        </button>
                      </div>

                      {/* Table */}
                      <div className="grid grid-cols-1 mt-2">
                        <div className="border border-gray-300 rounded-3 overflow-hidden">
                          <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="min-w-full sm:px-6 lg:px-0">
                                <div className="overflow-hidden">
                                  <div className="flex-auto px-0 pt-0">
                                    <div className="p-0 overflow-hidden">
                                      {partyChallanTableData &&
                                        partyChallanTableData.length > 0 ? (
                                        <div className="max-h-80 overflow-auto shadow-md">
                                          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">

                                            <thead className="bg-gray-50 capitalize">
                                              <tr>
                                                <th scope="col" className="px-6 py-3 font-bold text-gray-900">
                                                  Sr No.
                                                </th>
                                                <th scope="col" className="px-6 py-3 font-bold text-gray-900">
                                                  Remark
                                                </th>
                                                <th scope="col" className="px-6 py-3 font-bold text-gray-900">
                                                  Design No
                                                </th>
                                                <th scope="col" className="px-6 py-3 font-bold text-gray-900">
                                                  Fabric
                                                </th>
                                                <th scope="col" className="px-6 py-3 font-bold text-gray-900">
                                                  Fabric Color
                                                </th>
                                                <th scope="col" className="px-6 py-3 font-bold text-gray-900">
                                                  Pcs
                                                </th>
                                                <th scope="col" className="px-6 py-3 font-bold text-gray-900">
                                                  Rate
                                                </th>
                                                <th scope="col" className="px-6 font-bold text-center text-gray-900">
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                              {partyChallanTableData?.map((row: any, index: number) => (


                                                <tr key={index} className="hover:bg-gray-50">
                                                  <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                    {index + 1}
                                                  </td>
                                                  <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                    {row?.remark || "-"}
                                                  </td>
                                                  <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                    {row?.designNo || "-"}

                                                  </td>
                                                  <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                    {clothState[row?.clotheId] || "-"}
                                                  </td>
                                                  <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                    {fabricState[row?.fabricColorId] || "-"}
                                                  </td>
                                                  <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                    {row?.pcs || "-"}
                                                  </td>
                                                  <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">

                                                    {row?.rate || "-"}
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <button
                                                        className="m-2"
                                                        type="button"
                                                        onClick={() => {
                                                          setSelecetdJobRow(row?._id);
                                                          setPartyChallanData({
                                                            remark: row?.remark,
                                                            designNo: row?.designNo,
                                                            pcs: row?.pcs,
                                                            rate: row?.rate
                                                          });
                                                          setSelectedCloth({
                                                            label: clothState[row?.clotheId],
                                                            value: row.clotheId,
                                                          });
                                                          setSelectedFabric({
                                                            label: fabricState[row?.fabricColorId],
                                                            value: row?.fabricColorId,
                                                          });
                                                          setSelectedRemarks({
                                                            label: remarksState[row?.remarkId],
                                                            value: row?.remarkId,
                                                          });
                                                          setIsPartyUpdate(true);
                                                        }}
                                                      >
                                                        <i className="fas fa-pen hover:text-green-600"></i>

                                                      </button>
                                                      <button
                                                        className="m-2"
                                                        onClick={() => {
                                                          Swal.fire({
                                                            title: "Are you sure?",
                                                            text: "Once deleted, you will not be able to recover this record!",
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Delete",
                                                          }).then((willDelete) => {
                                                            if (willDelete.isConfirmed) {
                                                              let newData = partyChallanTableData.filter(
                                                                (item: any) => item?._id !== row?._id
                                                              );
                                                              
                                                              setPartyChallanTableData(newData);
                                                              setPartyChallanData({
                                                                remark: "",
                                                                designNo: "",
                                                                pcs: "",
                                                                rate: ""
                                                              });
                                                              setSelectedFabric({});
                                                              setSelectedCloth({});
                                                              setSelectedRemarks({});
                                                              setIsPartyUpdate(false);
                                                            }
                                                          });
                                                        }}
                                                      >
                                                        <i className="fas fa-trash hover:text-red-600"></i>
                                                      </button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                            <tfoot className="border-t bg-gray-50">
                                              <tr>
                                                <td colSpan={5} className="px-6 py-1 font-bold text-gray-900 text-left">
                                                  Total 
                                                </td>
                                                <td colSpan={3} className="px-6 font-bold text-gray-900 text-left">
                                                  {pcsTotal}
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="text-center py-3 text-gray-600 font-bold">
                                          No Job Work Found
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.jobwork}
                      </span>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-between visible lg:invisible xl:invisible">
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={showPreview}
                    onClick={openInvoicePreview}
                  >
                    <i className="fa fa-download"></i>
                    <span className="ml-2">Print</span>
                  </button>
                </div>
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={isUpdating}
                    onClick={isChallanUpdate ? updateData : addData}
                  >
                    {isUpdating ? (
                      <i className="fa fa-spinner animate-spin"></i>
                    ) : (
                      <i className="fa fa-save"></i>
                    )}
                    <span className="ml-2">
                      {isChallanUpdate ? "Update" : "Save"}
                    </span>
                  </button>
                </div>
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    onClick={() => {
                      navigate("/jobworkdashboard");
                    }}
                  >
                    <i className="fa fa-times hover:rotate-180 duration-300"></i>
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Pdf Preview */}
            {showPreview && (
              <PartyChallanPreview
                showPreview={showPreview}
                setShowPreview={setShowPreview}
                id={isChallanUpdate ? id : savedId}
                pageName="Challan"
              />
            )}

            {/* Modal */}
            {showAddParty && (
              <PartyAdd
                setShowAddParty={setShowAddParty}
                showAddParty={showAddParty}
                setPartyNameAdd={setPartyNameAdd}
                partyNameAdd={partyNameAdd}
                getData={getData}
              />
            )}

            {showAddCloth && (
              <ClothAdd
                setShowAddCloth={setShowAddCloth}
                showAddCloth={showAddCloth}
                setClothAdd={setClothAdd}
                clothAdd={clothAdd}
                getClothData={getClothData}
              />
            )}
            {showAddItem && (
              <ItemAdd
                setShowAddItem={setShowAddItem}
                showAddItem={showAddItem}
                setItemAdd={setItemAdd}
                itemAdd={itemAdd}
                getItemData={getItemData}
              />
            )}
            {showAddDesc && (
              <DescAdd
                setShowAddDesc={setShowAddDesc}
                showAddDesc={showAddDesc}
                setDescAdd={setDescAdd}
                descAdd={descAdd}
                getDescData={getDescData}
              />
            )}
            {showAddFabric && (
              <FabricAdd
                setShowAddFabric={setShowAddFabric}
                showAddFabric={showAddFabric}
                setFabricAdd={setFabricAdd}
                fabricAdd={fabricAdd}
                getFabricData={getFabricData}
              />
            )}
            {showAddName && (
              <NameAdd
                setShowAddName={setShowAddName}
                showAddName={showAddName}
                setNameAdd={setNameAdd}
                itemType="partyChallanName"
                nameAdd={nameAdd}
                getNameData={getNameData}
              />
            )}
            {showAddRemarks && (
              <ChallanRemarksAdd
                setShowAddRemarks={setShowAddRemarks}
                showAddRemarks={showAddRemarks}
                setRemarksAdd={setRemarksAdd}
                remarksAdd={remarksAdd}
                getRemarksData={getRemarksData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
