import { configureStore } from '@reduxjs/toolkit'
import loginSignupReducer from './loginSignupSlice'
import sampleCardSlice from './sampleCardSlice'
import globalReducer from './globalSlice'
import { userApiSlice } from '../servicesRtkQuery/userApi'
import { publicApiSlice } from '../servicesRtkQuery/publicApi'
import matchingCardSlice from './matchingCardSlice'
import rateCardSlice from './rateCardSilce'
import jobWorkSlice from './jobWorkSlice'
import dressChallanSlice from './dressChallanSlice'
import sareeChallanSlice from './sareeChallanSlice'
import lumpChallanSlice from './lumpChallanSlice'
import partyChallanSlice from './partyChallanSlice'
export const store = configureStore({
    reducer: {
        loginSignupReducer,
        sampleCardSlice,
        matchingCardSlice,
        rateCardSlice,
        jobWorkSlice,
        dressChallanSlice,
        sareeChallanSlice,
        lumpChallanSlice,
        globalReducer,
        partyChallanSlice,
        [publicApiSlice.reducerPath]: publicApiSlice.reducer,
        [userApiSlice.reducerPath]: userApiSlice.reducer,

    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            userApiSlice.middleware,
            publicApiSlice.middleware,
        ),
})

