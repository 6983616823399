import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    partyChallan: []
}


export const partyChallanSlice = createSlice({
    name: 'partyChallanSlice',
    initialState,
    reducers: {
        setPartyChallan: (state, action) => {
            state.partyChallan = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setPartyChallan
} = partyChallanSlice.actions
export default partyChallanSlice.reducer