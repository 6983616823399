import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { convertImageToBase64 } from "../../../utils/globalFunctions";
import ReactToPrint from "react-to-print";
import * as htmlToImage from 'html-to-image';
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../../utils/constants";

export const LumpRateCardPreview = (props: any) => {
    const exportRef: any = useRef(null);
    const exportPartyRef: any = useRef(null);
    const lumpRateCard = props.singleCard
    const [image, setImage]: any = useState();
    const [logo, setLogo]: any = useState();
    const [toggleState, setToggleState] = useState(1);
    const [loadingImage, setLoadingImage]: any = useState(false)

    const downloadImage = async (ref: any, fileName: any) => {
        const dataUrl = await htmlToImage.toPng(ref.current);
        // download image
        const link = document.createElement('a');
        link.download = fileName;
        link.href = dataUrl;
        link.click();
    }

    const toggleTab = (index: any) => {
        setToggleState(index);
    };

    useEffect(() => {
        setLoadingImage(true)
        if (lumpRateCard) {
            if (lumpRateCard?.image?.startsWith("https:")) {
                convertImageToBase64(lumpRateCard.image, setImage)
            }
            else {
                Swal.fire({
                    toast: true,
                    icon: 'warning',
                    title: "You have not uploaded any image\nPlease upload a new image.",
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: ERROR_MESSAGE_TIMEOUT,
                    timerProgressBar: true
                })
                setLoadingImage(false)
            }
        }
        convertImageToBase64("https://cnt-satv.s3.ap-south-1.amazonaws.com/logo.png", setLogo)
    }, [])

    useEffect(() => {
        if (image) {
            setLoadingImage(false)
        }
    }, [image])

    let total: any = 0

    lumpRateCard?.works?.forEach((card: any) => {
        total = total + (card?.totalQty * (card?.rate + card?.valueAdd))
    });

    const LumpRatePdf = () => {
        return (
            <div className="overflow-auto p-2">
                <div ref={exportRef} className={`overflow-hidden a5-landscape bg-white`}>
                    <div className="border border-gray-600">
                        <div className="grid grid-cols-2 font-bold items-center p-2 bg-gray-100">
                            <div className="text-themeColor pl-25 capitalize">
                                <img src={logo} alt="सत्व" />
                            </div>
                            <div className="text-themeColor text-7 text-left capitalize">
                                design no : {lumpRateCard?.designNo}
                            </div>
                        </div>
                        <div className="p-2 border-y border-black">
                            <div className={`text-sm grid grid-cols-6 gap-2`}>
                                <div className="flex justify-center col-span-2 row-span-2 overflow-hidden">
                                    {
                                        image ?
                                            <img src={image} className="max-h-120 w-75" alt="" />
                                            : null
                                    }
                                </div>
                                <div className="col-span-4">
                                    <table className="w-full border border-black border-collapse bg-white text-left text-5">
                                        <thead className="divide-black border-t border-x border-black bg-gray-100">
                                            <tr>
                                                <th scope="col" colSpan={lumpRateCard?.fabrics?.length == 1 ? 2 : 3} className="py-1.5 border-r text-red-800 border-black capitalize text-center font-extrabold text-2xl">{lumpRateCard?.mainTitle?.name ? lumpRateCard?.mainTitle?.name : "Lump"}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y text-center divide-black border-x border-t border-black text-5">
                                            <tr className="text-center">
                                                <td width={lumpRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 border-r py-1.5 border-black capitalize">fabric</td>
                                                {
                                                    lumpRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <td width={lumpRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 border-r border-black capitalize">{fabric?.clothe?.name}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td width={lumpRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 border-r py-1.5 border-black capitalize">Fabric party</td>
                                                {
                                                    lumpRateCard?.fabrics?.map((fabric: any) => {
                                                        return (
                                                            <td width={lumpRateCard?.fabrics?.length == 1 ? "50%" : "33.33%"} className="gap-3 border-r border-black text-center capitalize">{fabric?.clotheParty?.name}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className="w-full border border-collapse bg-white text-center text-5 mt-4">
                                        <thead className="bg-gray-100 divide-black border-t border-x border-black">
                                            <tr>
                                                <th scope="col" colSpan={2} className="py-1.5 border-r text-red-800 border-black capitalize text-2xl font-extrabold">rate</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-black border-x border-t border-black">
                                            <tr>
                                                <td width="50%" className="border-r border-black capitalize py-1.5">{lumpRateCard?.jobTitle?.name ? lumpRateCard?.jobTitle?.name : "job work"}</td>
                                                <td width="50%" className="border-r border-black capitalize py-1.5">{lumpRateCard?.finalCosting?.work?.amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="border-r border-black capitalize py-1.5">hand works</td>
                                                <td className="border-r border-black capitalize py-1.5">{lumpRateCard?.finalCosting?.hand?.amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="border-r border-black capitalize py-1.5">foil</td>
                                                <td className="border-r border-black text-gray-900r capitalize py-1.5">{lumpRateCard?.finalCosting?.foil?.amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="border-r border-black capitalize py-1.5">fabrics</td>
                                                <td className="border-r border-black text-gray-900r capitalize py-1.5">{lumpRateCard?.finalCosting?.fabrics?.amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="border-r border-black capitalize py-1.5">daying</td>
                                                <td className="border-r border-black text-gray-900r capitalize py-1.5">{lumpRateCard?.finalCosting?.daying?.amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="border-r border-black capitalize py-1.5">&nbsp;</td>
                                                <td className="border-r border-black text-gray-900r capitalize py-1.5">&nbsp;</td>
                                            </tr>
                                        </tbody>
                                        <tfoot className="text-red-800 bg-gray-100 border border-black">
                                            <tr>
                                                <td className="border-r border-b border-black capitalize font-extrabold py-1.5 text-2xl">total rate</td>
                                                <td className="border-r border-b border-black text-gray-900r capitalize font-extrabold py-1.5 text-2xl">{
                                                    Number(lumpRateCard?.finalCosting?.work?.amount) + Number(lumpRateCard?.finalCosting?.hand?.amount) + Number(lumpRateCard?.finalCosting?.foil?.amount) + Number(lumpRateCard?.finalCosting?.daying?.amount || 0) + Number(lumpRateCard?.finalCosting?.fabrics?.amount)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    console.log("lumpRateCard?.finalCosting",lumpRateCard?.finalCosting)
    const PartyPdf = () => {
        return (
            <div className="overflow-auto p-2">
                <div ref={exportPartyRef} className={`overflow-hidden a5-landscape bg-white`}>
                    <div className="border-t border-x border-black">
                        <div className="grid grid-cols-4 font-bold items-center p-2 bg-gray-100">
                            <div className="text-themeColor pl-25 capitalize">
                                <img src={logo} alt="सत्व" />
                            </div>
                            <div className="text-cyan-900 font-extrabold col-span-2 text-5xl text-center capitalize">
                                design no : {lumpRateCard?.designNo}
                            </div>
                        </div>
                        <div className="p-2 border-y border-black">
                            <div className={`text-sm grid grid-cols-6 gap-2`}>
                                <div className="flex justify-center col-span-6 row-span-2 overflow-hidden bg-black">
                                    {
                                        image ?
                                            <img src={image} className="max-h-[1200px] w-[800px]" alt="" />
                                            : null
                                    }
                                </div>
                                <div className="col-span-6">
                                    <table className="w-full border border-collapse bg-white text-center mx-auto">
                                        <tbody className="divide-black border border-black">
                                            <tr>
                                                <th scope="col" colSpan={4} className="bg-gray-100 py-1.5 border-r border-b text-red-800 border-black capitalize text-center text-4xl">{lumpRateCard?.mainTitle?.name ? lumpRateCard?.mainTitle?.name : "All Over Rate - 1 Mtr"}</th>
                                            </tr>
                                            {/* <tr>
                                                <td width="50%" className="gap-3 border-r border-b border-black capitalize py-1 text-xl">Design No</td>
                                                <td width="50%" className="gap-3 border-r border-b border-black capitalize text-xl py-1">{lumpRateCard?.designNo}</td>
                                            </tr> */}
                                            <tr>
                                                <td width="50%" className="gap-3 border-r border-b border-black capitalize text-3xl py-1 font-extrabold">{lumpRateCard?.jobTitle?.name ? lumpRateCard?.jobTitle?.name : "job rate"}</td>
                                                <td width="50%" className="gap-3 border-r border-b border-black capitalize text-2xl py-1 font-extrabold">
                                                    {
                                                        Number(lumpRateCard?.finalCosting?.work?.amount) +
                                                        (lumpRateCard?.finalCosting?.hand?.isSelected ? Number(lumpRateCard?.finalCosting?.hand?.amount) : 0) +
                                                        (lumpRateCard?.finalCosting?.stitching?.isSelected ? Number(lumpRateCard?.finalCosting?.stitching?.amount) : 0) +
                                                        (lumpRateCard?.finalCosting?.foil ? Number(lumpRateCard?.finalCosting?.foil?.amount) : 0) +
                                                        (lumpRateCard?.finalCosting?.daying?.isSelected ? Number(lumpRateCard?.finalCosting?.daying?.amount) : 0) +
                                                        (lumpRateCard?.finalCosting?.fabrics?.isSelected ? Number(lumpRateCard?.finalCosting?.fabrics?.amount) : 0)
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" className="gap-3 border-r border-b border-black capitalize text-2xl py-1">Height</td>
                                                <td width="50%" className="gap-3 border-r border-b border-black capitalize text-2xl py-1">{lumpRateCard?.height}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%" className="gap-3 border-r border-b border-black capitalize text-2xl py-1">Remarks</td>
                                                <td width="50%" className="gap-3 border-r border-b border-black capitalize text-2xl py-1">{lumpRateCard?.remark?.name}</td>
                                            </tr>
                                            {
                                                lumpRateCard?.clothe?.name ?
                                                    <tr>
                                                        <td width="50%" className="gap-3 border-r border-b border-black capitalize text-2xl py-1">Fabrics</td>
                                                        <td width="50%" className="gap-3 border-r border-b border-black capitalize text-2xl py-1">{lumpRateCard?.clothe?.name}</td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td colSpan={4} className="border-r py-2 text-center border-black capitalize text-4xl text-red-900 bg-gray-100 font-extrabold">Note : With&nbsp;
                                                    {
                                                        lumpRateCard?.finalCosting?.work?.itemName +
                                                        (lumpRateCard?.finalCosting?.fabrics?.isSelected ? " & " : "") +
                                                        (lumpRateCard?.finalCosting?.fabrics?.isSelected ? lumpRateCard?.finalCosting?.fabrics?.itemName : "") +
                                                        (lumpRateCard?.finalCosting?.hand?.isSelected ? " & " : "") +
                                                        (lumpRateCard?.finalCosting?.hand?.isSelected ? lumpRateCard?.finalCosting?.hand?.itemName : "") +
                                                        (lumpRateCard?.finalCosting?.stitching?.isSelected ? " & " : "") +
                                                        (lumpRateCard?.finalCosting?.stitching?.isSelected ? lumpRateCard?.finalCosting?.stitching?.itemName : "")
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Modal open={props.showLumpRatePreview} showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl"
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    },
                    modalContainer: {
                        overflow: "auto"
                    }
                }}
                onOverlayClick={() => props.setShowLumpRatePreview(false)}
                onEscKeyDown={() => props.setShowLumpRatePreview(false)}
                aria-labelledby="sample-card-pdf"
                aria-describedby="sample-card-pdf-description"
                animationDuration={400}
                onClose={() => props.setShowLumpRatePreview(false)}>
                <div className="overflow-auto">
                    <div className="text-center">
                        <div className="bg-white border rounded-lg text-left shadow-xl">
                            <div className="w-full rounded-xl overflow-hidden p-1">
                                <div className="border rounded-xl md:rounded-full p-1.5 bg-gray-50 shadow-sm">
                                    <ul className="flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row px-2" role="tablist">
                                        <li className={`${toggleState === 1 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "hover:bg-themeColor/5"} flex-auto text-center rounded-full`}>
                                            <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(1)}>lump rate card</button>
                                        </li>
                                        <li className={`${toggleState === 2 ? "border-b-2 border-themeColor bg-themeColor/13 font-bold" : "hover:bg-themeColor/5"} flex-auto text-center rounded-full`}>
                                            <button className={`block w-full border-x-0 border-t-0 border-transparent px-7 pt-4 pb-3.5 text-xs uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent focus:isolate focus:border-transparent`} onClick={() => toggleTab(2)}>party pdf</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-full h-full rounded-xl overflow-hidden bg-white">
                                <div
                                    className={`transition-opacity duration-150 ease-linear ${toggleState === 1 ? "opacity-100" : "opacity-0 hidden"}`}>
                                    <LumpRatePdf />
                                    <div className="m-3 flex flex-wrap gap-3">
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0' onClick={() => props.setShowLumpRatePreview(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <ReactToPrint
                                                trigger={() =>
                                                    <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' disabled={loadingImage}>
                                                        {loadingImage ?
                                                            <><span>Loading</span><i className='fas fa-spinner animate-spin ml-2'></i></> :
                                                            <><span>Lump Rate Print</span><i className='fas fa-file-download ml-2'></i></>
                                                        }
                                                    </button>
                                                }
                                                content={() => exportRef.current} copyStyles={true} documentTitle={props.pageName + "-Satv"}
                                            />
                                        </div>
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => {
                                                downloadImage(exportRef, lumpRateCard?.designNo + "-Satv")
                                            }} disabled={loadingImage}>
                                                {loadingImage ?
                                                    <><span>Loading</span><i className='fas fa-spinner animate-spin ml-2'></i></> :
                                                    <><span>Lump Rate Image</span><i className='fas fa-file-image ml-2'></i></>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`transition-opacity duration-150 ease-linear ${toggleState === 2 ? "opacity-100" : "opacity-0 hidden"}`}>
                                    <PartyPdf />
                                    <div className="m-3 flex flex-col lg:flex-row gap-2">
                                        <div>
                                            <ReactToPrint
                                                trigger={() =>
                                                    <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' disabled={loadingImage}>
                                                        {loadingImage ?
                                                            <><span>Loading</span><i className='fas fa-spinner animate-spin ml-2'></i></> :
                                                            <><span>Lump Party Print</span><i className='fas fa-file-download ml-2'></i></>
                                                        }
                                                    </button>
                                                }
                                                content={() => exportPartyRef.current} copyStyles={true} documentTitle={props.pageName + "- Party"}
                                            />
                                        </div>
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => {
                                                downloadImage(exportPartyRef, lumpRateCard?.designNo + "-Party")
                                            }} disabled={loadingImage}>
                                                {loadingImage ?
                                                    <><span>Loading</span><i className='fas fa-spinner animate-spin ml-2'></i></> :
                                                    <><span>Lump Party Image</span><i className='fas fa-file-image ml-2'></i></>
                                                }
                                            </button>
                                        </div>
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0' onClick={() => props.setShowLumpRatePreview(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    )
}
