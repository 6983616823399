import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactToPrint from "react-to-print";
import moment from "moment";
import {
    useLazyGetAllReadyStockDataQuery,
} from "../../../servicesRtkQuery/publicApi";
import DataTableLoader from "../../page/DataTableLoader";

export const ReadyStockDataPdf = (props: any) => {
    const exportRef: any = useRef(null);
    var { allCount, isSearching, search, type } = props;
    const [cardData, setCardData]: any = useState();
    const [trigger, result] = useLazyGetAllReadyStockDataQuery();
    const { isSuccess, isFetching, isError, error } = result;

    const [cardTrigger, cardResult] = useLazyGetAllReadyStockDataQuery();
    const {
        isSuccess: isCardSuccess,
        isFetching: isCardFetching,
        isError: isCardError,
        error: cardError,
    } = cardResult;

    useEffect(() => {
        if (!isSearching) {
            trigger({
                limit: allCount,
            });
        }
    }, []);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setCardData(result?.data?.data?.readyStock);
        }
    }, [isSuccess, isFetching]);

    const calculateTotalSum = (data: any[] = []) => {
        return data.reduce((sum: number, item: any) => {
            const totalColorsPcs = (item?.colors || []).reduce((colorSum: number, color: any) => {
                return colorSum + (color.pcs || 0);
            }, 0);

            const totalPartysPcs = (item?.partys || []).reduce((partySum: number, party: any) => {
                return partySum + (party.colorPcs || []).reduce((colorSum: number, color: any) => {
                    return colorSum + (color.pcs || 0);
                }, 0);
            }, 0);

            const result = totalColorsPcs - totalPartysPcs;
            return sum + result;
        }, 0);
    }




    useEffect(() => {
        if (isCardSuccess && !isCardFetching) {
            setCardData(cardResult?.data?.data?.readyStock);
        }
    }, [isCardSuccess, isCardFetching]);

    useEffect(() => {
        if (isSearching) {
            let searchData: any = {
                limit: allCount,
            };
            Object.keys(search).map((key: any) => {
                if (search[key] && search[key].text !== "") {
                    searchData[key] = search[key].text;
                }
            });
            if (type !== "all") {
                searchData = { ...searchData, type: type };
            }
            if (Object.keys(searchData).length !== 0) {
                cardTrigger(searchData);
            }
        }
    }, [search]);
    return (
        <div>
            <Modal
                open={props.isExportData}
                showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl",
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    },
                    modalContainer: {
                        overflow: "auto",
                    },
                }}
                onEscKeyDown={() => props.setIsExportData(false)}
                onOverlayClick={() => props.setIsExportData(false)}
                aria-labelledby="sample-card-pdf"
                aria-describedby="sample-card-pdf-description"
                animationDuration={400}
                onClose={() => props.setIsExportData(false)}
            >
                <div className="overflow-auto">
                    <div className="text-center">
                        <div className="bg-white border rounded-lg text-left shadow-xl">
                            <div ref={exportRef} className={`a5-portrait overflow-auto`}>
                                <div className="border border-gray-600">
                                    <div className="font-bold items-center p-2 bg-gray-100 text-5">
                                        <div className="text-themeColor capitalize text-center">
                                            Ready Stock Data
                                        </div>
                                    </div>
                                    {!isFetching ? (
                                        <div className="">
                                            <div className={`text-sm`}>
                                                <table className="w-full border-collapse bg-white border-b border-gray-600 text-left">
                                                    <thead className="bg-gray-100 divide-black border-t border-black text-4">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                sr no
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Date
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Card. No.
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Design. No.
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Fabric
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Fabric Party
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Total Pcs
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Send Pcs
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Balance Pcs
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y text-center divide-black border-t border-black text-4">
                                                        {cardData?.map((item: any, i: any) => {
                                                            const totalColorsPcs = item?.colors?.reduce((sum: number, color: any) => {
                                                                return sum + (color.pcs || 0);
                                                            }, 0);

                                                            const totalPartysPcs = item?.partys?.reduce((sum: number, party: any) => {
                                                                return sum + (party.colorPcs?.reduce((colorSum: number, color: any) => {
                                                                    return colorSum + (color.pcs || 0);
                                                                }, 0) || 0);
                                                            }, 0);

                                                            const result = totalColorsPcs - totalPartysPcs;
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                                                        {i + 1}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                                                        {moment(item?.createAt).format("DD-MM-YYYY")}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.cardNo}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.designNo}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.clothe?.name}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.clotheParty?.name}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.colors?.reduce((sum: number, color: any) => sum + color.pcs, 0)}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.partys?.reduce((sum: number, party: any) => {
                                                                            return sum + party.colorPcs.reduce((colorSum: number, color: any) => colorSum + color.pcs, 0);
                                                                        }, 0)}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {result}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        <tr className="bg-gray-200">
                                                            <td colSpan={6} >Total</td>
                                                            <td className="gap-3 border border-black font-black text-gray-900 text-center text-lg    capitalize">
                                                                {cardData?.reduce((sum: number, item: any) => {
                                                                    return sum + (item?.colors?.reduce((colorSum: number, color: any) => colorSum + (color.pcs || 0), 0) || 0);
                                                                }, 0)}
                                                            </td>
                                                            <td className="gap-3 border border-black font-black text-gray-900 text-center text-lg    capitalize">
                                                                {cardData?.reduce((sum: number, item: any) => {
                                                                    return sum + (item?.partys?.reduce((partySum: number, party: any) => {
                                                                        return partySum + (party.colorPcs?.reduce((colorSum: number, color: any) => {
                                                                            return colorSum + (color.pcs || 0);
                                                                        }, 0) || 0);
                                                                    }, 0) || 0);
                                                                }, 0)}
                                                            </td>
                                                            <td className="gap-3 border border-black font-black text-gray-900 text-center text-lg    capitalize">
                                                                {calculateTotalSum(cardData || [])}
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        <DataTableLoader />
                                    )}
                                </div>
                            </div>
                            <div className="m-3 flex flex-col lg:flex-row gap-2">
                                <div>
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                                                Print <i className="fas fa-file-download"></i>
                                            </button>
                                        )}
                                        content={() => exportRef.current}
                                        documentTitle={props.pageName}
                                    />
                                </div>
                                <div>
                                    <button
                                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                                        onClick={() => props.setIsExportData(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
