import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { convertImageToBase64 } from "../../../utils/globalFunctions";
import ReactToPrint from "react-to-print";
import { useLazyGetSingleReadyStockDataQuery } from "../../../servicesRtkQuery/publicApi";
import { ERROR_MESSAGE_TIMEOUT } from "../../../utils/constants";
import Swal from "sweetalert2";

export const ReadyStockDataPreviewPdf = (props: any) => {
    const exportCheking: any = useRef(null);

    const [image, setImage]: any = useState();
    const [toggleState, setToggleState] = useState(1);

    const [readyStock, setReadyStock]: any = useState();
    var userData: any = localStorage.getItem("user");
    userData = JSON.parse(userData);
    const id = props.id;
    const [trigger, result] = useLazyGetSingleReadyStockDataQuery();
    const { isSuccess, isFetching } = result;
    const [loadingImage, setLoadingImage]: any = useState(false);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setReadyStock(result?.data?.data?.readyStock?.[0]);
        }
    }, [isSuccess, isFetching]);
    useEffect(() => {
        let payload = {
            rsId: id,
            query: {
                isSingle: true,
            },
        };
        trigger(payload);
    }, []);
    useEffect(() => {
        setLoadingImage(true);

        if (readyStock) {
            if (readyStock?.image?.URL?.startsWith("https:")) {
                convertImageToBase64(readyStock?.image?.URL, setImage);
            } else {
                Swal.fire({
                    toast: true,
                    icon: "warning",
                    title: "You have not uploaded any image\nPlease upload a new image.",
                    position: "top-end",
                    showConfirmButton: false,
                    timer: ERROR_MESSAGE_TIMEOUT,
                    timerProgressBar: true,
                });
                setLoadingImage(false);
            }
        }
    }, [readyStock]);

    useEffect(() => {
        if (image) {
            setLoadingImage(false);
        }
    }, [image]);

    const calculatePcsDifference = () => {
        // Step 1: Calculate total pcs in colors
        const totalColorsPcs = readyStock?.colors?.reduce((sum: number, color: any) => sum + color.pcs, 0);

        // Step 2: Calculate total pcs in partys
        const totalPartysPcs = readyStock?.partys?.reduce((sum: number, party: any) => sum + party.totalPcs, 0);

        // Step 3: Subtract partys total from colors total
        const remainingTotalPcs = totalColorsPcs - totalPartysPcs;
        // Step 4: Return updated colors array with new field
        return readyStock?.colors?.map((color: any) => {
            // Sum all pcs from partys where fabricColorId matches
            const totalUsedPcs = readyStock?.partys?.reduce((sum: number, party: any) => {
                const colorInParty = party.colorPcs.find((colorPcs: any) => colorPcs.fabricColorId === color.fabricColorId);
                return sum + (colorInParty?.pcs || 0);
            }, 0);

            // Subtract and get remaining pcs for each color
            const remainingPcs = color.pcs - totalUsedPcs;

            // Add remainingTotalPcs as new field
            return {
                ...color,
                remainingPcs,
                remainingTotalPcs
            };
        });
    };


    function Cheking() {
        let colors: any = [];
        // readyStock?.fabricColors?.forEach((item: any) => {
        //     colors.push(item?.fabricColorId?.colorName);
        // });
        return (

            <div ref={exportCheking} className={`a4-landscape overflow-hidden w-full`}>
                <div className="h-full">
                    <div className="mt-2 text-xs">
                        {/* <div className="flex item-center justify-between my-2">
                            <table className="w-full border-collapse bg-white text-center border border-black mb-3">
                                <tbody>
                                    <tr className="border border-black text-lg h-6">
                                        <td className="text-center border-r border-black pr-2 font-black">D.No</td>
                                        <td className="text-left pl-2">{readyStock?.designNo}</td>
                                    </tr>
                                    <tr className="border border-black text-base h-6">
                                        <td className="text-center border-r border-black pr-2 font-black">Card No.</td>
                                        <td className="text-left pl-2">{readyStock?.cardNo}</td>
                                    </tr>
                                    <tr className="border border-black text-base h-6">
                                        <td className="text-center border-r border-black pr-2 font-black">Fabrics</td>
                                        <td className="text-left pl-2">{readyStock?.clothe?.name}</td>
                                    </tr>
                                    <tr className="border border-black text-base h-6">
                                        <td className="text-center border-r border-black pr-2 font-black">Fab. Party</td>
                                        <td className="text-left pl-2">{readyStock?.clotheParty?.name}</td>
                                    </tr>
                                </tbody>                              

                            </table>
                            <div className="mr-4">
                                {image ? (
                                    <img
                                        src={image}
                                        className="object-contain h-56 w-56"
                                        alt=""
                                    />
                                ) : null}
                            </div>
                        </div> */}

                        <div className="flex items-center justify-between my-2">
                            <div className="flex item-centerh-16 w-[70%] mr-4">
                                <div className="w-full justify-center items-center">
                                    <h1 className="font-black text-2xl text-black text-center underline">Ready Stock</h1>
                                    <table className="w-full border-collapse bg-white text-center border border-black mb-3">
                                        <thead>
                                            <tr className="border border-black text-xl h-16">
                                                <th className="text-center border-r border-black pr-2 font-black py-1">D.No</th>
                                                <th className="text-center border-r border-black pr-2 font-black py-1">Card No.</th>
                                                <th className="text-center border-r border-black pr-2 font-black py-1">Fabrics</th>
                                                <th className="text-center border-r border-black pr-2 font-black py-1">Fab. Party</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border border-black text-lg h-16">
                                                <td className="text-center border-r border-black pr-2 font-black py-1">{readyStock?.designNo}</td>
                                                <td className="text-center border-r border-black pr-2 font-black py-1">{readyStock?.cardNo}</td>
                                                <td className="text-center border-r border-black pr-2 font-black py-1">{readyStock?.clothe?.name}</td>
                                                <td className="text-center border-r border-black pr-2 font-black py-1">{readyStock?.clotheParty?.name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="mr-4 w-[30%] flex justify-center item-center">
                                {image ? (
                                    <img
                                        src={image}
                                        className="object-fill h-44 w-44"
                                        alt=""
                                    />
                                ) : null}
                            </div>
                        </div>

                        <div className="flex item-center w-full">
                            {/* first table */}
                            <table className="w-[50%] border-collapse bg-white text-center mr-1">
                                <tbody className="border border-black">
                                    <tr className="border border-t-2 border-black border-x-2">
                                        <td className="px-2 py-2 text-[16px] text-black font-black w-14 h-8 border border-black">
                                            नंबर
                                        </td>
                                        {readyStock?.colors?.map((color: any, index: number) => (
                                            <td key={index} className="px-2 py-2 text-black w-14 h-8 text-base border border-black">
                                                {color?.number}
                                            </td>
                                        ))}
                                        {[...Array(Math.max(0, 7 - (readyStock?.colors?.length || 0)))].map((_, index) => (
                                            <td key={`empty-${index}`} className="px-2 py-2 text-black w-14 h-8 border border-black">

                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="border border-x-2 border-black">
                                        <td className="px-2 py-2 text-[16px] text-black font-black w-14 h-8 border border-black">
                                            कलर
                                        </td>
                                        {readyStock?.colors?.map((color: any, index: number) => (
                                            <td key={index} className="px-2 py-2 text-black w-14 text-base border h-8 border-black">
                                                {color?.fabricColor?.colorName}
                                            </td>
                                        ))}
                                        {[...Array(Math.max(0,7 - (readyStock?.colors?.length || 0)))].map((_, index) => (
                                            <td key={`empty-${index}`} className="px-2 py-2 text-black w-14 h-8 border border-black">

                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="border border-x-2 border-b-2 border-black">
                                        <td className="px-2 py-2 text-[16px] text-black font-black w-14 h-8 border border-black">
                                            {readyStock?.colors?.reduce((sum: number, item: any) => sum + (item?.pcs || 0), 0)}

                                        </td>
                                        {readyStock?.colors?.map((color: any, index: number) => (
                                            <td key={index} className="px-2 py-2 text-black w-14 h-8 text-base border border-black">
                                                {color?.pcs}
                                            </td>
                                        ))}
                                        {[...Array(Math.max(0, 7 - (readyStock?.colors?.length || 0)))].map((_, index) => (
                                            <td key={`empty-${index}`} className="px-2 py-2 text-black w-14 h-8 border border-black">

                                            </td>
                                        ))}
                                    </tr>

                                    {readyStock?.partys?.map((party: any, partyIndex: number) => (
                                        <tr key={partyIndex} className="text-base">
                                            <td className="px-2 py-2 text-[16px] text-black font-black h-8 w-14 border border-black">
                                                {party.totalPcs}
                                            </td>
                                            {party.colorPcs.map((color: any, colorIndex: number) => (
                                                <td key={colorIndex} className="px-2 py-2 text-black w-14 h-8 border border-black">
                                                    {color?.pcs}
                                                </td>
                                            ))}
                                            {[...Array(Math.max(0, 7 - (party.colorPcs.length || 0)))].map((_, index) => (
                                                <td key={`empty-party-${partyIndex}-${index}`} className="px-2 py-2 text-black w-14 border h-8 border-black">

                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    {[...Array(Math.max(0,8 - (readyStock?.partys?.length || 0)))].map((_, index) => (
                                        <tr key={`empty-party-${index}`} className="border border-black">
                                            <td className="px-2 py-2 text-black w-14 border h-8 border-black">

                                            </td>
                                            {[...Array(Math.max(0, 7))].map((_, colIndex) => (
                                                <td key={`empty-col-${colIndex}`} className="px-2 py-2 h-8 text-black w-14 border border-black">

                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* second table */}
                            <table className="w-[49%] border-collapse bg-white text-center ml-1">
                                <tbody>
                                    <tr className="border border-black border-b-2 text-lg">
                                        <td className="text-center border-r border-black pr-2 font-black h-33">Send Date</td>
                                        <td className="text-center border-r border-black pr-2 font-black h-33">Party Name</td>
                                        <td className="text-center border-r border-black pr-2 font-black h-33">Bill No.</td>
                                        <td className="text-center border-r border-black pr-2 font-black h-33">Rate</td>
                                    </tr>
                                    {readyStock?.partys?.map((party: any, index: number) => (
                                        <tr key={index}>
                                            <td className="px-2 py-2 text-black w-[70px] h-8 border text-[14px] border-black">
                                                {moment(party?.date).format(
                                                    "DD-MM-yyyy"
                                                )}
                                            </td>
                                            <td className="px-2 py-2 text-black w-[30px] text-base h-8 border border-black">
                                                {party?.partyName}
                                            </td>
                                            <td className="px-2 py-2 text-black w-[30px] text-base h-8 border border-black">
                                                {party?.billNo}
                                            </td>
                                            <td className="px-2 py-2 text-black w-[30px] text-base h-8 border border-black">
                                                {party?.rate}
                                            </td>
                                        </tr>
                                    ))}
                                    {[...Array(Math.max(0, 8 - (readyStock?.partys?.length || 0)))].map((_, index) => (
                                        <tr key={`empty-party-${index}`} className="border border-black">
                                            <td className="px-2 py-2 text-black w-[30px] border h-8 border-black">

                                            </td>
                                            {[...Array(Math.max(0, 3))].map((_, colIndex) => (
                                                <td key={`empty-col-${colIndex}`} className="px-2 py-2 h-8 text-black w-[30px] border border-black">

                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex item-center">
                            <div className="w-[50%] mr-2 mt-1">
                                <table className="w-full border-collapse bg-white text-center">
                                    <tbody>
                                        <tr className="border border-x-2 border-y-2 border-black text-base">
                                            <td className="px-2 py-2 text-[16px] text-black font-black w-14 h-8 border border-black">
                                                {calculatePcsDifference()?.[0]?.remainingTotalPcs}
                                            </td>
                                            {calculatePcsDifference()?.map((color: any, index: number) => (
                                                <>
                                                    <td className="px-2 py-2 text-black h-8 w-14 border border-black">
                                                        {color.remainingPcs}
                                                    </td>
                                                </>
                                            ))}
                                            {[...Array(Math.max(0, 7 - (readyStock?.colors?.length || 0)))].map((_, index) => (
                                                <td key={`empty-${index}`} className="px-2 py-2 w-14 text-black h-8 border border-black">

                                                </td>
                                            ))}
                                        </tr>


                                    </tbody>
                                </table>

                            </div>

                            <div className="w-[49%]"></div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    return (
        <div>
            <Modal
                open={props.showReadyStockPreview}
                showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl",
                }}
                onEscKeyDown={() => props.setShowReadyStockPreview(false)}
                onOverlayClick={() => props.setShowReadyStockPreview(false)}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    },
                    modalContainer: {
                        overflow: "auto",
                    },
                }}
                aria-labelledby="matching-card-pdf"
                aria-describedby="matching-card-pdf-description"
                animationDuration={400}
                onClose={() => props.setShowReadyStockPreview(false)}
            >
                <div className="overflow-auto">
                    <div className="text-center">
                        <div className="bg-white border text-left shadow-xl">

                            <div className="w-full h-full rounded-xl overflow-hidden bg-white">

                                <div
                                    className={`transition-opacity duration-150 ease-linear opacity-100`}
                                >
                                    <Cheking />
                                </div>

                                <div className="m-3 flex flex-col lg:flex-row gap-2">
                                    <div>
                                        <ReactToPrint
                                            trigger={() => (
                                                <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                                                    Ready Stock <i className="fas fa-file-download"></i>
                                                </button>
                                            )}
                                            content={() => exportCheking.current}
                                            documentTitle={props.pageName}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                                            onClick={() => props.setShowReadyStockPreview(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
