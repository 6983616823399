import React, { useMemo, useState, useEffect, useRef } from "react";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router";
import CountUp from "react-countup";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import moment from "moment";
import customStyles from "../../utils/globalFunctions";
import DataTableLoader from "../../components/page/DataTableLoader";
import {
    useLazyGetAllPlainStockDataQuery,
    useLazyGetAllReadyStockDataQuery,
    useLazyGetSingleReadyStockDataQuery,
    useUpdateReadyStockMutation,
} from "../../servicesRtkQuery/publicApi";
import { EmployeeReportPreview } from "../../components/pdf/report/employeeReportPreview";
import { TableAction } from "../../components/modal/TableAction";
import TextBox from "../../components/form/TextBox";
import { SampleCardDataPdf } from "../../components/pdf/dashboard/sampleCardDataPdf";
import { PlainStockDataPdf } from "../../components/pdf/dashboard/plainStockDataPdf";
import { ReadyStockDataPdf } from "../../components/pdf/dashboard/readyStockDataPdf";
import { ReadyStockDataPreviewPdf } from "../../components/pdf/stock/readyStockDataPreviewPdf";

export default function ReadyStockDashboard() {
    const navigate = useNavigate();

    const [allTrigger, allResult] = useLazyGetAllReadyStockDataQuery();
    const {
        isSuccess: isAllSuccess,
        isFetching: isAllFetching,
        isError: isAllError,
        error: allError,
    } = allResult;



    const [readyStockSingleTrigger, readyStockSingleResult] = useLazyGetSingleReadyStockDataQuery();
    const { isSuccess: isMatchingSuccess, isFetching: isMatchingFetching } =
        readyStockSingleResult;
    const [isSearching, setIsSearching]: any = useState(false);
    const [cardData, setCardData]: any = useState<any>([]);
    const [updateReportTrigger] = useUpdateReadyStockMutation();
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [showReportPreview, setShowReportPreview] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExportData, setIsExportData] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState("");
    const [perPage, setPerPage] = useState(20);
    const [showReadyStockPreview, setShowReadyStockPreview] = useState(false);
    const [singleCard, setSingleCard]: any = useState<any>();
    const [page, setPage] = useState(1);
    const [search, setSearch]: any = useState({
        date: {
            text: "",
            status: false,
        },
        cardNo: {
            text: "",
            status: false,
        },
        designNo: {
            text: "",
            status: false
        },
        pcs: {
            text: "",
            status: false,
        },
        partyName: {
            text: "",
            status: false,
        },
        clotheName: {
            text: "",
            status: false,
        },
        clothePartyName: {
            text: "",
            status: false,
        },
        billNo: {
            text: "",
            status: false
        },
        totalPcs: {
            text: "",
            status: false
        },
        fromDate: {
            text: "",
            status: false,
        },
        toDate: {
            text: "",
            status: false,
        },
    });


    // Counters
    const [readyStock, setReadyStock]: any = useState(0);

    useEffect(() => {
        allTrigger({
            page: page,
            limit: perPage,
        });

    }, []);


    const delayedCardTrigger: any = useRef(null);
    const latestRequest: any = useRef(null);


    const openInvoicePreview = async (row: any) => {
        setShowReadyStockPreview(true);
        if (isSearching) {
            let payload = {
                rsId: row._id,
                query: {
                    isSingle: true,
                },
            };
            readyStockSingleTrigger(payload);
        } else {
            setSingleCard(row)
        }
    };

    useEffect(() => {
        if (isMatchingSuccess && !isMatchingFetching) {
            setSingleCard(readyStockSingleResult?.data?.data?.jobWorks?.[0]);
        }
    }, [isMatchingSuccess, isMatchingFetching]);


    useEffect(() => {
        let searchData: any = {};
        let isAllEmpty = true;
        Object.keys(search).map((key: any) => {
            if (search[key] && search[key].text !== "") {
                searchData[key] = search[key].text;
                isAllEmpty = false;
            }
        });
        if (Object.keys(searchData).length != 0) {
            searchData = { ...searchData };
            setIsSearching(true);
            // cardTrigger(searchData);
            clearTimeout(delayedCardTrigger.current);
            delayedCardTrigger.current = setTimeout(() => {
                latestRequest.current = allTrigger(searchData);
            }, 300);
        } else {
            setIsSearching(false);
        }
    }, [search]);

    useEffect(() => {
        if (isAllSuccess && !isAllFetching) {
            console.log("allResult", allResult?.data?.data);
            setCardData(allResult?.data?.data?.readyStock);
            setReadyStock(allResult?.data?.data?.count);
            setSingleCard(allResult?.data?.data?.readyStock?.[0])
        }
    }, [isAllSuccess, isAllFetching]);
    const searchComponent = (key: string, label: string) => {
        return (
            <div className={`text-left`}>
                {
                    search[key]?.status ? (
                        <TextBox
                            type={"text"}
                            placeholder={label}
                            autoFocus
                            className="font-light"
                            value={search[key]?.text}
                            onChange={(e: any) => {
                                const searchText = e.target.value;
                                if (searchText.length === 0) {
                                    const updatedSearch = {
                                        ...search,
                                        [key]: {
                                            text: "",
                                            status: false,
                                        },
                                    };
                                    setSearch(updatedSearch);

                                    const allTextsEmpty = Object.values(updatedSearch).every(
                                        (item: any) => item?.text === ""
                                    );

                                    if (allTextsEmpty) {
                                        localStorage.removeItem("filterData");
                                    } else {
                                        saveToLocalStorage(updatedSearch);
                                    }
                                    setCardData(allResult?.data?.data?.readyStock);
                                    setReadyStock(allResult?.data?.data?.count);
                                } else {
                                    const updatedSearch = {
                                        ...search,
                                        [key]: {
                                            text: searchText,
                                            status: true,
                                        },
                                    };
                                    setSearch(updatedSearch);
                                    saveToLocalStorage(updatedSearch);
                                }
                            }}
                        />
                    ) : (
                        <div className="mb-2 flex flex-col">
                            <div
                                className="mb-1 cursor-pointer"
                                onClick={() => {
                                    onSearch(key);
                                }}
                            >
                                {label}
                            </div>
                            {search[key]?.text !== "" && search[key]?.text && (
                                <div className="flex justify-center items-center m-1 font-medium py-1 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
                                    <div className="flex flex-auto flex-row-reverse">
                                        <i
                                            className="fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4"
                                            onClick={() => {
                                                setSearch({
                                                    ...search,
                                                    [key]: {
                                                        text: "",
                                                        status: false,
                                                    },
                                                });
                                                const storedData: any =
                                                    localStorage.getItem("filterData");
                                                const parsedData: any = JSON.parse(storedData);
                                                if (
                                                    parsedData &&
                                                    parsedData.data &&
                                                    parsedData.data[key]
                                                ) {
                                                    delete parsedData.data[key]?.text;
                                                    localStorage.setItem(
                                                        "filterData",
                                                        JSON.stringify(parsedData)
                                                    );
                                                }
                                            }}
                                        ></i>
                                    </div>
                                    <div className="text-xs font-normal leading-none max-w-full flex-initial pr-4">
                                        {search[key]?.text}
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }
            </div>
        );
    };

    const saveToLocalStorage = (data: any) => {
        let obj: any = { data };
        localStorage.setItem("filterData", JSON.stringify(obj));
    };


    const columns: any = [
        {
            name: searchComponent("createAt", "Date"),
            selector: (row: { [x: string]: any }) =>
                moment(row.createAt).format(
                    "DD-MM-yyyy"
                )
        },
        {
            name: searchComponent("cardNo", "Card No"),
            selector: (row: { [x: string]: any }) => console.log("row", row),
            // selector: (row: { [x: string]: any }) => row?.cardNo,
        },
        {
            name: searchComponent("designNo", "Design No"),
            selector: (row: { [x: string]: any }) => row?.designNo,
        },
        // {
        //     name: searchComponent("partyName", "Party Name"),
        //     selector: (row: { [x: string]: any }) => row?.partys?.[0]?.partyName,
        // },
        {
            name: searchComponent("clotheName", "Fabric"),
            selector: (row: { [x: string]: any }) => row?.clothe?.name,
        },
        {
            name: searchComponent("clothePartyName", "Fabric Party"),
            selector: (row: { [x: string]: any }) => row?.clotheParty?.name,
        },
        // {
        //     name: searchComponent("billNo", "Bill No"),
        //     selector: (row: { [x: string]: any }) => row?.partys?.[0]?.billNo,
        // },
        {
            name: searchComponent("totalPcs", "Total Pcs"),
            selector: (row: { [x: string]: any }) => row?.colors?.reduce((sum: number, color: any) => sum + color.pcs, 0),

        },
        {
            name: searchComponent("sendPcs", "Send Pcs"),
            // selector: (row: { [x: string]: any }) => row?.colors?.reduce((sum:number, color:any) => sum + color.pcs, 0),
            selector: (row: { [x: string]: any }) => row?.partys?.reduce((sum: number, party: any) => {
                return sum + party.colorPcs.reduce((colorSum: number, color: any) => colorSum + color.pcs, 0);
            }, 0)
        },
        {
            name: searchComponent("balancePcs", "Balance Pcs"),
            selector: (row: { [x: string]: any }) => {
                const totalColorsPcs = row?.colors.reduce((sum: number, color: any) => sum + color.pcs, 0);
                const totalPartysPcs = row?.partys.reduce((sum: number, party: any) => {
                    return sum + party.colorPcs.reduce((colorSum: number, color: any) => colorSum + color.pcs, 0);
                }, 0);

                return totalColorsPcs - totalPartysPcs;
            }
        },
    ];

    const GetCurrentMonthData = (row: any) => {
        setSelectedRow({
            challanNo: row?.challanNo,
            _id: row._id,
        });
    };

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        return (
            <div className="flex flex-wrap gap-2">
                <button
                    className="w-max justify-start"
                    onClick={() => {
                        setResetPaginationToggle(!resetPaginationToggle);
                        setSearch({
                            date: {
                                text: "",
                                status: false,
                            },
                            cardNo: {
                                text: "",
                                status: false,
                            },
                            pcs: {
                                text: "",
                                status: false,
                            },
                            partyName: {
                                text: "",
                                status: false,
                            },
                            clotheName: {
                                text: "",
                                status: false,
                            },
                            clothePartyName: {
                                text: "",
                                status: false,
                            },
                            billNo: {
                                text: "",
                                status: false
                            },
                            totalPcs: {
                                text: "",
                                status: false
                            },
                            fromDate: {
                                text: "",
                                status: false,
                            },
                            toDate: {
                                text: "",
                                status: false,
                            },
                        });
                        handleClear();
                        allTrigger({
                            page: page,
                            limit: perPage,
                        });
                    }}
                >
                    <i className="fas fa-filter" title="Clear Filter"></i>
                </button>
                <div className="w-full md:w-max">
                    <TextBox
                        id="fromDate"
                        type="date"
                        placeholder=" "
                        label="From"
                        aria-label="Search Input"
                        onChange={(e: any) => {
                            if (e.target.value.length == 0) {
                                setSearch((previous: any) => {
                                    return {
                                        ...previous,
                                        fromDate: {
                                            text: "",
                                            status: false,
                                        },
                                    };
                                });
                                //     globalTriggers(page, perPage)
                            } else {
                                setIsSearching(true);
                                setSearch((previous: any) => {
                                    return {
                                        ...previous,
                                        fromDate: {
                                            text: e.target.value,
                                            status: false,
                                        },
                                    };
                                });
                            }
                        }}
                    />
                </div>
                <div className="w-full md:w-max">
                    <TextBox
                        id="toDate"
                        type="date"
                        placeholder=" "
                        label="To"
                        aria-label="Search Input"
                        onChange={(e: any) => {
                            if (e.target.value.length == 0) {
                                setSearch((previous: any) => {
                                    return {
                                        ...previous,
                                        toDate: {
                                            text: "",
                                            status: false,
                                        },
                                    };
                                });
                                // globalTriggers(page, perPage)
                            } else {
                                setIsSearching(true);
                                setSearch((previous: any) => {
                                    return {
                                        ...previous,
                                        toDate: {
                                            text: e.target.value,
                                            status: false,
                                        },
                                    };
                                });
                            }
                        }}
                    />
                </div>
                <div className="md:block w-full md:w-max">
                    <div className="relative flex items-center text-gray-400 focus-within:text-themeColor">
                        <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
                            <i className="fas fa-search w-4 fill-current"></i>
                        </span>
                        <input
                            type="search"
                            name="leadingIcon"
                            id="leadingIcon"
                            placeholder="Search here"
                            value={filterText}
                            onChange={(e: any) => {
                                setFilterText(e.target.value);
                            }}
                            className="w-full pl-14 pr-4 py-2 rounded-xl text-sm text-gray-600 outline-none border border-gray-500 focus:border-themeColor transition"
                        />
                    </div>
                </div>
                <div>
                    <button
                        className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                        type="button"
                        onClick={() => {
                            setIsExportData(true);
                        }}
                    >
                        <i className="fas fa-file"></i>
                        <span className="ml-2">Export Pdf</span>
                    </button>
                </div>
            </div>
        );
    }, [search, filterText, resetPaginationToggle]);

    useEffect(() => {
        if (filterText) {
            allTrigger({
                search: filterText,
            });
        }
    }, [filterText]);

    const handleDelete = (row: any) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                let updatedData = {
                    status: false,
                };
                updateReportTrigger({
                    payload: updatedData,
                    query: {
                        rsId: row._id,
                    },
                }).then((data: any) => {
                    if (data.error) {
                        Swal.fire({
                            toast: true,
                            icon: "error",
                            title: data.error.data.message,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: ERROR_MESSAGE_TIMEOUT,
                            timerProgressBar: true,
                        });
                    } else {
                        Swal.fire({
                            toast: true,
                            icon: "success",
                            title: "Ready Stock has been deleted!",
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                        });
                        allTrigger();
                    }
                });
            }
        });
    };

    const onSearch = (key: string) => {
        let searchData = search;
        setIsSearching(true);
        Object.keys(searchData).forEach((searchkey: any, value: any) => {
            let item = searchData[searchkey];
            item["status"] = false;
            return item;
        });
        setSearch({
            ...searchData,
            [key]: {
                ...search[key],
                status: !search[key]["status"],
            },
        });
    };

    const handlePageChange = (page: any) => {
        setFilterText("")
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage: any, page: any) => {
        setFilterText("")
        setPage(page);
        setPerPage(newPerPage);
    };


    return (
        <>
            <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
                <div className="w-full px-6 mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-0 -mx-3">
                        <div className="w-full max-w-full px-3 sm:flex-none cursor-pointer" onClick={() => {
                            navigate("/plainstockdashboard");
                        }}>
                            <div
                                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-white`}
                            >
                                <div className="flex-auto p-4">
                                    <div className="flex flex-row -mx-3">
                                        <div className="flex-none w-2/3 max-w-full px-3">
                                            <div>
                                                <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                                                    Plain Stock
                                                </p>
                                                <h5 className="mb-2 font-bold">
                                                    <CountUp end={0} duration={0.5} />
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="px-3 text-right basis-1/3">
                                            <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                                                <i className="ni leading-none fas fa-file-alt text-xl relative top-3.5 text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full max-w-full px-3 sm:flex-none cursor-pointer">
                            <div
                                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-gradient-to-r from-yellow-100 to-red-100`}
                            >
                                <div className="flex-auto p-4">
                                    <div className="flex flex-row -mx-3">
                                        <div className="flex-none w-2/3 max-w-full px-3">
                                            <div>
                                                <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                                                    Ready Stock
                                                </p>
                                                <h5 className="mb-2 font-bold">
                                                    <CountUp end={readyStock} duration={0.5} />
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="px-3 text-right basis-1/3">
                                            <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                                                <i className="ni leading-none fas fa-file-alt text-xl relative top-3.5 text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full max-w-full px-3 sm:flex-none cursor-pointer" onClick={() => {
                            navigate("/partyreportdashboard");
                        }}>
                            <div
                                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-white`}
                            >
                                <div className="flex-auto p-4">
                                    <div className="flex flex-row -mx-3">
                                        <div className="flex-none w-2/3 max-w-full px-3">
                                            <div>
                                                <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                                                    RF Report
                                                </p>
                                                <h5 className="mb-2 font-bold">
                                                    <CountUp end={0} duration={0.5} />
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="px-3 text-right basis-1/3">
                                            <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                                                <i className="ni leading-none fas fa-file-alt text-xl relative top-3.5 text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full pt-6 mx-auto">
                        <div className="rounded-xl bg-white">
                            <div className="p-3">
                                <button
                                    className="px-3 py-2 mr-2 mb-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                    type="button"
                                    onClick={() => {
                                        navigate("/readystockcreate");
                                    }}
                                >
                                    <i className="fas fa-folder-plus"></i>
                                    <span className="ml-2">New Ready Stock</span>
                                </button>
                            </div>
                            <div className="flex-auto px-0 pt-0 pb-2">
                                <div className="p-0 overflow-x-auto capitalize">
                                    <DataTable
                                        title={"Ready Stock"}
                                        className="touch-auto"
                                        columns={columns}
                                        progressPending={isAllFetching}
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                        progressComponent={<DataTableLoader />}
                                        data={cardData}
                                        fixedHeader={true}
                                        defaultSortFieldId="invoiceNo"
                                        defaultSortAsc={true}
                                        pagination
                                        striped={true}
                                        responsive={true}
                                        persistTableHead={true}
                                        highlightOnHover
                                        customStyles={customStyles}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        onRowClicked={(e: any) => {
                                            GetCurrentMonthData(e);
                                            setIsModalOpen(true);
                                        }}
                                        paginationPerPage={20}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    {isModalOpen ? (
                        <TableAction
                            header={selectedRow?.challanNo}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            selectedRow={selectedRow}
                            isCopy={false}
                            isPreview={true}
                            openInvoicePreview={openInvoicePreview}
                            handleDelete={handleDelete}
                            route={"readyStock/update"}
                        />
                    ) : null}
                    {showReportPreview && (
                        <EmployeeReportPreview
                            showReportPreview={showReportPreview}
                            setShowReportPreview={setShowReportPreview}
                            singleReport={selectedRow}
                            pageName="Report"
                        />
                    )}

                    {showReadyStockPreview && (
                        <ReadyStockDataPreviewPdf
                            showReadyStockPreview={showReadyStockPreview}
                            setShowReadyStockPreview={setShowReadyStockPreview}
                            id={singleCard?._id}
                            pageName="Ready Stock Preview"
                        />
                    )}

                    {isExportData && (
                        <ReadyStockDataPdf
                            isExportData={isExportData}
                            setIsExportData={setIsExportData}
                            sampleCard={cardData}
                            allCount={readyStock}
                            isSearching={isSearching}
                            search={search}
                            pageName="Ready Stock Data"
                        />
                    )}


                </div>
            </div>
        </>
    );
}
