import React, { useMemo, useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router";
import CountUp from "react-countup";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../../utils/constants";
import TextBox from "../../../components/form/TextBox";
import moment from "moment";
import customStyles from "../../../utils/globalFunctions";
import DataTableLoader from "../../../components/page/DataTableLoader";
import {
  useLazyGetAllReportDataQuery,
  useUpdateEmployeeReportMutation,
} from "../../../servicesRtkQuery/publicApi";
import { TableAction } from "../../../components/modal/TableAction";
import { EmployeeReportPreview } from "../../../components/pdf/report/employeeReportPreview";
import { DateComponent } from "../../../components/modal/Date";

export default function EmployeeReportDashboard() {
  const navigate = useNavigate();

  const [allTrigger, allResult] = useLazyGetAllReportDataQuery();
  const {
    isSuccess: isAllSuccess,
    isFetching: isAllFetching,
    isError: isAllError,
    error: allError,
  } = allResult;

  const [cardData, setCardData]: any = useState<any>([]);
  const [updateReportTrigger] = useUpdateEmployeeReportMutation();
  const [showReportPreview, setShowReportPreview] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState("");
  const [currDate]: any = useState(new Date());

  // Counters
  const [dressCount, setDressCount]: any = useState(0);

  useEffect(() => {
    allTrigger();
  }, []);

  useEffect(() => {
    if (isAllSuccess && !isAllFetching) {
      setCardData(allResult?.data?.data?.report);
      setDressCount(allResult?.data?.data?.count);
    }
  }, [isAllSuccess, isAllFetching]);

  const openInvoicePreview = () => {
    setShowReportPreview(true);
  };

  const columns: any = [
    {
      name: "Date",
      selector: (row: { [x: string]: any }) =>
        moment(new Date(currDate.getFullYear(), currDate.getMonth(), 1)).format(
          "DD-MM-yyyy"
        ),
    },
    {
      name: "Employee Name",
      selector: (row: { [x: string]: any }) => row?.name,
    },
    {
      name: "Total Salary",
      selector: (row: { [x: string]: any }) => row?.totalRate,
    },
    {
      name: "Create At",
      selector: (row: { [x: string]: any }) =>
        moment(row.createAt).format(
          "DD-MM-yyyy"
        ),
    },
    {
      name: "Action",
      selector: (row: any) => {
        return (
          <>
            <button
              type="button"
              className="me-5"
              onClick={() => {
                navigate({
                  pathname: `/createemployeereport/${row._id}`,
                });
              }}
            >
              <i className="fas fa-edit text-lg -ml-px" title="Edit"></i>
            </button>
            <button
              type="button"
              className="me-5"
              onClick={() => {
                setSelectedRow(row);
                openInvoicePreview();
              }}
            >
              <i
                className="fas fa-file-download text-lg -ml-px"
                title="Download"
              ></i>
            </button>
            <button
              type="button"
              className="me-5"
              onClick={() => {
                handleDelete(row);
                // setIsModalOpen(false);
              }}
            >
              <i className="fas fa-trash-alt text-lg -ml-px" title="Delete"></i>
            </button>
          </>
        );
      },
    },
  ];

  const GetCurrentMonthData = (row: any) => {
    const workData: any = [];
    var workTotal: any = 0;
    var totalPcs: any = 0;
    let today: any = new Date() as any;

    row?.work?.forEach((element: any, index: any) => {
      var date: any = new Date(element.date);
      if (
        element.status === true &&
        date.getMonth() + 1 === today.getMonth() + 1
      ) {
        workData?.push({
          srNo: index + 1,
          type: element?.type,
          date: element?.date,
          cardNo: element?.cardNo,
          party: element?.party,
          challanNo: element?.challanNo,
          designNo: element?.designNo,
          clothe: element?.clothe,
          pcs: element?.pcs,
          rate: element?.rate,
          totalWork: element?.totalWork,
          status: element?.status,
        });
      }
    });
    workTotal = workData
      ?.filter((row: any) => row?.status === true)
      .reduce(
        (total: number, currentItem: any) =>
          total + parseInt(currentItem.pcs) * parseInt(currentItem.rate),
        0
      );
    totalPcs = workData
      ?.filter((row: any) => row?.status === true)
      .reduce(
        (total: number, currentItem: any) => total + parseInt(currentItem.pcs),
        0
      );
    setSelectedRow({
      work: [...workData],
      name: row.name,
      date: row.date,
      totalRate: workTotal,
      totalPcs: totalPcs,
      _id: row._id,
    });
  };

  // const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // const subHeaderComponentMemo = useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };

  //   return (
  //     <div className="md:block w-full md:w-max">
  //       <div className="relative flex items-center text-gray-400 focus-within:text-themeColor">
  //         <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
  //           <i className='fas fa-search w-4 fill-current'></i>
  //         </span>
  //         <input type="search" name="leadingIcon" id="leadingIcon" placeholder="Search here" value={filterText} onChange={(e: any) => {
  //           setFilterText(e.target.value)
  //         }} className="w-full pl-14 pr-4 py-2 rounded-xl text-sm text-gray-600 outline-none border border-gray-500 focus:border-themeColor transition" />
  //       </div>
  //     </div>
  //   );
  // }, [filterText, resetPaginationToggle]);

  // useEffect(() => {
  //   if (filterText) {
  //     allTrigger({
  //       search: filterText,
  //     });
  //   } else {
  //     allTrigger();
  //   }
  // }, [filterText]);

  const handleDelete = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let updatedData = {
          status: false,
        };
        updateReportTrigger({
          payload: updatedData,
          query: {
            rId: row._id,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Report has been deleted!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            allTrigger();
          }
        });
      }
    });
  };

  return (
    <>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="w-full px-6 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-0 -mx-3">
            <div className="w-full max-w-full px-3 sm:flex-none cursor-pointer">
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-gradient-to-r from-yellow-100 to-red-100`}
              >
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                          Employee Reports
                        </p>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={dressCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i className="ni leading-none fas fa-file-alt text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full pt-6 mx-auto">
            <div className="rounded-xl bg-white">
              <div className="p-3">
                <button
                  className="px-3 py-2 mr-2 mb-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  type="button"
                  onClick={() => {
                    navigate("/createemployeereport");
                  }}
                >
                  <i className="fas fa-folder-plus"></i>
                  <span className="ml-2">New Report</span>
                </button>
              </div>
              <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto capitalize">
                  <DataTable
                    title={"Employee Reports"}
                    className="touch-auto"
                    columns={columns}
                    // subHeaderComponent={subHeaderComponentMemo}
                    paginationResetDefaultPage={resetPaginationToggle}
                    progressPending={isAllFetching}
                    progressComponent={<DataTableLoader />}
                    data={cardData}
                    fixedHeader={true}
                    defaultSortFieldId="invoiceNo"
                    defaultSortAsc={true}
                    pagination
                    striped={true}
                    responsive={true}
                    persistTableHead={true}
                    highlightOnHover
                    // subHeader
                    customStyles={customStyles}
                    onRowClicked={(e: any) => {
                      GetCurrentMonthData(e);
                      setIsModalOpen(true);
                    }}
                    paginationPerPage={20}
                  />
                </div>
              </div>
            </div>
          </div>
          {isModalOpen ? (
            <DateComponent
              header={selectedRow?.name}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              selectedRow={selectedRow}
              isCopy={false}
              isPreview={true}
              openInvoicePreview={openInvoicePreview}
              handleDelete={handleDelete}
              route={"createemployeereport"}
            />
          ) : null}
          {showReportPreview && (
            <EmployeeReportPreview
              showReportPreview={showReportPreview}
              setShowReportPreview={setShowReportPreview}
              singleReport={selectedRow}
              pageName="Report"
            />
          )}
        </div>
      </div>
    </>
  );
}
