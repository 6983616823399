import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import Select from "react-select";
import { useChangeFinancialYearMutation } from "../../servicesRtkQuery/publicApi";
import { getUserDetailFromLocalSorage, setUserDetailInLocalSorage } from "../../utils/localStorage";

export const FinancialYearChange = (props: any) => {
    const { header, isModalOpen, setIsModalOpen, onYearChange } = props;
    const [selectedYear, setSelectedYear] = useState<any>(null);
    const [changeFinancialYear] = useChangeFinancialYearMutation();

    const Close = (
        <i className="fas fa-times bg-black text-white rounded-full px-2 py-1.5"></i>
    );

    // Generate financial year options (e.g., 2023-24, 2022-23, etc.)
    const generateYearOptions = () => {
        const startYear = 2022;
        const currentYear = new Date().getFullYear();
        const years = [];
        const currentMonth = new Date().getMonth();
        const currentFinancialYearStart =
            currentMonth > 4 ? currentYear : currentYear - 1;

        for (let year = startYear; year <= currentFinancialYearStart + 1; year++) {
            years.push({
                value: `${year}-${year + 1}`,
                label: `${year}-${year + 1}`,
            });
        }
        return years;
    };

    useEffect(() => {
        setSelectedYear(generateYearOptions().find((option: any) => option.label === getUserDetailFromLocalSorage()?.financialYear));
    }, [isModalOpen]);


    const handleSubmit = async () => {
        if (selectedYear) {
            try {
                const response = await changeFinancialYear({ financialYear: selectedYear.label });
                onYearChange(selectedYear.label);
                setIsModalOpen(false);
                setUserDetailInLocalSorage({
                    ...getUserDetailFromLocalSorage(),
                    financialYear: response?.data?.data?.financialYear,
                    access_token: response?.data?.data?.access_token,
                    refresh_token: response?.data?.data?.refresh_token
                });
                window.location.reload(); // Refresh the current location data
            } catch (error) {
                console.error('Failed to change financial year:', error);
            }
        }
    };

    return (
        <div>
            <Modal
                open={isModalOpen}
                closeIcon={Close}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                center
                closeOnEsc={false}
                closeOnOverlayClick={false}
                onOverlayClick={() => {
                    setIsModalOpen(false);
                }}
                onEscKeyDown={() => {
                    setIsModalOpen(false);
                }}
                classNames={{
                    modal: "rounded-xl pb-0",
                }}
                styles={{
                    modal: { background: "#e9ecef", width: '500px', overflow: 'visible' },
                    overlay: {
                        height: "auto",
                    },
                }}
            >
                <div className="p-2 mt-5">
                    <div className="font-bold mb-2 text-center">
                        {header} - {selectedYear ? selectedYear.label : "Select a year"}
                    </div>
                    <div className="p-1 flex justify-between items-center">
                        <Select
                            className="w-full mb-2 mr-2"
                            placeholder="Select Financial Year"
                            options={generateYearOptions()}
                            value={selectedYear}
                            onChange={(option) => setSelectedYear(option)}
                            isSearchable={false}
                            styles={{ control: (base) => ({ ...base, width: '100%', minWidth: '200px', height: '50px' }) }} // Increased dropdown width and height
                        />
                        <button
                            className="px-4 py-3 mr-2 mb-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-full shadow-md bg-white hover:shadow-md"
                            onClick={handleSubmit}
                            disabled={!selectedYear}
                            style={{ borderRadius: '50px', height: '50px' }} // Increased button size and height
                        >
                            <i className="fas fa-check text-xl text-black hover:text-themeColor"></i>
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default FinancialYearChange;
