import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactToPrint from "react-to-print";
import moment from "moment";
import {
    useLazyGetMatchingCardByKeywordQuery,
    useLazyMatchingCardQuery,
} from "../../../servicesRtkQuery/publicApi";
import DataTableLoader from "../../page/DataTableLoader";

export const MatchingCardPartyDataPdf = (props: any) => {
    const exportRef: any = useRef(null);
    var { matchingCard, allCount, isSearching, totalTPPMCount, search, type, isPartyPdf,reminderDays } =
        props;
    const [cardData, setCardData]: any = useState();
    const [trigger, result] = useLazyMatchingCardQuery();
    const { isSuccess, isFetching, isError, error } = result;
    const [cardTrigger, cardResult] = useLazyGetMatchingCardByKeywordQuery();
    const {
        isSuccess: isCardSuccess,
        isFetching: isCardFetching,
        isError: isCardError,
        error: cardError,
    } = cardResult;
    useEffect(() => {
        if (!isSearching) {
            trigger({
                limit: allCount,
            });
        } else {
            setCardData(matchingCard);
        }
    }, []);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setCardData(result?.data?.data?.matchingCard);
            totalTPPMCount = result?.data?.data?.TTPPM;
        }
    }, [isSuccess, isFetching]);

    useEffect(() => {
        if (isCardSuccess && !isCardFetching) {
            setCardData(cardResult?.data?.data?.matchingCard);
        }
    }, [isCardSuccess, isCardFetching]);

    useEffect(() => {
        let searchData: any = {
            limit: allCount,
            partyWise: true,
        };
        Object.keys(search).map((key: any) => {
            if (search[key] && search[key].text !== "") {
                searchData[key] = search[key].text;
            }
        });
        if (type !== "all") {
            searchData = { ...searchData, partyWise: true, type: type };
        }
        if (Object.keys(searchData).length != 0) {
            cardTrigger(searchData);
        }
    }, [search]);
    console.log(cardData)
    return (
        <div>
            <Modal
                open={props.isPartyPdf}
                showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl",
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    },
                    modalContainer: {
                        overflow: "auto",
                    },
                }}
                onEscKeyDown={() => props.setIsPartyPdf(false)}
                onOverlayClick={() => props.setIsPartyPdf(false)}
                aria-labelledby="sample-card-pdf"
                aria-describedby="sample-card-pdf-description"
                animationDuration={400}
                onClose={() => props.setIsPartyPdf(false)}
            >
                <div className="overflow-auto">
                    <div className="text-center">
                        <div className="bg-white border rounded-lg text-left shadow-xl">
                            <div ref={exportRef} className={`a5-portrait overflow-auto`}>
                                <div className="border border-gray-600">
                                    <div className="font-bold items-center p-2 bg-gray-100 text-5">
                                        <div className="text-themeColor capitalize text-center">
                                            Matching Card Party Wise Data
                                        </div>
                                    </div>
                                    {!isFetching ? (
                                        <div className="">
                                            <div className={`text-sm`}>
                                                <table className="w-full border-collapse bg-white text-left">
                                                    <thead className="bg-gray-100 divide-black border-t border-black text-4">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                sr no
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                card
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                date
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                days
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                party name
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                design no
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-16 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                challan no
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                total pcs
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                clothe
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-19 text-red-800 border-black capitalize text-center"
                                                            >
                                                                {" "}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y text-center divide-black border-t border-black text-4">
                                                        {cardData?.map((item: any, i: any) => {
                                                            return (
                                                                <>
                                                                    <tr key={i}>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                                                            {cardData.filter((data: any) => data?.party?.name === item?.party?.name).indexOf(item) + 1}
                                                                        </td>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                                                            {item?.invoiceNo + "-" + item?.cardNo}
                                                                        </td>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                            {moment(item?.date).format("DD-MM-YYYY")}
                                                                        </td>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                            {/* {item?.delivery === "pending"
                                                                                ? Math.ceil((new Date().getTime() - new Date(item?.date).getTime()) / (1000 * 3600 * 24)) > 20
                                                                                    ? <span className="text-red-500">{Math.ceil((new Date().getTime() - new Date(item?.date).getTime()) / (1000 * 3600 * 24))}</span>
                                                                                    : Math.ceil((new Date().getTime() - new Date(item?.date).getTime()) / (1000 * 3600 * 24))
                                                                                : 0} */}
                                                                            <span style={{ color: item?.days > reminderDays ? "red" : "inherit" }}>{item?.days}</span>
                                                                        </td>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                            {item?.party?.name}
                                                                        </td>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                            {item?.designNo}
                                                                        </td>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                            {item?.challanNo}
                                                                        </td>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                            {item?.TPPM}
                                                                        </td>
                                                                        <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                            {item?.clothe?.name}
                                                                        </td>
                                                                        <td className="gap-3 border-black font-semibold text-gray-900 text-center capitalize">
                                                                            {" "}
                                                                        </td>
                                                                    </tr>
                                                                    {i <= cardData.length - 1 && cardData[i + 1]?.party?.name !== item?.party?.name && (
                                                                        <>
                                                                            <tr>
                                                                                {/* <td
                                            colSpan={type === "all" ? 12 : 11}
                                            className="gap-3 border-r border-black font-semibold text-gray-900 capitalize"
                                        >
                                            Total{" "}
                                        </td> */}
                                                                                <td
                                                                                    colSpan={7}
                                                                                    className="gap-3 border-r border-black font-semibold text-gray-900 capitalize"
                                                                                >

                                                                                </td>
                                                                                <td className="gap-3 border-2 text-lg text-black border-black font-semibold text-gray-900 capitalize">
                                                                                    {cardData.reduce((acc: number, curr: any) => curr.party?.name === cardData[i]?.party?.name ? acc + (curr.TPPM || 0) : acc, 0)}
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-none h-3">

                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </tbody>
                                                    {/* <tfoot className="divide-y text-center divide-black bg-gray-100 border-t border-black text-4">
                                                        <tr>
                                                            <td
                                                                colSpan={7}
                                                                className="gap-3 border-r border-black font-semibold text-gray-900 capitalize"
                                                            >
                                                                Total{" "}
                                                            </td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                                                {totalTPPMCount}
                                                            </td>
                                                            <td
                                                                colSpan={2}
                                                                className="gap-3 border-black font-semibold text-gray-900 capitalize"
                                                            ></td>
                                                        </tr>
                                                    </tfoot> */}
                                                </table>
                                            </div>

                                            <div className={`text-sm mt-10 w-[50%] ml-14`}>
                                                <table className="w-full border-collapse bg-white text-left">
                                                    {/* <thead className="bg-gray-100 divide-black border-t border-black text-4">
                                                        <tr>

                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Party name
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Total Pcs
                                                            </th>



                                                        </tr>
                                                    </thead> */}
                                                    <tbody className="divide-y text-center divide-black border border-black text-4">
                                                        {cardData?.reduce((unique: any, item: any) => {
                                                            return unique.some((i: any) => i.party.name === item.party.name) ? unique : [...unique, item];
                                                        }, []).map((item: any, i: any) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize" style={{ width: '50%' }}>
                                                                        {item?.party?.name}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize" style={{ width: '50%' }}>
                                                                        {cardData.filter((data: any) => data?.party?.name === item?.party?.name).reduce((acc: number, curr: any) => acc + (curr?.TPPM || 0), 0).toString()}
                                                                    </td>

                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>

                                                </table>
                                            </div>


                                            <div className={`text-sm mt-10 w-[50%] ml-14`}>
                                                <table className="w-full border-collapse bg-white text-left">
                                                    <tbody className="divide-y text-center divide-black border border-black text-4">
                                                        <tr>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize" style={{ width: '50%' }}>
                                                                Total Pcs
                                                            </td>
                                                            <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize" style={{ width: '50%' }}>
                                                                {totalTPPMCount}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        <DataTableLoader />
                                    )}
                                </div>
                            </div>
                            <div className="m-3 flex flex-col lg:flex-row gap-2">
                                <div>
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                                                Print <i className="fas fa-file-download"></i>
                                            </button>
                                        )}
                                        content={() => exportRef.current}
                                        documentTitle={props.pageName}
                                    />
                                </div>
                                <div>
                                    <button
                                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                                        onClick={() => props.setIsPartyPdf(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
