import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactToPrint from "react-to-print";
import {
  convertImageToBase64,
  getFormattedNumber,
} from "../../../utils/globalFunctions";
import moment from "moment";
import { useLazyGetJobWorkQuery } from "../../../servicesRtkQuery/publicApi";

export const JobWorkPreview = (props: any) => {
  const exportRef: any = useRef(null);
  const [multiPagePrint, setMultiPagePrint]: any = useState(false);
  const [logo, setLogo]: any = useState();

  let total: any = 0;
  const [challan, setChallan]: any = useState();
  const id = props?.id;
  const [trigger, result] = useLazyGetJobWorkQuery();
  const { isSuccess, isFetching, isError, error } = result;

  useEffect(() => {
    trigger(id);
  }, []);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setChallan(result?.data?.data?.jobWorks?.[0]);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    convertImageToBase64(
      "https://cnt-satv.s3.ap-south-1.amazonaws.com/logo.png",
      setLogo
    );
  }, [challan]);

  challan?.work?.forEach((card: any) => {
    total = total + card?.PM;
  });

  console.log("challan", challan);

  return (
    <div>
      <Modal
        open={props.showPreview}
        showCloseIcon={false}
        closeOnEsc={false}
        onEscKeyDown={() => props.setShowPreview(false)}
        onOverlayClick={() => props.setShowPreview(false)}
        closeOnOverlayClick={false}
        classNames={{
          modal: "rounded-xl",
        }}
        styles={{
          overlay: {
            height: "auto",
            width: "auto",
          },
          modalContainer: {
            overflow: "auto",
          },
        }}
        aria-labelledby="dressrate-card-pdf"
        aria-describedby="dressrate-card-pdf-description"
        animationDuration={400}
        onClose={() => props.setShowPreview(false)}
      >
        <div className="overflow-auto p-2">
          <div className="flex ml-5 mb-2">
            <label className="font-normal mr-2 cursor-pointer select-none text-sm text-slate-700 ">
              Single Print
            </label>
            <div className="flex flex-row items-center pl-12 mb-0.5 text-left min-h-6">
              <input
                id="originalImage"
                className="mt-0.5 rounded-10 duration-250 ease-in-out after:rounded-circle after:shadow-2xl after:duration-250 checked:after:translate-x-5.3 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-zinc-700/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-themeColor checked:bg-themeColor checked:bg-none checked:bg-right"
                type="checkbox"
                onChange={(e: any) => {
                  setMultiPagePrint(e.target.checked);
                }}
              />
            </div>
            <label className="ml-2 font-normal cursor-pointer select-none text-sm text-slate-700 ">
              Two Print
            </label>
          </div>
          <div ref={exportRef} className={`overflow-auto a4-portrait`}>
            {Array(multiPagePrint ? 2 : 1)
              .fill(" ")
              .map((row: any, index: number) => {
                return (
                  <div className="border border-gray-600 mb-8">
                    <div className="grid grid-cols-6 font-bold items-center p-2 bg-gray-100">
                      <div className=" flex justify-center capitalize">
                        <img src={logo} alt="सत्व" />
                      </div>
                      <div className="col-span-2 text-center ">
                        <div className="text-4xl my-1">Satv</div>
                        <div>Mo. 9724783030</div>
                      </div>
                      <div className="col-span-3 font-semibold text-lg">
                        <div className="capitalize">
                          <span className="">Name : </span>
                          {challan?.cName?.name}
                        </div>
                        <div className="capitalize">
                          <span className=" capitalize">Address : </span>
                          {challan?.cName?.address}
                        </div>
                        <div className="capitalize">
                          <span className=" capitalize">Job Ch No : </span>
                          {challan?.jobNo}
                        </div>
                      </div>
                    </div>
                    <div className="p-2 border-y border-black">
                      <div className={`text-sm grid grid-cols-6 gap-2`}>
                        <div className="col-span-6">
                          <table className="w-full border border-collapse bg-white text-left">
                            <thead className="bg-themeColor/40 divide-black border-t border-x border-black text-4">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r w-1/6  border-black capitalize text-center"
                                >
                                  Party Name
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r w-1/6  border-black capitalize text-center"
                                >
                                  P Ch. No.
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r w-1/6  border-black capitalize text-center"
                                >
                                  Design No
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r w-1/6  border-black capitalize text-center"
                                >
                                  Date
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r w-1/6  border-black capitalize text-center"
                                >
                                  Item
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r w-1/6  border-black capitalize text-center"
                                >
                                  Card No.
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y text-center divide-black border border-black text-4">
                              <tr>
                                <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                  {challan?.party?.shortName}
                                </td>
                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                  {challan?.PCNo}
                                </td>
                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                  {challan?.designNo}
                                </td>
                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                  {challan?.date &&
                                    moment(challan?.date).format("DD/MM/YYYY")}
                                </td>
                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                  {challan?.item?.name}
                                </td>
                                <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                  {challan?.card?.number.split("-")[0]}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-span-6 mt-2">
                          <table className="w-full border border-collapse bg-white text-left">
                            <thead className="bg-themeColor/40 divide-black border-t border-x border-black text-4">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-black capitalize text-center"
                                >
                                  Description
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-black capitalize text-center"
                                >
                                  Fabric
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-black capitalize text-center"
                                >
                                  Color
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-black capitalize text-center"
                                >
                                  Quantity
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-black capitalize text-center"
                                >
                                  Pcs/Mtr
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y text-center divide-black border border-black text-4">
                              {challan?.work?.map((item: any) => {
                                return (
                                  <tr>
                                    <td className="gap-3 py-1 border-r border-black font-semibold text-gray-900 capitalize">
                                      {item?.description?.name}
                                    </td>
                                    <td className="gap-3 py-1 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.clothe?.name}
                                    </td>
                                    <td className="gap-3 py-1 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.fabricColor?.colorName}
                                    </td>
                                    <td className="gap-3 py-1 border-r border-black font-semibold text-gray-900 capitalize">
                                      {item?.remark?.name}
                                    </td>
                                    <td className="gap-3 py-1 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.PM}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td
                                  colSpan={4}
                                  className="px-3 gap-3 border-r border-black font-semibold text-gray-900 capitalize text-left"
                                >
                                  &nbsp;
                                </td>
                                <td className="gap-3 border-2 border-black font-semibold text-gray-900 capitalize">
                                  {total}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="w-full border border-collapse bg-white text-left mt-2">
                            <thead className="bg-themeColor/40 divide-black border border-black text-4">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-b  border-black capitalize text-center"
                                >
                                  Total Pcs/Mtr
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-b  border-black capitalize text-center"
                                >
                                  Rate
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-b  border-black capitalize text-center"
                                >
                                  Total Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y text-center divide-black border border-black text-4">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-black capitalize text-center"
                                >
                                  {total}
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-black capitalize text-center"
                                >
                                  {challan?.rate}
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 border-r border-black capitalize text-center"
                                >
                                  {total * Number(challan?.rate)}
                                </th>
                              </tr>
                            </tbody>
                          </table>

                          <table className="w-full border border-collapse bg-white text-left mt-2">
                            <thead className="bg-themeColor/40 divide-black border border-black text-4">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-1.5 pl-3 border-r  border-black capitalize text-left"
                                >
                                  {challan?.cName?.name}
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 pl-3 border-r  border-black capitalize text-left"
                                >
                                  Satv Job Recieved
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 pl-3 border-r  border-black capitalize text-left"
                                >
                                  Recieved Pcs{" "}
                                  {challan?.RTPM && `(${challan?.RTPM})`}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y text-center divide-black border border-black text-4">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-1.5 px-3 border-r border-t border-black capitalize text-left"
                                >
                                  Sign :
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 px-3 border-r border-t border-black capitalize text-left"
                                >
                                  Date :{" "}
                                  {challan?.receiveDate &&
                                    moment(challan?.receiveDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                </th>
                                <th
                                  scope="col"
                                  className="py-1.5 px-3 border-r border-t border-black capitalize text-left"
                                >
                                  Sign :
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="m-3 flex flex-col lg:flex-row gap-2">
            <div>
              <ReactToPrint
                trigger={() => (
                  <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                    Challan Print <i className="fas fa-file-download"></i>
                  </button>
                )}
                content={() => exportRef.current}
                copyStyles={true}
                documentTitle={props.pageName}
              />
            </div>
            <div>
              <button
                className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                onClick={() => props.setShowPreview(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
