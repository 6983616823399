import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Swal from "sweetalert2";
import { setApiError } from "../redux/globalSlice";
import {
  getUserRefreshTokenInLocalSorage,
  getUserTokenInLocalSorage,
  setUserTokenInLocalSorage,
} from "../utils/localStorage";
import { ERROR_MESSAGE_TIMEOUT } from "../utils/constants";

let time_zone = new Date()
  .toString()
  .substring(new Date().toString().indexOf("+") + 0)
  .slice(0, 5);
time_zone = time_zone.substring(0, 3) + ":" + time_zone.substring(3);

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_SERVER_API,
  prepareHeaders: (headers) => {
    headers.set(
      "Authorization",
      getUserTokenInLocalSorage()
        ? getUserTokenInLocalSorage()
        : `Basic ${process.env.REACT_APP_BASE_TOKEN}`
    );
    return headers;
  },
});

const baseQueryForRefreshToken = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_SERVER_API,
  prepareHeaders: (headers) => {
    headers.set("Authorization", `Basic ${process.env.REACT_APP_BASE_TOKEN}`);
    return headers;
  },
});

const baseQueryMiddleware = async (args: any, api: any, extraOptions: any) => {
  let result: any = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === "FETCH_ERROR") {
    if (result?.error?.error !== "AbortError: The user aborted a request.") {
      Swal.fire({
        toast: true,
        icon: "error",
        title: "Something went wrong!\nPlease contact the administrator.",
        position: "bottom",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
    }
    // localStorage.clear();
    return;
  }
  if (result.error && result.error.status === 401) {
    //generate new access token
    const refreshResult = await baseQueryForRefreshToken(
      {
        url: "/auth/accessToken",
        method: "POST",
        body: {
          token: getUserRefreshTokenInLocalSorage(),
        },
      },
      api,
      extraOptions
    );

    //check token result
    if (refreshResult.data) {
      result = await baseQuery(args, api, extraOptions);
      setUserTokenInLocalSorage(result.data.data);
    } else {
      // alert('You are unathorized. please login again')
      localStorage.clear();
      window.location.reload();
      return;
    }
  }
  if (result.error && result.error.originalStatus > 499) {
    const query: any = {
      isShowError: Math.random(),
      statusCode: result?.error?.originalStatus,
    };
    api.dispatch(setApiError(query));
    return;
  }
  return result;
};

export const publicApiSlice: any = createApi({
  reducerPath: "publicApi",
  baseQuery: baseQueryMiddleware,
  endpoints: (builder) => ({
    //#region User
    login: builder.query({
      query: (payload) => ({
        url: "user/login",
        method: "POST",
        body: payload,
      }),
    }),
    register: builder.query({
      query: (payload) => ({
        url: "register",
        method: "POST",
        body: payload,
      }),
    }),
    user: builder.query({
      query: (payload) => ({
        url: "user",
        method: "GET",
        params: payload,
      }),
    }),
    addUser: builder.mutation({
      query: (payload) => ({
        url: "user",
        method: "POST",
        body: payload,
      }),
    }),
    updateUser: builder.mutation({
      query: (payload) => ({
        url: `user?userId=${payload.query.userId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Setting
    setting: builder.query({
      query: (payload) => ({
        url: "setting",
        method: "GET",
        params: payload,
      }),
    }),
    singleSetting: builder.query({
      query: (metaKey) => ({
        url: `setting?metaKey=${metaKey}`,
        method: "GET",
      }),
    }),
    addSetting: builder.mutation({
      query: (payload) => ({
        url: "setting",
        method: "POST",
        body: payload,
      }),
    }),
    updateSetting: builder.mutation({
      query: (payload) => ({
        url: `setting?metaId=${payload.query.metaId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion

    //changed financial year
    changeFinancialYear: builder.mutation({
      query: (payload) => ({
        url: "/user/change-fyear",
        method: "POST",
        body: payload,
      }),
    }),

    //#region MatchingCard
    matchingCard: builder.query({
      query: (payload) => ({
        url: `matching-card`,
        method: "GET",
        params: payload,
      }),
    }),
    getMatchingCard: builder.query({
      query: (payload) => ({
        url: `matching-card?mcId=${payload?.mcId}`,
        method: "GET",
        params: payload?.query
      }),
    }),
    addMatchingCard: builder.mutation({
      query: (payload) => ({
        url: "matching-card",
        method: "POST",
        body: payload,
      }),
    }),
    updateMatchingCard: builder.mutation({
      query: (payload) => ({
        url: `matching-card?mcId=${payload.query.mcId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    copyMatchingCard: builder.query({
      query: (mcId) => ({
        url: `matching-card/newCardNumber?mcId=${mcId}`,
        method: "GET",
      }),
    }),
    newMatchingInvoiceNumber: builder.query({
      query: (payload) => ({
        url: "matching-card/newInvoiceNumber",
        method: "GET",
        params: payload,
      }),
    }),
    getMatchingCardByType: builder.query({
      query: (payload) => ({
        url: `matching-card`,
        method: "GET",
        params: payload,
      }),
    }),
    getMatchingCardByKeyword: builder.query({
      query: (payload) => ({
        url: `matching-card/search`,
        method: "GET",
        params: payload,
      }),
    }),
    getMatchingCardByReport: builder.query({
      query: (payload) => ({
        url: `matching-card/report`,
        method: "GET",
        params: payload,
      }),
    }),

    allMatchingInvoiceNumber: builder.query({
      query: (payload) => ({
        url: `matching-card/newInvoiceNumber?invoiceNo=${payload?.invoiceNo}&type=${payload?.type}`,
        method: "GET",
      }),
    }),
    matchingNewCardNumberB: builder.query({
      query: (id) => ({
        url: `matching-card/newCardNumberB?mcId=${id}`,
        method: "GET",
      }),
    }),
    getMatchingCardCount: builder.query({
      query: () => ({
        url: `matching-card/count`,
        method: "GET",
      }),
    }),
    //#endregion
    //#region samplecard
    newInvoiceNumber: builder.query({
      query: (payload) => ({
        url: `sample-card/newInvoiceNumber`,
        method: "GET",
        params: payload,
      }),
    }),
    sampleCard: builder.query({
      query: (payload) => ({
        url: `sample-card`,
        method: "GET",
        params: payload,
      }),
    }),
    getSampleCard: builder.query({
      query: (scId) => ({
        url: `sample-card?scId=${scId}`,
        method: "GET",
      }),
    }),
    addSampleCard: builder.mutation({
      query: (payload) => ({
        url: "sample-card",
        method: "POST",
        body: payload,
      }),
    }),
    updateSampleCard: builder.mutation({
      query: (payload) => ({
        url: `sample-card?scId=${payload.query.scId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    copySampleCard: builder.query({
      query: (scId) => ({
        url: `sample-card/newCardNumber?scId=${scId}`,
        method: "GET",
      }),
    }),
    getSampleCardCount: builder.query({
      query: () => ({
        url: `sample-card/count`,
        method: "GET",
      }),
    }),
    getSampleCardByType: builder.query({
      query: (payload) => ({
        url: `sample-card`,
        method: "GET",
        params: payload,
      }),
    }),
    getSampleCardByKeyword: builder.query({
      query: (payload) => ({
        url: `sample-card/search`,
        method: "GET",
        params: payload,
      }),
    }),
    allInvoiceNumber: builder.query({
      query: (payload) => ({
        url: `sample-card/newInvoiceNumber?invoiceNo=${payload?.invoiceNo}&type=${payload?.type}`,
        method: "GET",
      }),
    }),
    sampleNewCardNumberB: builder.query({
      query: (id) => ({
        url: `sample-card/newCardNumberB?scId=${id}`,
        method: "GET",
      }),
    }),
    //#endregion
    //#region party
    party: builder.query({
      query: (payload) => ({
        url: "party",
        method: "GET",
        params: payload,
      }),
    }),
    addParty: builder.mutation({
      query: (payload) => ({
        url: "party",
        method: "POST",
        body: payload,
      }),
    }),
    updateParty: builder.mutation({
      query: (payload) => ({
        url: `party?partyId=${payload.query.partyId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region DesignCode
    designCode: builder.query({
      query: (payload) => ({
        url: "design-code",
        method: "GET",
        params: payload,
      }),
    }),
    addDesignCode: builder.mutation({
      query: (payload) => ({
        url: "design-code",
        method: "POST",
        body: payload,
      }),
    }),
    updateDesignCode: builder.mutation({
      query: (payload) => ({
        url: `design-code?dcId=${payload.query.dcId}`,
        method: "PATCH",
        body: payload?.payload,
      }),
    }),
    //#endregion
    //#region Cloth
    cloth: builder.query({
      query: (payload) => ({
        url: "clothe",
        method: "GET",
        params: payload,
      }),
    }),
    addCloth: builder.mutation({
      query: (payload) => ({
        url: "clothe",
        method: "POST",
        body: payload,
      }),
    }),
    updateCloth: builder.mutation({
      query: (payload) => ({
        url: `clothe?clotheId=${payload.query.clotheId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    clothParty: builder.query({
      query: (payload) => ({
        url: "clotheParty",
        method: "GET",
        params: payload,
      }),
    }),
    addClothParty: builder.mutation({
      query: (payload) => ({
        url: "clotheParty",
        method: "POST",
        body: payload,
      }),
    }),
    updateClothParty: builder.mutation({
      query: (payload) => ({
        url: `clotheParty?cpId=${payload.query.cpId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Item
    item: builder.query({
      query: (payload) => ({
        url: "item",
        method: "GET",
        params: payload,
      }),
    }),
    addItem: builder.mutation({
      query: (payload) => ({
        url: "item",
        method: "POST",
        body: payload,
      }),
    }),
    updateItem: builder.mutation({
      query: (payload) => ({
        url: `item?itemId=${payload.query.itemId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region FabricColor
    fabricColor: builder.query({
      query: (payload) => ({
        url: "fabric-color",
        method: "GET",
        params: payload,
      }),
    }),
    addFabricColor: builder.mutation({
      query: (payload) => ({
        url: "fabric-color",
        method: "POST",
        body: payload,
      }),
    }),
    updateFabricColor: builder.mutation({
      query: (payload) => ({
        url: `fabric-color?fcId=${payload.query.fcId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Material
    material: builder.query({
      query: (payload) => ({
        url: "materials",
        method: "GET",
        params: payload,
      }),
    }),
    addMaterial: builder.mutation({
      query: (payload) => ({
        url: "materials",
        method: "POST",
        body: payload,
      }),
    }),
    updateMaterial: builder.mutation({
      query: (payload) => ({
        url: `materials?materialId=${payload.query.materialId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region RateCard
    dressRateCard: builder.query({
      query: (payload) => ({
        url: "rate-card/dress",
        method: "GET",
        params: payload,
      }),
    }),
    getDressRateCard: builder.query({
      query: (drId) => ({
        url: `rate-card/dress?drId=${drId}`,
        method: "GET",
      }),
    }),
    addDressRateCard: builder.mutation({
      query: (payload) => ({
        url: "rate-card/dress",
        method: "POST",
        body: payload,
      }),
    }),
    updateDressRateCard: builder.mutation({
      query: (payload) => ({
        url: `rate-card/dress?drId=${payload.query.drId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    newDressRateInvoiceNumber: builder.query({
      query: (payload) => ({
        url: "rate-card/dress/newInvoiceNumber",
        method: "GET",
        params: payload,
      }),
    }),
    allDressRateInvoiceNumber: builder.query({
      query: (payload) => ({
        url: `rate-card/dress/newInvoiceNumber?invoiceNo=${payload?.invoiceNo}`,
        method: "GET",
      }),
    }),
    allSareeRateInvoiceNumber: builder.query({
      query: (payload) => ({
        url: `rate-card/saree/newInvoiceNumber?invoiceNo=${payload?.invoiceNo}`,
        method: "GET",
      }),
    }),
    allLumpRateInvoiceNumber: builder.query({
      query: (payload) => ({
        url: `rate-card/lump/newInvoiceNumber?invoiceNo=${payload?.invoiceNo}`,
        method: "GET",
      }),
    }),
    newSareeRateInvoiceNumber: builder.query({
      query: (payload) => ({
        url: "rate-card/saree/newInvoiceNumber",
        method: "GET",
        params: payload,
      }),
    }),
    sareeRateCard: builder.query({
      query: (payload) => ({
        url: "rate-card/saree",
        method: "GET",
        params: payload,
      }),
    }),
    getSareeRateCard: builder.query({
      query: (srId) => ({
        url: `rate-card/saree?srId=${srId}`,
        method: "GET",
      }),
    }),
    addSareeRateCard: builder.mutation({
      query: (payload) => ({
        url: "rate-card/saree",
        method: "POST",
        body: payload,
      }),
    }),
    updateSareeRateCard: builder.mutation({
      query: (payload) => ({
        url: `rate-card/saree?srId=${payload.query.srId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //lump rate card
    lumpRateCard: builder.query({
      query: (payload) => ({
        url: "rate-card/lump",
        method: "GET",
        params: payload,
      }),
    }),
    getLumpRateCard: builder.query({
      query: (lrId) => ({
        url: `rate-card/lump?lrId=${lrId}`,
        method: "GET",
      }),
    }),
    addLumpRateCard: builder.mutation({
      query: (payload) => ({
        url: "rate-card/lump",
        method: "POST",
        body: payload,
      }),
    }),
    updateLumpRateCard: builder.mutation({
      query: (payload) => ({
        url: `rate-card/lump?lrId=${payload.query.lrId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    newLumpRateInvoiceNumber: builder.query({
      query: (payload) => ({
        url: "rate-card/lump/newInvoiceNumber",
        method: "GET",
        params: payload,
      }),
    }),
    getDressRateCardByKeyword: builder.query({
      query: (payload) => ({
        url: `rate-card/dress/search`,
        method: "GET",
        params: payload,
      }),
    }),
    getSareeRateCardByKeyword: builder.query({
      query: (payload) => ({
        url: `rate-card/saree/search`,
        method: "GET",
        params: payload,
      }),
    }),
    getLumpRateCardByKeyword: builder.query({
      query: (payload) => ({
        url: `rate-card/lump/search`,
        method: "GET",
        params: payload,
      }),
    }),
    allRateCard: builder.query({
      query: (payload) => ({
        url: `rate-card/count`,
        method: "GET",
      }),
    }),
    rateCardParty: builder.query({
      query: (payload) => ({
        url: `rate-card/image`,
        method: "GET",
        params: payload,
      }),
    }),
    //#endregion
    //#region diamonds
    diamonds: builder.query({
      query: (payload) => ({
        url: "diamonds",
        method: "GET",
        params: payload,
      }),
    }),
    addDiamonds: builder.mutation({
      query: (payload) => ({
        url: "diamonds",
        method: "POST",
        body: payload,
      }),
    }),
    updateDiamonds: builder.mutation({
      query: (payload) => ({
        url: `diamonds?dId=${payload.query.dId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    // #matching card stock
    matchingCardStock: builder.query({
      query: (payload) => ({
        url: "mstock",
        method: "GET",
        params: payload,
      }),
    }),
    matchingCardStockCount: builder.query({
      query: (payload) => ({
        url: "mstock/count",
        method: "GET",
        params: payload,
      }),
    }),
    updateStock: builder.mutation({
      query: (payload) => ({
        url: `mstock?msId=${payload.query.msId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    getMatchingCardStockByKeyword: builder.query({
      query: (payload) => ({
        url: `mstock/search`,
        method: "GET",
        params: payload,
      }),
    }),
    // #end matching card stock
    //#region FoilColor
    foilColor: builder.query({
      query: () => ({
        url: "foil-color?limit=10000",
        method: "GET",
      }),
    }),
    addFoilColor: builder.mutation({
      query: (payload) => ({
        url: "foil-color",
        method: "POST",
        body: payload,
      }),
    }),
    updateFoilColor: builder.mutation({
      query: (payload) => ({
        url: `foil-color?fcId=${payload.query.fcId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Remarks
    remarks: builder.query({
      query: (payload) => ({
        url: "remarks",
        method: "GET",
        params: payload,
      }),
    }),
    addRemarks: builder.mutation({
      query: (payload) => ({
        url: "remarks",
        method: "POST",
        body: payload,
      }),
    }),
    updateRemarks: builder.mutation({
      query: (payload) => ({
        url: `remarks?rId=${payload.query.rId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Remarks
    challanRemarks: builder.query({
      query: (payload) => ({
        url: "challan/remarks",
        method: "GET",
        params: payload,
      }),
    }),
    addChallanRemarks: builder.mutation({
      query: (payload) => ({
        url: "challan/remarks",
        method: "POST",
        body: payload,
      }),
    }),
    updateChallanRemarks: builder.mutation({
      query: (payload) => ({
        url: `challan/remarks?rId=${payload.query.rId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Description
    desc: builder.query({
      query: (payload) => ({
        url: "description",
        method: "GET",
        params: payload,
      }),
    }),
    addDesc: builder.mutation({
      query: (payload) => ({
        url: "description",
        method: "POST",
        body: payload,
      }),
    }),
    updateDesc: builder.mutation({
      query: (payload) => ({
        url: `description?dId=${payload.query.dId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Title
    title: builder.query({
      query: (payload) => ({
        url: "title",
        method: "GET",
        params: payload,
      }),
    }),
    addTitle: builder.mutation({
      query: (payload) => ({
        url: `title`,
        method: "POST",
        body: payload,
      }),
    }),
    updateTitle: builder.mutation({
      query: (payload) => ({
        url: `title?tId=${payload.query.tId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Get Single Records by key
    getFabricColor: builder.query({
      query: (colorName) => ({
        url: `fabric-color?colorName=${colorName}`,
        method: "GET",
      }),
    }),
    getCloth: builder.query({
      query: (cloth) => ({
        url: `clothe?name=${cloth}`,
        method: "GET",
      }),
    }),
    getClothParty: builder.query({
      query: (name) => ({
        url: `clotheParty?name=${name}`,
        method: "GET",
      }),
    }),
    getItem: builder.query({
      query: (name) => ({
        url: `item?name=${name}`,
        method: "GET",
      }),
    }),
    getPartyName: builder.query({
      query: (name) => ({
        url: `party?name=${name}`,
        method: "GET",
      }),
    }),
    getDesignCode: builder.query({
      query: (designCode) => ({
        url: `design-code?designCode=${designCode}`,
        method: "GET",
      }),
    }),
    getMaterial: builder.query({
      query: (cloth) => ({
        url: `materials?name=${cloth}`,
        method: "GET",
      }),
    }),
    getFoilColor: builder.query({
      query: (name) => ({
        url: `foil-color?name=${name}`,
        method: "GET",
      }),
    }),
    getDiamond: builder.query({
      query: (name) => ({
        url: `diamonds?name=${name}`,
        method: "GET",
      }),
    }),
    getRemark: builder.query({
      query: (name) => ({
        url: `remarks?name=${name}`,
        method: "GET",
      }),
    }),
    getDesc: builder.query({
      query: (name) => ({
        url: `description?name=${name}`,
        method: "GET",
      }),
    }),
    //#endregion
    //#region employee
    allEmployee: builder.query({
      query: (payload) => ({
        url: `employee`,
        method: "GET",
        params: payload,
      }),
    }),
    employee: builder.query({
      query: (type) => ({
        url: `employee?type=${type}`,
        method: "GET",
      }),
    }),
    skechersEmployee: builder.query({
      query: (type) => ({
        url: `employee?type=${type}`,
        method: "GET",
      }),
    }),
    imageEmployee: builder.query({
      query: (type) => ({
        url: `employee?type=${type}`,
        method: "GET",
      }),
    }),
    addEmployee: builder.mutation({
      query: (payload) => ({
        url: "employee",
        method: "POST",
        body: payload,
      }),
    }),
    updateEmployee: builder.mutation({
      query: (payload) => ({
        url: `employee?eId=${payload.query.eId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    getEmployee: builder.query({
      query: (payload) => ({
        url: `employee?page=${payload?.page}&limit=${payload?.limit}&name=${payload.name}`,
        method: "GET",
      }),
    }),
    //#endregion
    //#region Challan
    jobWork: builder.query({
      query: (payload) => ({
        url: `job-work`,
        method: "GET",
        params: payload,
      }),
    }),
    partyChallan: builder.query({
      query: (payload) => ({
        url: `party-challan`,
        method: "GET",
        params: payload,
      }),
    }),
    getJobWork: builder.query({
      query: (jwId) => ({
        url: `job-work?jwId=${jwId}`,
        method: "GET",
      }),
    }),
    getPartyChallan: builder.query({
      query: (pcId) => ({
        url: `party-challan?pcId=${pcId}`,
        method: "GET",
      }),
    }),

    addJobWork: builder.mutation({
      query: (payload) => ({
        url: "job-work",
        method: "POST",
        body: payload,
      }),
    }),
    addPartyChallan: builder.mutation({
      query: (payload) => ({
        url: "party-challan",
        method: "POST",
        body: payload,
      }),
    }),

    updateJobWork: builder.mutation({
      query: (payload) => ({
        url: `job-work?jwId=${payload.query.jwId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    updatePartyChallan: builder.mutation({
      query: (payload) => ({
        url: `party-challan?pcId=${payload.query.pcId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    newJobWorkInvoiceNumber: builder.query({
      query: (payload) => ({
        url: "job-work/newInvoiceNumber",
        method: "GET",
        params: payload,
      }),
    }),
    newPartyChallanInvoiceNumber: builder.query({
      query: (payload) => ({
        url: "party-challan/newInvoiceNumber",
        method: "GET",
        params: payload,
      }),
    }),
    getJobWorkByKeyword: builder.query({
      query: (payload) => ({
        url: `job-work/search`,
        method: "GET",
        params: payload,
      }),
    }),
    getPartyChallanByKeyword: builder.query({
      query: (payload) => ({
        url: `party-challan/search`,
        method: "GET",
        params: payload,
      }),
    }),

    challan: builder.query({
      query: (payload) => ({
        url: `challan`,
        method: "GET",
        params: payload,
      }),
    }),
    getChallan: builder.query({
      query: (payload) => ({
        url: `challan`,
        method: "GET",
        params: payload,
      }),
    }),
    addChallan: builder.mutation({
      query: (payload) => ({
        url: "challan",
        method: "POST",
        body: payload,
      }),
    }),
    updateChallan: builder.mutation({
      query: (payload) => ({
        url: `challan?cId=${payload.query.cId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    newChallanInvoiceNumber: builder.query({
      query: (payload) => ({
        url: "challan/newInvoiceNumber",
        method: "GET",
        params: payload,
      }),
    }),
    getChallanByKeyword: builder.query({
      query: (payload) => ({
        url: `challan/search`,
        method: "GET",
        params: payload,
      }),
    }),
    challanCount: builder.query({
      query: (payload) => ({
        url: `challan/count`,
        method: "GET",
        params: payload,
      }),
    }),
    getJobWorkName: builder.query({
      query: (payload) => ({
        url: `challanName`,
        method: "GET",
        params: payload,
      }),
    }),
    addJobWorkName: builder.mutation({
      query: (payload) => ({
        url: `challanName`,
        method: "POST",
        body: payload,
      }),
    }),
    updateJobWorkName: builder.mutation({
      query: (payload) => ({
        url: `challanName?cnId=${payload.query?.cnId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion
    //#region Reports
    getAllReportData: builder.query({
      query: (payload) => ({
        url: `report`,
        method: "GET",
        params: payload,
      }),
    }),
    getSingleReportData: builder.query({
      query: (payload) => ({
        url: `report`,
        method: "GET",
        params: payload,
      }),
    }),
    getSareeReportData: builder.query({
      query: (payload) => ({
        url: `report/saree`,
        method: "GET",
        params: payload,
      }),
    }),
    addEmployeeReport: builder.mutation({
      query: (payload) => ({
        url: "report",
        method: "POST",
        body: payload,
      }),
    }),
    updateEmployeeReport: builder.mutation({
      query: (payload) => ({
        url: `report?rId=${payload.query.rId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    //#endregion

    //plain stock
    addPlainStock: builder.mutation({
      query: (payload) => ({
        url: "plain-stock",
        method: "POST",
        body: payload
      })
    }),

    getAllPlainStockData: builder.query({
      query: (payload) => ({
        url: `plain-stock/search`,
        method: "GET",
        params: payload
      })
    }),
    getSinglePlainStockData: builder.query({
      query: (payload) => ({
        url: `plain-stock/search`,
        method: "GET",
        params: payload,
      }),
    }),
    updatePlainStock: builder.mutation({
      query: (payload) => ({
        url: `plain-stock?psId=${payload.query.psId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),

    // party report
    addPartyReport: builder.mutation({
      query: (payload) => ({
        url: "RF-report",
        method: "POST",
        body: payload
      })
    }),
    getAllPartyReportData: builder.query({
      query: (payload) => ({
        url: `RF-report/search`,
        method: "GET",
        params: payload
      })
    }),
    getSinglePartyReportData: builder.query({
      query: (payload) => ({
        url: `RF-report/search`,
        method: "GET",
        params: payload,
      }),
    }),
    updatePartyReport: builder.mutation({
      query: (payload) => ({
        url: `RF-report?rfId=${payload.query.rfId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),


    // readyStock
    addReadyStock: builder.mutation({
      query: (payload) => ({
        url: "ready-stock",
        method: "POST",
        body: payload,
      }),
    }),
    getAllReadyStockData: builder.query({
      query: (payload) => ({
        url: `ready-stock/search`,
        method: "GET",
        params: payload
      })
    }),
    updateReadyStock: builder.mutation({
      query: (payload) => ({
        url: `ready-stock?rsId=${payload.query.rsId}`,
        method: "PATCH",
        body: payload.payload,
      }),
    }),
    getSingleReadyStockData: builder.query({
      query: (payload) => ({
        url: `ready-stock/search`,
        method: "GET",
        params: payload,
      }),
    }),
  }),
});



export const {
  //User
  useLazyLoginQuery,
  useLazyRegisterQuery,
  useLazyUserQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  //Setting
  useLazySettingQuery,
  useAddSettingMutation,
  useUpdateSettingMutation,
  useLazySingleSettingQuery,
  //changed financial year
  useChangeFinancialYearMutation,
  //MatchingCard
  useLazyMatchingCardQuery,
  useAddMatchingCardMutation,
  useUpdateMatchingCardMutation,
  useLazyGetMatchingCardQuery,
  useLazyNewMatchingInvoiceNumberQuery,
  useLazyCopyMatchingCardQuery,
  useLazyGetMatchingCardByTypeQuery,
  useLazyGetMatchingCardByKeywordQuery,
  useLazyGetMatchingCardByReportQuery,
  useLazyAllMatchingInvoiceNumberQuery,
  useLazyMatchingNewCardNumberBQuery,
  useLazyGetMatchingCardCountQuery,
  //SampleCard
  useAddSampleCardMutation,
  useLazySampleCardQuery,
  useUpdateSampleCardMutation,
  useLazyNewInvoiceNumberQuery,
  useLazyGetSampleCardQuery,
  useLazyCopySampleCardQuery,
  useLazyGetSampleCardByKeywordQuery,  //
  useLazyAllInvoiceNumberQuery,
  useLazySampleNewCardNumberBQuery,
  useLazyGetSampleCardCountQuery,
  //party
  useLazyPartyQuery,
  useAddPartyMutation,
  useUpdatePartyMutation,
  //designCode
  useLazyDesignCodeQuery,
  useAddDesignCodeMutation,
  useUpdateDesignCodeMutation,
  //cloth
  useLazyClothQuery,
  useAddClothMutation,
  useUpdateClothMutation,
  //items
  useAddItemMutation,
  useUpdateItemMutation,
  useLazyItemQuery,
  //fabricColor
  useLazyFabricColorQuery,
  useAddFabricColorMutation,
  useUpdateFabricColorMutation,
  //clothparty
  useLazyClothPartyQuery,
  useAddClothPartyMutation,
  useUpdateClothPartyMutation,
  //diamond
  useLazyDiamondsQuery,
  useAddDiamondsMutation,
  useLazyGetDiamondQuery,
  useUpdateDiamondsMutation,
  //foilcolor
  useLazyFoilColorQuery,
  useAddFoilColorMutation,
  useUpdateFoilColorMutation,
  //remarks
  useLazyRemarksQuery,
  useAddRemarksMutation,
  useUpdateRemarksMutation,
  //challanRemarks
  useLazyChallanRemarksQuery,
  useAddChallanRemarksMutation,
  useUpdateChallanRemarksMutation,
  //material
  useLazyMaterialQuery,
  useAddMaterialMutation,
  useUpdateMaterialMutation,
  //desc
  useLazyDescQuery,
  useAddDescMutation,
  useUpdateDescMutation,
  //title
  useLazyTitleQuery,
  useAddTitleMutation,
  useUpdateTitleMutation,
  //jobTitle
  useLazyJobTitleQuery,
  useAddJobTitleMutation,
  useUpdateJobTitleMutation,
  //ratecard
  useLazyDressRateCardQuery,
  useAddDressRateCardMutation,
  useUpdateDressRateCardMutation,
  useLazyGetDressRateCardQuery,
  useLazyCopyDressRateCardQuery,
  useLazyNewSareeRateInvoiceNumberQuery,
  useLazySareeRateCardQuery,
  useUpdateSareeRateCardMutation,
  useLazyGetSareeRateCardQuery,
  useAddLumpRateCardMutation,
  useUpdateLumpRateCardMutation,
  useLazyGetLumpRateCardQuery,
  useLazyNewLumpRateInvoiceNumberQuery,
  useLazyGetDressRateCardByKeywordQuery,
  useLazyGetSareeRateCardByKeywordQuery,
  useLazyGetLumpRateCardByKeywordQuery,
  useLazyAllRateCardQuery,
  useLazyRateCardPartyQuery,
  useLazyLumpRateCardQuery,
  useAddSareeRateCardMutation,
  useLazyAllDressRateInvoiceNumberQuery,
  useLazyAllSareeRateInvoiceNumberQuery,
  useLazyAllLumpRateInvoiceNumberQuery,
  // get single query
  useLazyGetFabricColorQuery,
  useLazyGetClothQuery,
  useLazyGetClothPartyQuery,
  useLazyGetItemQuery,
  useLazyGetPartyNameQuery,
  useLazyGetDesignCodeQuery,
  useLazyGetFoilColorQuery,
  useLazyGetMaterialQuery,
  useLazyGetRemarkQuery,
  useLazyGetDescQuery,
  useLazyNewDressRateInvoiceNumberQuery,
  //employee
  useLazyEmployeeQuery,
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
  useLazyGetEmployeeQuery,
  useLazySkechersEmployeeQuery,
  useLazyImageEmployeeQuery,
  useLazyAllEmployeeQuery,
  //#region challan
  useLazyJobWorkQuery,
  useLazyPartyChallanQuery,
  useLazyGetJobWorkQuery,
  useLazyGetPartyChallanQuery,
  useAddJobWorkMutation,
  useAddPartyChallanMutation,
  useUpdateJobWorkMutation,
  useUpdatePartyChallanMutation,
  useLazyNewJobWorkInvoiceNumberQuery,
  useLazyNewPartyChallanInvoiceNumberQuery,


  useLazyChallanQuery,
  useLazyGetChallanQuery,
  useAddChallanMutation,

  useUpdateChallanMutation,
  useLazyNewChallanInvoiceNumberQuery,
  useLazyGetJobWorkByKeywordQuery,
  useLazyGetPartyChallanByKeywordQuery,


  useLazyGetChallanByKeywordQuery,
  useLazyChallanCountQuery,
  useLazyGetJobWorkNameQuery,
  useAddJobWorkNameMutation,
  useUpdateJobWorkNameMutation,
  // endregion
  //#region Report
  useLazyGetSareeReportDataQuery,
  useAddEmployeeReportMutation,
  useLazyGetAllReportDataQuery,
  useLazyGetSingleReportDataQuery,
  useUpdateEmployeeReportMutation,
  // endregion
  //   #matchingcard Stock
  useLazyMatchingCardStockQuery,
  useUpdateStockMutation,
  useLazyGetMatchingCardStockByKeywordQuery,
  useLazyMatchingCardStockCountQuery,

  //plain stock
  useAddPlainStockMutation,
  useLazyGetAllPlainStockDataQuery,
  useLazyGetSinglePlainStockDataQuery,
  useUpdatePlainStockMutation,

  // party report
  useAddPartyReportMutation,
  useLazyGetAllPartyReportDataQuery,
  useLazyGetSinglePartyReportDataQuery,
  useUpdatePartyReportMutation,

  //ready Stock
  useAddReadyStockMutation,
  useLazyGetAllReadyStockDataQuery,
  useUpdateReadyStockMutation,
  useLazyGetSingleReadyStockDataQuery
} = publicApiSlice;
