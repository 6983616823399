import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../components/form/Select";
import TextBox from "../../components/form/TextBox";
import CreatableSelect from "react-select/creatable";
import { PartyAdd } from "../../components/modal/PartyAdd";
import { ClothAdd } from "../../components/modal/ClothAdd";
import { ItemAdd } from "../../components/modal/ItemAdd";
import {
  useLazyPartyQuery,
  useLazyClothQuery,
  useLazyItemQuery,
  useUpdateChallanMutation,
  useLazyFabricColorQuery,
  useAddChallanMutation,
  useLazyGetFabricColorQuery,
  useLazyGetClothQuery,
  useLazyGetItemQuery,
  useLazyGetPartyNameQuery,
  useLazyNewChallanInvoiceNumberQuery,
  useLazyGetChallanQuery,
  useLazyChallanQuery,
} from "../../servicesRtkQuery/publicApi";
import { useSelector } from "react-redux";
import {
  challanColorValidation,
  jobWorkValidation,
} from "../../utils/validation";
import { FabricAdd } from "../../components/modal/FabricAdd";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import { DressChallanPreview } from "../../components/pdf/challan/dressChallanPreview";
import { createFilter } from "react-select";
import { selectFilterConfig } from "../../utils/globalFunctions";

export default function DressChallan() {
  const [isChallanUpdate, setIsChallanUpdate] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsChallanUpdate(true);
    } else {
      setIsChallanUpdate(false);
    }
  }, [id]);
  // Redux Data State
  const dressChallanData = useSelector(
    (state: any) => state.dressChallanSlice.dressChallanData
  );

  // Global Data State
  const [cardData, setCardData]: any = useState();
  const [colorData, setColorData]: any = useState();
  const [jobWorkData, setJobWorkData]: any = useState({});

  const [jobWorkTableData, setJobWorkTableData] = useState<any>([]);
  const [colorTableData, setColorTableData] = useState<any>([]);

  const [selectedJobRow, setSelectedJobRow]: any = useState("");
  const [selectedColorRow, setSelecetdColorRow]: any = useState("");

  const [trigger, result] = useLazyPartyQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [clothTrigger, clothResult] = useLazyClothQuery();
  const {
    isSuccess: isClothSuccess,
    isFetching: isClothFetching,
    isError: isClothError,
    error: clothError,
  } = clothResult;

  const [itemTrigger, itemResult] = useLazyItemQuery();
  const {
    isSuccess: isItemSuccess,
    isFetching: isItemFetching,
    isError: isItemError,
    error: itemError,
  } = itemResult;

  const [fabricTrigger, fabricResult] = useLazyFabricColorQuery();
  const {
    isSuccess: isFabricSuccess,
    isFetching: isFabricFetching,
    isError: isFabricError,
    error: fabricError,
  } = fabricResult;

  const [getClothTrigger, getClothResult] = useLazyGetClothQuery();
  const {
    isSuccess: isgetClothSuccess,
    isFetching: isgetClothFetching,
    isError: isgetClothError,
    error: getClothError,
  } = getClothResult;

  const [getFabricColorTrigger, getFabricColorResult] =
    useLazyGetFabricColorQuery();
  const {
    isSuccess: isgetFabricColorSuccess,
    isFetching: isgetFabricColorFetching,
    isError: isgetFabricColorError,
    error: getFabricColorError,
  } = getFabricColorResult;

  const [invoiceTrigger, invoiceResult] = useLazyNewChallanInvoiceNumberQuery();
  const {
    isSuccess: isInvoiceSuccess,
    isFetching: isInvoiceFetching,
    isError: isInvoiceError,
    error: invoiceError,
  } = invoiceResult;

  const [challanTrigger, challanResult] = useLazyGetChallanQuery();
  const {
    isSuccess: isChallanSuccess,
    isFetching: isChallanFetching,
    isError: isChallanError,
    error: challanError,
  } = challanResult;

  const [allTrigger, allResult] = useLazyChallanQuery();
  const {
    isSuccess: isAllSuccess,
    isFetching: isAllFetching,
    isError: isAllError,
    error: allError,
  } = allResult;
  //Getting paginated records
  const [getItemTrigger, getItemResult] = useLazyGetItemQuery();
  const {
    isSuccess: isgetItemSuccess,
    isFetching: isgetItemFetching,
    isError: isgetItemError,
    error: getItemError,
  } = getItemResult;

  const [getPartyNameTrigger, getPartyNameResult] = useLazyGetPartyNameQuery();
  const {
    isSuccess: isgetPartyNameSuccess,
    isFetching: isgetPartyNameFetching,
    isError: isgetPartyNameError,
    error: getPartyNameError,
  } = getPartyNameResult;

  const [showPreview, setShowPreview] = useState(false);
  const [savedId, setSavedId]: any = useState("");

  useEffect(() => {
    trigger("");
    clothTrigger("");
    itemTrigger("");
    fabricTrigger("");
    invoiceTrigger({
      type: "dress",
    });
    challanTrigger({
      _id: id,
      type: "dress",
    });
  }, []);
  const navigate = useNavigate();
  // error message state
  const [errorMessage, setErrorMessage]: any = useState({});


  // Party Creatable State
  const [showAddParty, setShowAddParty] = useState(false);
  const [partyNameAdd, setPartyNameAdd] = useState("");
  const [Party, setParty]: any = useState();
  const [selectedParty, setSelectedParty]: any = useState({});

  // Cloth Creatable State
  const [showAddCloth, setShowAddCloth] = useState(false);
  const [clothAdd, setClothAdd] = useState("");
  const [Cloth, setCloth]: any = useState<any>([]);
  const [selectedCloth, setSelectedCloth]: any = useState({});
  const [clothState, setClothState]: any = useState({});

  // Item Creatable State
  const [showAddItem, setShowAddItem] = useState(false);
  const [itemAdd, setItemAdd] = useState("");
  const [Item, setItem]: any = useState<any>([]);
  const [selectedItem, setSelectedItem]: any = useState({});
  const [itemState, setItemState]: any = useState({});

  // Fabric Creatable State
  const [showAddFabric, setShowAddFabric] = useState(false);
  const [fabricAdd, setFabricAdd] = useState("");
  const [Fabric, setFabric]: any = useState([]);
  const [selectedFabric, setSelectedFabric]: any = useState({});
  const [fabricState, setFabricState]: any = useState({});
  const [jobChallanNo, setJobChallanNo]: any = useState<any>([]);

  const [images, setImages]: any = useState();
  const [selectedJobChallanNo, setSelectedJobChallanNo]: any = useState("");

  // Is SampleCard Update State
  const [isJobUpdate, setIsJobUpdate] = useState(false);
  const [isColorUpdate, setIsColorUpdate] = useState(false);

  //Total States
  const [pcsTotal, setPcsTotal]: any = useState(0);
  const [colorTotal, setColorTotal]: any = useState(0);

  const [updateDressRateCard, updateResult] = useUpdateChallanMutation();
  const [addDressRateCard, addResult] = useAddChallanMutation();
  // reference props
  const jobWorkRef: any = useRef(null);

  useEffect(() => {
    let date = document.getElementById("date") as HTMLInputElement | any;
    let today = new Date() as any;
    let month = today.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();

    date.value = today.getFullYear() + "-" + month + "-" + day;
    if (isChallanUpdate) {
      if (dressChallanData?.challan) {
        let apiData = dressChallanData?.challan?.filter((item: any) => {
          return item._id === id;
        })[0];
        if (apiData) {
          setCardData(apiData);
          setCardData({
            ...apiData,
            date: today.getFullYear() + "-" + month + "-" + day,
          });
          setSelectedParty({
            label: apiData?.party?.name,
            value: apiData?.party?._id,
          });
          setSelectedCloth({
            label: apiData?.clothe?.name,
            value: apiData?.clothe?._id,
          });

          let colors: any = [];
          apiData && apiData?.fabricColors?.forEach((item: any) => {
            let object = {
              srNo: item?.srNo,
              fabricColorId: item?.fabricColor?._id,
              PM: item?.PM,

            };
            colors.push(object);
          });
          console.log("colors", colors)
          setColorTableData(colors);

          let jobData: any = [];
          apiData && apiData?.items?.forEach((item: any) => {
            let object = {
              srNo: item?.srNo,
              itemId: item?.item?._id,
              stitch: item?.stitch,
              PM: item?.PM,
              code: item?.code,

              area: item?.area,
              TR: item?.TR,
              rate: item?.rate,
            };
            jobData.push(object);
          });
          setJobWorkTableData(jobData);
        } else {
          challanTrigger({
            _id: id,
            type: "dress",
          });
        }
      } else {
        challanTrigger({
          _id: id,
          type: "dress",
        });
      }
    } else {
      setCardData({});
      setCardData({
        ...cardData,
        date: today.getFullYear() + "-" + month + "-" + day,
      });
    }
  }, [fabricState]);

  useEffect(() => {
    if (isChallanUpdate) {
      if (isChallanSuccess && !isChallanFetching) {
        let apiData = challanResult?.data?.data?.challan?.[0];
        setCardData(apiData);

        setSelectedParty({
          label: apiData?.party?.name,
          value: apiData?.party?._id,
        });
        setSelectedCloth({
          label: apiData?.clothe?.name,
          value: apiData?.clothe?._id,
        });

        if (Object.keys(fabricState).length > 0 && Object.keys(itemState).length > 0) {

          let colors: any = [];
          apiData && apiData?.fabricColors?.forEach((item: any) => {
            let object = {
              srNo: item?.srNo,
              fabricColorId: item?.fabricColor?._id,
              PM: item?.PM,

            };
            colors.push(object);
          });
          console.log("colors1", colors)
          setColorTableData(colors);

          let jobData: any = [];
          apiData && apiData?.items?.forEach((item: any) => {
            let object = {
              srNo: item?.srNo,
              itemId: item?.item?._id,
              stitch: item?.stitch,
              PM: item?.PM,
              code: item?.code,

              area: item?.area,
              TR: item?.TR,
              rate: item?.rate,
            };
            jobData.push(object);
          });
          setJobWorkTableData(jobData);
        }
      }
    }
  }, [isChallanSuccess, isChallanFetching, isChallanUpdate, fabricState, itemState]);

  useEffect(() => {
    if (isInvoiceSuccess && !isInvoiceFetching) {
      var data: any = [];
      invoiceResult &&
        invoiceResult.data?.data?.map((invoice: any) => {
          if (id) {
            // If Updating SampleCard
            if (invoice._id) {
              data.push(invoice.challanNo);
              if (id === invoice?._id) {
                setSelectedJobChallanNo(invoice.challanNo);
              }
            }
          } else {
            // If Adding SampleCard
            data.push(invoice.challanNo);
            setSelectedJobChallanNo(data[0]);
          }
        });
      setJobChallanNo(data);
    }
  }, [isInvoiceSuccess, isInvoiceFetching]);

  useEffect(() => {
    if (isAllSuccess && !isAllFetching) {
      if (!isChallanUpdate) {
        navigate(`/jobwork/dresschallan/${savedId}`);
      }
    }
  }, [isAllSuccess, isAllFetching]);

  useEffect(() => {
    if (isgetItemSuccess && !isgetItemFetching) {
      let item = getItemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      if (item) {
        setItem(item);
      }
    }
  }, [isgetItemSuccess, isgetItemFetching]);

  useEffect(() => {
    if (isgetPartyNameSuccess && !isgetPartyNameFetching) {
      let party = getPartyNameResult.data?.data?.party.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (party) {
        setParty(party);
      }
    }
  }, [isgetPartyNameSuccess, isgetPartyNameFetching]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      let getAllParty = result.data?.data?.party.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });

      setParty(getAllParty ? getAllParty : []);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isClothSuccess && !isClothFetching) {
      let getAllCloth = clothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setCloth(getAllCloth ? getAllCloth : []);
      let clothes: any = [];
      getAllCloth?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        clothes[id] = label;
      });

      setClothState(clothes);
    }
  }, [isClothSuccess, isClothFetching]);

  useEffect(() => {
    if (isFabricSuccess && !isFabricFetching) {
      let getAllFabric = fabricResult.data?.data?.fabricColor?.map(
        ({ _id, colorName }: any) => {
          return {
            label: colorName,
            value: _id,
          };
        }
      );

      setFabric(getAllFabric ? getAllFabric : []);

      let fabricColors: any = {};

      getAllFabric?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        fabricColors[id] = label;
      });

      setFabricState(fabricColors);
    }
  }, [isFabricSuccess, isFabricFetching]);

  useEffect(() => {
    if (isItemSuccess && !isItemFetching) {
      let getAllItem = itemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      setItem(getAllItem ? getAllItem : []);
      let items: any = {};

      getAllItem?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        items[id] = label;
      });

      setItemState(items);
    }
  }, [isItemSuccess, isItemFetching]);

  useEffect(() => {
    if (isgetClothSuccess && !isgetClothFetching) {
      let cloth = getClothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (cloth) {
        setCloth(cloth);
      }
    }
  }, [isgetClothSuccess, isgetClothFetching]);

  useEffect(() => {
    let total = 0;
    jobWorkTableData.forEach((item: any) => {
      total = total + item?.TR;
    });
    setPcsTotal(total);
  }, [jobWorkTableData]);

  useEffect(() => {
    let total = 0;
    colorTableData.forEach((item: any) => {
      total = total + item?.PM;
    });
    setColorTotal(total);
  }, [colorTableData]);

  useEffect(() => {
    let rate = Math.floor(
      (Number(jobWorkData?.stitch) / Number(jobWorkData?.area)) * Number(jobWorkData?.code)
    );

    setJobWorkData({
      ...jobWorkData,
      rate: rate,
    });
    setErrorMessage({
      ...errorMessage,
      rate: "",
    });
  }, [
    jobWorkData?.stitch,
    jobWorkData?.area,
    jobWorkData?.PM,
    jobWorkData?.code,
  ]);

  const getData = (data: any) => {
    setSelectedParty(data);
    setParty([...Party, data]);
    setShowAddParty(false);
  };

  const getClothData = (data: any) => {
    setSelectedCloth(data);
    setCloth([...Cloth, data]);
    setShowAddCloth(false);
  };

  const [itemType, setItemType] = useState("");

  const getItemData = (data: any, type: any) => {
    setSelectedItem(data);
    setItem([...Item, data]);
    setShowAddItem(false);
  };

  const getFabricData = (data: any) => {
    setSelectedFabric(data);
    setFabric([...Fabric, data]);
    setShowAddFabric(false);
  };

  const handleJobWorkChanges = (e: any) => {
    setJobWorkData({
      ...jobWorkData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange = (e: any, type: any = null) => {
    switch (type) {
      case "party":
        let selectParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedParty(selectParty);
        break;
      case "cloth":
        let selectedCloth = {
          label: e.label,
          value: e.value,
        };
        setSelectedCloth(selectedCloth);
        break;
      case "item":
        let selectedItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedItem(selectedItem);
        break;
      case "fabricColor":
        let selectedFabric = {
          label: e.label,
          value: e.value,
        };
        setSelectedFabric(selectedFabric);
        break;
      default:
    }
  };

  const handleChanges = (e: any) => {
    setCardData({
      ...cardData,
      [e.target.name]: e.target.value,
    });
  };

  const handleColorChanges = (e: any) => {
    setColorData({
      ...colorData,
      [e.target.name]: e.target.value,
    });
  };

  const [isUpdating, setIsUpdating]: any = useState(false);
  const updateData = () => {
    let status = false;
    if (!cardData?.name || cardData?.name == "") {
      errorData["name"] = "Name is Required";
      status = true;
    } else errorData["name"] = "";
    if (!cardData?.address || cardData?.address == "") {
      errorData["address"] = "Address is Required";
      status = true;
    } else errorData["address"] = "";
    if (Object.keys(selectedParty)?.length === 0) {
      errorData["partyName"] = "Party is Required";
      status = true;
    } else errorData["partyName"] = "";
    if (!cardData?.PCNo || cardData?.PCNo == "") {
      errorData["PCNo"] = "Party Challan No is Required";
      status = true;
    } else errorData["PCNo"] = "";
    if (!cardData?.designNo) {
      errorData["designNo"] = "Design No is Required";
      status = true;
    } else errorData["designNo"] = "";
    if (!cardData?.cardNo || cardData?.cardNo == "") {
      errorData["cardNo"] = "Card No is Required";
      status = true;
    } else errorData["cardNo"] = "";
    if (Object.keys(selectedCloth)?.length === 0) {
      errorData["cloth"] = "Fabric is Required";
      status = true;
    } else errorData["cloth"] = "";
    if (colorTableData?.length == 0) errorData["color"] = "Color is Required";
    else errorData["color"] = "";
    if (jobWorkTableData?.length == 0) {
      errorData["jobWork"] = "Jobwork is Required";
      status = true;
    } else errorData["jobWork"] = "";
    setErrorMessage(errorData);
    if (!status) {
      setIsUpdating(true);
      let finalData = {
        partyId: selectedParty.value,
        clotheId: selectedCloth?.value,
        type: "dress",
        designNo: "D-" + cardData?.designNo?.replace("D-", ""),
        date: cardData?.date,
        PCNo: cardData?.PCNo,
        name: cardData?.name,
        address: cardData?.address,
        cardNo: cardData?.cardNo,
        items: jobWorkTableData,
        fabricColors: colorTableData,
      };
      updateDressRateCard({
        payload: finalData,
        query: {
          cId: id,
        },
      }).then((data: any) => {
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Dress Challan Successfully Updated !!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          allTrigger({
            type: "dress",
          });
        }
      });
    }
  };
  let errorData = {
    name: "",
    address: "",
    partyName: "",
    PCNo: "",
    designNo: "",
    cardNo: "",
    cloth: "",
    color: "",
    jobWork: "",
    fabricColor: "",
    PM: "",
    item: "",
    stitch: "",
    area: "",
    PMColor: "",
    code: "",
    rate: "",
    Qty: "",
  };
  const addData = () => {
    let status = false;
    if (!cardData?.name || cardData?.name == "") {
      errorData["name"] = "Name is Required";
      status = true;
    } else errorData["name"] = "";
    if (!cardData?.address || cardData?.address == "") {
      errorData["address"] = "Address is Required";
      status = true;
    } else errorData["address"] = "";
    if (Object.keys(selectedParty)?.length === 0) {
      errorData["partyName"] = "Party is Required";
      status = true;
    } else errorData["partyName"] = "";
    if (!cardData?.PCNo || cardData?.PCNo == "") {
      errorData["PCNo"] = "Party Challan No is Required";
      status = true;
    } else errorData["PCNo"] = "";
    if (!cardData?.designNo) {
      errorData["designNo"] = "Design No is Required";
      status = true;
    } else errorData["designNo"] = "";
    if (!cardData?.cardNo || cardData?.cardNo == "") {
      errorData["cardNo"] = "Card No is Required";
      status = true;
    } else errorData["cardNo"] = "";
    if (Object.keys(selectedCloth)?.length === 0) {
      errorData["cloth"] = "Fabric is Required";
      status = true;
    } else errorData["cloth"] = "";
    if (colorTableData?.length == 0) errorData["color"] = "Color is Required";
    else errorData["color"] = "";
    if (jobWorkTableData?.length == 0) {
      errorData["jobWork"] = "Jobwork is Required";
      status = true;
    } else errorData["jobWork"] = "";
    setErrorMessage(errorData);
    if (!status) {
      setIsUpdating(true);
      let finalData = {
        partyId: selectedParty.value,
        clotheId: selectedCloth?.value,
        challanNo: selectedJobChallanNo,
        type: "dress",
        designNo: "D-" + cardData?.designNo?.replace("D-", ""),
        date: cardData?.date,
        PCNo: cardData?.PCNo,
        name: cardData?.name,
        address: cardData?.address,
        cardNo: cardData?.cardNo,
        items: jobWorkTableData,
        fabricColors: colorTableData,
      };
      addDressRateCard(finalData).then((data: any) => {
        setSavedId(data?.data?.data?.insertedIds[0]);
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Dress Challan Successfully Added!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          allTrigger({
            type: "dress",
          });
        }
      });
    }
  };

  const openInvoicePreview = () => {
    setShowPreview(true);
  };

  console.log("colorTableData", colorTableData)
  return (
    <div>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="invisible mr-6 lg:visible xl:visible sticky top-5 z-90">
          <nav className="absolute ml-0 sm:ml-0 md:ml-0 lg:ml-0 sm:top-0 md:top-0 lg:top-0 left-6 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
            <div className="flex gap-5 w-full justify-between items-center">
              <div className="px-3 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                <span className="text-xl">Dress Challan</span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 w-2/5">
                <div className="w-1/2">
                  <Select
                    id="jobNo"
                    name="jobNo"
                    htmlFor="jobNo"
                    label="Job Challan No."
                    disabled
                    options={jobChallanNo}
                    value={selectedJobChallanNo}
                  />
                </div>
              </div>
              <div className="px-3 w-2/5 flex justify-end">
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={showPreview}
                  onClick={openInvoicePreview}
                >
                  <i className="fa fa-download"></i>
                  <span className="ml-2">Print</span>
                </button>
                <button
                  className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={isUpdating}
                  onClick={isChallanUpdate ? updateData : addData}
                >
                  {isUpdating ? (
                    <i className="fa fa-spinner animate-spin"></i>
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  <span className="ml-2">
                    {isChallanUpdate ? "Update" : "Save"}
                  </span>
                </button>
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    navigate({
                      pathname: `/dresschallandashboard`,
                    });
                  }}
                >
                  <i className="fa fa-close"></i>
                  <span className="ml-2">Cancel</span>
                </button>
              </div>
            </div>
          </nav>
        </div>

        <div className="flex mt-0 bg-themeBgColor lg-mt-19 xl:mt-19 items-center p-0 overflow-hidden bg-cover">
          <div className="w-full mx-6">
            <div className="w-full h-full rounded-xl bg-white px-2">
              <div className="p-2 lg:p-0 xl:p-0 lg:hidden visible xl:hidden lg:invisible xl:invisible">
                <div className="mt-2 md:mt-0">
                  <Select
                    id="jobNo"
                    name="jobNo"
                    htmlFor="jobNo"
                    label="Job Challan No."
                    disabled
                    options={jobChallanNo}
                    value={selectedJobChallanNo}
                  />
                </div>
              </div>

              <div className="p-2 pb-0">
                <div>
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-4 md:pt-3 pb-0">
                    <div>
                      <TextBox
                        id="name"
                        name="name"
                        htmlFor="name"
                        label="Name"
                        placeholder=" "

                        hasError={errorMessage?.name}
                        value={cardData?.name}
                        onChange={(e: any) => {
                          handleChanges(e);
                          setErrorMessage({
                            ...errorMessage,
                            name: "",
                          });
                        }}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.name}
                      </span>
                    </div>

                    <div>
                      <TextBox
                        id="address"
                        name="address"
                        htmlFor="address"
                        label="Address"

                        hasError={errorMessage?.address}
                        placeholder=" "
                        value={cardData?.address}
                        onChange={(e: any) => {
                          handleChanges(e);
                          setErrorMessage({
                            ...errorMessage,
                            address: "",
                          });
                        }}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.address}
                      </span>
                    </div>
                    <div>
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="partyName"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block z-40 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                          classNames={{
                            control: () =>
                              errorMessage?.partyName
                                ? "border-red-600 peer-focus:border-red-500"
                                : "border-gray-500",
                          }}
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "party");
                              setErrorMessage({
                                ...errorMessage,
                                partyName: "",
                              });
                            } else {
                              setSelectedParty({});
                            }
                          }}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getPartyNameTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         let getAllPartyName = result.data?.data?.party?.map(({ _id, name }: any) => {
                          //             return {
                          //                 label: name,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllPartyName) {
                          //             setParty(getAllPartyName)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setPartyNameAdd(data);
                            setShowAddParty(true);
                          }}
                          options={Party && Party}
                          value={selectedParty}
                          menuPosition="fixed"
                        />
                        <label
                          htmlFor="partyName"
                          className={`absolute z-40 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.partyName
                            ? "text-red-500"
                            : "text-gray-500"
                            }`}
                        >
                          Party Name
                        </label>
                      </div>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.partyName}
                      </span>
                    </div>
                    <div>
                      <TextBox
                        id="PCNo"
                        name="PCNo"
                        htmlFor="PCNo"
                        label="Party Challan No"

                        hasError={errorMessage?.PCNo}
                        placeholder=" "
                        value={cardData?.PCNo}
                        onChange={(e: any) => {
                          handleChanges(e);
                          setErrorMessage({
                            ...errorMessage,
                            PCNo: "",
                          });
                        }}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.PCNo}
                      </span>
                    </div>

                    <div>
                      <div className="relative">
                        <div className="rounded-md">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">
                              D -
                            </span>
                          </div>
                          <input
                            type="number"
                            name="designNo"
                            id="designNo"
                            className={`block w-full py-1.5 pl-8 sm:text-sm sm:leading-6 first-line:block px-2.5 h-10 text-sm text-gray-900 bg-transparent rounded-lg border border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0  peer ${errorMessage?.designNo
                              ? "border-red-500 focus:border-red-500"
                              : "border-gray-300 focus:border-themeColor"
                              }`}
                            placeholder=""
                            autoComplete="off"
                            value={cardData?.designNo?.split?.("-")?.[1]}
                            onChange={(e: any) => {
                              handleChanges(e);
                              setErrorMessage({
                                ...errorMessage,
                                designNo: "",
                              });
                            }}
                          />
                        </div>
                        <label
                          className={`absolute capitalize text-sm  duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ${errorMessage?.designNo
                            ? "border-red-500 text-red-500  peer-focus:border-red-500"
                            : "border-gray-300 text-gray-500  peer-focus:text-themeColor"
                            } `}
                        >
                          Design No
                        </label>
                      </div>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.designNo}
                      </span>
                    </div>
                    <TextBox
                      id="date"
                      type="date"
                      name="date"
                      htmlFor="date"
                      label="Date"
                      placeholder=" "
                      value={cardData?.date}
                      onChange={(e: any) => handleChanges(e)}
                    />
                    <div>
                      <TextBox
                        id="cardNo"
                        name="cardNo"
                        htmlFor="cardNo"

                        hasError={errorMessage?.cardNo}
                        label="Card No."
                        placeholder=" "
                        value={cardData?.cardNo}
                        onChange={(e: any) => {
                          handleChanges(e);
                          setErrorMessage({
                            ...errorMessage,
                            cardNo: "",
                          });
                        }}
                      />
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.cardNo}
                      </span>
                    </div>

                    <div className="relative rounded-md">
                      <CreatableSelect
                        name="cloth"
                        classNamePrefix="customSelect"
                        filterOption={createFilter(selectFilterConfig)}
                        className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-themeColor"
                        onChange={(e: any) => {
                          if (e) {
                            handleChange(e, "cloth");
                            setErrorMessage({
                              ...errorMessage,
                              cloth: "",
                            });
                          } else {
                            setSelectedCloth({});
                          }
                        }}
                        classNames={{
                          control: () =>
                            errorMessage?.cloth
                              ? "border-red-600 peer-focus:border-red-500"
                              : "border-gray-500 ",
                        }}
                        // onInputChange={(e: any) => {
                        //     if (e.length >= 1) {
                        //         getClothTrigger(e)
                        //     }
                        //     if (e.length == 0) {
                        //         let getAllCloth = clothResult.data?.data?.clothe?.map(({ _id, name }: any) => {
                        //             return {
                        //                 label: name,
                        //                 value: _id,
                        //             };
                        //         });
                        //         if (getAllCloth) {
                        //             setCloth(getAllCloth)
                        //         }
                        //     }
                        // }}
                        onCreateOption={(data: any) => {
                          setClothAdd(data);
                          setShowAddCloth(true);
                        }}
                        options={Cloth}
                        value={selectedCloth}
                        menuPosition="fixed"
                      />
                      <label
                        htmlFor="cloth"
                        className={`absolute text-md  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.cloth ? "text-red-500" : "text-gray-500"
                          }`}
                      >
                        {" "}
                        Fabric
                      </label>
                      <span className="text-sm text-red-500 text-left">
                        {errorMessage?.cloth}
                      </span>
                    </div>
                  </div>

                  <div>
                    <fieldset
                      className={`border-2 p-2 rounded-2 ${errorMessage?.color
                        ? "border-red-500"
                        : "border-gray-400"
                        }`}
                    >
                      <legend
                        className={`capitalize px-2 ${errorMessage?.color ? "text-red-500" : ""
                          }`}
                      >
                        Colors
                      </legend>
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-3">
                        <div className="relative rounded-md">
                          <CreatableSelect
                            name="fabricColor"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            classNames={{
                              control: () =>
                                errorMessage?.fabricColor
                                  ? "border-red-600"
                                  : "border-grey-300",
                            }}
                            onChange={(e: any) => {
                              if (e) {
                                handleChange(e, "fabricColor");
                                setErrorMessage({
                                  ...errorMessage,
                                  fabricColor: "",
                                  needle: "",
                                });
                              } else {
                                setSelectedFabric({});
                              }
                            }}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getFabricColorTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         let getAllFabric = fabricResult.data?.data?.fabricColor?.map(({ _id, colorName }: any) => {
                            //             return {
                            //                 label: colorName,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllFabric) {
                            //             setFabric(getAllFabric)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setFabricAdd(data);
                              setShowAddFabric(true);
                            }}
                            options={Fabric}
                            value={selectedFabric}
                            menuPosition="fixed"
                          />
                          <label
                            htmlFor="item"
                            className={`absolute text-md  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.fabricColor
                              ? "text-red-500"
                              : "text-gray-500"
                              }`}
                          >
                            Fabric Color
                          </label>
                          <span className="text-sm text-red-500 flex justify-start">
                            {errorMessage?.fabricColor}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            htmlFor="PMColor"
                            id="PM"
                            name="PM"
                            label="Pcs / Meater"

                            hasError={errorMessage?.PM}
                            type="number"
                            placeholder=" "
                            value={colorData?.PM}
                            onChange={(e: any) => {
                              handleColorChanges(e);
                              setErrorMessage({
                                ...errorMessage,
                                PM: "",
                              });
                            }}
                          />
                          <span className="text-sm text-red-500 flex justify-start">
                            {errorMessage?.PM}
                          </span>
                        </div>

                        <button
                          className="w-full h-10 px-4 py-2 text-sm leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                          onClick={() => {
                            if (isColorUpdate) {
                              let record = {
                                srNo: selectedColorRow + 1,
                                fabricColorId: selectedFabric?.value || "",
                                PM: Number(colorData?.PM) || "",
                              };
                              if (Object?.keys(selectedFabric)?.length == 0) {
                                errorData["fabricColor"] =
                                  "Fabric Color is Required";
                              } else {
                                errorData["fabricColor"] = "";
                              }
                              if (!colorData?.PM) {
                                errorData["PM"] = "Pcs / Meater  is Required";
                              } else {
                                errorData["PM"] = "";
                              }
                              setErrorMessage(errorData);
                              const result: any =
                                challanColorValidation(record);
                              if (result === true) {
                                let newArray = [...colorTableData];

                                newArray[selectedColorRow] = {
                                  ...newArray[selectedColorRow],
                                  ...record,
                                };

                                setColorData({
                                  PM: "",
                                });
                                setSelectedFabric({});
                                setIsColorUpdate(false);
                                setColorTableData(newArray);
                              } else {
                                Swal.fire({
                                  toast: true,
                                  icon: "error",
                                  title: result.errorMessage,
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: ERROR_MESSAGE_TIMEOUT,
                                  timerProgressBar: true,
                                });
                              }
                            } else {
                              let record = {
                                srNo: colorTableData?.length + 1,
                                fabricColorId: selectedFabric?.value || "",
                                PM: Number(colorData?.PM) || "",
                              };
                              if (Object?.keys(selectedFabric)?.length == 0) {
                                errorData["fabricColor"] =
                                  "Fabric Color is Required";
                              } else {
                                errorData["fabricColor"] = "";
                              }
                              if (!colorData?.PM) {
                                errorData["PM"] = "Pcs / Meater is Required";
                              } else {
                                errorData["PM"] = "";
                              }
                              setErrorMessage(errorData);
                              const result: any =
                                challanColorValidation(record);
                              if (result === true) {
                                setColorTableData([...colorTableData, record]);
                                setColorData({
                                  PM: "",
                                });
                                setSelectedFabric({});
                              } else {
                                Swal.fire({
                                  toast: true,
                                  icon: "error",
                                  title: result.errorMessage,
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: ERROR_MESSAGE_TIMEOUT,
                                  timerProgressBar: true,
                                });
                              }
                            }
                          }}
                        >
                          {isColorUpdate ? "Update Color" : "Add Color"}{" "}
                        </button>
                      </div>
                      {/* Table */}
                      <div className="grid grid-cols-1 mt-2">
                        <div className="border border-gray-300 rounded-3 overflow-hidden">
                          <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="min-w-full sm:px-6 lg:px-0">
                                <div className="overflow-hidden">
                                  <div className="flex-auto px-0 pt-0">
                                    <div className="p-0 overflow-hidden">
                                      {colorTableData &&
                                        colorTableData.length > 0 ? (
                                        <div className="max-h-80 overflow-auto shadow-md">
                                          <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                            <thead className="bg-gray-50 capitalize">
                                              <tr>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Sr No.
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Fabric Color
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 py-3 font-bold text-gray-900"
                                                >
                                                  Pcs
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="px-6 font-bold text-center text-gray-900"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                              {colorTableData?.map(
                                                (row: any, index: number) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      className="hover:bg-gray-50"
                                                    >
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {index + 1}
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {
                                                          fabricState[
                                                          row?.fabricColorId
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                        {row?.PM}
                                                      </td>
                                                      <td>
                                                        <div>
                                                          <button
                                                            className="m-2"
                                                            type="button"
                                                            onClick={() => {
                                                              setSelecetdColorRow(
                                                                index
                                                              );
                                                              setColorData({
                                                                srNo: row?.srNo,
                                                                PM: row?.PM,
                                                              });
                                                              setSelectedFabric(
                                                                {
                                                                  label:
                                                                    fabricState[
                                                                    row
                                                                      ?.fabricColorId
                                                                    ],
                                                                  value:
                                                                    row?.fabricColorId,
                                                                }
                                                              );
                                                              setIsColorUpdate(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <i className="fas fa-pen hover:text-green-600"></i>
                                                          </button>
                                                          <button
                                                            className="m-2"
                                                            onClick={() => {
                                                              Swal.fire({
                                                                title: "Are you sure?",
                                                                text: "Once deleted, you will not be able to recover this record!",
                                                                icon: "warning",
                                                                showCancelButton: true,
                                                                confirmButtonColor: "#3085d6",
                                                                cancelButtonColor: "#d33",
                                                                confirmButtonText: "Delete",
                                                              }).then((willDelete) => {
                                                                if (willDelete.isConfirmed) {
                                                                  let newData =
                                                                    colorTableData.filter(
                                                                      (
                                                                        item: any,
                                                                        index: any
                                                                      ) => {
                                                                        return (
                                                                          item?.srNo !==
                                                                          row?.srNo
                                                                        );
                                                                      }
                                                                    );

                                                                  setColorTableData(
                                                                    newData
                                                                  );
                                                                  setColorData({
                                                                    PM: "",
                                                                  });
                                                                  setSelectedFabric(
                                                                    {}
                                                                  );
                                                                  setIsColorUpdate(
                                                                    false
                                                                  );
                                                                }
                                                              })
                                                            }}
                                                          >
                                                            <i className="fas fa-trash hover:text-red-600"></i>
                                                          </button>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                            <tfoot className="border-t bg-gray-50">
                                              <tr>
                                                <td
                                                  colSpan={2}
                                                  className="px-6 py-1 font-bold text-gray-900 text-left"
                                                >
                                                  Total
                                                </td>
                                                <td
                                                  colSpan={1}
                                                  className="px-6 font-bold text-gray-900 text-left"
                                                >
                                                  {colorTotal}
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      ) : (
                                        <div className="text-center py-3 text-gray-600 font-bold">
                                          No Colors Found
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="text-sm text-red-500 flex justify-start">
                        {errorMessage?.color}
                      </span>
                    </fieldset>
                  </div>
                  <div className="pb-0 mt-2">
                    <fieldset
                      className={`border-2 p-2 rounded-2 ${errorMessage?.jobWork
                        ? "border-red-500"
                        : "border-gray-400"
                        }`}
                    >
                      <legend
                        className={`capitalize px-2 ${errorMessage?.jobWork ? "text-red-500" : "text-black"
                          }`}
                      >
                        Job Work
                      </legend>
                      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-7 gap-3">
                        <div className="relative rounded-md">
                          <CreatableSelect
                            ref={jobWorkRef}
                            name="item"
                            classNamePrefix="customSelect"
                            filterOption={createFilter(selectFilterConfig)}
                            className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                            classNames={{
                              control: () =>
                                errorMessage?.item
                                  ? "border-red-500 focus:border-red-500"
                                  : "border-gray-500 focus:border-themeColor",
                            }}
                            onChange={(e: any) => {
                              handleChange(e, "item");
                              setErrorMessage({
                                ...errorMessage,
                                item: "",
                              });
                            }}
                            // onInputChange={(e: any) => {
                            //     if (e.length >= 1) {
                            //         getItemTrigger(e)
                            //     }
                            //     if (e.length == 0) {
                            //         let getAllItem = itemResult?.data?.data?.item?.map(({ _id, name }: any) => {
                            //             return {
                            //                 label: name,
                            //                 value: _id,
                            //             };
                            //         });
                            //         if (getAllItem) {
                            //             setItem(getAllItem)
                            //         }
                            //     }
                            // }}
                            onCreateOption={(data: any) => {
                              setItemAdd(data);
                              setShowAddItem(true);
                              setItemType("");
                            }}
                            options={Item}
                            value={selectedItem}
                            menuPosition="fixed"
                          />
                          <label
                            htmlFor="item"
                            className={`absolute text-md  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.item
                              ? "text-red-500"
                              : "text-gray-500"
                              }`}
                          >
                            Item
                          </label>
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.item}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            id="stitch"
                            type="number"
                            name="stitch"
                            htmlFor="stitch"
                            label="stitch"

                            hasError={errorMessage?.stitch}
                            placeholder=" "
                            value={jobWorkData?.stitch}
                            onChange={(e: any) => {
                              handleJobWorkChanges(e);
                              setErrorMessage({
                                ...errorMessage,
                                stitch: "",
                              });
                            }}
                          />
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.stitch}
                          </span>
                        </div>

                        <div>
                          <TextBox
                            id="area"
                            type="text"
                            name="area"
                            htmlFor="area"
                            hasError={errorMessage?.area}

                            label="area"
                            placeholder=" "
                            value={jobWorkData?.area}
                            onChange={(e: any) => {
                              handleJobWorkChanges(e);
                              setErrorMessage({
                                ...errorMessage,
                                area: "",
                              });
                            }}
                          />
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.area}
                          </span>
                        </div>
                        <div>
                          <TextBox
                            id="PM"
                            type="number"
                            name="PM"
                            htmlFor="meter1"
                            hasError={errorMessage?.PMColor}

                            label="Pcs / Meater"
                            placeholder=" "
                            value={jobWorkData?.PM}
                            onChange={(e: any) => {
                              handleJobWorkChanges(e);
                              setErrorMessage({
                                ...errorMessage,
                                PMColor: "",
                              });
                            }}
                          />
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.PMColor}
                          </span>
                        </div>

                        <div>
                          <TextBox
                            id="code"
                            type="number"
                            name="code"
                            htmlFor="code"
                            hasError={errorMessage?.code}

                            label="code"
                            placeholder=" "
                            value={jobWorkData?.code}
                            onChange={(e: any) => {
                              handleJobWorkChanges(e);
                              setErrorMessage({
                                ...errorMessage,
                                code: "",
                              });
                            }}
                          />
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.code}
                          </span>
                        </div>

                        <div>
                          <TextBox
                            id="rate"
                            type="number"
                            name="rate"
                            htmlFor="rate1"
                            label="rate"
                            placeholder=" "
                            hasError={errorMessage?.rate}

                            value={jobWorkData?.rate}
                            disabled
                            onChange={(e: any) => {
                              handleJobWorkChanges(e);
                            }}
                          />
                          <span className="text-sm text-red-500 text-left">
                            {errorMessage?.rate}
                          </span>
                        </div>

                        <div className="w-full">
                          <button
                            className="w-full px-2 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                            onClick={() => {
                              if (isJobUpdate) {
                                let rate = Math.floor(
                                  (Number(jobWorkData?.stitch) /
                                    Number(jobWorkData?.area)) *
                                  Number(jobWorkData?.PM) *
                                  Number(jobWorkData?.code)
                                );
                                let record = {
                                  srNo: selectedJobRow,
                                  area: jobWorkData?.area,
                                  itemId: selectedItem?.value,
                                  stitch: jobWorkData?.stitch,
                                  code: jobWorkData?.code,
                                  rate: rate,
                                  PM: jobWorkData?.PM,
                                  TR: Math.floor(
                                    Number(jobWorkData?.PM) * rate
                                  ),
                                };
                                if (
                                  !jobWorkData?.area ||
                                  jobWorkData?.area == ""
                                ) {
                                  errorData["area"] = "Area is Required";
                                } else errorData["area"] = "";
                                if (
                                  !jobWorkData?.stitch ||
                                  jobWorkData?.stitch == ""
                                ) {
                                  errorData["stitch"] = "Stitch is Required";
                                } else errorData["stitch"] = "";
                                if (
                                  !jobWorkData?.code ||
                                  jobWorkData?.code == ""
                                ) {
                                  errorData["code"] = "Code is Required";
                                } else errorData["code"] = "";
                                if (
                                  !jobWorkData?.rate ||
                                  jobWorkData?.rate == ""
                                ) {
                                  errorData["rate"] = "Rate is Required";
                                } else errorData["rate"] = "";
                                if (!jobWorkData?.PM) {
                                  errorData["PMColor"] =
                                    "Pcs / Meater is Required";
                                } else {
                                  errorData["PMColor"] = "";
                                }
                                if (Object.keys(selectedItem).length == 0) {
                                  errorData["item"] = "Item is Required";
                                } else errorData["item"] = "";
                                setErrorMessage(errorData);
                                const result: any = jobWorkValidation(record);
                                if (result === true) {
                                  let newArray = [...jobWorkTableData];

                                  newArray[selectedJobRow] = {
                                    ...newArray[selectedJobRow],
                                    ...record,
                                  };

                                  setJobWorkTableData(newArray);

                                  setJobWorkData({
                                    area: "",
                                    stitch: "",
                                    code: "",
                                    rate: "",
                                    PM: "",
                                  });
                                  setSelectedItem({});
                                  setIsJobUpdate(false);
                                } else {
                                  Swal.fire({
                                    toast: true,
                                    icon: "error",
                                    title: result.errorMessage,
                                    position: "top-end",
                                    showConfirmButton: false,
                                    timer: ERROR_MESSAGE_TIMEOUT,
                                    timerProgressBar: true,
                                  });
                                }
                              } else {
                                let rate = Math.floor(
                                  (Number(jobWorkData?.stitch) /
                                    Number(jobWorkData?.area)) *
                                  Number(jobWorkData?.PM) *
                                  Number(jobWorkData?.code)
                                );
                                let object = {
                                  srNo: jobWorkTableData?.length + 1,
                                  area: jobWorkData?.area || "",
                                  itemId: selectedItem?.value || "",
                                  stitch: jobWorkData?.stitch || "",
                                  code: jobWorkData?.code || "",
                                  rate: rate,
                                  PM: jobWorkData?.PM || "",
                                  TR: Math.floor(
                                    Number(jobWorkData?.PM) * rate
                                  ),
                                };
                                if (
                                  !jobWorkData?.area ||
                                  jobWorkData?.area == ""
                                ) {
                                  errorData["area"] = "Area is Required";
                                } else errorData["area"] = "";
                                if (
                                  !jobWorkData?.stitch ||
                                  jobWorkData?.stitch == ""
                                ) {
                                  errorData["stitch"] = "Stitch is Required";
                                } else errorData["stitch"] = "";
                                if (
                                  !jobWorkData?.code ||
                                  jobWorkData?.code == ""
                                ) {
                                  errorData["code"] = "Code is Required";
                                } else errorData["code"] = "";
                                if (
                                  !jobWorkData?.rate ||
                                  jobWorkData?.rate == ""
                                ) {
                                  errorData["rate"] = "Rate is Required";
                                } else errorData["rate"] = "";
                                if (!jobWorkData?.PM) {
                                  errorData["PMColor"] =
                                    "Pcs / Meater is Required";
                                } else {
                                  errorData["PMColor"] = "";
                                }
                                if (Object.keys(selectedItem).length == 0) {
                                  errorData["item"] = "Item is Required";
                                } else errorData["item"] = "";
                                setErrorMessage(errorData);
                                const result: any = jobWorkValidation(object);
                                if (result === true) {
                                  setJobWorkTableData([
                                    ...jobWorkTableData,
                                    object,
                                  ]);

                                  setJobWorkData({
                                    area: "",
                                    stitch: "",
                                    code: "",
                                    rate: "",
                                    PM: "",
                                  });
                                  setSelectedItem({});
                                } else {
                                  Swal.fire({
                                    toast: true,
                                    icon: "error",
                                    title: result.errorMessage,
                                    position: "top-end",
                                    showConfirmButton: false,
                                    timer: ERROR_MESSAGE_TIMEOUT,
                                    timerProgressBar: true,
                                  });
                                }
                              }
                              jobWorkRef.current.focus();
                            }}
                          >
                            <i className="fa fa-plus"></i>
                            <span className="ml-2">
                              {isJobUpdate ? "Update" : "Add"} Job work
                            </span>
                          </button>
                        </div>
                        <div className="col-span-2 lg:col-span-7">
                          <div className="border border-gray-300 rounded-3 overflow-hidden">
                            <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="overflow-hidden">
                                  <div className="p-0 overflow-hidden">
                                    {jobWorkTableData &&
                                      jobWorkTableData?.length > 0 ? (
                                      <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                        <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                          <thead className="bg-gray-50">
                                            <tr className="capitalize">
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-gray-900"
                                              >
                                                Sr No
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-gray-900"
                                              >
                                                Item
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-gray-900"
                                              >
                                                Stitch
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-gray-900"
                                              >
                                                Area
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-gray-900"
                                              >
                                                Code
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-gray-900"
                                              >
                                                Meter
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-gray-900"
                                              >
                                                Rate
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-gray-900"
                                              >
                                                Total Rate
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-6 font-bold text-center text-gray-900"
                                              >
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                            {jobWorkTableData?.map(
                                              (item: any, index: number) => {
                                                return (
                                                  <tr
                                                    key={index}
                                                    className="hover:bg-gray-50"
                                                  >
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {index + 1}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {itemState[item?.itemId]}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.stitch}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.area}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.code}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.PM}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.rate}
                                                    </td>
                                                    <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                      {item?.TR}
                                                    </td>
                                                    <td>
                                                      <div>
                                                        <button
                                                          className="m-2"
                                                          type="button"
                                                          onClick={() => {
                                                            setSelectedJobRow(
                                                              index
                                                            );
                                                            setSelectedItem({
                                                              value:
                                                                item?.itemId,
                                                              label:
                                                                itemState[
                                                                item?.itemId
                                                                ],
                                                            });

                                                            setJobWorkData({
                                                              area: item?.area,
                                                              stitch:
                                                                item?.stitch,
                                                              code: item?.code,
                                                              rate: item?.rate,
                                                              PM: item?.PM,
                                                            });

                                                            setIsJobUpdate(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <i className="fas fa-pen hover:text-green-600"></i>
                                                        </button>
                                                        <button
                                                          className="m-2"
                                                          onClick={() => {
                                                            Swal.fire({
                                                              title: "Are you sure?",
                                                              text: "Once deleted, you will not be able to recover this record!",
                                                              icon: "warning",
                                                              showCancelButton: true,
                                                              confirmButtonColor: "#3085d6",
                                                              cancelButtonColor: "#d33",
                                                              confirmButtonText: "Delete",
                                                            }).then((willDelete) => {
                                                              if (willDelete.isConfirmed) {
                                                                let newData =
                                                                  jobWorkTableData.filter(
                                                                    (
                                                                      record: any
                                                                    ) => {
                                                                      return (
                                                                        record?._id !==
                                                                        item?._id
                                                                      );
                                                                    }
                                                                  );
                                                                setJobWorkTableData(
                                                                  newData
                                                                );
                                                                setIsJobUpdate(
                                                                  false
                                                                );
                                                                setJobWorkData({
                                                                  area: "",
                                                                  stitch: "",
                                                                  code: "",
                                                                  rate: "",
                                                                  PM: "",
                                                                });
                                                                setSelectedItem({});
                                                              }
                                                            })
                                                          }}
                                                        >
                                                          <i className="fas fa-trash hover:text-red-600"></i>
                                                        </button>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                          <tfoot>
                                            <tr className="border-t border-gray-400">
                                              <td
                                                colSpan={7}
                                                className=" text-gray-900 px-5"
                                              >
                                                Total
                                              </td>
                                              <td className="px-6 font-bold text-gray-900">
                                                {pcsTotal}
                                              </td>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </div>
                                    ) : (
                                      <div className="text-center py-3 text-gray-600 font-bold">
                                        No Job Work Data Found
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="text-sm text-red-500 flex justify-start">
                        {errorMessage?.jobWork}
                      </span>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-between visible lg:invisible xl:invisible">
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={showPreview}
                    onClick={openInvoicePreview}
                  >
                    <i className="fa fa-download"></i>
                    <span className="ml-2">Print</span>
                  </button>
                </div>
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={isUpdating}
                    onClick={isChallanUpdate ? updateData : addData}
                  >
                    {isUpdating ? (
                      <i className="fa fa-spinner animate-spin"></i>
                    ) : (
                      <i className="fa fa-save"></i>
                    )}
                    <span className="ml-2">
                      {isChallanUpdate ? "Update" : "Save"}
                    </span>
                  </button>
                </div>
                <div className="p-2 lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    onClick={() => {
                      navigate("/dresschallandashboard");
                    }}
                  >
                    <i className="fa fa-times hover:rotate-180 duration-300"></i>
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Pdf Preview */}
            {showPreview && (
              <DressChallanPreview
                showPreview={showPreview}
                setShowPreview={setShowPreview}
                id={isChallanUpdate ? id : savedId}
                pageName="Dress Challan"
              />
            )}

            {/* Modal */}
            {showAddParty && (
              <PartyAdd
                setShowAddParty={setShowAddParty}
                showAddParty={showAddParty}
                setPartyNameAdd={setPartyNameAdd}
                partyNameAdd={partyNameAdd}
                getData={getData}
              />
            )}

            {showAddCloth && (
              <ClothAdd
                setShowAddCloth={setShowAddCloth}
                showAddCloth={showAddCloth}
                setClothAdd={setClothAdd}
                clothAdd={clothAdd}
                getClothData={getClothData}
              />
            )}

            {showAddItem && (
              <ItemAdd
                setShowAddItem={setShowAddItem}
                showAddItem={showAddItem}
                setItemAdd={setItemAdd}
                itemAdd={itemAdd}
                getItemData={getItemData}
                itemType={itemType}
              />
            )}
            {showAddFabric && (
              <FabricAdd
                setShowAddFabric={setShowAddFabric}
                showAddFabric={showAddFabric}
                setFabricAdd={setFabricAdd}
                fabricAdd={fabricAdd}
                getFabricData={getFabricData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
