import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useAddJobWorkNameMutation } from "../../servicesRtkQuery/publicApi";
import TextBox from "../form/TextBox";
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../redux/globalSlice';
import Error from "../error";
import Swal from "sweetalert2";
import { capitalize } from "../../utils/globalFunctions";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";

export const NameAdd = (props: any) => {
    const dispatch = useDispatch()
    const [data, setData]: any = useState({})
    useEffect(() => {
        if (props.nameAdd) {
            setData({
                name: props.nameAdd,
                address: ""
            })
        }
    }, [props.nameAdd])

    const handleChange = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


    const [addName, { isSuccess, isError, isLoading, error, data: addData }] = useAddJobWorkNameMutation();
    const createItem = () => {
        let setError: any = '';
        dispatch(setErrorMessage(setError))
        if (data?.name?.trim() === "") {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: "Please Enter Valid Name !",
                position: 'top-end',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
        // else if (data?.address?.trim() === "") {
        //     Swal.fire({
        //         toast: true,
        //         icon: 'error',
        //         title: "Please Enter Valid Address !",
        //         position: 'top-end',
        //         showConfirmButton: false,
        //         timer: ERROR_MESSAGE_TIMEOUT,
        //         timerProgressBar: true
        //     })
        // }
        else {
            addName({
                name: capitalize(data?.name?.trim()),
                address: capitalize(data?.address?.trim()),
            })
        }
    }

    useEffect(() => {
        if (isSuccess && !isLoading) {
            let newItem: any = {
                label: capitalize(data?.name.trim()),
                value: addData?.data?.insertedIds[0],
            }
            props.getNameData(newItem, props?.itemType)
        }
        if (isError && !isLoading) {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: "Name Already Exists !",
                position: 'top-end',
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true
            })
        }
    }, [isSuccess, isError, isLoading])


    return (
        <div>
            <Modal open={props.showAddName} showCloseIcon={false}
                center
                closeOnEsc={false}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl"
                }}
                styles={{
                    overlay: {
                        height: "auto",
                    },
                }}
                onClose={() => props.setShowAddName(false)}>
                <div className="inset-0 overflow-auto">
                    <div className="flex items-end sm:items-center justify-center text-center sm:p-0">
                        <div className="relative rounded-lg overflow-hidden text-left shadow-xl">
                            <div className="w-full">
                                <div className="text-lg text-left">Add New Name</div>
                                <div className="p-6">
                                    <TextBox
                                        id="name" name="name" htmlFor="name"
                                        label="Name" placeholder=" " onChange={(e: any) => handleChange(e)} value={data?.name}
                                    />
                                    {props.itemType === "partyChallanName" ? (null) : (
                                        <div className="mt-2">
                                            <TextBox
                                                id="address" name="address" htmlFor="address"
                                                label="Address" placeholder=" " onChange={(e: any) => handleChange(e)} value={data?.address}
                                            />
                                        </div>
                                    )}
                                    <div className="m-3 flex justify-between">
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => props.setShowAddName(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button className='rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2' onClick={() => createItem()}>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Modal >
        </div >
    )
}
