import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactToPrint from "react-to-print";
import moment from "moment";
import {
    useLazyGetPartyChallanByKeywordQuery,
    useLazyPartyChallanQuery,
} from "../../../servicesRtkQuery/publicApi";
import DataTableLoader from "../../page/DataTableLoader";

export const PartyChallanDataPdf = (props: any) => {
    let total = 0;
    let totalShort = 0;
    let totalPlain = 0;
    let totalRecP = 0;
    let totalrate = 0;
    const exportRef: any = useRef(null);


    var { allCount, isSearching, search, type } = props;
    const [cardData, setCardData]: any = useState();
    const [trigger, result] = useLazyPartyChallanQuery();
    const { isSuccess, isFetching } = result;

    const [totalP, setTotalP]: any = useState(0);

    const [cardTrigger, cardResult] = useLazyGetPartyChallanByKeywordQuery();
    const { isSuccess: isCardSuccess, isFetching: isCardFetching } = cardResult;

    useEffect(() => {
        if (!isSearching) {
            trigger({
                limit: allCount,
            });
        }
    }, []);
    useEffect(() => {
        if (isSuccess && !isFetching) {
            setCardData(result?.data?.data?.partyChallan);
            console.log("total", result?.data?.data?.partyChallan)
            

            setTotalP(
                result?.data?.data?.partyChallan?.reduce(
                    (total: number, currentItem: any) =>
                        total + parseInt(currentItem.TPM),
                    0
                )
            );

        }
    }, [isSuccess, isFetching]);

    useEffect(() => {
        if (isCardSuccess && !isCardFetching) {
            setCardData(cardResult?.data?.data?.partyChallan);
           

            setTotalP(
                cardResult?.data?.data?.partyChallan?.reduce(
                    (total: number, currentItem: any) =>
                        total + parseInt(currentItem.TPM),

                    0
                )
            );
        }
    }, [isCardSuccess, isCardFetching]);

    useEffect(() => {
        let searchData: any = {
            limit: allCount,
        };
        Object.keys(search).map((key: any) => {
            if (search[key] && search[key].text !== "") {
                searchData[key] = search[key].text;
            }
        });
        if (type !== "all") {
            searchData = { ...searchData, type: type };
        }
        if (Object.keys(searchData).length !== 0) {
            cardTrigger(searchData);
        }
    }, [search]);
    return (
        <div>
            <Modal
                open={props.isExportData}
                showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl",
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    },
                    modalContainer: {
                        overflow: "auto",
                    },
                }}
                onEscKeyDown={() => props.setIsExportData(false)}
                onOverlayClick={() => props.setIsExportData(false)}
                aria-labelledby="jobwork-pdf"
                aria-describedby="jobwork-pdf-description"
                animationDuration={400}
                onClose={() => props.setIsExportData(false)}
            >
                <div className="overflow-auto">
                    <div className="text-center">
                        <div className="bg-white border rounded-lg text-left shadow-xl">
                            <div ref={exportRef} className={``}>
                                <div className="border border-gray-600">
                                    <div className="font-bold items-center p-2 bg-gray-100 text-5">
                                        <div className="text-themeColor capitalize text-center">
                                            Party Challan Data
                                        </div>
                                    </div>

                                    {!isFetching ? (
                                        <div className="">
                                            <div className="text-sm">
                                                <table className="w-full border-collapse bg-white text-left">
                                                    <thead className="bg-gray-100 divide-black border-t border-black text-4">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-4 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                sr no
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="py-1.5  border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Send date
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5  border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Receive date
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-4 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Days
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                party
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5  border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Cloth
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5  border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Color
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-4 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                D no
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-4 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Rece. Pcs
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-4 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Pcs
                                                            </th>


                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-[3.75rem] border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Rate
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 w-5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Total
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y text-center divide-black border-t border-black text-4">
                                                        {cardData?.map((item: any, i: any) => {
                                                            total = Number(total + (
                                                                Number(item?.TPM) - Number(
                                                                    Number(item?.RTPM) +
                                                                    Number(item?.plain) +
                                                                    Number(item?.short)
                                                                )
                                                            ));
                                                            totalShort = Number(
                                                                Number(totalShort) + Number(item?.short)
                                                            );
                                                            totalPlain = Number(
                                                                Number(totalPlain) + Number(item?.plain)
                                                            );
                                                            totalRecP = Number(
                                                                Number(totalRecP) + Number(item?.receivePcs)
                                                            );
                                                            totalrate = Number(
                                                                Number(totalrate) +
                                                                (item?.work?.reduce((total: number, item: any) => total + item.rate, 0))
                                                            );

                                                            return (
                                                                <tr key={i}>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                                                        {i + 1}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {moment(item?.date).format("DD-MM-YYYY")}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.receiveDate
                                                                            ? moment(item?.receiveDate).format(
                                                                                "DD-MM-YYYY"
                                                                            )
                                                                            : "-"}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.day}
                                                                    </td>

                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.challanName?.name}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.work[0]?.clothe?.name}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.work[0]?.fabricColor?.colorName}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.work?.map((item: any) => item?.designNo).join("/")}
                                                                    </td>
                                                                    <td className="gap-3 border-black border-r font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.receivePcs}
                                                                    </td>
                                                                    <td className="gap-3 border-black border-r font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.work?.map((item: any) => item?.pcs).join("/")}
                                                                    </td>


                                                                    <td className="gap-3 border-black border-r font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.work?.map((item: any) => item?.rate).join("/")}
                                                                    </td>
                                                                    <td className="gap-3 border-black border-r font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.work?.map((item: any) => item?.rate * item?.pcs).join("/")}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        <tr>
                                                            <td colSpan={8}></td>
                                                            <td className="gap-3 border border-black font-semibold text-gray-900 text-center capitalize">
                                                                {totalRecP ? totalRecP : ""}
                                                            </td>
                                                            <td className="gap-3 border border-black font-semibold text-gray-900 text-center capitalize">
                                                                {totalP}
                                                            </td>
                                                            <td className="gap-3 border border-black font-semibold text-gray-900 text-center capitalize">
                                                                {totalrate ? totalrate : ""}
                                                            </td>
                                                            <td
                                                                colSpan={2}

                                                                className="gap-3 border border-black font-semibold text-gray-900 text-center capitalize"
                                                            >
                                                                {result?.data?.data?.partyChallan?.reduce(
                                                                    (total: number, item: any) => total + item?.work?.reduce(
                                                                        (subTotal: number, workItem: any) => subTotal + workItem.rate * workItem.pcs,
                                                                        0
                                                                    ),
                                                                    0
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        <DataTableLoader />
                                    )}
                                </div>
                            </div>
                            <div className="m-3 flex flex-col lg:flex-row gap-2">
                                <div>
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                                                Print <i className="fas fa-file-download"></i>
                                            </button>
                                        )}
                                        content={() => exportRef.current}
                                        documentTitle={props.pageName}
                                    />
                                </div>
                                <div>
                                    <button
                                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                                        onClick={() => props.setIsExportData(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
