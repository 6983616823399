import { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  useLazyGetMatchingCardQuery,
  useLazyChallanQuery,
  useLazyGetChallanByKeywordQuery,
  useUpdateChallanMutation,
  useLazyChallanCountQuery,
} from "../../servicesRtkQuery/publicApi";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { setLumpChallan } from "../../redux/lumpChallanSlice";
import Swal from "sweetalert2";
import TextBox from "../../components/form/TextBox";
import CountUp from "react-countup";
import DataTableLoader from "../../components/page/DataTableLoader";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import moment from "moment";
import customStyles from "../../utils/globalFunctions";
import { LumpChallanPreview } from "../../components/pdf/challan/lumpChallanPreview";
import { TableAction } from "../../components/modal/TableAction";
import { ChallanDataPdf } from "../../components/pdf/dashboard/challanDataPdf";

export default function LumpChallanDashboard() {
  const dispatch = useDispatch();
  const [trigger, result] = useLazyGetChallanByKeywordQuery();
  const { isSuccess, isFetching, isError, error } = result;
  const [matchingTrigger, matchingResult] = useLazyGetMatchingCardQuery();
  const {
    isSuccess: isMatchingSuccess,
    isFetching: isMatchingFetching,
    isError: isMatchingError,
    error: matchingError,
  } = matchingResult;
  const [cardTrigger, cardResult] = useLazyGetChallanByKeywordQuery();
  const {
    isSuccess: isCardSuccess,
    isFetching: isCardFetching,
    isError: isCardError,
    error: cardError,
  } = cardResult;
  const [countTrigger, countResult] = useLazyChallanCountQuery();
  const {
    isSuccess: isCountSuccess,
    isFetching: isCountFetching,
    isError: isCountError,
    error: countError,
  } = countResult;
  const [cardData, setCardData]: any = useState<any>([]);
  const navigate = useNavigate();
  const [updateCard] = useUpdateChallanMutation();
  const [showPreview, setShowPreview] = useState(false);
  const [singleCard, setSingleCard]: any = useState<any>();

  const lumpChallanData = useSelector(
    (state: any) => state.lumpChallanSlice.lumpChallanData
  );

  // Counters
  const [allCount, setAllCount]: any = useState(0);
  const [dressCount, setDressCount]: any = useState(0);
  const [sareeCount, setSareeCount]: any = useState(0);
  const [lumpCount, setLumpCount]: any = useState(0);
  const [partyCount, setPartyCount]: any = useState(0);
  // Filtering states
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isExportData, setIsExportData] = useState(false);

  //Pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const urlData: any = useLocation().search;
  const dashboardType = new URLSearchParams(urlData).get("type");
  const [type, setType]: any = useState(dashboardType ? dashboardType : "all");
  const [isSearching, setIsSearching]: any = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState("");

  //#region API triggers
  useEffect(() => {
    countTrigger();
    if (lumpChallanData?.challan) {
      setCardData(lumpChallanData?.challan);
    } else {
      trigger({
        type: "lump",
        page: page,
        limit: perPage,
      });
    }
  }, []);

  const globalTriggers = (page: Number, perPage: Number) => {
    if (isSearching) {
      let searchData: any = {
        page: page,
        limit: perPage,
        type: "lump",
      };
      Object.keys(search).map((key: any) => {
        if (search[key] && search[key].text !== "") {
          searchData[key] = search[key].text;
        }
      });
      if (Object.keys(searchData).length > 3) {
        cardTrigger(searchData);
      }
    } else {
      trigger({
        type: "lump",
        page: page,
        limit: perPage,
      });
    }
  };

  useEffect(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    globalTriggers(page, perPage);
  }, [type]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      if (!isSearching) {
        dispatch(setLumpChallan(result?.data?.data));
      }
      setCardData(result?.data?.data?.challan);
      setTotalRows(result?.data?.data?.count);
      setLumpCount(result?.data?.data?.count);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isCardSuccess && !isCardFetching) {
      setCardData(cardResult?.data?.data?.challan);
      // if (!isSearching) {
      //   dispatch(setLumpChallan(cardResult?.data?.data));
      // }
      setTotalRows(cardResult?.data?.data?.count);
    }
  }, [isCardSuccess, isCardFetching]);

  useEffect(() => {
    if (isMatchingSuccess && !isMatchingFetching) {
      setSingleCard(matchingResult.data.data.challan[0]);
    }
  }, [isMatchingSuccess, isMatchingFetching]);

  useEffect(() => {
    if (isCountSuccess && !isCountFetching) {
      setAllCount(countResult?.data?.data?.job);
      setDressCount(countResult?.data?.data?.dress);
      setSareeCount(countResult?.data?.data?.saree);
      setLumpCount(countResult?.data?.data?.lump);
      setPartyCount(countResult?.data?.data?.party);
    }
  }, [isCountSuccess, isCountFetching]);
  //#endregion

  //#region Field Search section
  const [search, setSearch]: any = useState({
    cardNo: {
      text: "",
      status: false,
    },
    partyName: {
      text: "",
      status: false,
    },
    designNo: {
      text: "",
      status: false,
    },
    challanNo: {
      text: "",
      status: false,
    },
    name: {
      text: "",
      status: false,
    },
    address: {
      text: "",
      status: false,
    },
    PCNo: {
      text: "",
      status: false,
    },
    itemName: {
      text: "",
      status: false,
    },
    date: {
      text: "",
      status: false,
    },
    fromDate: {
      text: "",
      status: false,
    },
    toDate: {
      text: "",
      status: false,
    },
  });

  const onSearch = (key: string) => {
    let searchData = search;
    setIsSearching(true);
    Object.keys(searchData).forEach((searchkey: any, value: any) => {
      let item = searchData[searchkey];
      item["status"] = false;
      return item;
    });
    setSearch({
      ...searchData,
      [key]: {
        ...search[key],
        status: !search[key]["status"],
      },
    });
  };

  useEffect(() => {
    let searchData: any = {
      type: "lump",
      page: page,
      limit: perPage,
    };
    Object.keys(search).map((key: any) => {
      if (search[key] && search[key].text !== "") {
        searchData[key] = search[key].text;
      }
      setTotalRows(result?.data?.data?.count);
    });
    searchData = { ...searchData };
    if (Object.keys(searchData).length > 3) {
      setIsSearching(true);
      cardTrigger(searchData);
    } else {
      setIsSearching(false);
      globalTriggers(page, perPage);
    }
  }, [search]);

  const searchComponent = (key: string, label: string) => {
    return (
      <div className={`text-left`}>
        {search[key].status ? (
          <TextBox
            type={"text"}
            placeholder={label}
            autoFocus
            className="font-light"
            value={search[key].text}
            onChange={(e: any) => {
              if (e.target.value.length === 0) {
                setSearch({
                  ...search,
                  [key]: {
                    text: "",
                    status: false,
                  },
                });
                setCardData(result?.data?.data?.challan);
              } else {
                setSearch({
                  ...search,
                  [key]: {
                    text: e.target.value,
                    status: true,
                  },
                });
              }
            }}
          />
        ) : (
          <div className="mb-2">
            <div
              className="mb-1 cursor-pointer"
              onClick={() => {
                onSearch(key);
              }}
            >
              {label}
              {/* <i className='fas fa-search ml-2 text-gray-600'></i> */}
            </div>
            {search[key].text != "" && (
              <div className="flex justify-center items-center m-1 font-medium py-1 px-3 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
                <div className="text-xs font-normal leading-none max-w-full flex-initial px-4">
                  {search[key].text}
                </div>
                <div className="flex flex-auto flex-row-reverse">
                  <i
                    className="fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4"
                    onClick={() => {
                      setSearch({
                        ...search,
                        [key]: {
                          text: "",
                          status: false,
                        },
                      });
                      setCardData(result?.data?.data?.challan);
                    }}
                  ></i>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  //#endregion

  const columns: any = [
    {
      name: searchComponent("date", "Date"),
      selector: (row: { [x: string]: any }) =>
        moment(row.date).format("DD-MM-yyyy"),
      width: "180px",
    },
    {
      name: searchComponent("challanNo", "Challan No"),
      selector: (row: { [x: string]: any }) => row?.challanNo,
    },
    {
      name: searchComponent("name", "Name"),
      selector: (row: { [x: string]: any }) => row?.name,
    },
    {
      name: searchComponent("cardNo", "Card No"),
      selector: (row: { [x: string]: any }) => row?.cardNo,
    },
    {
      selector: (row: { [x: string]: any }) => row?.party?.name,
      name: searchComponent("partyName", "Party Name"),
    },
    {
      name: searchComponent("designNo", "Design No"),
      selector: (row: { [x: string]: any }) => row?.designNo,
    },
    {
      name: searchComponent("PCNo", "P Challan No"),
      selector: (row: { [x: string]: any }) => row?.PCNo,
    }
  ];

  //#region Global searving section
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <div className="flex flex-wrap gap-2">
        <div className='w-full md:w-max'>
          <TextBox
            id="fromDate"
            type="date"
            placeholder=" "
            label="From"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (e.target.value.length == 0) {
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
              } else {
                setIsSearching(true);
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: e.target.value,
                      status: false,
                    },
                  };
                });
              }
            }}
          />
        </div>
        <div className='w-full md:w-max'>
          <TextBox
            id="toDate"
            type="date"
            placeholder=" "
            label="To"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (e.target.value.length == 0) {
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    toDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
              } else {
                setIsSearching(true);
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    toDate: {
                      text: e.target.value,
                      status: false,
                    },
                  };
                });
              }
            }}
          />
        </div>
        <div className="md:block w-full md:w-max">
          <div className="relative flex items-center text-gray-400 focus-within:text-themeColor">
            <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
              <i className='fas fa-search w-4 fill-current'></i>
            </span>
            <input type="search" name="leadingIcon" id="leadingIcon" placeholder="Search here" value={filterText} onChange={(e: any) => {
              setFilterText(e.target.value)
            }} className="w-full pl-14 pr-4 py-2 rounded-xl text-sm text-gray-600 outline-none border border-gray-500 focus:border-themeColor transition" />
          </div>
        </div>
        <div>
          <button className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px" type="button"
            onClick={() => {
              setIsExportData(true)
            }}>
            <i className='fas fa-file'></i><span className='ml-2'>Export Pdf</span>
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    if (filterText) {
      cardTrigger({
        search: filterText,
        type: "lump",
      });
    } else {
      globalTriggers(page, perPage);
    }
  }, [filterText]);
  //#endregion

  const openInvoicePreview = async (row: any) => {
    setShowPreview(true);
    if (isSearching) {
      let payload = {
        mcId: row._id,
        query: {
          isSingle: true,
        },
      };
      matchingTrigger(payload);
    } else {
      setSingleCard(row);
    }
  };

  const handleDelete = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let cId = row._id;
        let updatedData = {
          status: false,
        };
        updateCard({
          payload: updatedData,
          query: {
            cId: cId,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Challan has been deleted!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            globalTriggers(page, perPage);
          }
        });
      }
    });
  };

  //#region Pagination section
  const handlePageChange = (page: any) => {
    setFilterText("");
    setPage(page);
    globalTriggers(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setFilterText("");
    setPage(page);
    setPerPage(newPerPage);
    globalTriggers(page, newPerPage);
  };

  return (
    <>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="w-full px-6 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/5 cursor-pointer"
              onClick={() => {
                setType("all");
                navigate(`/jobworkdashboard`);
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-white`}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Job Work Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">

                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={allCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                        <i className="leading-none fas fa-layer-group text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/5 cursor-pointer"
              onClick={() => {
                setType("dress");
                navigate(`/dresschallandashboard`);
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-white`}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Dress Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={dressCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/5 cursor-pointer"
              onClick={() => {
                setType("saree");
                navigate(`/sareechallandashboard`);
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-white`}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Saree Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={sareeCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/5 cursor-pointer"
              onClick={() => {
                setType("lump");
                navigate(`/lumpchallandashboard`);
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-gradient-to-r from-yellow-100 to-orange-200`}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Lump Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={lumpCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/5 cursor-pointer"
              onClick={() => {
                setType("party");
                navigate(`/partychallandashboard`);
              }}
            >

              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-white`}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Party Challan
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">

                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={partyCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <div className="w-full px-6 pt-6 mx-auto">
          <div className="rounded-xl bg-white">
            <div className="mb-6 py-4 break-words border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
              <div className="px-3">
                <button
                  className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  type="button"
                  onClick={() => {
                    navigate(`/jobwork/lumpchallan`);
                  }}
                >
                  <i className="fas fa-folder-plus"></i>
                  <span className="ml-2">Add Lump Challan</span>
                </button>
              </div>
              <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto capitalize">
                  <DataTable
                    title={"Lump Challan"}
                    className="touch-auto"
                    columns={columns}
                    subHeaderComponent={subHeaderComponentMemo}
                    paginationResetDefaultPage={resetPaginationToggle}
                    progressPending={isFetching}
                    progressComponent={<DataTableLoader />}
                    data={cardData}
                    fixedHeader={true}
                    defaultSortFieldId="invoiceNo"
                    defaultSortAsc={false}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    striped={true}
                    responsive={true}
                    highlightOnHover
                    persistTableHead
                    subHeader
                    customStyles={customStyles}
                    onRowClicked={(e: any) => {
                      setSelectedRow(e);
                      setIsModalOpen(true);
                    }}
                    paginationPerPage={20}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <TableAction
          header={selectedRow?.designNo}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedRow={selectedRow}
          isCopy={false}
          isPreview={true}
          openInvoicePreview={openInvoicePreview}
          handleDelete={handleDelete}
          route={"jobwork/lumpchallan"}
        />
      ) : null}
      {/* Pdf Preview */}
      {showPreview && (
        <LumpChallanPreview
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          id={singleCard?._id}
          pageName="Lump Challan"
        />
      )}
      {isExportData &&
          <ChallanDataPdf
              isExportData={isExportData}
              setIsExportData={setIsExportData}
              allCount={allCount}
              isSearching={isSearching}
              search={search}
              type={"lump"}
              pageName="Lump Challan Report"
          />
      }
    </>
  );
}
