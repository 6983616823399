import { useEffect, useState } from 'react';
import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip, Legend
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import CountUp from 'react-countup';
import { useLazyAllRateCardQuery, useLazyGetDressRateCardByKeywordQuery, useLazyGetLumpRateCardByKeywordQuery, useLazyGetMatchingCardCountQuery, useLazyGetSampleCardCountQuery, useLazyGetSareeRateCardByKeywordQuery, useLazyMatchingCardQuery, useLazySampleCardQuery, useLazyUserQuery } from '../../servicesRtkQuery/publicApi';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"
import { groupBy, groupByMonth } from '../../utils/globalFunctions';
import { useDispatch } from 'react-redux';
import { setSampleCard } from '../../redux/sampleCardSlice';
import { setMatchingCard } from '../../redux/matchingCardSlice';
ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function Dashboard() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // User Data
    const [userTrigger, userResult] = useLazyUserQuery()
    const { isSuccess, isFetching, isError, error } = userResult
    const [userCount, setUserCount]: any = useState(0)

    const [perPage] = useState(1000);
    const [page] = useState(1);

    // Sample Card data
    const [sampleCardTrigger, sampleCardResult] = useLazySampleCardQuery()
    
    const { isSuccess: isSuccessSampleCard, isFetching: isFetchingSampleCard } = sampleCardResult
    const [countSampleCardTrigger, countSampleCardResult] = useLazyGetSampleCardCountQuery();
    const { isSuccess: isSuccessSampleCardCount, isFetching: isFetchingSampleCardCount } = countSampleCardResult
    const [sampleCardCount, setSampleCardCount]: any = useState(0)
    const [sampleDressCount, setSampleDressCount]: any = useState(0);
    const [sampleSareeCount, setSampleSareeCount]: any = useState(0);
    const [sampleLumpCount, setSampleLumpCount]: any = useState(0);
    const [sampleDressMonthlyCount, setDressSampleMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });
    const [sampleSareeMonthlyCount, setSareeSampleMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });
    const [sampleLumpMonthlyCount, setLumpSampleMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });

    //Matching Card Data
    const [matchingCardTrigger, matchingCardResult] = useLazyMatchingCardQuery()
    const { isSuccess: isSuccessMatchingCard, isFetching: isFetchingMatchingCard } = matchingCardResult
    const [countTrigger, countResult] = useLazyGetMatchingCardCountQuery();
    const {isSuccess: isCountSuccess,isFetching: isCountFetching,isError: isCountError,error: countError}=countResult
    const [matchingCardCount, setMatchingCardCount]: any = useState(0)
    const [matchingDressCount, setMatchingDressCount]: any = useState(0);
    const [matchingSareeCount, setMatchingSareeCount]: any = useState(0);
    const [matchingLumpCount, setMatchingLumpCount]: any = useState(0);
    const [matchingDressMonthlyCount, setDressMatchingMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });
    const [matchingSareeMonthlyCount, setSareeMatchingMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });
    const [matchingLumpMonthlyCount, setLumpMatchingMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });

    //Rate Card Data
    const [rateCardTrigger, rateCardResult] = useLazyAllRateCardQuery()
    const { isSuccess: isSuccessRateCard, isFetching: isFetchingRateCard } = rateCardResult
    const [dressTrigger, dressResult] = useLazyGetDressRateCardByKeywordQuery()
    const { isSuccess: isDressSuccess, isFetching: isDressFetching } = dressResult
    const [lumpTrigger, lumpResult] = useLazyGetLumpRateCardByKeywordQuery()
    const { isSuccess: isLumpSuccess, isFetching: isLumpFetching } = lumpResult
    const [sareeTrigger, sareeResult] = useLazyGetSareeRateCardByKeywordQuery()
    const { isSuccess: isSareeSuccess, isFetching: isSareeFetching } = sareeResult
    const [rateCardCount, setRateCardCount]: any = useState(0)
    const [rateDressCount, setRateDressCount]: any = useState(0);
    const [rateSareeCount, setRateSareeCount]: any = useState(0);
    const [rateLumpCount, setRateLumpCount]: any = useState(0);
    const [rateDressMonthlyCount, setDressRateMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });
    const [rateSareeMonthlyCount, setSareeRateMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });
    const [rateLumpMonthlyCount, setLumpRateMonthlyCount]: any = useState({
        "01": [], "02": [], "03": [], "04": [], "05": [], "06": [], "07": [], "08": [], "09": [], "10": [], "11": [], "12": []
    });

    const sampleCardPiedata = {
        labels: ['Saree Cards', 'Dress Cards', 'Lump Cards'],
        datasets: [
            {
                label: 'Total Count',
                data: [sampleSareeCount, sampleDressCount, sampleLumpCount],
                backgroundColor: [
                    'rgba(252, 20, 78, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)'
                ]
            },
        ],
    };

    const matchingCardPiedata = {
        labels: ['Saree Cards', 'Dress Cards', 'Lump Cards'],
        datasets: [
            {
                label: 'Total Count',
                data: [matchingSareeCount, matchingDressCount, matchingLumpCount],
                backgroundColor: [
                    'rgba(252, 20, 78, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)'
                ]
            },
        ],
    };

    const rateCardPiedata = {
        labels: ['Saree Cards', 'Dress Cards', 'Lump Cards'],
        datasets: [
            {
                label: 'Total Count',
                data: [rateSareeCount, rateDressCount, rateLumpCount],
                backgroundColor: [
                    'rgba(252, 20, 78, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)'
                ]
            },
        ],
    };

    useEffect(() => {
        userTrigger({});
        sampleCardTrigger({
            page: page,
            limit: perPage
        });
        matchingCardTrigger({
            page: page,
            limit: perPage
        });
        rateCardTrigger({});
        countTrigger({})
        countSampleCardTrigger({})
    }, []);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setUserCount(userResult?.data?.data?.count)
        }
    }, [isSuccess, isFetching])

    useEffect(() => {
        if (isSuccessSampleCard && !isFetchingSampleCard) {
            var data = groupBy(sampleCardResult?.data?.data?.sampleCard, 'type')
            setDressSampleMonthlyCount(groupByMonth(data?.dress, 'date'))
            setSareeSampleMonthlyCount(groupByMonth(data?.saree, 'date'))
            setLumpSampleMonthlyCount(groupByMonth(data?.lump, 'date'))

            dispatch(setSampleCard(sampleCardResult?.data?.data))

            // setSampleCardCount(sampleCardResult?.data?.data?.count)
            setSampleDressCount(sampleCardResult?.data?.data?.dress)

            setSampleSareeCount(sampleCardResult?.data?.data?.saree)
            setSampleLumpCount(sampleCardResult?.data?.data?.lump)
        }
    }, [isSuccessSampleCard, isFetchingSampleCard])

    useEffect(() => {
        if (isSuccessSampleCardCount && !isFetchingSampleCardCount) {
            setSampleCardCount(countSampleCardResult?.data?.data?.all)
        }
    }, [isSuccessSampleCardCount, isFetchingSampleCardCount])

    useEffect(() => {
        if (isSuccessMatchingCard && !isFetchingMatchingCard) {
            var data = groupBy(matchingCardResult?.data?.data?.matchingCard, 'type')
            setDressMatchingMonthlyCount(groupByMonth(data?.dress, 'date'))
            setSareeMatchingMonthlyCount(groupByMonth(data?.saree, 'date'))
            setLumpMatchingMonthlyCount(groupByMonth(data?.lump, 'date'))

            dispatch(setMatchingCard(matchingCardResult?.data?.data))
        }
    }, [isSuccessMatchingCard, isFetchingMatchingCard])
    useEffect(() => {
        if (isCountSuccess && !isCountFetching) {

            dispatch(setMatchingCard(matchingCardResult?.data?.data))

            setMatchingCardCount(countResult?.data?.data?.all)
            setMatchingDressCount(countResult?.data?.data?.dress)
            setMatchingSareeCount(countResult?.data?.data?.saree)
            setMatchingLumpCount(countResult?.data?.data?.lump)
        }
    }, [isCountSuccess, isCountFetching])

    useEffect(() => {
        if (isSuccessRateCard && !isFetchingRateCard) {
            setRateCardCount(rateCardResult?.data?.data?.dress + rateCardResult?.data?.data?.saree + rateCardResult?.data?.data?.lump)
            setRateDressCount(rateCardResult?.data?.data?.dress)
            setRateSareeCount(rateCardResult?.data?.data?.saree)
            setRateLumpCount(rateCardResult?.data?.data?.lump)
            dressTrigger({
                page: page,
                limit: rateCardResult?.data?.data?.dress
            })
            sareeTrigger({
                page: page,
                limit: rateCardResult?.data?.data?.saree
            })
            lumpTrigger({
                page: page,
                limit: rateCardResult?.data?.data?.lump
            })
        }
    }, [isSuccessRateCard, isFetchingRateCard])

    useEffect(() => {
        if (isDressSuccess && !isDressFetching) {
            var newData: any = []
            dressResult?.data?.data?.dressCard?.map((matchingCard: any) => {
                newData.push({
                    ...matchingCard,
                    type: "dress"
                })
            })
            
            var data = groupBy(newData, 'type')
            setDressRateMonthlyCount(groupByMonth(data.dress, 'date'))
        }
    }, [isDressSuccess, isDressFetching])

    useEffect(() => {
        if (isSareeSuccess && !isSareeFetching) {
            var newData: any = []
            sareeResult?.data?.data?.sareeCard?.map((sareeCard: any) => {
                newData.push({
                    ...sareeCard,
                    type: "saree"
                })
            })
            
            var data = groupBy(newData, 'type')
            setSareeRateMonthlyCount(groupByMonth(data?.saree, 'date'))
        }
    }, [isSareeSuccess, isSareeFetching])

    useEffect(() => {
        if (isLumpSuccess && !isLumpFetching) {
            var newData: any = []
            lumpResult?.data?.data?.lumpCard?.map((lumpCard: any) => {
                newData.push({
                    ...lumpCard,
                    type: "lump"
                })
            })
            
            var data = groupBy(newData, 'type')
            setLumpRateMonthlyCount(groupByMonth(data?.lump, 'date'))
        }
    }, [isLumpSuccess, isLumpFetching])

    const options = { maintainAspectRatio: false };
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Augest', 'September', 'October', 'November', 'December'];

    const sample = {
        labels,
        datasets: [
            {
                label: "Dress",
                data: [sampleDressMonthlyCount["01"]?.length || 0, sampleDressMonthlyCount["02"]?.length || 0, sampleDressMonthlyCount["03"]?.length || 0, sampleDressMonthlyCount["04"]?.length || 0, sampleDressMonthlyCount["05"]?.length || 0, sampleDressMonthlyCount["06"]?.length || 0, sampleDressMonthlyCount["07"]?.length || 0, sampleDressMonthlyCount["08"]?.length || 0, sampleDressMonthlyCount["09"]?.length || 0, sampleDressMonthlyCount["10"]?.length || 0, sampleDressMonthlyCount["11"]?.length || 0, sampleDressMonthlyCount["12"]?.length || 0],
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "Saree",
                data: [sampleSareeMonthlyCount["01"]?.length || 0, sampleSareeMonthlyCount["02"]?.length || 0, sampleSareeMonthlyCount["03"]?.length || 0, sampleSareeMonthlyCount["04"]?.length || 0, sampleSareeMonthlyCount["05"]?.length || 0, sampleSareeMonthlyCount["06"]?.length || 0, sampleSareeMonthlyCount["07"]?.length || 0, sampleSareeMonthlyCount["08"]?.length || 0, sampleSareeMonthlyCount["09"]?.length || 0, sampleSareeMonthlyCount["10"]?.length || 0, sampleSareeMonthlyCount["11"]?.length || 0, sampleSareeMonthlyCount["12"]?.length || 0],
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Lump",
                data: [sampleLumpMonthlyCount["01"]?.length || 0, sampleLumpMonthlyCount["02"]?.length || 0, sampleLumpMonthlyCount["03"]?.length || 0, sampleLumpMonthlyCount["04"]?.length || 0, sampleLumpMonthlyCount["05"]?.length || 0, sampleLumpMonthlyCount["06"]?.length || 0, sampleLumpMonthlyCount["07"]?.length || 0, sampleLumpMonthlyCount["08"]?.length || 0, sampleLumpMonthlyCount["09"]?.length || 0, sampleLumpMonthlyCount["10"]?.length || 0, sampleLumpMonthlyCount["11"]?.length || 0, sampleLumpMonthlyCount["12"]?.length || 0],
                borderColor: "rgba(255, 206, 86, 0.5)",
                backgroundColor: "rgba(255, 206, 86, 0.5)",
            },
        ],
    };

    const matching = {
        labels,
        datasets: [
            {
                label: "Dress",
                data: [matchingDressMonthlyCount["01"]?.length || 0, matchingDressMonthlyCount["02"]?.length || 0, matchingDressMonthlyCount["03"]?.length || 0, matchingDressMonthlyCount["04"]?.length || 0, matchingDressMonthlyCount["05"]?.length || 0, matchingDressMonthlyCount["06"]?.length || 0, matchingDressMonthlyCount["07"]?.length || 0, matchingDressMonthlyCount["08"]?.length || 0, matchingDressMonthlyCount["09"]?.length || 0, matchingDressMonthlyCount["10"]?.length || 0, matchingDressMonthlyCount["11"]?.length || 0, matchingDressMonthlyCount["12"]?.length || 0],
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "Saree",
                data: [matchingSareeMonthlyCount["01"]?.length || 0, matchingSareeMonthlyCount["02"]?.length || 0, matchingSareeMonthlyCount["03"]?.length || 0, matchingSareeMonthlyCount["04"]?.length || 0, matchingSareeMonthlyCount["05"]?.length || 0, matchingSareeMonthlyCount["06"]?.length || 0, matchingSareeMonthlyCount["07"]?.length || 0, matchingSareeMonthlyCount["08"]?.length || 0, matchingSareeMonthlyCount["09"]?.length || 0, matchingSareeMonthlyCount["10"]?.length || 0, matchingSareeMonthlyCount["11"]?.length || 0, matchingSareeMonthlyCount["12"]?.length || 0],
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Lump",
                data: [matchingLumpMonthlyCount?.["01"]?.length || 0, matchingLumpMonthlyCount?.["02"]?.length || 0, matchingLumpMonthlyCount?.["03"]?.length || 0, matchingLumpMonthlyCount?.["04"]?.length || 0, matchingLumpMonthlyCount?.["05"]?.length || 0, matchingLumpMonthlyCount?.["06"]?.length || 0, matchingLumpMonthlyCount?.["07"]?.length || 0, matchingLumpMonthlyCount?.["08"]?.length || 0, matchingLumpMonthlyCount?.["09"]?.length || 0, matchingLumpMonthlyCount?.["10"]?.length || 0, matchingLumpMonthlyCount?.["11"]?.length || 0, matchingLumpMonthlyCount?.["12"]?.length || 0],
                borderColor: "rgba(255, 206, 86, 0.5)",
                backgroundColor: "rgba(255, 206, 86, 0.5)",
            },
        ],
    };

    const rate = {
        labels,
        datasets: [
            {
                label: "Dress",
                data: [rateDressMonthlyCount["01"]?.length || 0, rateDressMonthlyCount["02"]?.length || 0, rateDressMonthlyCount["03"]?.length || 0, rateDressMonthlyCount["04"]?.length || 0, rateDressMonthlyCount["05"]?.length || 0, rateDressMonthlyCount["06"]?.length || 0, rateDressMonthlyCount["07"]?.length || 0, rateDressMonthlyCount["08"]?.length || 0, rateDressMonthlyCount["09"]?.length || 0, rateDressMonthlyCount["10"]?.length || 0, rateDressMonthlyCount["11"]?.length || 0, rateDressMonthlyCount["12"]?.length || 0],
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "Saree",
                data: [rateSareeMonthlyCount["01"]?.length || 0, rateSareeMonthlyCount["02"]?.length || 0, rateSareeMonthlyCount["03"]?.length || 0, rateSareeMonthlyCount["04"]?.length || 0, rateSareeMonthlyCount["05"]?.length || 0, rateSareeMonthlyCount["06"]?.length || 0, rateSareeMonthlyCount["07"]?.length || 0, rateSareeMonthlyCount["08"]?.length || 0, rateSareeMonthlyCount["09"]?.length || 0, rateSareeMonthlyCount["10"]?.length || 0, rateSareeMonthlyCount["11"]?.length || 0, rateSareeMonthlyCount["12"]?.length || 0],
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Lump",
                data: [rateLumpMonthlyCount["01"]?.length || 0, rateLumpMonthlyCount["02"]?.length || 0, rateLumpMonthlyCount["03"]?.length || 0, rateLumpMonthlyCount["04"]?.length || 0, rateLumpMonthlyCount["05"]?.length || 0, rateLumpMonthlyCount["06"]?.length || 0, rateLumpMonthlyCount["07"]?.length || 0, rateLumpMonthlyCount["08"]?.length || 0, rateLumpMonthlyCount["09"]?.length || 0, rateLumpMonthlyCount["10"]?.length || 0, rateLumpMonthlyCount["11"]?.length || 0, rateLumpMonthlyCount["12"]?.length || 0],
                borderColor: "rgba(255, 206, 86, 0.5)",
                backgroundColor: "rgba(255, 206, 86, 0.5)",
            },
        ],
    };

    return (
        <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
            <div className="">
                <motion.div initial={{ y: -30, opacity: 1 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 30, opacity: 1 }}
                    transition={{ duration: 0.5 }}>
                    <div className="w-full px-6 mx-auto">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 -mx-3">
                            <div className="w-full max-w-full px-3 mb-6">
                                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl rounded-2xl bg-clip-border">
                                    <div className="flex-auto p-4">
                                        <div className="flex flex-row -mx-3">
                                            <div className="flex-none w-2/3 max-w-full px-3">
                                                <div>
                                                    <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">Total sample cards</p>
                                                    <h5 className="mb-2 font-bold"><CountUp end={sampleCardCount} duration={0.5} /></h5>
                                                </div>
                                            </div>
                                            <div className="text-right basis-1/2 overflow-hidden">
                                                <div className="text-center rounded-circle transition ease-in-out delay-150">
                                                    <i className="leading-none fas fa-file-image relative font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-blue-500 hover:from-blue-600 to-violet-500 hover:to-violet-400"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full max-w-full px-3 mb-6">
                                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl rounded-2xl bg-clip-border">
                                    <div className="flex-auto p-4">
                                        <div className="flex flex-row -mx-3">
                                            <div className="flex-none w-2/3 max-w-full px-3">
                                                <div>
                                                    <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">Total Users</p>
                                                    <h5 className="mb-2 font-bold"><CountUp end={userCount} duration={0.5} /></h5>
                                                </div>
                                            </div>
                                            <div className="text-right basis-1/2 overflow-hidden">
                                                <div className="text-center rounded-circle">
                                                    <i className="leading-none fas fa-users relative font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-red-600 to-orange-600 hover:from-red-700 hover:to-orange-500"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full max-w-full px-3 mb-6">
                                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl rounded-2xl bg-clip-border">
                                    <div className="flex-auto p-4">
                                        <div className="flex flex-row -mx-3">
                                            <div className="flex-none w-2/3 max-w-full px-3">
                                                <div>
                                                    <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">Total Matching Cards</p>
                                                    <h5 className="mb-2 font-bold"><CountUp end={matchingCardCount} duration={0.5} /></h5>
                                                </div>
                                            </div>
                                            <div className="text-right basis-1/2 overflow-hidden">
                                                <div className="text-center rounded-circle">
                                                    <i className="leading-none fas fa-images relative font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-emerald-500 to-teal-400 hover:from-emerald-600 hover:to-teal-500"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full max-w-full px-3 ">
                                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl rounded-2xl bg-clip-border">
                                    <div className="flex-auto p-4">
                                        <div className="flex flex-row -mx-3">
                                            <div className="flex-none w-2/3 max-w-full px-3">
                                                <div>
                                                    <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">total Rate Card</p>
                                                    <h5 className="mb-2 font-bold"><CountUp end={rateCardCount} duration={0.5} /></h5>
                                                </div>
                                            </div>
                                            <div className="text-right basis-1/2 overflow-hidden">
                                                <div className="text-center rounded-circle">
                                                    <i className="leading-none fas fa-chart-line relative font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-orange-500 to-yellow-500 hover:from-orange-700 hover:to-yellow-400"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <div className="px-6 rounded-2xl mt-6 md:mt-0">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div className="p-8 rounded-2xl bg-white">
                            <div className="bg-white divide-y">
                                <div className='flex justify-between mb-1 items-center'>
                                    <div className='text-center font-bold'>
                                        Sample Card
                                    </div>
                                    <button className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-themeColor rounded-lg hover:bg-themeColor/60 focus:ring-4 focus:outline-none focus:ring-themeColor/90" onClick={() => navigate("/samplecarddashboard")}>
                                        View All
                                        <i className='fas fa-arrow-right w-4 h-4 ml-2 -mr-1'></i>
                                    </button>
                                </div>
                                <div className="m-auto">
                                    <Pie data={sampleCardPiedata} width={"300vh"} height={"300vh"}
                                        options={{ maintainAspectRatio: false }} />
                                </div>
                            </div>
                        </div>
                        <div className="p-8 rounded-2xl bg-white md:col-span-2">
                            <div className="flex bg-clip-border w-full">
                                <div className="rounded-sm bg-transparent w-full justify-between">
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="text-2xl ">Overview</div>
                                    </div>
                                    <div>
                                        <Line
                                            options={options}
                                            data={sample}
                                            width={"250vh"}
                                            height={"250vh"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-6 rounded-2xl mt-3">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div className="p-8 rounded-2xl bg-white">
                            <div className="bg-white divide-y">
                                <div className='flex justify-between items-center mb-1'>
                                    <div className='text-center font-bold'>
                                        Matching Card
                                    </div>
                                    <button className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-themeColor rounded-lg hover:bg-themeColor/60 focus:ring-4 focus:outline-none focus:ring-themeColor/90 " onClick={() => navigate("/matchingcarddashboard")}>
                                        View All
                                        <i className='fas fa-arrow-right w-4 h-4 ml-2 -mr-1'></i>
                                    </button>
                                </div>
                                <div className="m-auto">
                                    <Pie data={matchingCardPiedata} width={"300vh"} height={"300vh"}
                                        options={{ maintainAspectRatio: false }} />
                                </div>
                            </div>
                        </div>
                        <div className="p-8 rounded-2xl bg-white md:col-span-2">
                            <div className="flex bg-clip-border w-full">
                                <div className="rounded-sm bg-transparent w-full justify-between">
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="text-2xl ">Overview</div>
                                    </div>
                                    <div>
                                        <Line
                                            options={options}
                                            data={matching}
                                            width={"250vh"}
                                            height={"250vh"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-6 rounded-2xl mt-3">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div className="p-8 rounded-2xl bg-white">
                            <div className="bg-white divide-y">
                                <div className='flex justify-between items-center mb-1'>
                                    <div className='text-center font-bold'>
                                        Rate Card
                                    </div>
                                    <button className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-themeColor rounded-lg hover:bg-themeColor/60 focus:ring-4 focus:outline-none focus:ring-themeColor/90 " onClick={() => navigate("/sareeratedashboard")}>
                                        View All
                                        <i className='fas fa-arrow-right w-4 h-4 ml-2 -mr-1'></i>
                                    </button>
                                </div>
                                <div className="m-auto">
                                    <Pie data={rateCardPiedata} width={"300vh"} height={"300vh"}
                                        options={{ maintainAspectRatio: false }} />
                                </div>
                            </div>
                        </div>
                        <div className="p-8 rounded-2xl bg-white md:col-span-2">
                            <div className="flex bg-clip-border w-full">
                                <div className="rounded-sm bg-transparent w-full justify-between">
                                    <div className="flex items-center justify-between mb-4">
                                        <div className="text-2xl ">Overview</div>
                                    </div>
                                    <div>
                                        <Line
                                            options={options}
                                            data={rate}
                                            width={"250vh"}
                                            height={"250vh"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}