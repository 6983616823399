import { useMemo, useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import {
  useLazySampleCardQuery,
  useUpdateSampleCardMutation,
  useLazyCopySampleCardQuery,
  useLazyGetSampleCardByKeywordQuery,
  useLazyGetSampleCardQuery,
  useLazyGetSampleCardCountQuery,
} from "../../servicesRtkQuery/publicApi";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { SampleCardPreview } from "../../components/pdf/samplecardPreview";
import { setSampleCard } from "../../redux/sampleCardSlice";
import Swal from "sweetalert2";
import TextBox from "../../components/form/TextBox";
import CountUp from "react-countup";
import DataTableLoader from "../../components/page/DataTableLoader";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import moment from "moment";
import customStyles from "../../utils/globalFunctions";
import { TableAction } from "../../components/modal/TableAction";
import { SampleCardDataPdf } from "../../components/pdf/dashboard/sampleCardDataPdf";

export default function SampleCardDashboard() {
  const dispatch = useDispatch();
  const [trigger, result] = useLazyGetSampleCardByKeywordQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [sampleTrigger, sampleResult] = useLazyGetSampleCardQuery();
  const {
    isSuccess: isSampleSuccess,
    isFetching: isSampleFetching,
    isError: isSampleError,
    error: sampleError,
  } = sampleResult;
  const [copyTrigger, copyResult] = useLazyCopySampleCardQuery();
  const {
    isSuccess: isCopySuccess,
    isFetching: isCopyFetching,
    isError: isCopyError,
    error: copyError,
  } = copyResult;
  const [countTrigger, countResult] = useLazyGetSampleCardCountQuery();
  const {
    isSuccess: isCountSuccess,
    isFetching: isCountFetching,
    isError: isCountError,
    error: countError,
  } = countResult;
  const [cardTrigger, cardResult] = useLazyGetSampleCardByKeywordQuery();
  const {
    isSuccess: isCardSuccess,
    isFetching: isCardFetching,
    isError: isCardError,
    error: cardError,
  } = cardResult;
  const [cardData, setCardData]: any = useState<any>([]);
  const [singleCard, setSingleCard]: any = useState<any>();
  const navigate = useNavigate();
  const [updateCard] = useUpdateSampleCardMutation();
  const [showSamplePreview, setShowSamplePreview] = useState(false);
  const sampleCardData = useSelector(
    (state: any) => state.sampleCardSlice.sampleCardData
  );

  // Counters
  const [allCount, setAllCount]: any = useState(0);
  const [dressCount, setDressCount]: any = useState(0);
  const [sareeCount, setSareeCount]: any = useState(0);
  const [lumpCount, setLumpCount]: any = useState(0);

  // Filtering states
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //Pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const urlData: any = useLocation().search;
  const dashboardType = new URLSearchParams(urlData).get("type");

  const [type, setType]: any = useState(dashboardType ? dashboardType : "all");
  const [isSearching, setIsSearching]: any = useState(false);
  const [isExportData, setIsExportData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState("");

  const [search, setSearch]: any = useState({
    cardNo: {
      text: "",
      status: false,
    },
    invoiceNo: {
      text: "",
      status: false,
    },
    partyName: {
      text: "",
      status: false,
    },
    designNo: {
      text: "",
      status: false,
    },
    type: {
      text: "",
      status: false,
    },
    workType: {
      text: "",
      status: false,
    },
    totalDays: {
      text: "",
      status: false,
    },
    clotheName: {
      text: "",
      status: false,
    },
    designerName: {
      text: "",
      status: false,
    },
    puncherName: {
      text: "",
      status: false,
    },
    challanNo: {
      text: "",
      status: false,
    },
    clothePartyName: {
      text: "",
      status: false,
    },
    CM: {
      text: "",
      status: false,
    },
    height: {
      text: "",
      status: false,
    },
    date: {
      text: "",
      status: false,
    },
    fromDate: {
      text: "",
      status: false,
    },
    toDate: {
      text: "",
      status: false,
    },
  });

  //#region API triggers
  useEffect(() => {
    countTrigger();
    if (sampleCardData?.sampleCard) {
      setCardData(sampleCardData?.sampleCard);
    } else {
      trigger({
        page: page,
        limit: perPage,
      });
    }
  }, []);

  const globalTriggers = (page: Number, perPage: Number) => {
    if (isSearching) {
      let searchData: any = {
        page: page,
        limit: perPage,
      };
      Object.keys(search).map((key: any) => {
        if (search[key] && search[key].text !== "") {
          searchData[key] = search[key].text;
        }
      });
      if (type !== "all") {
        searchData = { ...searchData, type: type };
      }
      if (Object.keys(searchData).length != 0) {
        cardTrigger(searchData);
      }
    } else {
      if (type === "all") {
        trigger({
          page: page,
          limit: perPage,
        });
      } else {
        trigger({
          type: type,
          page: page,
          limit: perPage,
        });
      }
    }
  };

  useEffect(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    globalTriggers(page, perPage);
  }, [type]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      if (!isSearching) {
        dispatch(setSampleCard(result?.data?.data));
      }
      setCardData(result?.data?.data?.sampleCard);
      setTotalRows(result?.data?.data?.count);
    }
  }, [isSuccess, isFetching]);


  useEffect(() => {
    if (isCountSuccess && !isCountFetching) {
      setAllCount(countResult?.data?.data?.all);
      setTotalRows(countResult?.data?.data?.all);
      setDressCount(countResult?.data?.data?.dress);
      setSareeCount(countResult?.data?.data?.saree);
      setLumpCount(countResult?.data?.data?.lump);
    }
  }, [isCountSuccess, isCountFetching]);

  useEffect(() => {
    if (isCardSuccess && !isCardFetching) {
      setCardData(cardResult?.data?.data?.sampleCard);
      setTotalRows(cardResult?.data?.data?.count);

    }
  }, [isCardSuccess, isCardFetching]);

  useEffect(() => {
    if (isCopySuccess && !isCopyFetching) {
      localStorage.setItem(
        "copy-number",
        copyResult?.data?.data[0]?.invoiceNo +
        "-" +
        copyResult?.data?.data[0]?.cardNo
      );
      localStorage.setItem("invoices", JSON.stringify(copyResult?.data?.data));
      navigate({
        pathname: `/samplecards/copy`,
      });
    }
  }, [isCopySuccess, isCopyFetching]);

  useEffect(() => {
    if (isSampleSuccess && !isSampleFetching) {
      setSingleCard(sampleResult.data.data.sampleCard[0]);
    }
  }, [isSampleSuccess, isSampleFetching]);
  //#endregion

  //#region Field Search section

  const onSearch = (key: string) => {
    let searchData = search;
    setIsSearching(true);
    Object.keys(searchData).forEach((searchkey: any, value: any) => {
      let item = searchData[searchkey];
      item["status"] = false;
      return item;
    });
    setSearch({
      ...searchData,
      [key]: {
        ...search[key],
        status: !search[key]["status"],
      },
    });
  };

  const delayedCardTrigger: any = useRef(null);
  const latestRequest: any = useRef(null);

  useEffect(() => {
    let searchData: any = {};
    Object.keys(search).map((key: any) => {
      if (search[key] && search[key].text !== "") {
        searchData[key] = search[key].text;
      }
    });
    if (Object.keys(searchData).length != 0) {
      if (type !== "all") {
        searchData = { ...searchData, type: type };
      }

      setIsSearching(true);
      // cardTrigger(searchData);
      clearTimeout(delayedCardTrigger.current);
      delayedCardTrigger.current = setTimeout(() => {
        latestRequest.current = cardTrigger(searchData);
      }, 300);
    } else {
      setIsSearching(false);
      globalTriggers(page, perPage);
    }
  }, [search]);

  const getFromLocalStorage = (keys: string[]) => {
    const storedData = localStorage.getItem("filterData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      // keys.forEach(key => {
      //   if (parsedData?.data[key]) {
      //     parsedData.data[key].status = false;
      //   }
      // });
      setSearch(parsedData?.data);
    }
  };

  useEffect(() => {
    const componentKeys = [
      "date",
      "invoiceNo",
      "cardNo",
      "partyName",
      "designNo",
      "type",
      "challanNo",
      "clotheName",
      "clothePartyName",
      "CM",
      "height",
      "workType",
      "designerName",
      "puncherName",
    ];
    getFromLocalStorage(componentKeys);
  }, []);

  const saveToLocalStorage = (data: any) => {
    let obj: any = { data };
    localStorage.setItem("filterData", JSON.stringify(obj));
  };

  const searchComponent = (key: string, label: string) => {
    return (
      <div className={`text-left`}>
        {
          search[key]?.status ? (
            <TextBox
              type={"text"}
              placeholder={label}
              autoFocus
              className="font-light"
              value={search[key]?.text}
              onChange={(e: any) => {
                const searchText = e.target.value;
                if (searchText.length === 0) {
                  const updatedSearch = {
                    ...search,
                    [key]: {
                      text: "",
                      status: false,
                    },
                  };
                  setSearch(updatedSearch);

                  const allTextsEmpty = Object.values(updatedSearch).every(
                    (item: any) => item?.text === ""
                  );

                  if (allTextsEmpty) {
                    localStorage.removeItem("filterData");
                  } else {
                    saveToLocalStorage(updatedSearch);
                  }
                  setCardData(result?.data?.data?.sampleCard);
                  setTotalRows(result?.data?.data?.count);
                } else {
                  // setSearch({
                  //   ...search,
                  //   [key]: {
                  //     text: e.target.value,
                  //     status: true,
                  //   },
                  // });
                  const updatedSearch = {
                    ...search,
                    [key]: {
                      text: searchText,
                      status: true,
                    },
                  };
                  setSearch(updatedSearch);
                  saveToLocalStorage(updatedSearch);
                }
              }}
            />
          ) : (
            <div className="mb-2 flex flex-col">
              <div
                className="mb-1 cursor-pointer"
                onClick={() => {
                  onSearch(key);
                }}
              >
                {label}
              </div>
              {search[key]?.text !== "" && search[key]?.text && (
                <div className="flex justify-center items-center m-1 font-medium py-1 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
                  <div className="flex flex-auto flex-row-reverse">
                    <i
                      className="fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4"
                      onClick={() => {
                        setSearch({
                          ...search,
                          [key]: {
                            text: "",
                            status: false,
                          },
                        });
                        const storedData: any =
                          localStorage.getItem("filterData");
                        const parsedData: any = JSON.parse(storedData);
                        if (
                          parsedData &&
                          parsedData.data &&
                          parsedData.data[key]
                        ) {
                          delete parsedData.data[key]?.text;
                          localStorage.setItem(
                            "filterData",
                            JSON.stringify(parsedData)
                          );
                        }
                      }}
                    ></i>
                  </div>
                  <div className="text-xs font-normal leading-none max-w-full flex-initial pr-4">
                    {search[key]?.text}
                  </div>
                </div>
              )}
            </div>
          )

          //  {search[key]?.text !== "" &&
          //     <div className="flex justify-center items-center m-1 font-medium py-1 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
          //          <div className="flex flex-auto flex-row-reverse">
          //             <i className='fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4'
          //                 onClick={() => {
          //                     setSearch({
          //                         ...search,
          //                         [key]: {
          //                             text: "",
          //                             status: false
          //                         }
          //                     })
          //                 }}
          //             ></i>
          //         </div>
          //         <div className="text-xs font-normal leading-none max-w-full flex-initial pr-4">{search[key]?.text}</div>
          //      </div>}
        }
      </div>
    );
  };
  //#endregion

  const columns: any = [
    {
      name: searchComponent("date", "Date"),
      selector: (row: { [x: string]: any }) =>
        moment(row?.date).format("DD-MM-yyyy"),
    },
    {
      name: searchComponent("invoiceNo", "Card A"),
      selector: (row: { [x: string]: any }) => row?.invoiceNo,
      width: "5%",
    },
    {
      name: searchComponent("cardNo", "Card B"),
      selector: (row: { [x: string]: any }) => row?.cardNo,
      width: "5%",
    },
    {
      selector: (row: { [x: string]: any }) => row?.party?.name,
      name: searchComponent("partyName", "Party Name"),
    },
    {
      name: searchComponent("designNo", "Design No"),
      selector: (row: { [x: string]: any }) => row?.designNo,
      width: "7%",
    },
    {
      name: searchComponent("type", "Type"),
      selector: (row: { [x: string]: any }) => row?.type,
      width: "5%",
    },
    {
      name: searchComponent("challanNo", "Challan No"),
      selector: (row: { [x: string]: any }) => row?.challanNo,
    },
    {
      name: searchComponent("clotheName", "Fabric"),
      selector: (row: { [x: string]: any }) => row?.clothe?.name,
    },
    // {
    //     name: searchComponent("designerName", "Designer Name"),
    //     selector: (row: { [x: string]: any; }) => row?.designer?.name,
    // },
    // {
    //     name: searchComponent("puncherName", "Sketcher Name"),
    //     selector: (row: { [x: string]: any; }) => row?.puncher?.name ? row?.puncher?.name : "-",
    //     center: true
    // },
    {
      name: searchComponent("clothePartyName", "Fabric Party"),
      selector: (row: { [x: string]: any }) => row?.clotheParty?.name,
    },
    {
      name: searchComponent("CM", "Cut"),
      selector: (row: { [x: string]: any }) => row?.CM,
    },
    {
      name: searchComponent("height", "Height"),
      selector: (row: { [x: string]: any }) => row?.height,
    },
    {
      name: searchComponent("workType", "Work Type"),
      selector: (row: { [x: string]: any }) => row?.workType,
    },
    {
      name: searchComponent("designerName", "Designer Name"),
      selector: (row: { [x: string]: any }) => row?.designer?.name,
    },
    {
      name: searchComponent("puncherName", "Sketcher Name"),
      selector: (row: { [x: string]: any }) =>
        row?.puncher?.name ? row?.puncher?.name : "-",
      center: true,
    },
  ];
  //#region Global searching section
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <div className="flex flex-wrap gap-2 items-center">

        <button
          className="w-max justify-start"
          onClick={() => {
            setResetPaginationToggle(!resetPaginationToggle);
            setSearch({
              cardNo: {
                text: "",
                status: false,
              },
              invoiceNo: {
                text: "",
                status: false,
              },
              partyName: {
                text: "",
                status: false,
              },
              designNo: {
                text: "",
                status: false,
              },
              type: {
                text: "",
                status: false,
              },
              workType: {
                text: "",
                status: false,
              },
              totalDays: {
                text: "",
                status: false,
              },
              clotheName: {
                text: "",
                status: false,
              },
              designerName: {
                text: "",
                status: false,
              },
              puncherName: {
                text: "",
                status: false,
              },
              clothePartyName: {
                text: "",
                status: false,
              },
              CM: {
                text: "",
                status: false,
              },
              height: {
                text: "",
                status: false,
              },
              date: {
                text: "",
                status: false,
              },
              fromDate: {
                text: "",
                status: false,
              },
              toDate: {
                text: "",
                status: false,
              },
            });
            handleClear();
          }}
        >
          <i className="fas fa-filter" title="Clear Filter"></i>
        </button>
        <div className="w-full md:w-max">
          <TextBox
            id="fromDate"
            type="date"
            placeholder=" "
            label="From"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (e.target.value.length == 0) {
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
                //     globalTriggers(page, perPage)
              } else {
                setIsSearching(true);
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: e.target.value,
                      status: false,
                    },
                  };
                });
              }
            }}
          />
        </div>
        <div className="w-full md:w-max">
          <TextBox
            id="toDate"
            type="date"
            placeholder=" "
            label="To"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (e.target.value.length == 0) {
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    toDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
                // globalTriggers(page, perPage)
              } else {
                setIsSearching(true);
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    toDate: {
                      text: e.target.value,
                      status: false,
                    },
                  };
                });
              }
            }}
          />
        </div>
        <div className="md:block w-full md:w-max">
          <div className="relative flex items-center text-gray-400 focus-within:text-themeColor">
            <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
              <i className="fas fa-search w-4 fill-current"></i>
            </span>
            <input
              type="search"
              name="leadingIcon"
              id="leadingIcon"
              placeholder="Search here"
              value={filterText}
              onChange={(e: any) => {
                setFilterText(e.target.value);
              }}
              className="w-full pl-14 pr-4 py-2 rounded-xl text-sm text-gray-600 outline-none border border-gray-500 focus:border-themeColor transition"
            />
          </div>
        </div>
        <div>
          <button
            className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
            type="button"
            onClick={() => {
              setIsExportData(true);
            }}
          >
            <i className="fas fa-file"></i>
            <span className="ml-2">Export Pdf</span>
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    if (filterText) {
      if (type === "all") {
        cardTrigger({
          search: filterText,
        });
      } else {
        cardTrigger({
          search: filterText,
          type: type,
        });
      }
    } else {
      globalTriggers(page, perPage);
    }
  }, [filterText]);
  //#endregion

  const openInvoicePreview = async (row: any) => {
    setShowSamplePreview(true);
    if (isSearching) {
      sampleTrigger(row._id);
    } else {
      setSingleCard(row);
    }
  };

  const handleDelete = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let scId = row._id;
        let updatedData = {
          status: false,
        };
        updateCard({
          payload: updatedData,
          query: {
            scId: scId,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Sample Card has been deleted!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            globalTriggers(page, perPage);
          }
        });
      }
    });
  };

  //#region Pagination section
  const handlePageChange = (page: any) => {
    setFilterText("");
    setPage(page);
    globalTriggers(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setFilterText("");
    setPage(page);
    setPerPage(newPerPage);
    globalTriggers(page, newPerPage);
  };
  //#endregion

  return (
    <>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="w-full px-6 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("all");
                navigate("/samplecarddashboard");
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${type === "all"
                    ? "bg-gradient-to-r from-blue-100 to-violet-100"
                    : "bg-white"
                  } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    All Sample Cards
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={allCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                        <i className="leading-none fas fa-layer-group text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("dress");
                navigate("/samplecarddashboard?type=dress");
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${type === "dress"
                    ? "bg-gradient-to-r from-yellow-100 to-red-100"
                    : "bg-white"
                  } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Dress Sample Cards
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={dressCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("saree");
                navigate("/samplecarddashboard?type=saree");
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${type === "saree"
                    ? "bg-gradient-to-r from-blue-100 to-teal-200"
                    : "bg-white"
                  } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Saree Sample Cards
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={sareeCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("lump");
                navigate("/samplecarddashboard?type=lump");
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${type === "lump"
                    ? "bg-gradient-to-r from-yellow-100 to-orange-200"
                    : "bg-white"
                  } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Lump Sample Cards
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={lumpCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-6 pt-6 mx-auto">
          <div className="rounded-xl bg-white">
            <div className="mb-6 py-4 break-words border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
              <div className="px-3">
                {type === "all" ? null : (
                  <button
                    className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    type="button"
                    onClick={() => {
                      navigate(`/samplecards?type=${type}`);
                    }}
                  >
                    <i className="fas fa-folder-plus"></i>
                    <span className="ml-2">Add {type} Card</span>
                  </button>
                )}
              </div>
              <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto capitalize">
                  <DataTable
                    title={type + " Cards"}
                    className="touch-auto"
                    columns={columns}
                    subHeaderComponent={subHeaderComponentMemo}
                    paginationResetDefaultPage={resetPaginationToggle}
                    progressPending={isFetching}
                    progressComponent={<DataTableLoader />}
                    data={cardData}
                    fixedHeader={true}
                    defaultSortAsc={false}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    striped={true}
                    responsive={true}
                    highlightOnHover
                    persistTableHead
                    subHeader
                    customStyles={customStyles}
                    onRowClicked={(e: any) => {
                      setSelectedRow(e);
                      setIsModalOpen(true);
                    }}
                    paginationPerPage={20}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <TableAction
          header={selectedRow?.invoiceNo + "-" + selectedRow?.cardNo}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedRow={selectedRow}
          isCopy={true}
          isPreview={true}
          copyTrigger={copyTrigger}
          openInvoicePreview={openInvoicePreview}
          handleDelete={handleDelete}
          route={"samplecards"}
        />
      ) : null}

      {/* Pdf Preview */}
      {showSamplePreview && (
        <SampleCardPreview
          showSamplePreview={showSamplePreview}
          setShowSamplePreview={setShowSamplePreview}
          singleCard={singleCard}
          id={singleCard?._id}
          pageName="Sample card"
        />
      )}
      {isExportData && (
        <SampleCardDataPdf
          isExportData={isExportData}
          setIsExportData={setIsExportData}
          sampleCard={cardData}
          allCount={allCount}
          isSearching={isSearching}
          search={search}
          type={type}
          pageName="Sample card Report"
        />
      )}
    </>
  );
}
