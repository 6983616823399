import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../pages/dashboard/dashboard";
import NotProtectedRoute from "./NotProtectedRoute";
import Login from "../pages/login/Login";
import User from "../pages/user/user";
import Settings from "../pages/settings/settings";
import SampleCards from "../pages/samplecards/samplecard";
import MatchingCards from "../pages/matchingcards/matchingcard";
import MatchingCardDashboard from "../pages/matchingcards/matchingCardDashboard";
import SampleCardDashboard from "../pages/samplecards/sampleCardDashboard";
import DressRateCard from "../pages/ratecards/dressRatecard";
import Offline from "../components/page/offline";
import LumpRateCard from "../pages/ratecards/lumpratecard";
import LumpRateDashboard from "../pages/ratecards/dashboard/lumpRateDashboard";
import SareeRateDashboard from "../pages/ratecards/dashboard/sareeRateDashboard";
import DressRateCardDashboard from "../pages/ratecards/dashboard/dressRateCardDashboard";
import SareeRateCard from "../pages/ratecards/sareeratecard";
import JobworkDashboard from "../pages/jobwork/jobworkDashboard";
import Challan from "../pages/jobwork/challan";
import DressChallan from "../pages/jobwork/dressChallan";
import SareeChallan from "../pages/jobwork/sareeChallan";
import LumpChallan from "../pages/jobwork/lumpChallan";
import LumpChallanDashboard from "../pages/jobwork/lumpChallanDashboard";
import DressChallanDashboard from "../pages/jobwork/dressChallanDashboard";
import SareeChallanDashboard from "../pages/jobwork/sareeChallanDashboard";
import EmployeeReportDashboard from "../pages/reports/employee/employeereportdash";
import CreateEmployeeReport from "../pages/reports/employee/createempreport";
import PartyList from "../pages/partyImages/partylist";
import RateCardImages from "../pages/partyImages/ratecardimages";
import SatvRateCardImages from "../pages/partyImages/satvratecardimages";
import PartyRateCardImages from "../pages/partyImages/partyratecardimages";
import MatchingCardStocks from "../pages/matchingCardStocks/matchingCardStocks";
import UpdateStocks from "../pages/matchingCardStocks/updateStocks";
import Profile from "../pages/profile/userprofile";
import PartyChallanDashboard from "../pages/jobwork/partyChallanDashboard";
import PartyChallan from "../pages/jobwork/partyChallan";
import PlainStockDashboard from "../pages/plainStock/plainStockDashboard";
import CreatePlainStock from "../pages/plainStock/createPlainStock";
import ReadyStockCreate from "../pages/readyStock/readyStockCreate";
import ReadyStockDashboard from "../pages/readyStock/readyStockDashboard";
import PartyReportDashboard from "../pages/partyReport/partyReportDashboard";
import CreatePartyReport from "../pages/partyReport/createPartyReport";

// import MatchingCardStocks from "../pages/matchingCardStocks/matchingCardStocks";
// import UpodateStock from "../pages/matchingCardStocks/updateStocks";
// import UpdateStocks from "../pages/matchingCardStocks/updateStocks";

const CustomeRoutes = () => {
  useEffect(() => {
    authenticate().then(() => {
      const ele = document.getElementById("progress-indicator");
      if (ele) {
        ele.classList.add("available");
        setTimeout(() => { }, 2000);
      }
    });
  }, []);

  const authenticate = () => {
    return new Promise((resolve) => setTimeout(resolve, 2000));
  };
  return (
    <Routes>
      {window.navigator.onLine ? (
        <>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <NotProtectedRoute>
                <Login />
              </NotProtectedRoute>
            }
          />
          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <User />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/samplecards"
            element={
              <ProtectedRoute>
                <SampleCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/samplecards/:id"
            element={
              <ProtectedRoute>
                <SampleCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/samplecards/copy"
            element={
              <ProtectedRoute>
                <SampleCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/samplecarddashboard"
            element={
              <ProtectedRoute>
                <SampleCardDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/matchingcards"
            element={
              <ProtectedRoute>
                <MatchingCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/matchingcards/:id"
            element={
              <ProtectedRoute>
                <MatchingCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/matchingcards/copy"
            element={
              <ProtectedRoute>
                <MatchingCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="/matchingcarddashboard"
            element={
              <ProtectedRoute>
                <MatchingCardDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/matchingcarddashboardstock"
            element={
              <ProtectedRoute>
                <MatchingCardStocks />
              </ProtectedRoute>
            }
          />

          <Route
            path="/readystockcreate"
            element={
              <ProtectedRoute>
                <ReadyStockCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/readystockdashboard"
            element={
              <ProtectedRoute>
                <ReadyStockDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/readyStock/update/:id"
            element={
              <ProtectedRoute>
                <ReadyStockCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/matchingcarddashboardstock/update/:id"
            element={
              <ProtectedRoute>
                <UpdateStocks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dressratecarddashboard"
            element={
              <ProtectedRoute>
                <DressRateCardDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lumpratedashboard"
            element={
              <ProtectedRoute>
                <LumpRateDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sareeratedashboard"
            element={
              <ProtectedRoute>
                <SareeRateDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/saree"
            element={
              <ProtectedRoute>
                <SareeRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/lump"
            element={
              <ProtectedRoute>
                <LumpRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/dress"
            element={
              <ProtectedRoute>
                <DressRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/dress/:id"
            element={
              <ProtectedRoute>
                <DressRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/saree/:id"
            element={
              <ProtectedRoute>
                <SareeRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/lump/:id"
            element={
              <ProtectedRoute>
                <LumpRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/dress/copy"
            element={
              <ProtectedRoute>
                <DressRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/saree/copy"
            element={
              <ProtectedRoute>
                <SareeRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecard/lump/copy"
            element={
              <ProtectedRoute>
                <LumpRateCard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/setting"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobworkdashboard"
            element={
              <ProtectedRoute>
                <JobworkDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partychallandashboard"
            element={
              <ProtectedRoute>
                <PartyChallanDashboard />
              </ProtectedRoute>
            }

          />
          <Route
            path="/lumpchallandashboard"
            element={
              <ProtectedRoute>
                <LumpChallanDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dresschallandashboard"
            element={
              <ProtectedRoute>
                <DressChallanDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sareechallandashboard"
            element={
              <ProtectedRoute>
                <SareeChallanDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/challan"
            element={
              <ProtectedRoute>
                <Challan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/challan/:id"
            element={
              <ProtectedRoute>
                <Challan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/dresschallan"
            element={
              <ProtectedRoute>
                <DressChallan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/partychallan"
            element={
              <ProtectedRoute>
                <PartyChallan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/partychallan/:id"
            element={
              <ProtectedRoute>
                <PartyChallan />
              </ProtectedRoute>
            }
          />

          <Route
            path="/jobwork/dresschallan/:id"
            element={
              <ProtectedRoute>
                <DressChallan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/sareechallan"
            element={
              <ProtectedRoute>
                <SareeChallan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/sareechallan/:id"
            element={
              <ProtectedRoute>
                <SareeChallan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/lumpchallan"
            element={
              <ProtectedRoute>
                <LumpChallan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobwork/lumpchallan/:id"
            element={
              <ProtectedRoute>
                <LumpChallan />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employeereportdashboard"
            element={
              <ProtectedRoute>
                <EmployeeReportDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/plainstockdashboard"
            element={
              <ProtectedRoute>
                <PlainStockDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partyreportdashboard"
            element={
              <ProtectedRoute>
                <PartyReportDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createplainstock"
            element={
              <ProtectedRoute>
                <CreatePlainStock />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createpartyreport"
            element={
              <ProtectedRoute>
                <CreatePartyReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/plainStock/update/:id"
            element={
              <ProtectedRoute>
                <CreatePlainStock />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partyReport/update/:id"
            element={
              <ProtectedRoute>
                <CreatePartyReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createplainstock/:id"
            element={
              <ProtectedRoute>
                <CreatePlainStock />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createpartyreport/:id"
            element={
              <ProtectedRoute>
                <CreatePartyReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createemployeereport"
            element={
              <ProtectedRoute>
                <CreateEmployeeReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createemployeereport/:id"
            element={
              <ProtectedRoute>
                <CreateEmployeeReport />
              </ProtectedRoute>
            }
          />

          <Route
            path="/partylist"
            element={
              <ProtectedRoute>
                <PartyList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ratecardimage/:id"
            element={
              <ProtectedRoute>
                <RateCardImages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/satvratecardimage"
            element={
              <ProtectedRoute>
                <SatvRateCardImages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partyratecardimage"
            element={
              <ProtectedRoute>
                <PartyRateCardImages />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </>
      ) : (
        <Route
          path="*"
          element={
            <NotProtectedRoute>
              <Offline />
            </NotProtectedRoute>
          }
        />
      )}
    </Routes>
  );
};
export default CustomeRoutes;
