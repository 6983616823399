import { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactToPrint from "react-to-print";
import moment from "moment";
import {
    useLazyGetAllPlainStockDataQuery,
} from "../../../servicesRtkQuery/publicApi";
import DataTableLoader from "../../page/DataTableLoader";

export const PlainStockDataPdf = (props: any) => {
    const exportRef: any = useRef(null);
    var { allCount, isSearching, search, type } = props;
    const [cardData, setCardData]: any = useState();
    const [trigger, result] = useLazyGetAllPlainStockDataQuery();
    const { isSuccess, isFetching, isError, error } = result;

    const [cardTrigger, cardResult] = useLazyGetAllPlainStockDataQuery();
    const {
        isSuccess: isCardSuccess,
        isFetching: isCardFetching,
        isError: isCardError,
        error: cardError,
    } = cardResult;

    useEffect(() => {
        if (!isSearching) {
            trigger({
                limit: allCount,
            });
        }
    }, []);

    useEffect(() => {
        if (isSuccess && !isFetching) {
            setCardData(result?.data?.data?.plainStock);
        }
    }, [isSuccess, isFetching]);

    useEffect(() => {
        if (isCardSuccess && !isCardFetching) {
            setCardData(cardResult?.data?.data?.plainStock);
        }
    }, [isCardSuccess, isCardFetching]);

    useEffect(() => {
        if (isSearching) {
            let searchData: any = {
                limit: allCount,
            };
            Object.keys(search).map((key: any) => {
                if (search[key] && search[key].text !== "") {
                    searchData[key] = search[key].text;
                }
            });
            if (type !== "all") {
                searchData = { ...searchData, type: type };
            }
            if (Object.keys(searchData).length !== 0) {
                cardTrigger(searchData);
            }
        }
    }, [search]);

    return (
        <div>
            <Modal
                open={props.isExportData}
                showCloseIcon={false}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                classNames={{
                    modal: "rounded-xl",
                }}
                styles={{
                    overlay: {
                        height: "auto",
                        width: "auto",
                    },
                    modalContainer: {
                        overflow: "auto",
                    },
                }}
                onEscKeyDown={() => props.setIsExportData(false)}
                onOverlayClick={() => props.setIsExportData(false)}
                aria-labelledby="sample-card-pdf"
                aria-describedby="sample-card-pdf-description"
                animationDuration={400}
                onClose={() => props.setIsExportData(false)}
            >
                <div className="overflow-auto">
                    <div className="text-center">
                        <div className="bg-white border rounded-lg text-left shadow-xl">
                            <div ref={exportRef} className={`a5-portrait overflow-auto`}>
                                <div className="border border-gray-600">
                                    <div className="font-bold items-center p-2 bg-gray-100 text-5">
                                        <div className="text-themeColor capitalize text-center">
                                            Plain Stock Data
                                        </div>
                                    </div>
                                    {!isFetching ? (
                                        <div className="">
                                            <div className={`text-sm`}>
                                                <table className="w-full border-collapse bg-white border-b border-gray-600 text-left">
                                                    <thead className="bg-gray-100 divide-black border-t border-black text-4">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                sr no
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Date
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Party Name
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Ch. No.
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Cloth
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                CLoth Party
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                                            >
                                                                Pcs
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y text-center divide-black border-t border-black text-4">
                                                        {cardData?.map((item: any, i: any) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                                                        {i + 1}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                                                        {moment(item?.createAt).format("DD-MM-YYYY")}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.party?.name}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.challanNo}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.clothe?.name}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.clotheParty?.name}
                                                                    </td>
                                                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                                                        {item?.pcs}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        <tr>
                                                            <td colSpan={6} className="bg-gray-200">Total</td>
                                                            <td className="gap-3 border border-black font-black text-gray-900 text-center text-lg    capitalize">
                                                                {cardData?.reduce((sum: number, item: any) => {
                                                                    return sum + (item?.pcs || 0);
                                                                }, 0)}

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ) : (
                                        <DataTableLoader />
                                    )}
                                </div>
                            </div>
                            <div className="m-3 flex flex-col lg:flex-row gap-2">
                                <div>
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                                                Print <i className="fas fa-file-download"></i>
                                            </button>
                                        )}
                                        content={() => exportRef.current}
                                        documentTitle={props.pageName}
                                    />
                                </div>
                                <div>
                                    <button
                                        className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                                        onClick={() => props.setIsExportData(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
