import { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigate, useParams } from "react-router-dom";
import TextBox from "../../components/form/TextBox";
import CreatableSelect from "react-select/creatable";
import { PartyAdd } from "../../components/modal/PartyAdd";
import { DesignCodeAdd } from "../../components/modal/DesignCodeAdd";
import { ClothAdd } from "../../components/modal/ClothAdd";
import { ClothPartyAdd } from "../../components/modal/ClothPartyAdd";
import { ItemAdd } from "../../components/modal/ItemAdd";
import {
    useLazyNewMatchingInvoiceNumberQuery,
    useLazyClothQuery,
    useLazyFabricColorQuery,
    useLazySingleSettingQuery,
    useLazyClothPartyQuery,
    useLazyGetClothQuery,
    useLazyGetClothPartyQuery,
    useLazyGetItemQuery,
    useLazyGetPartyNameQuery,
    useLazyMatchingCardQuery,
    useLazyAllMatchingInvoiceNumberQuery,
    useAddReadyStockMutation,
    useLazyGetSingleReadyStockDataQuery,
    useUpdateReadyStockMutation,
} from "../../servicesRtkQuery/publicApi";
import { MatchingCard } from "../interface/MathcingCard";
import { itemValidation } from "../../utils/validation";
import ProgressiveImage from "react-progressive-graceful-image";
import { FabricAdd } from "../../components/modal/FabricAdd";
import uuid from "react-uuid";
import S3 from "aws-sdk/clients/s3";
import ObjectID from "bson-objectid";
import Swal from "sweetalert2";
import Loader from "../../components/page/loader";
import { FoilColorAdd } from "../../components/modal/FoilColorAdd";
import {
    ERROR_MESSAGE_TIMEOUT,
    FILE_UPLOAD_SIZE,
} from "../../utils/constants";
import {
    setDefaultDate,
    selectFilterConfig,
} from "../../utils/globalFunctions";
import { createFilter } from "react-select";
import { ReadyStockDataPreviewPdf } from "../../components/pdf/stock/readyStockDataPreviewPdf";
import { AnyArn } from "aws-sdk/clients/groundstation";

interface itemData {
    srNo?: string;
    number?: string;
    fabricColorId?: string;
    pcs?: number;
    _id?: string;
}

var needleArray: any = [];

export default function ReadyStockCreate() {
    const bucketName: any = process.env.REACT_APP_AWS_BUCKET_NAME;
    const region: any = process.env.REACT_APP_AWS_BUCKET_REGION;
    const accessKeyId: any = process.env.REACT_APP_AWS_ACCESS_KEY;
    const secretAccessKey: any = process.env.REACT_APP_AWS_SECRET_KEY;

    const s3 = new S3({
        region: region,
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
    });
    const [isReadyStockUpdate, setIsReadyStockUpdate] = useState(false);
    let { id } = useParams();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const [type, setType]: any = useState<any>(null);



    // Global Sample Card Data State
    const [cardData, setCardData]: any = useState<MatchingCard>();
    const [ColorpcsData, setColorPcsData] = useState<{ [key: string]: string }>({});
    const [itemData, setItemData] = useState<itemData>({
        srNo: "",
        number: "",
        fabricColorId: "",
        _id: "",
    });
    const [tableData, setTableData] = useState<any>([]);
    const [errorMessage, setErrorMessage]: any = useState({});
    const [colorPcsErrorMessages, setColorPcsErrorMessages] = useState<{ [key: string]: string }>({});

    const [invoicetrigger, invoiceResult] =
        useLazyNewMatchingInvoiceNumberQuery();
    const { isSuccess: isInvoiceSuccess, isFetching: isInvoiceFetching } =
        invoiceResult;

    const [allInvoicetrigger, allInvoiceResult] =
        useLazyAllMatchingInvoiceNumberQuery();
    const { isSuccess: isAllInvoiceSuccess, isFetching: isAllInvoiceFetching } =
        allInvoiceResult;

    const [clothTrigger, clothResult] = useLazyClothQuery();
    const { isSuccess: isClothSuccess, isFetching: isClothFetching } =
        clothResult;

    const [clothPartyTrigger, clothPartyResult] = useLazyClothPartyQuery();
    const { isSuccess: isClothPartySuccess, isFetching: isClothPartyFetching } =
        clothPartyResult;

    const [fabricTrigger, fabricResult] = useLazyFabricColorQuery();
    const { isSuccess: isFabricSuccess, isFetching: isFabricFetching } =
        fabricResult;

    const [readyStockSingleTrigger, readyStockSingleResult] = useLazyGetSingleReadyStockDataQuery();
    const { isSuccess: isMatchingSuccess, isFetching: isMatchingFetching } =
        readyStockSingleResult;

    const [allMatchingTrigger, allMatchingResult] = useLazyMatchingCardQuery();
    const { isSuccess: isAllMatchingSuccess, isFetching: isAllMatchingFetching } =
        allMatchingResult;

    const [settingTrigger, settingResult] = useLazySingleSettingQuery();
    const { isSuccess: isSettingSuccess, isFetching: isSettingFetching } =
        settingResult;



    const [getClothTrigger, getClothResult] = useLazyGetClothQuery();
    const { isSuccess: isgetClothSuccess, isFetching: isgetClothFetching } =
        getClothResult;

    const [getClothPartyTrigger, getClothPartyResult] =
        useLazyGetClothPartyQuery();
    const {
        isSuccess: isgetClothPartySuccess,
        isFetching: isgetClothPartyFetching,
    } = getClothPartyResult;

    const [getItemTrigger, getItemResult] = useLazyGetItemQuery();
    const { isSuccess: isgetItemSuccess, isFetching: isgetItemFetching } =
        getItemResult;

    const [getPartyNameTrigger, getPartyNameResult] = useLazyGetPartyNameQuery();
    const {
        isSuccess: isgetPartyNameSuccess,
        isFetching: isgetPartyNameFetching,
    } = getPartyNameResult;

    const navigate = useNavigate();

    // Party Creatable State
    const [showAddParty, setShowAddParty] = useState(false);
    const [partyNameAdd, setPartyNameAdd] = useState("");
    const [Party, setParty]: any = useState();
    // Cloth Creatable State
    const [showAddCloth, setShowAddCloth] = useState(false);
    const [clothAdd, setClothAdd] = useState("");
    const [Cloth, setCloth]: any = useState([]);
    const [selectedCloth, setSelectedCloth]: any = useState({});

    // Item Creatable State
    const [showAddItem, setShowAddItem] = useState(false);
    const [itemAdd, setItemAdd] = useState("");
    const [Item, setItem]: any = useState<any>([]);
    const [selectedItem, setSelectedItem]: any = useState({});

    // Fabric Creatable State
    const [showAddFabric, setShowAddFabric] = useState(false);
    const [fabricAdd, setFabricAdd] = useState("");
    const [Fabric, setFabric]: any = useState([]);
    const [selectedFabric, setSelectedFabric]: any = useState({});
    const [fabricState, setFabricState]: any = useState({});

    // Design Code Creatable State
    const [showAddDesignCode, setShowAddDesignCode] = useState(false);
    const [designCodeAdd, setDesignCodeAdd] = useState("");
    const [designCode, setDesignCode]: any = useState();
    const [designCodeType, setDesignCodeType] = useState("designCodeOne");

    // Cloth Party Creatable State
    const [showAddClothParty, setShowAddClothParty] = useState(false);
    const [clothPartyAdd, setClothPartyAdd] = useState("");
    const [clothParty, setClothParty]: any = useState<any>([]);
    const [selectedClothParty, setSelectedClothParty]: any = useState({});

    // Foil Color Creatable State
    const [showAddFoil, setShowAddFoil] = useState(false);
    const [foilAdd, setFoilAdd] = useState("");
    const [Foil, setFoil]: any = useState([]);
    const [images, setImages]: any = useState();
    const [selectedInvoice, setSelectedInvoice]: any = useState("");
    const [tableHead, setTableHead]: any = useState([]);
    const [needleData, setNeedleData]: any = useState([]);
    const [showReadyStockPreview, setShowReadyStockPreview] = useState(false);



    // Is SampleCard Update State
    const [isUpdate, setIsUpdate] = useState(false);
    // Is Needle Update State
    const [needleUpdate, setNeedleUpdate] = useState(false);
    const [selectedNeedle, setSelectedNeedle]: any = useState({});


    const SetTodayDate = () => {
        const date: any = setDefaultDate();
        setCardData({
            ...cardData,
            date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
        });
    };

    useEffect(() => {
        clothTrigger("");
        clothPartyTrigger("");
        fabricTrigger("");
        if (id) {
            setIsReadyStockUpdate(true)
            readyStockSingleTrigger({
                rsId: id
            })
        }
    }, []);

    useEffect(() => {
        if (readyStockSingleResult) {
            const apiData = readyStockSingleResult?.data?.data?.readyStock;
            if (apiData && Array.isArray(apiData) && apiData.length > 0) {
                setNeedleData(apiData[0].partys || []);
                setTableData(apiData?.[0]?.colors)
                setCardData({
                    designNo: apiData?.[0]?.designNo,
                    cardNo: apiData?.[0]?.cardNo
                })
                setSelectedCloth({
                    label: apiData?.[0]?.clothe?.name,
                    value: apiData?.[0]?.clothe?._id
                })
                setSelectedClothParty({
                    label: apiData?.[0]?.clotheParty?.name,
                    value: apiData?.[0]?.clotheParty?._id
                })
                setImages(apiData?.[0]?.image?.URL)
            }
        }
    }, [readyStockSingleResult, isMatchingSuccess]);

    const addRecord = () => {
        let partys: any;
        let errorData = {
            partyName: "",
            billNo: "",
            rate: "",
            date: ""
        };

        if (!cardData?.billNo) {
            errorData["billNo"] = "Bill No is Required";
        } else {
            errorData["billNo"] = "";
        }
        if (!cardData?.rate) {
            errorData["rate"] = "Rate is Required";
        } else {
            errorData["rate"] = "";
        }
        if (!cardData?.partyName) {
            errorData["partyName"] = "PartyName is Required";
        } else {
            errorData["partyName"] = "";
        }
        if (!cardData?.date) {
            errorData["date"] = "Date Selection is Required";
        } else {
            errorData["date"] = "";
        }
        setErrorMessage(errorData);

        const hasErrors = Object.values(errorData).some((msg) => msg !== "");

        if (hasErrors) {
            return;
        }
        
        const colorPcs = Object.entries(ColorpcsData).map(([fabricColorId, pcs]) => ({
            fabricColorId,
            pcs
        }));
        partys = {
            _id: ObjectID().toHexString(),
            partyName: cardData?.partyName || "",
            billNo: cardData?.billNo,
            date: cardData?.date,
            rate: cardData?.rate,
            colorPcs
        }
        if (partys?.partyName == "" || partys?.billNo == "" || partys?.rate == "") {
            return null;
        } else {
            setNeedleData((prevData: any) => [...(Array.isArray(prevData) ? prevData : []), partys])
        }
        setCardData({
            ...cardData,
            date: "",
            rate: "",
            billNo: "",
            partyName: ""
        })
        setColorPcsData({})
    };

    const updateRecord = () => {
        let errorData = {
            partyName: "",
            billNo: "",
            rate: "",
        };

        if (!cardData?.billNo) {
            errorData["billNo"] = "Bill No is Required";
        } else {
            errorData["billNo"] = "";
        }
        if (!cardData?.rate) {
            errorData["rate"] = "Rate is Required";
        } else {
            errorData["rate"] = "";
        }
        if (!cardData?.partyName) {
            errorData["partyName"] = "PartyName is Required";
        } else {
            errorData["partyName"] = "";
        }
        setErrorMessage(errorData);

        const colorPcs = Object.entries(ColorpcsData).map(([fabricColorId, pcs]) => ({
            fabricColorId,
            pcs
        }));

        const updatedRecord = {
            _id: selectedNeedle?._id,
            partyName: cardData?.partyName || "",
            billNo: cardData?.billNo,
            date: cardData?.date,
            rate: cardData?.rate,
            colorPcs
        };


        setNeedleData((prevData: any) => {
            if (!Array.isArray(prevData)) return [updatedRecord];

            const index = prevData.findIndex((item: any) => item._id === selectedNeedle?._id);
            if (index !== -1) {
                const updatedData = [...prevData];
                updatedData[index] = updatedRecord;
                return updatedData;
            } else {
                return [...prevData, updatedRecord];
            }
        });

        setCardData({ ...cardData, date: "", rate: "", billNo: "", partyName: "" });
        setColorPcsData({});
        setNeedleUpdate(false);
    };

    const [updateReadyStock] = useUpdateReadyStockMutation();

    const [addMatchingCard, addmatchingCardresult] = useAddReadyStockMutation();
    const {
        isSuccess: isMatchingAddSuccess,
        isLoading: isMatchingAddLoading,
    }: any = addmatchingCardresult;

    useEffect(() => {
        if (isClothSuccess && !isClothFetching) {
            let getAllCloth = clothResult.data?.data?.clothe.map(
                ({ _id, name }: any) => {
                    return {
                        label: name,
                        value: _id,
                    };
                }
            );
            setCloth(getAllCloth ? getAllCloth : []);
        }
    }, [isClothSuccess, isClothFetching]);

    useEffect(() => {
        if (isClothPartySuccess && !isClothPartyFetching) {
            let getAllClothParty = clothPartyResult.data?.data?.clotheParty.map(
                ({ _id, name }: any) => {
                    return {
                        label: name,
                        value: _id,
                    };
                }
            );
            setClothParty(getAllClothParty ? getAllClothParty : []);
        }
    }, [isClothPartySuccess, isClothPartyFetching]);

    useEffect(() => {
        if (isFabricSuccess && !isFabricFetching) {
            let getAllFabric = fabricResult.data?.data?.fabricColor?.map(
                ({ _id, colorName }: any) => {
                    return {
                        label: colorName,
                        value: _id,
                    };
                }
            );
            setFabric(getAllFabric ? getAllFabric : []);
            let fabricColors: any = {};
            getAllFabric?.forEach((item: any) => {
                let label = item.label;
                let id = item.value;
                fabricColors[id] = label;
            });
            setFabricState(fabricColors);
        }
    }, [isFabricSuccess, isFabricFetching]);



    useEffect(() => {
        if (isSettingSuccess && !isSettingFetching) {
            let options: any = [];
            // options.push(" ")
            settingResult?.data?.data?.setting?.[0]?.metaValue?.forEach(
                (item: any) => {
                    options.push(item);
                }
            );
        }
    }, [isSettingSuccess, isSettingFetching]);


    const getClothData = (data: any) => {
        setSelectedCloth(data);
        setCloth([...Cloth, data]);
        setShowAddCloth(false);
    };

    const getClothPartyData = (data: any) => {
        setSelectedClothParty(data);
        setClothParty([...clothParty, data]);
        setShowAddClothParty(false);
    };

    const getItemData = (data: any) => {
        setSelectedItem(data);
        setItem([...Item, data]);
        setShowAddItem(false);
    };

    const getFabricData = (data: any) => {
        setSelectedFabric(data);
        setFabric([...Fabric, data]);
        setShowAddFabric(false);
        let fabricColors = fabricState;
        let id = data?.value;
        let name = data?.label;
        fabricColors[id] = name;
        setFabricState(fabricColors);
    };

    const getDesignCode = (data: any, type: any = null) => {
        setDesignCode([...designCode, data]);
        setShowAddDesignCode(false);
    };

    const getFoilData = (data: any) => {
        setFoil([...Foil, data]);
        setShowAddFoil(false);
    };

    const handleChange = (e: any, type: any = null) => {
        switch (type) {
            case "cloth":
                let selectedCloth = {
                    label: e.label,
                    value: e.value,
                };
                setSelectedCloth(selectedCloth);
                break;
            case "clothParty":
                let selectedClothParty = {
                    label: e.label,
                    value: e.value,
                };
                setSelectedClothParty(selectedClothParty);
                break;
            case "item":
                let selectedItem = {
                    label: e.label,
                    value: e.value,
                };
                setSelectedItem(selectedItem);
                break;
            case "fabricColor":
                let selectedFabric = {
                    label: e.label,
                    value: e.value,
                };
                setSelectedFabric(selectedFabric);
                break;
            default:
        }
    };


    useEffect(() => {
        if (isInvoiceSuccess && !isInvoiceFetching) {
            var invoices: any = [];
            invoiceResult &&
                invoiceResult.data?.data?.map((invoice: any) => {
                    if (invoices.indexOf(invoice?.invoiceNo) === -1) {
                        invoices.push(invoice?.invoiceNo);
                        if (id) {
                            if (id === invoice?._id) {
                                setSelectedInvoice(invoice?.invoiceNo);
                                if (type) {
                                    allInvoicetrigger({
                                        type: type,
                                        invoiceNo: Number(invoice?.invoiceNo),
                                    });
                                }
                            }
                        } else {
                            setSelectedInvoice(invoices[0]);
                            if (type) {
                                allInvoicetrigger({
                                    type: type?.toLowerCase(),
                                    invoiceNo: Number(invoices[0]),
                                });
                            }
                        }
                    }
                });
        }
    }, [isInvoiceSuccess, isInvoiceFetching]);

    const handleTypeChange = (e: any) => {
        if (e.target.value !== "Select Type") {
            setCardData({
                ...cardData,
                [e.target.name]: e.target.value,
            });
            setType(e.target.value);
            invoicetrigger({ type: e.target.value.toLowerCase() });
        } else {
            setType(null);
        }
    };


    const handleItemChanges = (e: any) => {
        setItemData({
            ...itemData,
            [e.target.name]: e.target.value,
        });
        setErrorMessage({
            ...errorMessage,
            [e.target.name]: "",
        });
    };

    const handleChanges = (e: any) => {
        setCardData({
            ...cardData,
            [e.target.name]: e.target.value,
        });
    };


    useEffect(() => {
        setItemData({
            ...itemData,
        });
    }, [itemData?.pcs, itemData?.fabricColorId]);

    const handlepostImage = (image: any) => {
        imageUpload(image[0], (url: any) => {
            setImages(url);
        });
    };

    const [imageCopy, setImageCopy]: any = useState([]);
    const imageDelete = (imageFile: any) => {
        setImageCopy([...imageCopy, imageFile]);
        setImages("");

    };

    const deleteImageOnSaveUpdate = () => {
        if (imageCopy?.length > 0) {
            imageCopy?.map((image: any) => {
                s3.deleteObject({
                    Bucket: bucketName,
                    Key: image?.replace(
                        "https://cnt-satv.s3.ap-south-1.amazonaws.com/",
                        ""
                    ),
                });
            });
            setImageCopy([]);
        }
    };

    const imageUpload = async (imageFile: any, callback: any) => {
        const rawBytes = uuid();
        const imageName =
            rawBytes.toString() + "." + imageFile?.file?.name?.split(".")[1];
        const uploadURL = await s3.getSignedUrlPromise("putObject", {
            Bucket: bucketName,
            Key: "Matching-cards/" + imageName,
            Expires: 60,
        });

        await fetch(uploadURL, {
            method: "PUT",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            body: imageFile.file,
        });
        const imageUrl = uploadURL.split("?")[0];
        callback(imageUrl);
    };
    const openInvoicePreview = () => {
        setShowReadyStockPreview(true);
    };
    const [isUpdating, setIsUpdating]: any = useState(false);
    const [isSaving, setIsSaving]: any = useState(false);
    const [isSaved, setIsSaved]: any = useState(false);

    const updateData = () => {

        let Colors = tableData?.map((item: any, index: number) => {
            return {
                _id: item?._id,
                number: item?.number,
                fabricColorId: item?.fabricColorId,
                pcs: item?.pcs
            }
        });

        needleData.forEach((party: any) => {
            if (typeof party.rate === 'string') {
                party.rate = Number(party.rate);
            }

            party.colorPcs.forEach((color: any) => {
                if (typeof color.pcs === 'string') {
                    color.pcs = Number(color.pcs);
                }
            });

            if (!party.totalPcs) {
                const totalPcs = party.colorPcs.reduce((sum: number, color: any) => sum + color.pcs, 0);
                party.totalPcs = totalPcs;
            }
        });


        let finalData = {
            designNo: cardData?.designNo,
            cardNo: cardData?.cardNo,
            clotheId: selectedCloth?.value,
            clothePartyId: selectedClothParty?.value,
            colors: Colors,
            partys: needleData,
            image: {
                URL: images
            }
        };

        updateReadyStock({
            payload: finalData,
            query: {
                rsId: id,
            },
        }).then((data: any) => {
            setIsUpdating(false);
            if (data.error) {
                Swal.fire({
                    toast: true,
                    icon: "error",
                    title: data.error.data.message,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: ERROR_MESSAGE_TIMEOUT,
                    timerProgressBar: true,
                });
            } else {
                Swal.fire({
                    title: "Great",
                    text: "Matching Card Successfully Updated !!",
                    icon: "success",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                });
                deleteImageOnSaveUpdate();

            }
        });
    };

    const addData = () => {
        let hasError: boolean = false;
        let errorData = {
            designNo: "",
            cardNo: "",
            cloth: "",
            clothParty: "",
            image: ""
        };

        if (!cardData?.designNo) {
            errorData["designNo"] = "Design No is Required";
            hasError = true;
        } else {
            errorData["designNo"] = "";
        }
        if (!cardData?.cardNo) {
            errorData["cardNo"] = "Card No is Required";
            hasError = true;
        } else {
            errorData["cardNo"] = "";
        }
        if (!selectedCloth?.value) {
            errorData["cloth"] = "Fabric Selection is Required"
            hasError = true
        } else {
            errorData["cloth"] = ""
        }
        if (!selectedClothParty?.value) {
            errorData["clothParty"] = "FabricParty Selection is Required";
            hasError = true;
        } else {
            errorData["clothParty"] = ""
        }
        if (!images) {
            errorData["image"] = "Image Selection is Required";
            hasError = true;
        } else {
            errorData["image"] = ""
        }


        setErrorMessage(errorData);
        // if (!hasError) {
        let Colors = tableData?.map((item: any, index: number) => {
            return {
                _id: item?._id,
                number: item?.number,
                fabricColorId: item?.fabricColorId,
                pcs: item?.pcs
            }
        });

        needleData.forEach((party: any) => {
            const totalPcs = party.colorPcs.reduce((sum: any, color: any) => sum + parseInt(color.pcs, 10), 0);
            party.totalPcs = totalPcs;
        });
        let finalData = {
            designNo: cardData?.designNo,
            cardNo: cardData?.cardNo,
            clotheId: selectedCloth?.value,
            clothePartyId: selectedClothParty?.value,
            colors: Colors,
            partys: needleData,
            image: {
                URL: images
            }
        };

        // setIsSaving(!hasError);
        if (tableData?.length <= 0 && hasError === false) {
            Swal.fire({
                toast: true,
                icon: "error",
                title: "Cloth Configuration is required",
                position: "top-end",
                showConfirmButton: false,
                timer: ERROR_MESSAGE_TIMEOUT,
                timerProgressBar: true,
            });
            return;
        }

        addMatchingCard(finalData).then((data: any) => {
            if (data.error) {
                Swal.fire({
                    toast: true,
                    icon: "error",
                    title: data.error.data.message,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: ERROR_MESSAGE_TIMEOUT,
                    timerProgressBar: true,
                });
            } else {
                Swal.fire({
                    title: "Great",
                    text: "Ready Stock Successfully Added!!",
                    icon: "success",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                });
                localStorage.setItem("copy", "");
                deleteImageOnSaveUpdate();
                setIsSaved(true);
            }
        });
    };

    useEffect(() => {
        if (isgetItemSuccess && !isgetItemFetching) {
            let item = getItemResult.data?.data?.item.map(({ _id, name }: any) => {
                return {
                    label: name,
                    value: _id,
                };
            });
            if (item) {
                setItem(item);
            }
        }
    }, [isgetItemSuccess, isgetItemFetching]);


    useEffect(() => {
        if (isgetClothSuccess && !isgetClothFetching) {
            let cloth = getClothResult.data?.data?.clothe.map(
                ({ _id, name }: any) => {
                    return {
                        label: name,
                        value: _id,
                    };
                }
            );
            if (cloth) {
                setCloth(cloth);
            }
        }
    }, [isgetClothSuccess, isgetClothFetching]);

    useEffect(() => {
        if (isgetClothPartySuccess && !isgetClothPartyFetching) {
            let clothParty = getClothPartyResult.data?.data?.clotheParty.map(
                ({ _id, name }: any) => {
                    return {
                        label: name,
                        value: _id,
                    };
                }
            );
            if (clothParty) {
                setClothParty(clothParty);
            }
        }
    }, [isgetClothPartySuccess, isgetClothPartyFetching]);

    useEffect(() => {
        if (isgetPartyNameSuccess && !isgetPartyNameFetching) {
            let party = getPartyNameResult.data?.data?.party.map(
                ({ _id, name }: any) => {
                    return {
                        label: name,
                        value: _id,
                    };
                }
            );
            if (party) {
                setParty(party);
            }
        }
    }, [isgetPartyNameSuccess, isgetPartyNameFetching]);



    const handleColorChanges = (e: React.ChangeEvent<HTMLInputElement>, key: string, maxPcs: number) => {

        const value = parseInt(e.target.value)
        const updatedErrorMessages = { ...colorPcsErrorMessages };
        if (value > maxPcs) {
            updatedErrorMessages[key] = `Value can't be Greater than ${maxPcs}`;
            setColorPcsErrorMessages(updatedErrorMessages);
            return;
        } else {
            delete updatedErrorMessages[key];
            setColorPcsErrorMessages(updatedErrorMessages)
        }

        setColorPcsData((prev) => ({
            ...prev,
            [key]: value as any,
        }));

    };


    useEffect(() => {
        handleColorPcsData();
    }, [selectedNeedle]);

    const handleColorPcsData = () => {
        const updatedColorpcsData: any = {};
        selectedNeedle?.colorPcs?.forEach((color: any) => {
            updatedColorpcsData[color.fabricColorId] = color.pcs;
        });
        setColorPcsData(updatedColorpcsData);
    };




    return (
        <div className="m-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl bg-themeBgColor pl-28 bg-cover overflow-hidden">
            <div className="mx-6 mt-24 lg:mt-0 xl:mt-0 fixed inset-y-0 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 xl:left-28 top-0 right-0 xl:translate-x-0 overflow-auto">
                <div className="invisible lg:visible xl:visible sticky top-5 z-90">
                    <nav className="absolute sm:top-0 md:top-0 lg:top-0 left-0 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
                        <div className="flex gap-5 w-full justify-between items-center">
                            <div className="px-4 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                                <span className="text-xl">Ready Stock</span>
                            </div>
                            <div className="px-3 w-2/5 flex justify-end gap-2">
                                <button
                                    className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                    disabled={showReadyStockPreview}
                                    onClick={openInvoicePreview}
                                >
                                    <i className="fas fa-file-download"></i>
                                    <span className="ml-2">Print</span>
                                </button>
                                {isReadyStockUpdate ? (
                                    <button
                                        className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                        disabled={isUpdating}
                                        onClick={updateData}
                                    >
                                        {isUpdating ? (
                                            <i className="fa fa-spinner animate-spin"></i>
                                        ) : (
                                            <i className="fa fa-save"></i>
                                        )}
                                        <span className="ml-2">Update</span>
                                    </button>
                                ) : null}
                                {!isSaved && !isReadyStockUpdate ? (
                                    <button
                                        className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                        disabled={isSaving}
                                        onClick={addData}
                                    >
                                        {isSaving ? (
                                            <i className="fa fa-spinner animate-spin"></i>
                                        ) : (
                                            <i className="fa fa-save"></i>
                                        )}
                                        <span className="ml-2">Save</span>
                                    </button>
                                ) : null}
                                <button
                                    className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                    onClick={() => {
                                        navigate(
                                            `/readystockdashboard`
                                        );
                                    }}
                                >
                                    <i className="fa fa-close"></i>
                                    <span className="ml-2">Cancel</span>
                                </button>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="mx-6 xl:mx-0 lg:mt-0 xl:mt-0 fixed inset-y-0 mb-4 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 top-24 right-0 xl:translate-x-0 overflow-auto">
                    <div className="w-full overflow-auto">
                        <div className="w-full h-full rounded-xl bg-white">
                            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-2  pt-3 ">

                                <div>
                                    <TextBox
                                        id="designNo"
                                        type="text"
                                        name="designNo"
                                        htmlFor="designNo"
                                        label="designNo"
                                        hasError={errorMessage?.designNo}
                                        placeholder=" "
                                        value={cardData?.designNo}
                                        onChange={(e: any) => {
                                            handleChanges(e);
                                            setErrorMessage({
                                                ...errorMessage,
                                                designNo: "",
                                            });
                                        }}
                                    />
                                    <span className="text-xs text-red-500 text-left">
                                        {errorMessage?.designNo}
                                    </span>
                                </div>
                                <div>

                                    <TextBox
                                        id="cardNo"
                                        type="number"
                                        name="cardNo"
                                        htmlFor="cardNo"
                                        label="card No"
                                        hasError={errorMessage?.cardNo}
                                        placeholder=" "
                                        value={cardData?.cardNo}
                                        onChange={(e: any) => {
                                            handleChanges(e);
                                            setErrorMessage({
                                                ...errorMessage,
                                                cardNo: "",
                                            });
                                        }}
                                    />
                                    <span className="text-xs text-red-500 text-left">
                                        {errorMessage?.cardNo}
                                    </span>
                                </div>
                                <div>
                                    <div className="relative rounded-md">
                                        <CreatableSelect
                                            name="cloth"
                                            classNamePrefix="customSelect"
                                            filterOption={createFilter(
                                                selectFilterConfig
                                            )}
                                            className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                                            onChange={(e: any) => {
                                                if (e) {
                                                    handleChange(e, "cloth");
                                                    setErrorMessage({
                                                        ...errorMessage,
                                                        cloth: "",
                                                    });
                                                } else {
                                                    setSelectedCloth({});
                                                }
                                            }}
                                            classNames={{
                                                control: () =>
                                                    errorMessage?.cloth
                                                        ? "border-red-500 focus:border-red-500"
                                                        : "border-gray-500 focus:border-themeColor",
                                            }}

                                            onCreateOption={(data: any) => {
                                                setClothAdd(data);
                                                setShowAddCloth(true);
                                            }}
                                            options={Cloth}
                                            value={selectedCloth}
                                        />
                                        <label
                                            htmlFor="cloth"
                                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.cloth
                                                ? "text-red-500"
                                                : "text-gray-500"
                                                }`}
                                        >
                                            {" "}
                                            Fabric
                                        </label>
                                    </div>
                                    <span className="text-xs text-red-500 text-left">
                                        {errorMessage?.cloth}
                                    </span>
                                </div>
                                <div>
                                    <div className="relative rounded-md">
                                        <CreatableSelect
                                            name="clothParty"
                                            classNamePrefix="customSelect"
                                            filterOption={createFilter(
                                                selectFilterConfig
                                            )}
                                            className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                                            onChange={(e: any) => {
                                                if (e) {
                                                    handleChange(e, "clothParty");
                                                    setErrorMessage({
                                                        ...errorMessage,
                                                        clothParty: "",
                                                    });
                                                } else {
                                                    setSelectedClothParty({});
                                                }
                                            }}
                                            classNames={{
                                                control: () =>
                                                    errorMessage?.clothParty
                                                        ? "border-red-500 focus:border-red-500"
                                                        : "border-gray-500 focus:border-themeColor",
                                            }}
                                            onCreateOption={(data: any) => {
                                                setClothPartyAdd(data);
                                                setShowAddClothParty(true);
                                            }}
                                            options={clothParty}
                                            value={selectedClothParty}
                                        />
                                        <label
                                            htmlFor="clothParty"
                                            className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.clothParty
                                                ? "text-red-500"
                                                : "text-gray-500"
                                                }`}
                                        >
                                            Fabric Party
                                        </label>
                                    </div>
                                    <span className="text-xs text-red-500 text-left">
                                        {errorMessage?.clothParty}
                                    </span>
                                </div>


                            </div>

                            {/* color Configuration */}
                            <div className="px-2">
                                <fieldset className="border-2 mt-2 rounded-2 border-gray-400 p-2">
                                    <legend className="capitalize px-2">
                                        Color Configuration
                                    </legend>
                                    <div className="grid gap-4 grid-cols-1 xl:grid-cols-5">

                                        {/* Item Configuration */}
                                        <fieldset className="border-2 rounded-2 border-gray-400 p-2 lg:col-span-5">
                                            <div
                                                className={`grid grid-cols-1 md:grid-cols-4 gap-4`}
                                            >
                                                <div>
                                                    <TextBox
                                                        id="number"
                                                        name="number"
                                                        htmlFor="number"
                                                        label="Number"
                                                        type="text"
                                                        placeholder=" "
                                                        className={`px-0 ${errorMessage?.number
                                                            ? "border-red-500 focus:border-red-500"
                                                            : "focus:border-themeColor"
                                                            }`}
                                                        labelClassName={`${errorMessage?.number
                                                            ? "text-red-500 peer-focus:text-red-500"
                                                            : "text-gray-500"
                                                            }`}
                                                        hasError={errorMessage?.number}
                                                        onChange={(e: any) =>
                                                            handleItemChanges(e)
                                                        }
                                                        value={itemData?.number || ""}
                                                    />
                                                    <span className="text-xs text-red-500 text-left">
                                                        {errorMessage?.number}
                                                    </span>
                                                </div>
                                                <div className="relative rounded-md">
                                                    <CreatableSelect
                                                        name="fabricColor"
                                                        classNamePrefix="customSelect"
                                                        filterOption={createFilter(selectFilterConfig)}
                                                        className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                                                        classNames={{
                                                            control: () =>
                                                                errorMessage?.fabricColorId
                                                                    ? "border-red-600"
                                                                    : "border-grey-300",
                                                        }}
                                                        onChange={(e: any) => {
                                                            if (e) {
                                                                handleChange(e, "fabricColor");
                                                                setErrorMessage({
                                                                    ...errorMessage,
                                                                    fabricColorId: "",
                                                                });
                                                            } else {
                                                                setSelectedFabric({});
                                                            }
                                                        }}

                                                        onCreateOption={(data: any) => {
                                                            setFabricAdd(data);
                                                            setShowAddFabric(true);
                                                        }}
                                                        // options={Fabric}
                                                        options={Fabric.filter((option: any) =>
                                                            !tableData.some((item: any) => item.fabricColorId === option.value)
                                                        )}
                                                        value={selectedFabric}
                                                    />
                                                    <label
                                                        htmlFor="item"
                                                        className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.fabricColorId
                                                            ? "text-red-500"
                                                            : "text-gray-500"
                                                            }`}
                                                    >
                                                        {" "}
                                                        Fabric Color
                                                    </label>
                                                    <span className="text-xs text-red-500 flex justify-start">
                                                        {errorMessage?.fabricColorId}
                                                    </span>
                                                </div>
                                                <div>
                                                    <TextBox
                                                        id="pcs"
                                                        name="pcs"
                                                        htmlFor="pcs"
                                                        label="pcs"
                                                        placeholder=" "
                                                        type="number"
                                                        className={`px-0 ${errorMessage?.pcs
                                                            ? "border-red-500 focus:border-red-500"
                                                            : "focus:border-themeColor"
                                                            }`}
                                                        labelClassName={`${errorMessage?.pcs
                                                            ? "text-red-500 peer-focus:text-red-500"
                                                            : "text-gray-500"
                                                            }`}
                                                        hasError={errorMessage?.pcs}
                                                        onChange={(e: any) => handleItemChanges(e)}
                                                        value={
                                                            Number(itemData?.pcs)
                                                        }
                                                    />
                                                    <span className="text-xs text-red-500 text-left">
                                                        {errorMessage?.pcs}
                                                    </span>
                                                </div>
                                                <div className="col-span-1 md:col-span-1">
                                                    <button
                                                        className="w-full py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                                        onClick={() => {
                                                            if (isUpdate) {
                                                                var item: any = {
                                                                    srNo: itemData?.srNo,
                                                                    number: itemData?.number,
                                                                    fabricColorId: selectedFabric.value
                                                                        ? selectedFabric.value
                                                                        : "",
                                                                    fabricColor: {
                                                                        colorName: selectedFabric?.label,
                                                                        _id: selectedFabric?.value
                                                                    },
                                                                    pcs: itemData?.pcs,
                                                                    _id: itemData?._id
                                                                };
                                                                let errorData = {
                                                                    number: "",
                                                                    fabricColorId: "",
                                                                    pcs: ""
                                                                };
                                                                if (!itemData?.number || itemData?.number == "") {
                                                                    errorData["number"] = "Area is Required";
                                                                } else errorData["number"] = "";
                                                                if (Object.keys(selectedFabric)?.length == 0) {
                                                                    errorData["fabricColorId"] = "fabricColorId is Required";
                                                                } else errorData["fabricColorId"] = "";
                                                                if (!itemData?.pcs || itemData?.pcs == null) {
                                                                    errorData["pcs"] = "Pcs is Required"
                                                                } else {
                                                                    errorData["pcs"] = "";
                                                                }
                                                                setErrorMessage(errorData);
                                                                const result: any = itemValidation(item);
                                                                if (result === true) {
                                                                    const elementsIndex = tableData.findIndex(
                                                                        (element: any) =>
                                                                            element.srNo === itemData.srNo &&
                                                                            element._id === itemData._id
                                                                    );
                                                                    if (elementsIndex !== -1) {
                                                                        const newArray = [...tableData];
                                                                        newArray[elementsIndex] = {
                                                                            ...newArray[elementsIndex],
                                                                            number: item.number,
                                                                            fabricColorId: item.fabricColorId,
                                                                            fabricColor: item.fabricColor,
                                                                            pcs: item.pcs
                                                                        };
                                                                        setTableData(newArray);
                                                                        setItemData({});
                                                                        setSelectedFabric({});
                                                                        setIsUpdate(false);
                                                                    } else {
                                                                        Swal.fire({
                                                                            toast: true,
                                                                            icon: "error",
                                                                            title: "Record not found for update",
                                                                            position: "top-end",
                                                                            showConfirmButton: false,
                                                                            timer: ERROR_MESSAGE_TIMEOUT,
                                                                            timerProgressBar: true,
                                                                        });
                                                                    }
                                                                } else {
                                                                    Swal.fire({
                                                                        toast: true,
                                                                        icon: "error",
                                                                        title: result.errorMessage,
                                                                        position: "top-end",
                                                                        showConfirmButton: false,
                                                                        timer: ERROR_MESSAGE_TIMEOUT,
                                                                        timerProgressBar: true,
                                                                    });
                                                                }
                                                            } else {
                                                                var newCloth = {
                                                                    _id: ObjectID().toHexString(),
                                                                    srNo: Math.floor(
                                                                        Math.random() * 4000000 + 1
                                                                    ),
                                                                    number: itemData?.number,
                                                                    fabricColorId: selectedFabric.value
                                                                        ? selectedFabric.value
                                                                        : "",
                                                                    fabricColor: {
                                                                        colorName: selectedFabric?.label,
                                                                        _id: selectedFabric?.value
                                                                    },
                                                                    pcs: itemData?.pcs,
                                                                };
                                                                let errorData = {
                                                                    fabricColorId: "",
                                                                    number: "",
                                                                    pcs: ""
                                                                };
                                                                if (
                                                                    !itemData?.number ||
                                                                    itemData?.number == ""
                                                                ) {
                                                                    errorData["number"] =
                                                                        "Area is Required";
                                                                } else errorData["number"] = "";
                                                                if (
                                                                    Object.keys(selectedFabric)?.length ==
                                                                    0
                                                                ) {
                                                                    errorData["fabricColorId"] =
                                                                        "fabricColor is Required";
                                                                } else errorData["fabricColorId"] = "";
                                                                setErrorMessage(errorData);
                                                                if (tableData?.length == 7) {
                                                                    Swal.fire({
                                                                        toast: true,
                                                                        icon: "error",
                                                                        title: "You can only 7 items add",
                                                                        position: "top-end",
                                                                        showConfirmButton: false,
                                                                        timer: ERROR_MESSAGE_TIMEOUT,
                                                                        timerProgressBar: true,
                                                                    });
                                                                    return
                                                                }
                                                                const result: any =
                                                                    itemValidation(newCloth);
                                                                if (result === true) {
                                                                    setTableData([
                                                                        ...tableData,
                                                                        newCloth,
                                                                    ]);

                                                                    setItemData({})
                                                                    setSelectedFabric({});
                                                                    setIsUpdate(false);
                                                                } else {
                                                                    Swal.fire({
                                                                        toast: true,
                                                                        icon: "error",
                                                                        title: result.errorMessage,
                                                                        position: "top-end",
                                                                        showConfirmButton: false,
                                                                        timer: ERROR_MESSAGE_TIMEOUT,
                                                                        timerProgressBar: true,
                                                                    });
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {isUpdate ? "Update" : "Add"}
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>

                                        {/*Item Table */}
                                        <div className="col-span-1 lg:col-span-5">
                                            <div className="border border-gray-300 rounded-3 overflow-hidden">
                                                <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                                                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div className="overflow-hidden">
                                                            <div className="p-0 overflow-hidden capitalize">
                                                                {tableData && tableData?.length > 0 ? (
                                                                    <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                                                        <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                                                            <thead className="bg-gray-50">
                                                                                <tr>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="px-6 py-2 font-bold text-gray-900"
                                                                                    >
                                                                                        Number
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="px-6 font-bold text-gray-900"
                                                                                    >
                                                                                        Fabric Color
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="px-6 font-bold text-gray-900"
                                                                                    >
                                                                                        Pcs
                                                                                    </th>

                                                                                    <th
                                                                                        scope="col"
                                                                                        className="px-6 font-bold text-gray-900 text-center"
                                                                                    >
                                                                                        action
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                                                                {tableData && tableData?.map(
                                                                                    (
                                                                                        item: any,
                                                                                        index: number
                                                                                    ) => {
                                                                                        return (
                                                                                            <tr
                                                                                                key={index}
                                                                                                className="hover:bg-gray-50"
                                                                                            >
                                                                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                                                                    {item?.number}
                                                                                                </td>
                                                                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                                                                    {
                                                                                                        fabricState[
                                                                                                        item?.fabricColorId
                                                                                                        ]
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                                                                    {item?.pcs}
                                                                                                </td>

                                                                                                <td>
                                                                                                    <div>
                                                                                                        <button
                                                                                                            className="m-2"
                                                                                                            type="button"
                                                                                                            onClick={() => {
                                                                                                                setItemData({
                                                                                                                    srNo: item?.srNo,
                                                                                                                    number: item?.number,
                                                                                                                    pcs: item?.pcs,
                                                                                                                    _id: item?._id
                                                                                                                });
                                                                                                                setSelectedFabric(
                                                                                                                    {
                                                                                                                        label:
                                                                                                                            fabricState[
                                                                                                                            item
                                                                                                                                .fabricColorId
                                                                                                                            ],
                                                                                                                        value:
                                                                                                                            item.fabricColorId,
                                                                                                                    }
                                                                                                                );
                                                                                                                setIsUpdate(
                                                                                                                    true
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fas fa-pen hover:text-green-600"></i>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            className="m-2"
                                                                                                            onClick={() => {
                                                                                                                Swal.fire({
                                                                                                                    title:
                                                                                                                        "Are you sure?",
                                                                                                                    text: "Once deleted, you will not be able to recover this record!",
                                                                                                                    icon: "warning",
                                                                                                                    showCancelButton:
                                                                                                                        true,
                                                                                                                    confirmButtonColor:
                                                                                                                        "#3085d6",
                                                                                                                    cancelButtonColor:
                                                                                                                        "#d33",
                                                                                                                    confirmButtonText:
                                                                                                                        "Delete",
                                                                                                                }).then(
                                                                                                                    (
                                                                                                                        willDelete
                                                                                                                    ) => {
                                                                                                                        if (
                                                                                                                            willDelete.isConfirmed
                                                                                                                        ) {
                                                                                                                            let newData =
                                                                                                                                tableData && tableData?.filter(
                                                                                                                                    (
                                                                                                                                        clothe: any
                                                                                                                                    ) => {
                                                                                                                                        return (
                                                                                                                                            item.srNo !==
                                                                                                                                            clothe.srNo
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                );

                                                                                                                            setTableData(
                                                                                                                                newData
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fas fa-trash hover:text-red-600"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                ) : (
                                                                    <div className="text-center py-3 text-gray-600 font-bold">
                                                                        No Item Found
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            {/* Niddle Configaration */}
                            <div>


                                <div className="grid grid-cols-1 lg:grid-cols-5 xl:grid-cols-5">
                                    <div className="col-span-4">
                                        {/* Cloths Configuration */}
                                        <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 pl-2 pr-2 md:pr-0">
                                            <div className="col-span-4">
                                                <div>
                                                    <fieldset className="border-2 mt-2 rounded-2 border-gray-400 p-2">
                                                        <legend className="capitalize px-2">
                                                            Party Configuration
                                                        </legend>
                                                        <div className="grid gap-4 grid-cols-1 xl:grid-cols-5 mb-2">
                                                            <div>
                                                                <TextBox
                                                                    tabIndex={-1}
                                                                    id="date"
                                                                    type="date"
                                                                    name="date"
                                                                    htmlFor="date"
                                                                    label="Date"
                                                                    placeholder=" "
                                                                    hasError={errorMessage?.date}
                                                                    value={cardData?.date}
                                                                    onChange={(e: any) => {
                                                                        handleChanges(e)
                                                                        setErrorMessage({
                                                                            ...errorMessage,
                                                                            date: ""
                                                                        })
                                                                    }}
                                                                />
                                                                <span className="text-xs text-red-500 text-left">
                                                                    {errorMessage?.date}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <TextBox
                                                                    htmlFor="partyName"
                                                                    id="partyName"
                                                                    name="partyName"
                                                                    label="partyName"
                                                                    type="text"
                                                                    placeholder=" "
                                                                    className="px-0"
                                                                    value={cardData?.partyName}
                                                                    hasError={errorMessage?.partyName}
                                                                    onChange={(e: any) => {
                                                                        handleChanges(e);
                                                                        setErrorMessage({
                                                                            ...errorMessage,
                                                                            partyName: "",
                                                                        });
                                                                    }}
                                                                />
                                                                <span className="text-xs text-red-500 text-left">
                                                                    {errorMessage?.partyName}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <TextBox
                                                                    htmlFor="billNo"
                                                                    id="billNo"
                                                                    name="billNo"
                                                                    label="BillNo"
                                                                    type="text"
                                                                    placeholder=" "
                                                                    className="px-0"
                                                                    value={cardData?.billNo}
                                                                    hasError={errorMessage?.billNo}
                                                                    onChange={(e: any) => {
                                                                        handleChanges(e);
                                                                        setErrorMessage({
                                                                            ...errorMessage,
                                                                            billNo: "",
                                                                        });
                                                                    }}
                                                                />
                                                                <span className="text-xs text-red-500 text-left">
                                                                    {errorMessage?.billNo}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <TextBox
                                                                    id="rate"
                                                                    name="rate"
                                                                    htmlFor="rate"
                                                                    label="Rate"
                                                                    placeholder=" "
                                                                    type="number"
                                                                    value={cardData?.rate}
                                                                    hasError={errorMessage?.rate}
                                                                    onChange={(e: any) => {
                                                                        handleChanges(e);
                                                                        setErrorMessage({
                                                                            ...errorMessage,
                                                                            rate: "",
                                                                        });
                                                                    }}
                                                                />
                                                                <span className="text-xs text-red-500 text-left">
                                                                    {errorMessage?.rate}
                                                                </span>
                                                            </div>
                                                            <div >
                                                                {needleUpdate ? (
                                                                    <div className="md:w-1/3 lg:w-auto">
                                                                        <button
                                                                            className="px-2 w-full py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                                                            onClick={updateRecord}
                                                                        >
                                                                            {" "}
                                                                            Update <i className="fas fa-pen-nib"></i>
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div className="md:w-1/3 lg:w-auto">
                                                                        <button
                                                                            className="px-2 w-full py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                                                            onClick={addRecord}
                                                                        >
                                                                            {" "}
                                                                            Add
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="grid gap-4 grid-cols-1 xl:grid-cols-5 mb-2">
                                                            {tableData?.length > 0 && tableData?.map((item: any, index: number) => (
                                                                <div key={index}>
                                                                    <TextBox
                                                                        id={`colorpcs-${index}`}
                                                                        type="number"
                                                                        name={`colorpcs-${index}`}
                                                                        htmlFor={`colorpcs-${index}`}
                                                                        label={item?.fabricColor?.colorName}
                                                                        placeholder=" "
                                                                        value={ColorpcsData?.[item?.fabricColorId] || ""}
                                                                        onChange={(e: any) => handleColorChanges(e, item?.fabricColorId, item?.pcs)}
                                                                    />
                                                                    {colorPcsErrorMessages[item?.fabricColorId] && (
                                                                        <span className="text-red-600 text-sm">{colorPcsErrorMessages[item?.fabricColorId]}</span>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>



                                                        <div className="grid grid-cols-1 py-2">
                                                            <div className="border border-gray-300 rounded-3 overflow-hidden">
                                                                <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                                                                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                        <div className="min-w-full sm:px-6 lg:px-0">
                                                                            <div className="overflow-hidden">
                                                                                <div className="flex-auto px-0 pt-0">
                                                                                    <div className="p-0 overflow-hidden">
                                                                                        {needleData && needleData?.length > 0 ? (
                                                                                            <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                                                                                <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                                                                                    <thead className="bg-gray-50">
                                                                                                        <tr>
                                                                                                            <th
                                                                                                                scope="col"
                                                                                                                className="px-6 font-bold text-gray-900"
                                                                                                            >
                                                                                                                Sr No.
                                                                                                            </th>
                                                                                                            <th
                                                                                                                scope="col"
                                                                                                                className="px-6 font-bold text-gray-900"
                                                                                                            >
                                                                                                                Date.
                                                                                                            </th>
                                                                                                            <th
                                                                                                                scope="col"
                                                                                                                className="px-6 font-bold text-gray-900"
                                                                                                            >
                                                                                                                Bill No
                                                                                                            </th>
                                                                                                            <th
                                                                                                                scope="col"
                                                                                                                className="px-6 font-bold text-gray-900"
                                                                                                            >
                                                                                                                PartyName
                                                                                                            </th>
                                                                                                            <th
                                                                                                                scope="col"
                                                                                                                className="px-6 font-bold text-gray-900"
                                                                                                            >
                                                                                                                Rate
                                                                                                            </th>

                                                                                                            {/* {needleData?.length > 0 && (
                                                                                                                <>
                                                                                                                    {Array.from(
                                                                                                                        { length: Math.max(...needleData.map((fabric: any) => console.log(fabric?.colorPcs) )) },
                                                                                                                        // { length: Math.max(...needleData.map((fabric: any) => fabric.colorPcs?.length || 0)) },
                                                                                                                        (_, index) => (
                                                                                                                            <th
                                                                                                                                key={`C${index + 1}`}
                                                                                                                                scope="col"
                                                                                                                                className="px-2 text-center font-bold text-gray-900"
                                                                                                                            >
                                                                                                                                {`C${index + 1}`}
                                                                                                                            </th>
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )} */}
                                                                                                            {tableData?.length > 0 && tableData?.map((item: any, index: number) => (
                                                                                                                <th
                                                                                                                    key={`${index + 1}`}
                                                                                                                    scope="col"
                                                                                                                    className="px-2 text-center font-bold text-gray-900"
                                                                                                                >
                                                                                                                    {item?.fabricColor?.colorName}
                                                                                                                </th>
                                                                                                            ))}
                                                                                                            <th
                                                                                                                scope="col"
                                                                                                                className="px-6 font-bold text-center text-gray-900"
                                                                                                            >
                                                                                                                Action
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                                                                                        {needleData?.length > 0 &&
                                                                                                            needleData?.map(
                                                                                                                (fabric: any, index: number) => {
                                                                                                                    const maxColorPcs = Math.max(...needleData.map((item: any) => item.colorPcs?.length || 0));
                                                                                                                    return (
                                                                                                                        <tr
                                                                                                                            key={index}
                                                                                                                            className="hover:bg-gray-50"
                                                                                                                        >
                                                                                                                            <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                                                                                                {index + 1}
                                                                                                                            </td>
                                                                                                                            <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                                                                                                {new Date(fabric?.date).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-')}
                                                                                                                            </td>
                                                                                                                            <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                                                                                                {fabric?.billNo}
                                                                                                                            </td>
                                                                                                                            <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                                                                                                {
                                                                                                                                    fabric?.partyName
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                            <td className="gap-3 px-6 py-4 font-normal text-gray-900 text-left">
                                                                                                                                {fabric?.rate}
                                                                                                                            </td>

                                                                                                                            {[...Array(maxColorPcs)].map((_, index) => (
                                                                                                                                <td key={index} className="px-2 py-2 text-black">
                                                                                                                                    {fabric.colorPcs?.[index]?.pcs || "-"}
                                                                                                                                </td>
                                                                                                                            ))}
                                                                                                                            

                                                                                                                            <td>
                                                                                                                                <div>
                                                                                                                                    <button
                                                                                                                                        className="m-2"
                                                                                                                                        type="button"
                                                                                                                                        onClick={() => {
                                                                                                                                            setNeedleUpdate(true);
                                                                                                                                            setCardData({
                                                                                                                                                billNo: fabric?.billNo,
                                                                                                                                                rate: fabric?.rate,
                                                                                                                                                partyName: fabric?.partyName,
                                                                                                                                                date: fabric?.date
                                                                                                                                            })
                                                                                                                                            setSelectedNeedle(fabric)
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-pen hover:text-green-600"></i>
                                                                                                                                    </button>
                                                                                                                                    <button
                                                                                                                                        className="m-2"
                                                                                                                                        onClick={() => {
                                                                                                                                            Swal.fire({
                                                                                                                                                title:
                                                                                                                                                    "Are you sure?",
                                                                                                                                                text: "Once deleted, you will not be able to recover this record!",
                                                                                                                                                icon: "warning",
                                                                                                                                                showCancelButton:
                                                                                                                                                    true,
                                                                                                                                                confirmButtonColor:
                                                                                                                                                    "#3085d6",
                                                                                                                                                cancelButtonColor:
                                                                                                                                                    "#d33",
                                                                                                                                                confirmButtonText:
                                                                                                                                                    "Delete",
                                                                                                                                            }).then(
                                                                                                                                                (willDelete) => {
                                                                                                                                                    if (
                                                                                                                                                        willDelete.isConfirmed
                                                                                                                                                    ) {
                                                                                                                                                        let newData =
                                                                                                                                                            needleData?.filter(
                                                                                                                                                                (
                                                                                                                                                                    item: any
                                                                                                                                                                ) => {
                                                                                                                                                                    return (
                                                                                                                                                                        item?._id !==
                                                                                                                                                                        fabric?._id
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                            );
                                                                                                                                                        setNeedleData(
                                                                                                                                                            newData
                                                                                                                                                        );
                                                                                                                                                        if (
                                                                                                                                                            newData?.length ==
                                                                                                                                                            0
                                                                                                                                                        ) {
                                                                                                                                                            setTableHead(
                                                                                                                                                                []
                                                                                                                                                            );

                                                                                                                                                        } else {
                                                                                                                                                            if (
                                                                                                                                                                fabric
                                                                                                                                                                    ?.needles
                                                                                                                                                                    ?.length ==
                                                                                                                                                                tableHead?.length
                                                                                                                                                            ) {
                                                                                                                                                                let lengths: any =
                                                                                                                                                                    [];
                                                                                                                                                                newData?.map(
                                                                                                                                                                    (
                                                                                                                                                                        item: any
                                                                                                                                                                    ) => {
                                                                                                                                                                        lengths.push(
                                                                                                                                                                            item
                                                                                                                                                                                ?.needles
                                                                                                                                                                                ?.length
                                                                                                                                                                        );
                                                                                                                                                                    }
                                                                                                                                                                );
                                                                                                                                                                let maxValue: any =
                                                                                                                                                                    Math.max(
                                                                                                                                                                        ...lengths
                                                                                                                                                                    );
                                                                                                                                                                if (
                                                                                                                                                                    maxValue ==
                                                                                                                                                                    tableHead?.length
                                                                                                                                                                ) {
                                                                                                                                                                    setTableHead(
                                                                                                                                                                        tableHead
                                                                                                                                                                    );
                                                                                                                                                                } else {
                                                                                                                                                                    let newHead =
                                                                                                                                                                        tableHead.slice(
                                                                                                                                                                            0,
                                                                                                                                                                            maxValue
                                                                                                                                                                        );
                                                                                                                                                                    setTableHead(
                                                                                                                                                                        newHead
                                                                                                                                                                    );
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }

                                                                                                                                                        setSelectedFabric(
                                                                                                                                                            {}
                                                                                                                                                        );
                                                                                                                                                        setNeedleUpdate(
                                                                                                                                                            false
                                                                                                                                                        );
                                                                                                                                                        needleArray =
                                                                                                                                                            [];
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <i className="fas fa-trash hover:text-red-600"></i>
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    );
                                                                                                                }
                                                                                                            )}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="text-center py-3 text-gray-600 font-bold">
                                                                                                No Niddles Found
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="mt-4 px-2 col-span-3 md:col-span-1">
                                        <ImageUploading
                                            multiple={false}
                                            onChange={(e: any) => handlepostImage(e)}
                                            maxNumber={1}
                                            acceptType={["png", "jpg", "jpeg"]}
                                            maxFileSize={FILE_UPLOAD_SIZE}
                                            dataURLKey="data_url"
                                            value={images}
                                        >
                                            {({ onImageUpload, errors }) => {
                                                return (
                                                    <div className="text-center">
                                                        {errors && (
                                                            <div>
                                                                {errors.acceptType && (
                                                                    <span className="text-red-500">
                                                                        <i className="	fa fa-warning"></i>Your
                                                                        selected file type is not allow
                                                                    </span>
                                                                )}
                                                                {errors.maxFileSize && (
                                                                    <span className="text-red-500">
                                                                        <i className="fa fa-warning"></i>Selected
                                                                        file size exceed maxFileSize
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                        {images ? (
                                                            <>
                                                                <div className="grid grid-cols-7 text-sm">
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div className="col-span-5 text-center">
                                                                        <div className="">
                                                                            <button
                                                                                className="mt-1 w-full h-full flex justify-center rounded-md"
                                                                                onClick={() => onImageUpload()}
                                                                            ></button>

                                                                            <div className="relative">
                                                                                <button
                                                                                    tabIndex={-1}
                                                                                    className="absolute top-0 px-2 -right-10 bg-white rounded-lg"
                                                                                    onClick={() => {
                                                                                        imageDelete(images);
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-times"></i>
                                                                                </button>
                                                                                <ProgressiveImage
                                                                                    src={images}
                                                                                    placeholder=""
                                                                                >
                                                                                    {(src: any) => (
                                                                                        <img
                                                                                            className="object-contain h-60 w-60"
                                                                                            src={src}
                                                                                            alt="img"
                                                                                        />
                                                                                    )}
                                                                                </ProgressiveImage>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div></div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <button
                                                                className={`mt-1 h-full w-full flex justify-center rounded-md border-2 border-solid px-3 py-10 ${errorMessage?.image ? 'border-red-300' : 'border-gray-300'}`}
                                                                onClick={() => onImageUpload()}
                                                            >

                                                                <div className="w-full h-full flex justify-center items-center content-center flex-wrap space-y-1 text-center">

                                                                    <img
                                                                        src="../../../assets/images/imageIcon.svg"
                                                                        alt="Image"
                                                                    />
                                                                    <div className="w-full text-sm text-gray-600">
                                                                        <label
                                                                            htmlFor="file-upload"
                                                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                                                        >
                                                                            <span>Upload a file</span>
                                                                        </label>
                                                                        <p
                                                                            className="
                                    -1"
                                                                        >
                                                                            or drag and drop
                                                                        </p>
                                                                    </div>
                                                                    <p className="text-xs text-gray-500">
                                                                        PNG, JPG Upto 1.5 MB
                                                                    </p>
                                                                </div>
                                                            </button>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        </ImageUploading>

                                    </div>
                                </div>

                                <div className="flex flex-wrap justify-between visible lg:invisible xl:invisible gap-2 p-2">
                                    <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                                        <button
                                            className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                            disabled={showReadyStockPreview}
                                            onClick={openInvoicePreview}
                                        >
                                            <i className="fa fa-download"></i>
                                            <span className="ml-2">Print</span>
                                        </button>
                                    </div>
                                    <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                                        {isReadyStockUpdate ? (
                                            <button
                                                className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                                disabled={isUpdating}
                                                onClick={updateData}
                                            >
                                                {isUpdating ? (
                                                    <i className="fa fa-spinner animate-spin"></i>
                                                ) : (
                                                    <i className="fa fa-save"></i>
                                                )}
                                                <span className="ml-2">Update</span>
                                            </button>
                                        ) : null}
                                        {!isSaved && !isReadyStockUpdate ? (
                                            <button
                                                className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                                disabled={isSaving}
                                                onClick={addData}
                                            >
                                                {isSaving ? (
                                                    <i className="fa fa-spinner animate-spin"></i>
                                                ) : (
                                                    <i className="fa fa-save"></i>
                                                )}
                                                <span className="ml-2">Save</span>
                                            </button>
                                        ) : null}
                                    </div>
                                    <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                                        <button
                                            className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                            onClick={() => {
                                                navigate(
                                                    `/matchingcarddashboard${type ? `?type=${type}` : ""}`
                                                );
                                            }}
                                        >
                                            <i className="fa fa-times hover:rotate-180 duration-300"></i>
                                            <span className="ml-2">Cancel</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {showAddDesignCode && (
                            <DesignCodeAdd
                                setShowAddDesignCode={setShowAddDesignCode}
                                showAddDesignCode={showAddDesignCode}
                                setDesignCodeAdd={setDesignCodeAdd}
                                designCodeAdd={designCodeAdd}
                                getDesignCode={getDesignCode}
                                codeType={designCodeType}
                            />
                        )}
                        {showAddCloth && (
                            <ClothAdd
                                setShowAddCloth={setShowAddCloth}
                                showAddCloth={showAddCloth}
                                setClothAdd={setClothAdd}
                                clothAdd={clothAdd}
                                getClothData={getClothData}
                            />
                        )}
                        {showAddClothParty && (
                            <ClothPartyAdd
                                setShowAddClothParty={setShowAddClothParty}
                                showAddClothParty={showAddClothParty}
                                setClothPartyAdd={setClothPartyAdd}
                                clothPartyAdd={clothPartyAdd}
                                getClothPartyData={getClothPartyData}
                            />
                        )}
                        {showAddItem && (
                            <ItemAdd
                                setShowAddItem={setShowAddItem}
                                showAddItem={showAddItem}
                                setItemAdd={setItemAdd}
                                itemAdd={itemAdd}
                                getItemData={getItemData}
                            />
                        )}
                        {showAddFabric && (
                            <FabricAdd
                                setShowAddFabric={setShowAddFabric}
                                showAddFabric={showAddFabric}
                                setFabricAdd={setFabricAdd}
                                fabricAdd={fabricAdd}
                                getFabricData={getFabricData}
                            />
                        )}
                        {showAddFoil && (
                            <FoilColorAdd
                                setShowAddFoil={setShowAddFoil}
                                showAddFoil={showAddFoil}
                                setFoilAdd={setFoilAdd}
                                foilAdd={foilAdd}
                                getFoilData={getFoilData}
                            />
                        )}

                        {showReadyStockPreview && (
                            <ReadyStockDataPreviewPdf
                                showReadyStockPreview={showReadyStockPreview}
                                setShowReadyStockPreview={setShowReadyStockPreview}
                                id={id}
                                pageName="Ready Stock Preview"
                            />
                        )}

                    </div>
                </div>
            </div>
            {isMatchingFetching ? <Loader /> : null}
        </div >
    );
}
