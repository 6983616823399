import { useNavigate, useParams } from "react-router-dom";
import { createFilter } from "react-select";
import {
  useAddPlainStockMutation,
  useLazyClothPartyQuery,
  useLazyClothQuery,
  useLazyGetSinglePlainStockDataQuery,
  useLazyPartyQuery,
  useUpdatePlainStockMutation,
} from "../../servicesRtkQuery/publicApi";
import React, { useEffect, useState } from "react";
import TextBox from "../../components/form/TextBox";
import Swal from "sweetalert2";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import moment from "moment";
import { EmployeeReportPreview } from "../../components/pdf/report/employeeReportPreview";
import { selectFilterConfig } from "../../utils/globalFunctions";
import CreatableSelect from "react-select/creatable";
import { PartyAdd } from "../../components/modal/PartyAdd";
import { ClothAdd } from "../../components/modal/ClothAdd";
import { ClothPartyAdd } from "../../components/modal/ClothPartyAdd";

export default function CreatePlainStock() {
  const navigate = useNavigate();
  let { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const startDate = queryParams.get("startDate");
  const [selectedParty, setSelectedParty]: any = useState({});
  const [selectedCloth, setSelectedCloth]: any = useState({});
  const [selectedClothParty, setSelectedClothParty]: any = useState({});
  const [partyNameAdd, setPartyNameAdd] = useState("");
  const [showAddParty, setShowAddParty] = useState(false);
  const [clothAdd, setClothAdd] = useState("");
  const [showAddCloth, setShowAddCloth] = useState(false);
  const [Cloth, setCloth]: any = useState([]);
  const [Party, setParty]: any = useState();
  const [clothParty, setClothParty]: any = useState<any>([]);
  const [clothPartyAdd, setClothPartyAdd] = useState("");
  const [showAddClothParty, setShowAddClothParty] = useState(false);
  const [finalData, setFinalData]: any = useState({});
  const [date, setDate]: any = useState(new Date().toISOString().split("T")[0]);

  ///
  const [record, setRecord]: any = useState({});
  const [globalWorkData, setGlobalWorkData]: any = useState([]);
  const [singlePlainStockTrigger, singlePlainStockResult] =
    useLazyGetSinglePlainStockDataQuery();
  const {
    isSuccess: isSinglePlainStockSuccess,
    isFetching: isSinglePlainStockFetching,
    isError: isSinglePlainStockError,
    error: singlePlainStockerror,
  } = singlePlainStockResult;
  const [clothTrigger, clothResult] = useLazyClothQuery();
  const {
    isSuccess: isClothSuccess,
    isFetching: isClothFetching,
    isError: isClothError,
    error: clothError,
  } = clothResult;

  const [partyTrigger, partyResult] = useLazyPartyQuery();
  const { isSuccess: isPartySuccess, isFetching: isPartyFetching } =
    partyResult;


  const [addReportTrigger] = useAddPlainStockMutation();
  const [updateReportTrigger] = useUpdatePlainStockMutation();

  const [clothPartyTrigger, clothPartyResult] = useLazyClothPartyQuery();
  const { isSuccess: isClothPartySuccess, isFetching: isClothPartyFetching } =
    clothPartyResult;

  const [isUpdating, setIsUpdating]: any = useState(false);

  const [showReportPreview, setShowReportPreview] = useState(false);
  const [today, setToday]: any = useState("");
  const [errorMessage, setErrorMessage]: any = useState({});

  const [yearList, setYearList]: any = useState([]);
  const [selectedYear, setSelectedYear]: any = useState();
  const [monthList, setMonthList]: any = useState([]);
  const [selectedMonth, setSelectedMonth]: any = useState();

  useEffect(() => {
    if (startDate) {
      const selectedYear = yearList?.find(
        (item: any) => item?.label === Number(moment(startDate).format("YYYY"))
      );
      const selectedMonth = monthList?.find(
        (item: any) => item?.value === Number(moment(startDate).format("MM"))
      );
      setSelectedYear(selectedYear);
      setSelectedMonth(selectedMonth);
    }
  }, [startDate, yearList, monthList]);
  function GenerateYearMonthData(maxYears: number) {
    let thisYear = 2023; // Set the first year to 2023
    let allYears: any = [];

    for (let x = 0; x <= maxYears; x++) {
      allYears.push({
        label: thisYear + x,
        value: thisYear + x,
      });
    }

    setYearList(allYears);

    let allMonths: any = [];
    moment.monthsShort().map((month, index) => {
      allMonths.push({
        label: month,
        value: index + 1,
      });
    });

    setMonthList(allMonths);
  }

  useEffect(() => {
    let date = new Date() as any;
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    setToday(date.getFullYear() + "-" + month + "-" + day);
    GenerateYearMonthData(27);
  }, []);


  useEffect(() => {
    if (id) {
      singlePlainStockTrigger({
        psId: id,
      });
    }
  }, [id]);

  const getData = (data: any) => {
    setSelectedParty(data);
    setParty([...Party, data]);
    setShowAddParty(false);
  };

  const getClothData = (data: any) => {
    setSelectedCloth(data);
    setCloth([...Cloth, data]);
    setShowAddCloth(false);
  };

  const getClothPartyData = (data: any) => {
    setSelectedClothParty(data);
    setClothParty([...clothParty, data]);
    setShowAddClothParty(false);
  };

  useEffect(() => {
    partyTrigger("")
    clothTrigger("")
    clothPartyTrigger("")
  }, [])

  useEffect(() => {
    if (isClothPartySuccess && !isClothPartyFetching) {
      let getAllClothParty = clothPartyResult.data?.data?.clotheParty.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setClothParty(getAllClothParty ? getAllClothParty : []);
    }
  }, [isClothPartySuccess, isClothPartyFetching]);

  useEffect(() => {
    if (isPartySuccess && !isPartyFetching) {
      let getAllParty = partyResult.data?.data?.party.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setParty(getAllParty ? getAllParty : []);
    }
  }, [isPartySuccess, isPartyFetching]);

  useEffect(() => {
    if (isClothSuccess && !isClothFetching) {
      let getAllCloth = clothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setCloth(getAllCloth ? getAllCloth : []);
    }
  }, [isClothSuccess, isClothFetching]);


  useEffect(() => {
    setSelectedCloth({
      label: singlePlainStockResult?.data?.data?.plainStock?.[0]?.clothe?.name,
      value: singlePlainStockResult?.data?.data?.plainStock?.[0]?.clothe?._id
    })
    setSelectedClothParty({
      label: singlePlainStockResult?.data?.data?.plainStock?.[0]?.clotheParty?.name,
      value: singlePlainStockResult?.data?.data?.plainStock?.[0]?.clotheParty?._id
    })
    setSelectedParty({
      label: singlePlainStockResult?.data?.data?.plainStock?.[0]?.party?.name,
      value: singlePlainStockResult?.data?.data?.plainStock?.[0]?.party?._id
    })
    setRecord({
      ...record,
      challanNo: singlePlainStockResult?.data?.data?.plainStock?.[0]?.challanNo,
      pcs: singlePlainStockResult?.data?.data?.plainStock?.[0]?.pcs,
    })
    setDate(singlePlainStockResult?.data?.data?.plainStock?.[0]?.date ?? date)
  }, [
    isSinglePlainStockSuccess,
    isSinglePlainStockFetching,
    selectedYear,
    selectedMonth,
    singlePlainStockResult,
  ]);

  const handleSampleChanges = (e: any) => {
    setRecord({
      ...record,
      date: today,
      [e.target.name]: e.target.value,
    });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const handleWorkChanges = (e: any) => {

    setRecord({
      ...record,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setFinalData({
      ...finalData,
      date: date,
    });
  }, [date]);


  const ClearRecord = () => {
    setRecord({
      type: "Select Type",
      date: "",
      cardNo: "",
      pcs: "",
      rate: "",
      challanNo: "",
      totalWork: "",
      designNo: "",
      clothe: {
        _id: "",
        name: "",
      },
      partyName: {
        _id: "",
        name: "",
      },
      clotheParty:{
        _id:"",
        name:""
      }
    });
  };

  const saveReport = () => {
    let errorData: any = {
      name: "",
      work: "",
    };
    let status = false;
    if (!selectedParty?.value) {
      errorData["partyname"] = "Party Name is required";
      status = true;
    } else {
      errorData["partyname"] = "";
    }
    if (!record?.challanNo) {
      errorData["challanNo"] = "challanNo is required";
      status = true;
    } else {
      errorData["challanNo"] = "";
    }
    if (!record?.pcs) {
      errorData["pcs"] = "PCS is required";
      status = true;
    } else {
      errorData["pcs"] = "";
    }
    if (!selectedCloth?.value) {
      errorData["cloth"] = "Cloth is required";
      status = true;
    } else {
      errorData["cloth"] = "";
    }
    if (!selectedClothParty?.value) {
      errorData["clothParty"] = "clothParty is required";
      status = true;
    } else {
      errorData["clothParty"] = "";
    }

    setErrorMessage(errorData);
    console.log("date", date)
    const payload = {
      date: date,
      partyId: selectedParty?.value || "",
      challanNo: Number(record?.challanNo) || 0,
      pcs: Number(record?.pcs) || 0,
      clotheId: selectedCloth?.value || "",
      clothePartyId: selectedClothParty?.value || ""
    }
    console.log("payload", payload)

    if (!status) {
      setIsUpdating(true);
      addReportTrigger(payload).then((data: any) => {
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          ClearRecord();
          Swal.fire({
            title: "Great",
            text: "Report Created Successfully!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate(
            `/createplainstock/${data?.data?.data?.insertedIds?.[0]}`
          );
        }
        setIsUpdating(false);
      });
    }
  };

  const updateReport = () => {
    let errorData: any = {
      name: "",
      work: "",
    };
    let status = false;
    if (!selectedParty?.value) {
      errorData["partyname"] = "Party Name is required";
      status = true;
    } else {
      errorData["partyname"] = "";
    }
    if (!record?.challanNo) {
      errorData["challanNo"] = "challanNo is required";
      status = true;
    } else {
      errorData["challanNo"] = "";
    }
    if (!record?.pcs) {
      errorData["pcs"] = "PCS is required";
      status = true;
    } else {
      errorData["pcs"] = "";
    }
    if (!selectedCloth?.value) {
      errorData["cloth"] = "Cloth is required";
      status = true;
    } else {
      errorData["cloth"] = "";
    }
    if (!selectedClothParty?.value) {
      errorData["clothParty"] = "clothParty is required";
      status = true;
    } else {
      errorData["clothParty"] = "";
    }

    setErrorMessage(errorData);

    if (!status) {
      setIsUpdating(true);
      const payload = {
        date: date,
        partyId: selectedParty?.value || "",
        challanNo: Number(record?.challanNo) || 0,
        pcs: Number(record?.pcs) || 0,
        clotheId: selectedCloth?.value || "",
        clothePartyId: selectedClothParty?.value || ""
      }

      updateReportTrigger({
        payload: payload,
        query: {
          psId: id,
        },
      }).then((data: any) => {
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          // ClearRecord();
          Swal.fire({
            title: "Great",
            text: "Report Updated Successfully!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsUpdating(false);
      });
    }
  };



  const handleChange = (e: any, type: any = null) => {
    switch (type) {
      case "partyname":
        let selectParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedParty(selectParty);
        break;
      case "cloth":
        let selectedCloth = {
          label: e.label,
          value: e.value,
        };
        setSelectedCloth(selectedCloth);
        break;
      case "clothParty":
        let selectedClothParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedClothParty(selectedClothParty);
        break
      default:
    }
  };

  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl bg-themeBgColor pl-28 bg-cover overflow-hidden">
      <div className="mx-6 mt-24 lg:mt-0 xl:mt-0 fixed inset-y-0 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 xl:left-28 top-0 right-0 xl:translate-x-0 overflow-auto">
        <div className="invisible lg:visible xl:visible sticky top-5 z-90">
          <nav className="absolute sm:top-0 md:top-0 lg:top-0 left-0 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
            <div className="flex gap-5 w-full justify-between items-center">
              <div className="px-3 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                <span className="text-xl">Plain Stock</span>
              </div>

              <div className="px-3 md:w-2/5 flex justify-end">
                {/* <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    setShowReportPreview(true);
                  }}
                >
                  <i className="fa fa-download"></i>
                  <span className="ml-2">Print</span>
                </button> */}
                <button
                  className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={isUpdating}
                  onClick={id ? updateReport : saveReport}
                >
                  {isUpdating ? (
                    <i className="fa fa-spinner animate-spin"></i>
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  <span className="ml-2">{id ? "Update" : "Save"}</span>
                </button>
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    navigate({
                      pathname: `/plainstockdashboard`,
                    });
                  }}
                >
                  <i className="fa fa-close"></i>
                  <span className="ml-2">Cancel</span>
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className="mx-6 xl:mx-0 lg:mt-0 xl:mt-0 fixed inset-y-0 mb-4 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 top-24 right-0 xl:translate-x-0 overflow-auto">
          <div className="w-full overflow-visible">
            <div className="w-full rounded-xl bg-white">
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-6 py-8 px-2">
                <div>
                  <TextBox
                    id="date"
                    type="date"
                    name="date"
                    htmlFor="date"
                    value={date}
                    tabIndex={-1}
                    label="Date"
                    placeholder=" "
                    onChange={(e: any) => {
                      setDate(e.target.value);
                    }}
                  />
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.date}
                  </span>
                </div>

                <div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="partyName"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className="block z-20 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      classNames={{
                        control: () =>
                          errorMessage?.partyname
                            ? "border-red-600"
                            : "border-gray-500",
                      }}
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "partyname");
                          setErrorMessage({
                            ...errorMessage,
                            partyname: "",
                          });
                        } else {
                          setSelectedParty({});
                        }
                      }}
                      onCreateOption={(data: any) => {
                        setPartyNameAdd(data);
                        setShowAddParty(true);
                      }}
                      options={Party && Party}
                      value={selectedParty}
                    />
                    <label
                      htmlFor="partyName"
                      className={`absolute z-20 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.partyname ? "text-red-500" : "text-gray-500"
                        }`}
                    >
                      Party Name
                    </label>
                  </div>
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.partyname}
                  </span>
                </div>

                <div>
                  <TextBox
                    id="challanNo"
                    type="number"
                    value={record?.challanNo}

                    name="challanNo"
                    htmlFor="challanNo"
                    label="Challan No"
                    placeholder=" "
                    hasError={errorMessage.challanNo}
                    onChange={(e: any) => {
                      handleSampleChanges(e);
                      setErrorMessage({
                        ...errorMessage,
                        challanNo: "",
                      });
                    }}
                  />
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.challanNo}
                  </span>
                </div>

                <div>
                  <TextBox
                    id="pcs"
                    type="number"
                    name="pcs"
                    htmlFor="pcs"
                    value={record?.pcs}
                    label="Pcs"
                    placeholder=" "
                    onChange={(e: any) => {
                      handleWorkChanges(e);
                      setErrorMessage({
                        ...errorMessage,
                        pcs: "",
                      });
                    }}
                    hasError={errorMessage?.pcs}
                  />
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.pcs}
                  </span>
                </div>


                <div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="cloth"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(
                        selectFilterConfig
                      )}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "cloth");
                          setErrorMessage({
                            ...errorMessage,
                            cloth: "",
                          });
                        } else {
                          setSelectedCloth({});
                        }
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.cloth
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}

                      onCreateOption={(data: any) => {
                        setClothAdd(data);
                        setShowAddCloth(true);
                      }}
                      options={Cloth}
                      value={selectedCloth}
                    />
                    <label
                      htmlFor="cloth"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.cloth
                        ? "text-red-500"
                        : "text-gray-500"
                        }`}
                    >
                      {" "}
                      Fabric
                    </label>
                  </div>
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.cloth}
                  </span>
                </div>

                <div>
                  <div className="relative rounded-md">
                    <CreatableSelect
                      name="clothParty"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(
                        selectFilterConfig
                      )}
                      className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "clothParty");
                          setErrorMessage({
                            ...errorMessage,
                            clothParty: "",
                          });
                        } else {
                          setSelectedCloth({});
                        }
                      }}
                      classNames={{
                        control: () =>
                          errorMessage?.clothParty
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor",
                      }}

                      onCreateOption={(data: any) => {
                        setClothPartyAdd(data);
                        setShowAddClothParty(true);
                      }}
                      options={clothParty}
                      value={selectedClothParty}
                    />
                    <label
                      htmlFor="cloth party"
                      className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${errorMessage?.clothParty
                        ? "text-red-500"
                        : "text-gray-500"
                        }`}
                    >
                      {" "}
                      Fabric Party
                    </label>
                  </div>
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.clothParty}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Pdf Preview */}
      {showReportPreview && (
        <EmployeeReportPreview
          showReportPreview={showReportPreview}
          setShowReportPreview={setShowReportPreview}
          pageName="Report"
        />
      )}

      {showAddParty && (
        <PartyAdd
          setShowAddParty={setShowAddParty}
          showAddParty={showAddParty}
          setPartyNameAdd={setPartyNameAdd}
          partyNameAdd={partyNameAdd}
          getData={getData}
        />
      )}

      {showAddCloth && (
        <ClothAdd
          setShowAddCloth={setShowAddCloth}
          showAddCloth={showAddCloth}
          setClothAdd={setClothAdd}
          clothAdd={clothAdd}
          getClothData={getClothData}
        />
      )}
      {showAddClothParty && (
        <ClothPartyAdd
          setShowAddClothParty={setShowAddClothParty}
          showAddClothParty={showAddClothParty}
          setClothPartyAdd={setClothPartyAdd}
          clothPartyAdd={clothPartyAdd}
          getClothPartyData={getClothPartyData}
        />
      )}
    </div>
  );
}
