import { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../../components/form/Select";
import ReactSelect from "react-select";
import TextBox from "../../components/form/TextBox";
import CreatableSelect from "react-select/creatable";
import { PartyAdd } from "../../components/modal/PartyAdd";
import { DesignCodeAdd } from "../../components/modal/DesignCodeAdd";
import { ClothAdd } from "../../components/modal/ClothAdd";
import { ClothPartyAdd } from "../../components/modal/ClothPartyAdd";
import { ItemAdd } from "../../components/modal/ItemAdd";
import {
  useLazyPartyQuery,
  useLazyDesignCodeQuery,
  useLazyNewInvoiceNumberQuery,
  useLazyClothQuery,
  useLazyItemQuery,
  useUpdateSampleCardMutation,
  useLazyFabricColorQuery,
  useAddSampleCardMutation,
  useLazyClothPartyQuery,
  useLazyGetFabricColorQuery,
  useLazyGetClothQuery,
  useLazyGetClothPartyQuery,
  useLazyGetItemQuery,
  useLazyGetPartyNameQuery,
  useLazyEmployeeQuery,
  useLazySampleCardQuery,
  useLazyGetSampleCardQuery,
  useLazyAllInvoiceNumberQuery,
  useLazySkechersEmployeeQuery,
  useLazyImageEmployeeQuery,
  useLazyFoilColorQuery,
  useLazyGetFoilColorQuery,
} from "../../servicesRtkQuery/publicApi";
import { useDispatch, useSelector } from "react-redux";
import { SampleCard } from "../interface/SampleCard";
import { itemValidation } from "../../utils/validation";
import { needleValidation } from "../../utils/validation";
import ProgressiveImage from "react-progressive-graceful-image";
import Needles from "../../components/form/Needles";
import Carousel from "react-simply-carousel";
import { FabricAdd } from "../../components/modal/FabricAdd";
import uuid from "react-uuid";
import S3 from "aws-sdk/clients/s3";
import ObjectID from "bson-objectid";
import Swal from "sweetalert2";
import { setSampleCard } from "../../redux/sampleCardSlice";
import Loader from "../../components/page/loader";
import { SampleCardPreview } from "../../components/pdf/samplecardPreview";
import {
  ERROR_MESSAGE_TIMEOUT,
  FILE_UPLOAD_SIZE,
  MAX_NEEDLES,
} from "../../utils/constants";
import {
  setDefaultDate,
  selectFilterConfig,
} from "../../utils/globalFunctions";
import { createFilter } from "react-select";
import { FoilColorAdd } from "../../components/modal/FoilColorAdd";

interface itemData {
  srNo: string;
  area: string;
  itemId: string;
  frame: string;
  head: string;
  stitch: string;
  production: number;
  day: any;
  CC: any;
}

var needleArray: any = [];
export default function SampleCards() {
  const bucketName: any = process.env.REACT_APP_AWS_BUCKET_NAME;
  const region: any = process.env.REACT_APP_AWS_BUCKET_REGION;
  const accessKeyId: any = process.env.REACT_APP_AWS_ACCESS_KEY;
  const secretAccessKey: any = process.env.REACT_APP_AWS_SECRET_KEY;
  const dispatch = useDispatch();

  const s3 = new S3({
    region: region,
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
  });
  const [isSampleUpdate, setIsSampleUpdate] = useState(false);
  const [isSampleCopy, setIsSampleCopy] = useState(false);
  let { id } = useParams();
  let url = window.location.pathname;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [type, setType]: any = useState<any>(null);

  useEffect(() => {
    if (url == "/samplecards/copy") {
      setIsSampleCopy(true);
    } else if (url == "/samplecards/add") {
      clearData();
      setIsSampleUpdate(false);
      setIsSampleCopy(false);
    } else {
      if (id) {
        setIsSampleUpdate(true);
      } else {
        setIsSampleUpdate(false);
        setType(params?.type);
        invoicetrigger({ type: params?.type?.toLowerCase() });
      }
    }
  }, [id]);
  // Redux Data State
  const sampleCardData = useSelector(
    (state: any) => state.sampleCardSlice.sampleCardData
  );

  // Global Sample Card Data State
  const [cardData, setCardData]: any = useState<SampleCard>();
  const [itemData, setItemData] = useState<itemData>({
    srNo: "",
    area: "",
    itemId: "",
    frame: "",
    head: "",
    stitch: "",
    production: 0,
    day: "",
    CC: "",
  });
  const [tableData, setTableData] = useState<any>([]);

  const [errorMessage, setErrorMessage]: any = useState({});

  const [trigger, result] = useLazyPartyQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [designTrigger, designResult] = useLazyDesignCodeQuery();
  const {
    isSuccess: isDesignSuccess,
    isFetching: isDesignFetching,
    isError: isDesignError,
    error: designError,
  } = designResult;

  const [invoicetrigger, invoiceResult] = useLazyNewInvoiceNumberQuery();
  const {
    isSuccess: isInvoiceSuccess,
    isFetching: isInvoiceFetching,
    isError: isInvoiceError,
    error: invoiceerror,
  } = invoiceResult;

  const [allInvoicetrigger, allInvoiceResult] = useLazyAllInvoiceNumberQuery();
  const {
    isSuccess: isAllInvoiceSuccess,
    isFetching: isAllInvoiceFetching,
    isError: isAllInvoiceError,
    error: allInvoiceerror,
  } = allInvoiceResult;

  const [clothTrigger, clothResult] = useLazyClothQuery();
  const {
    isSuccess: isClothSuccess,
    isFetching: isClothFetching,
    isError: isClothError,
    error: clothError,
  } = clothResult;

  const [clothPartyTrigger, clothPartyResult] = useLazyClothPartyQuery();
  const {
    isSuccess: isClothPartySuccess,
    isFetching: isClothPartyFetching,
    isError: isClothPartyError,
    error: clothPartyError,
  } = clothPartyResult;

  const [itemTrigger, itemResult] = useLazyItemQuery();
  const {
    isSuccess: isItemSuccess,
    isFetching: isItemFetching,
    isError: isItemError,
    error: itemError,
  } = itemResult;

  const [fabricTrigger, fabricResult] = useLazyFabricColorQuery();
  const {
    isSuccess: isFabricSuccess,
    isFetching: isFabricFetching,
    isError: isFabricError,
    error: fabricError,
  } = fabricResult;

  const [employeeTrigger, employeeResult] = useLazyEmployeeQuery();
  const {
    isSuccess: isEmployeeSuccess,
    isFetching: isEmployeeFetching,
    isError: isEmployeeError,
    error: employeeError,
  } = employeeResult;

  const [skechersEmployeeTrigger, skechersEmployeeResult] =
    useLazySkechersEmployeeQuery();
  const {
    isSuccess: isSkechersEmployeeSuccess,
    isFetching: isSkechersEmployeeFetching,
    isError: isSkechersEmployeeError,
    error: skechersEmployeeError,
  } = skechersEmployeeResult;

  const [imageEmployeeTrigger, imageEmployeeResult] =
    useLazyImageEmployeeQuery();
  const {
    isSuccess: isImageEmployeeSuccess,
    isFetching: isImageEmployeeFetching,
    isError: isImageEmployeeError,
    error: imageEmployeeError,
  } = imageEmployeeResult;

  const [sampleTrigger, sampleResult] = useLazyGetSampleCardQuery();
  const {
    isSuccess: isSampleSuccess,
    isFetching: isSampleFetching,
    isError: isSampleError,
    error: sampleError,
  } = sampleResult;

  const [allSampleTrigger, allSampleResult] = useLazySampleCardQuery();
  const {
    isSuccess: isAllSampleSuccess,
    isFetching: isAllSampleFetching,
    isError: isAllSampleError,
    error: allSampleError,
  } = allSampleResult;

  //Getting paginated records
  const [getFabricColorTrigger, getFabricColorResult] =
    useLazyGetFabricColorQuery();
  const {
    isSuccess: isgetFabricColorSuccess,
    isFetching: isgetFabricColorFetching,
    isError: isgetFabricColorError,
    error: getFabricColorError,
  } = getFabricColorResult;

  const [getClothTrigger, getClothResult] = useLazyGetClothQuery();
  const {
    isSuccess: isgetClothSuccess,
    isFetching: isgetClothFetching,
    isError: isgetClothError,
    error: getClothError,
  } = getClothResult;

  const [getClothPartyTrigger, getClothPartyResult] =
    useLazyGetClothPartyQuery();
  const {
    isSuccess: isgetClothPartySuccess,
    isFetching: isgetClothPartyFetching,
    isError: isgetClothPartyError,
    error: getClothPartyError,
  } = getClothPartyResult;

  const [getItemTrigger, getItemResult] = useLazyGetItemQuery();
  const {
    isSuccess: isgetItemSuccess,
    isFetching: isgetItemFetching,
    isError: isgetItemError,
    error: getItemError,
  } = getItemResult;

  const [getPartyNameTrigger, getPartyNameResult] = useLazyGetPartyNameQuery();
  const {
    isSuccess: isgetPartyNameSuccess,
    isFetching: isgetPartyNameFetching,
    isError: isgetPartyNameError,
    error: getPartyNameError,
  } = getPartyNameResult;

  const [foilTrigger, foilResult] = useLazyFoilColorQuery();
  const { isSuccess: isFoilSuccess, isFetching: isFoilFetching } = foilResult;

  const [getFoilColorTrigger, getFoilColorResult] = useLazyGetFoilColorQuery();
  const { isSuccess: isFoilColorSuccess, isFetching: isFoilColorFetching } =
    getFoilColorResult;
  const navigate = useNavigate();

  // Party Creatable State
  const [showAddParty, setShowAddParty] = useState(false);
  const [partyNameAdd, setPartyNameAdd] = useState("");
  const [Party, setParty]: any = useState([]);
  const [selectedParty, setSelectedParty]: any = useState({});

  // Cloth Creatable State
  const [showAddCloth, setShowAddCloth] = useState(false);
  const [clothAdd, setClothAdd] = useState("");
  const [Cloth, setCloth]: any = useState<any>([]);
  const [selectedCloth, setSelectedCloth]: any = useState({});

  // Item Creatable State
  const [showAddItem, setShowAddItem] = useState(false);
  const [itemAdd, setItemAdd] = useState("");
  const [Item, setItem]: any = useState<any>([]);
  const [selectedItem, setSelectedItem]: any = useState({});
  const [itemState, setItemState]: any = useState({});

  // Fabric Creatable State
  const [showAddFabric, setShowAddFabric] = useState(false);
  const [fabricAdd, setFabricAdd] = useState("");
  const [Fabric, setFabric]: any = useState([]);
  const [selectedFabric, setSelectedFabric]: any = useState({});
  const [fabricState, setFabricState]: any = useState({});

  // Design Code Creatable State
  const [showAddDesignCode, setShowAddDesignCode] = useState(false);
  const [designCodeAdd, setDesignCodeAdd] = useState("");
  const [designCode, setDesignCode]: any = useState([]);
  const [designCodeType] = useState("designCodeOne");
  const [codes, setCodes]: any = useState({});

  // Cloth Party Creatable State
  const [showAddClothParty, setShowAddClothParty] = useState(false);
  const [clothPartyAdd, setClothPartyAdd] = useState("");
  const [clothParty, setClothParty]: any = useState<any>([]);
  const [selectedClothParty, setSelectedClothParty]: any = useState({});

  // Puncher Creatable State
  const [employee, setEmployee]: any = useState<any>([]);
  const [selectedPuncher, setSelectedPuncher]: any = useState({});
  const [skechersEmployee, setSkechersEmployee]: any = useState<any>([]);
  // Designer Creatable State
  const [selectedDesigner, setSelectedDesigner]: any = useState({});
  const [invoiceNo, setInvoiceNo]: any = useState<any>([]);
  const [invoice, setInvoice]: any = useState([]);
  const [newSampleCard, setNewSampleCard]: any = useState<any>([]);

  const [PM, setPM] = useState("");
  const [srNo, setSrNo]: any = useState(1);
  const [spacing, setSpacing]: any = useState({});
  const [images, setImages]: any = useState();
  const [selectedInvoiceCard, setSelectedInvoiceCard]: any = useState("");
  const [selectedInvoice, setSelectedInvoice]: any = useState("");
  const [tdays, setTdays] = useState(1);
  const [totalProd, setTotalProd] = useState(0);
  const [totalNeedles, setTotalNeedles] = useState(0);
  const [needlesAdded, setNeedlesAdded] = useState(false);
  const [showSamplePreview, setShowSamplePreview] = useState(false);
  const [savedId, setSavedId]: any = useState("");
  const [tableHead, setTableHead]: any = useState([]);
  const [needleData, setNeedleData]: any = useState([]);
  const [userType, setUserType]: any = useState({});
  // Is Item Update State
  const [isUpdate, setIsUpdate] = useState(false);
  // Is Needle Update State
  const [needleUpdate, setNeedleUpdate] = useState(false);
  const [selectedNeedle, setSelectedNeedle]: any = useState({});
  const [components, setComponents]: any = useState([]);

  const [updateSampleCard, updateResult] = useUpdateSampleCardMutation();
  const [addSampleCard, addResult] = useAddSampleCardMutation();

  const [activeSlide, setActiveSlide] = useState(0);

  //Image Spacing
  const [imageSize, setImageSize]: any = useState({});
  // Foil Color Creatable State
  const [showAddFoil, setShowAddFoil] = useState(false);
  const [foilAdd, setFoilAdd] = useState("");
  const [Foil, setFoil]: any = useState([]);
  const [selectedFoil, setSelectedFoil]: any = useState({});
  const getFoilData = (data: any) => {
    setSelectedFoil(data);
    setFoil([...Foil, data]);
    setShowAddFoil(false);
  };
  useEffect(() => {
    if (isFoilSuccess && !isFoilFetching) {
      let getAllFoil = foilResult.data?.data?.foilColor?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setFoil(getAllFoil ? getAllFoil : []);
    }
  }, [isFoilSuccess, isFoilFetching]);
  useEffect(() => {
    if (isFoilColorSuccess && !isFoilColorFetching) {
      let getAllFoil = getFoilColorResult.data?.data?.foilColor?.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setFoil(getAllFoil ? getAllFoil : []);
    }
  }, [isFoilColorSuccess, isFoilColorFetching]);

  const test = (
    data: any,
    thread: any,
    code1: any,
    code2: any,
    number: any,
    totalWeight: any,
    totalUsed: any,
    totalWeight1: any,
    totalUsed1: any
  ) => {
    // console.log(data,thread,code1,code2,number,totalWeight,totalUsed,totalWeight1,totalUsed1,"-test")
    needleArray[number - 1] = {
      designPattern: data ? data?.value : "",
      threadType: thread ? thread?.value : "",
      code1: Object?.keys(code1).length ? code1?.value : "",
      code2: Object?.keys(code2).length ? code2?.value : "",
      totalWeight: totalWeight,
      totalUsed: totalUsed,
      totalWeight1: totalWeight1,
      totalUsed1: totalUsed1,
    };
    designTrigger("");
  };
  const addRecord = () => {
    let needleHead: any = [];
    let record: any;
    let errorData = {
      fabricColor: "",
      needle: "",
      input: "",
    };
    if (Object?.keys(selectedFabric)?.length == 0) {
      errorData["fabricColor"] = "Fabric Color is Required";
    } else {
      errorData["fabricColor"] = "";
    }
    if (needleArray?.length == 0) {
      errorData["needle"] = "Needle is Required";
    } else {
      errorData["needle"] = "";
    }
    setErrorMessage(errorData);
    type === "saree"
      ? (record = {
          _id: ObjectID().toHexString(),
          fabricColorId: selectedFabric?.value || "",
        })
      : (record = {
          _id: ObjectID().toHexString(),
          fabricColorId: selectedFabric?.value || "",
          PM: PM,
        });

    let needles: any = [];
    needleArray?.forEach((needle: any, index: number) => {
      let obj = {};
      needle?.code2 !== ""
        ? (obj = {
            number: "N" + (index + 1),
            designCodeId1: needle?.code1,
            designCodeId2: needle?.code2,
          })
        : (obj = {
            number: "N" + (index + 1),
            designCodeId1: needle?.code1,
          });
      needles?.push(obj);
    });
    record["needles"] = needles;

    if (needleArray.length >= tableHead.length) {
      needleArray?.forEach((needle: any, index: number) => {
        let headRecord = {
          number: "N" + (index + 1),
          designPattern: needle?.designPattern || "",
          threadType: needle?.threadType || "",
          totalWeight: Number(needle?.totalWeight) || "",
          totalUsed: Number(needle?.totalUsed) || "",
          totalWeight1: Number(needle?.totalWeight1) || 0,
          totalUsed1: Number(needle?.totalUsed1) || 0,
        };
        needleHead.push(headRecord);
      });
    } else {
      needleHead = tableHead;
    }

    const result: any = needleValidation(record, needleHead);
    if (result === true) {
      errorData["needle"] = "";
      setTableHead(needleHead);

      setNeedleData([...needleData, record]);

      setComponents([]);
      setPM("");
      setSelectedFabric({});
      setSrNo(srNo + 1);
      needleArray = [];
      setNeedlesAdded(false);
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: result.errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
      if (result.errorMessage) {
        errorData["needle"] = result.errorMessage;
      }
    }
  };

  const updateRecord = () => {
    let needleHead: any = [];
    let record: any;
    let errorData = {
      fabricColor: "",
      needle: "",
      input: "",
    };
    if (Object?.keys(selectedFabric)?.length == 0) {
      errorData["fabricColor"] = "Fabric Color is Required";
    } else {
      errorData["fabricColor"] = "";
    }
    if (needleArray?.length == 0) {
      errorData["needle"] = "Needle is Required";
    } else {
      errorData["needle"] = "";
    }
    setErrorMessage(errorData);
    type === "saree"
      ? (record = {
          _id: ObjectID().toHexString(),
          fabricColorId: selectedFabric?.value || "",
        })
      : (record = {
          _id: ObjectID().toHexString(),
          fabricColorId: selectedFabric?.value || "",
          PM: PM,
        });

    let needles: any = [];
    needleArray?.forEach((needle: any, index: number) => {
      let obj = {};
      needle.code2 !== ""
        ? (obj = {
            number: "N" + (index + 1),
            designCodeId1: needle.code1,
            designCodeId2: needle.code2,
          })
        : (obj = {
            number: "N" + (index + 1),
            designCodeId1: needle.code1,
          });
      needles.push(obj);
    });
    record["needles"] = needles;

    // No Need To remove this Code { Needle Table Header Code}

    if (needleArray.length >= tableHead.length) {
      needleArray?.forEach((needle: any, index: number) => {
        let headRecord = {
          number: "N" + (index + 1),
          designPattern: needle?.designPattern || "",
          threadType: needle?.threadType || "",
          totalWeight: Number(needle?.totalWeight),
          totalUsed: Number(needle?.totalUsed),
          totalWeight1: Number(needle?.totalWeight1) || 0,
          totalUsed1: Number(needle?.totalUsed1) || 0,
        };
        needleHead.push(headRecord);
      });
    } else {
      if (selectedNeedle?.needles?.length == tableHead?.length) {
        let newData = needleData.filter((item: any) => {
          return item._id !== selectedNeedle?._id;
        });
        let lengths: any = [];
        newData?.map((item: any) => {
          lengths.push(item?.needles?.length);
        });
        let maxValue: any = Math.max(...lengths);

        if (needleArray?.length > maxValue) {
          let newHead = tableHead.slice(0, needleArray?.length);
          needleHead = newHead;
        } else {
          if (maxValue == tableHead?.length) {
            needleHead = tableHead;
          } else {
            let newHead = tableHead.slice(0, maxValue);
            needleHead = newHead;
          }
        }
      } else {
        needleHead = tableHead;
      }
    }

    const result: any = needleValidation(record, needleHead);
    if (result === true) {
      errorData["needle"] = "";
      const elementsIndex = needleData.findIndex(
        (element: any) => element._id == selectedNeedle?._id
      );
      let newArray = [...needleData];

      newArray[elementsIndex] = { ...newArray[elementsIndex], ...record };
      setNeedleData(newArray);
      setSrNo(needleData?.length + 1);
      setTableHead(needleHead);
      setNeedleUpdate(false);
      setComponents([]);
      setPM("");
      setSelectedFabric({});
      needleArray = [];
      setNeedlesAdded(false);
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: result.errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
      if (result.errorMessage) {
        errorData["needle"] = result.errorMessage;
      }
    }
  };

  const addNeedle = () => {
    setNeedlesAdded(true);
    setErrorMessage({
      ...errorMessage,
      needle: "",
    });
    let maxNeedles = MAX_NEEDLES;
    if (!maxNeedles) {
      maxNeedles = 18;
    }
    if (components.length + 1 > maxNeedles) {
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Max Needles Limit Reached !!",
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
    } else {
      if (needleData.length == 0) {
        setTableHead([]);
      }
      setComponents([
        ...components,
        <Needles
          number={components.length + 1}
          test={test}
          patt={tableHead?.[components.length]?.designPattern || ""}
          thr={tableHead?.[components.length]?.threadType || ""}
          totalWeight={tableHead?.[components.length]?.totalWeight || ""}
          totalUsed={tableHead?.[components.length]?.totalUsed || ""}
          totalWeight1={tableHead?.[components.length]?.totalWeight1 || ""}
          totalUsed1={tableHead?.[components.length]?.totalUsed1 || ""}
          code1={{}}
          code2={{}}
          disable={
            needleData.length == 0
              ? false
              : tableHead?.[components.length]
              ? true
              : false
          }
          twoCodes={
            tableHead?.[components.length]?.threadType == "1+1" ||
            tableHead?.[components.length]?.threadType == "2+2"
              ? true
              : false
          }
        />,
      ]);
    }
  };

  const removeNeedle = () => {
    let newComponents: any = components;
    newComponents = newComponents.slice(0, components.length - 1);
    needleArray = needleArray.slice(0, components.length - 1);
    setComponents(newComponents);
  };

  const addMultipleNeedles = () => {
    setNeedlesAdded(true);
    setComponents([]);

    const componentRender = [];
    for (let i = 0; i < totalNeedles; i++) {
      componentRender.push(
        <Needles
          number={i + 1}
          test={test}
          patt={tableHead?.[i]?.designPattern || ""}
          thr={tableHead?.[i]?.threadType || ""}
          totalWeight={tableHead?.[i]?.totalWeight || ""}
          totalUsed={tableHead?.[i]?.totalUsed || ""}
          totalWeight1={tableHead?.[i]?.totalWeight1 || ""}
          totalUsed1={tableHead?.[i]?.totalUsed1 || ""}
          code1={{}}
          code2={{}}
          disable={
            needleData.length == 0 ? false : tableHead?.[i] ? true : false
          }
          twoCodes={
            tableHead?.[i]?.threadType == "1+1" ||
            tableHead?.[i]?.threadType == "2+2"
              ? true
              : false
          }
        />
      );
    }
    setComponents([...componentRender]);
  };
  useEffect(() => {
    if (isSampleCopy) {
      let copyId = localStorage.getItem("copy");
      if (sampleCardData?.sampleCard) {
        let apiData = sampleCardData.sampleCard.filter((item: any) => {
          return item._id === copyId;
        })[0];
        if (apiData) {
          const date: any = setDefaultDate();
          setCardData({
            ...apiData,
            date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
            image: "",
          });
          let fabricColors = apiData?.fabricColors;
          let needlesHead = apiData?.needlesHead;
          let data: any = [];
          fabricColors?.forEach((item: any) => {
            let record: any;

            type === "saree"
              ? (record = {
                  _id: item._id,
                  fabricColorId: item?.fabricColorId?._id,
                })
              : (record = {
                  _id: item._id,
                  fabricColorId: item?.fabricColorId?._id,
                  PM: item?.PM,
                });
            let needles: any = [];
            let needleHead: any = [];
            item?.niddles?.forEach((needle: any) => {
              let obj = {};

              needle.designCodeId2
                ? (obj = {
                    number: needle?.number,
                    designCodeId1: needle?.designCodeId1?._id,
                    designCodeId2: needle?.designCodeId2?._id,
                  })
                : (obj = {
                    number: needle?.number,
                    designCodeId1: needle?.designCodeId1?._id,
                  });
              needles.push(obj);
            });
            record["needles"] = needles;
            data.push(record);
            needlesHead?.forEach((head: any) => {
              let headRecord = {
                number: head.number,
                designPattern: head.designPattern,
                threadType: head.threadType,
                totalWeight: head?.totalWeight,
                totalUsed: head?.totalUsed,
                totalWeight1: head?.totalWeight1 || 0,
                totalUsed1: head?.totalUsed1 || 0,
              };
              needleHead.push(headRecord);
            });

            setTableHead(needleHead);
          });
          setNeedleData(data);
          setSrNo(fabricColors?.length + 1);
          const clothData: any = [];
          if (Object.keys(apiData?.clothes[0])?.length > 0) {
            apiData?.clothes?.map((cloth: any) => {
              let newCloth = {
                srNo: cloth?.srNo,
                area: cloth?.area,
                itemId: cloth?.item?._id,
                stitch: cloth?.stitch,
                head: cloth?.head,
                frame: cloth?.frame,
                CC: cloth?.CC,
                production: cloth?.production,
                day: cloth?.day,
              };
              clothData.push(newCloth);
            });
          }
          setTableData(clothData);
        } else {
          sampleTrigger(copyId);
        }
      } else {
        sampleTrigger(copyId);
      }
    } else if (isSampleUpdate) {
      if (sampleCardData.sampleCard) {
        let apiData = sampleCardData?.sampleCard?.filter((item: any) => {
          return item._id === id;
        })[0];

        if (apiData) {
          setCardData(apiData);
          //To Edit SampleCard Data

          // if (apiData) {
          //     let needles: any = apiData.needlesHead;
          //     let fabriclength = apiData?.fabricColors?.length;
          //     let fabricColors: any = apiData.fabricColors[fabriclength - 1].niddles
          //     console.log(fabricColors, "N1")
          //     const componentRender = []
          //     for (let i = 0; i < needles.length; i++) {
          //         componentRender.push(<Needles number={needles[i].number.replace("N", "")} test={test} patt={needles[i].designPattern} thr={needles[i].threadType} code1={fabricColors[i]?.designCodeId1} code2={fabricColors[i]?.designCodeId2 ? fabricColors[i]?.designCodeId2 : {}} />)

          //     }
          //     setComponents([
          //         ...componentRender
          //     ])
          // }

          let fabricColors = apiData?.fabricColors;
          let needlesHead = apiData?.needlesHead;
          let data: any = [];
          fabricColors?.forEach((item: any) => {
            let record: any;
            type === "saree"
              ? (record = {
                  _id: item._id,
                  fabricColorId: item?.fabricColorId?._id,
                })
              : (record = {
                  _id: item._id,
                  fabricColorId: item?.fabricColorId?._id,
                  PM: item?.PM,
                });
            let needles: any = [];
            let needleHead: any = [];
            item?.niddles?.forEach((needle: any) => {
              let obj = {};

              needle.designCodeId2
                ? (obj = {
                    number: needle?.number,
                    designCodeId1: needle?.designCodeId1?._id,
                    designCodeId2: needle?.designCodeId2?._id,
                  })
                : (obj = {
                    number: needle?.number,
                    designCodeId1: needle?.designCodeId1?._id,
                  });
              needles.push(obj);
            });
            record["needles"] = needles;
            data.push(record);
            needlesHead?.forEach((head: any) => {
              let headRecord = {
                number: head.number,
                designPattern: head.designPattern,
                threadType: head.threadType,
                totalWeight: head?.totalWeight,
                totalUsed: head?.totalUsed,
                totalWeight1: head?.totalWeight1 || 0,
                totalUsed1: head?.totalUsed1 || 0,
              };
              needleHead.push(headRecord);
            });

            setTableHead(needleHead);
          });
          setNeedleData(data);
          setSrNo(fabricColors?.length + 1);
          const clothData: any = [];
          if (Object.keys(apiData?.clothes[0])?.length > 0) {
            apiData?.clothes?.map((cloth: any) => {
              let newCloth = {
                srNo: cloth?.srNo,
                area: cloth?.area,
                itemId: cloth?.item?._id,
                stitch: cloth?.stitch,
                head: cloth?.head,
                frame: cloth?.frame,
                CC: cloth?.CC,
                production: cloth?.production,
                day: cloth?.day,
              };
              clothData.push(newCloth);
            });
          }
          setTableData(clothData);
        } else {
          sampleTrigger(id);
        }
      } else {
        sampleTrigger(id);
      }
    } else {
      setCardData({});
      const date: any = setDefaultDate();
      setCardData({
        ...cardData,
        date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
      });
    }
  }, [fabricState, isSampleUpdate]);

  const clearData = () => {
    setCardData({
      partyId: "",
      designNo: "0-0",
      date: "",
      challanNo: "",
      clotheId: "",
      CM: 0,
      height: 0,
      totalProduction: 0,
      perDayProduction: 0,
      totalDays: 0,
      image: "",
      spacing: {
        top: 0,
        bottom: 0,
        right: 0,
      },
      needlesHead: [],
      fabricColors: [],
      clothes: [],
    });
    setSelectedParty({});
    setSelectedDesigner({});
    setSelectedPuncher({});
    setNeedleData([]);
    setTableHead([]);
    setSelectedCloth({});
    setSelectedClothParty({});
    setItemData({
      srNo: "",
      area: "",
      itemId: "",
      frame: "",
      head: "",
      stitch: "",
      production: 0,
      day: "",
      CC: "",
    });
    setSelectedItem({});
    setTableData([]);
    setTotalProd(0);
    setTdays(0);
    setSpacing({
      top: "",
      bottom: "",
      right: "",
    });
    setComponents([]);
    setSelectedFabric({});
    setPM("");
    setImageSize({});
    setSrNo(1);
    setImageSize({
      top: "",
      bottom: "",
      left: "",
      right: "",
    });
    setImages(null);
    setUserType("");
  };
  useEffect(() => {
    clearData();
  }, [isSampleUpdate]);

  useEffect(() => {
    trigger("");
    designTrigger("");
    clothTrigger("");
    clothPartyTrigger("");
    itemTrigger("");
    fabricTrigger("");
    employeeTrigger("designer");
    skechersEmployeeTrigger("skecher");
    foilTrigger("");
    sampleTrigger(id)
    // imageEmployeeTrigger('image')
  }, []);

  useEffect(() => {
    if (isSampleCopy) {
      if (isSampleSuccess && !isSampleFetching) {
        let apiData = sampleResult?.data?.data?.sampleCard?.[0];
        console.log("apiData",apiData)
        setCardData(apiData);
        const date: any = setDefaultDate();
        setCardData({
          ...apiData,
          date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
          image: "",
        });
        // setImages(apiData?.image?.URL)
        // if (apiData) {
        //     let needles: any = apiData.needlesHead;
        //     let fabriclength = apiData?.fabricColors?.length;
        //     let fabricColors: any = apiData.fabricColors[fabriclength - 1].niddles
        //     const componentRender = []
        //     for (let i = 0; i < needles.length; i++) {
        //         componentRender.push(<Needles number={needles[i].number.replace("N", "")} test={test} patt={needles[i].designPattern} thr={needles[i].threadType} code1={fabricColors[i]?.designCodeId1} code2={fabricColors[i]?.designCodeId2 ? fabricColors[i]?.designCodeId2 : {}} />)

        //     }
        //     setComponents([
        //         ...componentRender
        //     ])
        // }

        let fabricColors = apiData?.fabricColors;
        let needlesHead = apiData?.needlesHead;
        let data: any = [];
        fabricColors?.forEach((item: any) => {
          let record: any;

          type === "saree"
            ? (record = {
                _id: item._id,
                fabricColorId: item?.fabricColorId?._id,
              })
            : (record = {
                _id: item._id,
                fabricColorId: item?.fabricColorId?._id,
                PM: item?.PM,
              });
          let needles: any = [];
          let needleHead: any = [];
          item?.niddles?.forEach((needle: any) => {
            let obj = {};

            needle.designCodeId2
              ? (obj = {
                  number: needle?.number,
                  designCodeId1: needle?.designCodeId1?._id,
                  designCodeId2: needle?.designCodeId2?._id,
                })
              : (obj = {
                  number: needle?.number,
                  designCodeId1: needle?.designCodeId1?._id,
                });
            needles.push(obj);
          });
          record["needles"] = needles;
          data.push(record);
          needlesHead?.forEach((head: any) => {
            let headRecord = {
              number: head.number,
              designPattern: head.designPattern,
              threadType: head.threadType,
              totalWeight: head?.totalWeight,
              totalUsed: head?.totalUsed,
              totalWeight1: head?.totalWeight1 || 0,
              totalUsed1: head?.totalUsed1 || 0,
            };
            needleHead.push(headRecord);
          });

          setTableHead(needleHead);
        });
        setNeedleData(data);
        setSrNo(fabricColors?.length + 1);
        const clothData: any = [];
        if (Object.keys(apiData?.clothes[0])?.length > 0) {
          apiData?.clothes?.map((cloth: any) => {
            let newCloth = {
              srNo: cloth?.srNo,
              area: cloth?.area,
              itemId: cloth?.item?._id,
              stitch: cloth?.stitch,
              head: cloth?.head,
              frame: cloth?.frame,
              CC: cloth?.CC,
              production: cloth?.production,
              day: cloth?.day,
            };
            clothData.push(newCloth);
          });
        }
        setTableData(clothData);
      }
    }
     else if (isSampleUpdate) {
      if (isSampleSuccess && !isSampleFetching) {
        let apiData = sampleResult?.data?.data?.sampleCard?.[0];
        setCardData({
          ...apiData,
          designer: {},
        });
        setImages(apiData?.image?.URL);
        // if (apiData) {
        //     let needles: any = apiData.needlesHead;
        //     let fabriclength = apiData?.fabricColors?.length;
        //     let fabricColors: any = apiData.fabricColors[fabriclength - 1].niddles
        //     const componentRender = []
        //     for (let i = 0; i < needles.length; i++) {
        //         componentRender.push(<Needles number={needles[i].number.replace("N", "")} test={test} patt={needles[i].designPattern} thr={needles[i].threadType} code1={fabricColors[i]?.designCodeId1} code2={fabricColors[i]?.designCodeId2 ? fabricColors[i]?.designCodeId2 : {}} />)

        //     }
        //     setComponents([
        //         ...componentRender
        //     ])
        // }

        let fabricColors = apiData?.fabricColors;
        let needlesHead = apiData?.needlesHead;
        let data: any = [];
        fabricColors?.forEach((item: any) => {
          let record: any;

          type === "saree"
            ? (record = {
                _id: item._id,
                fabricColorId: item?.fabricColorId?._id,
              })
            : (record = {
                _id: item._id,
                fabricColorId: item?.fabricColorId?._id,
                PM: item?.PM,
              });
          let needles: any = [];
          let needleHead: any = [];
          item?.niddles?.forEach((needle: any) => {
            let obj = {};

            needle.designCodeId2
              ? (obj = {
                  number: needle?.number,
                  designCodeId1: needle?.designCodeId1?._id,
                  designCodeId2: needle?.designCodeId2?._id,
                })
              : (obj = {
                  number: needle?.number,
                  designCodeId1: needle?.designCodeId1?._id,
                });
            needles.push(obj);
          });
          record["needles"] = needles;
          data.push(record);
          needlesHead?.forEach((head: any) => {
            let headRecord = {
              number: head.number,
              designPattern: head.designPattern,
              threadType: head.threadType,
              totalWeight: head?.totalWeight,
              totalUsed: head?.totalUsed,
              totalWeight1: head?.totalWeight1 || 0,
              totalUsed1: head?.totalUsed1 || 0,
            };
            needleHead.push(headRecord);
          });

          setTableHead(needleHead);
        });
        setNeedleData(data);
        setSrNo(fabricColors?.length + 1);
        const clothData: any = [];
        if (Object.keys(apiData?.clothes[0])?.length > 0) {
          apiData?.clothes?.map((cloth: any) => {
            let newCloth = {
              srNo: cloth?.srNo,
              area: cloth?.area,
              itemId: cloth?.item?._id,
              stitch: cloth?.stitch,
              head: cloth?.head,
              frame: cloth?.frame,
              CC: cloth?.CC,
              production: cloth?.production,
              day: cloth?.day,
            };
            clothData.push(newCloth);
          });
        }
        setTableData(clothData);
      }
    } else {
      setCardData({});
      const date: any = setDefaultDate();
      setCardData({
        ...cardData,
        date: date.today.getFullYear() + "-" + date.month + "-" + date.day,
      });
    }
  }, [isSampleSuccess, isSampleFetching, fabricState]);

  useEffect(() => {
    if (isAllSampleSuccess && !isAllSampleFetching) {
      dispatch(setSampleCard(allSampleResult?.data?.data?.sampleCard));
      if (!isSampleUpdate) {
        navigate(`/samplecards/${savedId}`);
      }
    }
  }, [isAllSampleSuccess, isAllSampleFetching]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      let getAllParty = result.data?.data?.party.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });

      setParty(getAllParty ? getAllParty : []);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isClothSuccess && !isClothFetching) {
      let getAllCloth = clothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );

      setCloth(getAllCloth ? getAllCloth : []);
    }
  }, [isClothSuccess, isClothFetching]);

  useEffect(() => {
    if (isClothPartySuccess && !isClothPartyFetching) {
      let getAllClothParty = clothPartyResult.data?.data?.clotheParty.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setClothParty(getAllClothParty ? getAllClothParty : []);
    }
  }, [isClothPartySuccess, isClothPartyFetching]);

  useEffect(() => {
    if (isFabricSuccess && !isFabricFetching) {
      let getAllFabric = fabricResult.data?.data?.fabricColor?.map(
        ({ _id, colorName }: any) => {
          return {
            label: colorName,
            value: _id,
          };
        }
      );

      setFabric(getAllFabric ? getAllFabric : []);

      let fabricColors: any = {};

      getAllFabric?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        fabricColors[id] = label;
      });

      setFabricState(fabricColors);
    }
  }, [isFabricSuccess, isFabricFetching]);

  useEffect(() => {
    if (isItemSuccess && !isItemFetching) {
      let getAllItem = itemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      setItem(getAllItem ? getAllItem : []);
      let items: any = {};

      getAllItem?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        items[id] = label;
      });

      setItemState(items);
    }
  }, [isItemSuccess, isItemFetching]);

  useEffect(() => {
    if (isEmployeeSuccess && !isEmployeeFetching) {
      let getAll = employeeResult.data?.data?.employee.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setEmployee(getAll ? getAll : []);
    }
  }, [isEmployeeSuccess, isEmployeeFetching]);

  useEffect(() => {
    if (isSkechersEmployeeSuccess && !isSkechersEmployeeFetching) {
      let getAll = skechersEmployeeResult.data?.data?.employee.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setSkechersEmployee(getAll ? getAll : []);
    }
  }, [isSkechersEmployeeSuccess, isSkechersEmployeeFetching]);

  useEffect(() => {
    if (isImageEmployeeSuccess && !isImageEmployeeFetching) {
      let getAll = imageEmployeeResult.data?.data?.employee.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      setSkechersEmployee(getAll ? getAll : []);
    }
  }, [isImageEmployeeSuccess, isImageEmployeeFetching]);

  useEffect(() => {
    if (isDesignSuccess && !isDesignFetching) {
      let getAllDesign = designResult.data?.data?.designCode.map(
        ({ _id, designCode }: any) => {
          return {
            label: designCode,
            value: _id,
          };
        }
      );
      setDesignCode(getAllDesign ? getAllDesign : []);
      let designCodes: any = {};

      getAllDesign?.forEach((item: any) => {
        let label = item.label;
        let id = item.value;

        designCodes[id] = label;
      });

      setCodes(designCodes);
    }
  }, [isDesignSuccess, isDesignFetching]);

  useEffect(() => {
    let data: any = [];
    let totalProd: any = 0;
    tableData?.map((item: any) => {
      let day: any = cardData?.perDayProduction
        ? Math.ceil(
            Number(item?.production) / Number(cardData?.perDayProduction)
          )
        : 0;
      let record: any = {
        srNo: item?.srNo,
        area: item.area,
        itemId: item.itemId,
        frame: item.frame,
        CC: item?.CC,
        head: item.head,
        production: item.production,
        stitch: item.stitch,
        day: day || 0,
      };
      data.push(record);

      totalProd = totalProd + Number(item.production);
    });
    setTableData(data);
    setTotalProd(totalProd);
  }, [cardData?.perDayProduction]);

  const getData = (data: any) => {
    setSelectedParty(data);
    setParty([...Party, data]);
    setShowAddParty(false);
  };

  const getClothData = (data: any) => {
    setSelectedCloth(data);
    setCloth([...Cloth, data]);
    setShowAddCloth(false);
  };

  const getClothPartyData = (data: any) => {
    setSelectedClothParty(data);
    setClothParty([...clothParty, data]);
    setShowAddClothParty(false);
  };

  const getItemData = (data: any) => {
    setSelectedItem(data);
    setItem([...Item, data]);
    setShowAddItem(false);
  };

  const getFabricData = (data: any) => {
    setSelectedFabric(data);
    setFabric([...Fabric, data]);
    setShowAddFabric(false);
    let fabricColors = fabricState;
    let id = data?.value;
    let name = data?.label;
    fabricColors[id] = name;
    setFabricState(fabricColors);
  };

  const getDesignCode = (data: any, type: any = null) => {
    setDesignCode([...designCode, data]);
    setShowAddDesignCode(false);
  };

  const handleChange = (e: any, type: any = null) => {
    switch (type) {
      case "party":
        let selectParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedParty(selectParty);
        break;
      case "cloth":
        let selectedCloth = {
          label: e.label,
          value: e.value,
        };
        setSelectedCloth(selectedCloth);
        break;
      case "clothParty":
        let selectedClothParty = {
          label: e.label,
          value: e.value,
        };
        setSelectedClothParty(selectedClothParty);
        break;
      case "item":
        let selectedItem = {
          label: e.label,
          value: e.value,
        };
        setSelectedItem(selectedItem);
        break;
      case "fabricColor":
        let selectedFabric = {
          label: e.label,
          value: e.value,
        };
        setSelectedFabric(selectedFabric);
        break;
      case "designer":
        let designer = {
          label: e.label,
          value: e.value,
        };
        setSelectedDesigner(designer);
        break;
      case "image":
        let puncher = {
          label: e.label,
          value: e.value,
        };
        setSelectedPuncher(puncher);
        break;
      case "foilColor":
        let foilColor = {
          label: e.label,
          value: e.value,
        };
        setSelectedFoil(foilColor);
        break;
      default:
    }
  };

  useEffect(() => {
    if (isInvoiceSuccess && !isInvoiceFetching) {
      // setNewSampleCard(invoiceResult.data.data)
      var invoices: any = [];
      invoiceResult &&
        invoiceResult.data?.data?.map((invoice: any) => {
          if (invoices.indexOf(invoice?.invoiceNo) === -1) {
            invoices.push(invoice?.invoiceNo);
            if (id) {
              // To select the invoice number when editing
              if (id === invoice?._id) {
                setSelectedInvoice(invoice?.invoiceNo);
                if (type) {
                  allInvoicetrigger({
                    type: type,
                    invoiceNo: Number(invoice?.invoiceNo),
                  });
                }
              }
            } else {
              setSelectedInvoice(invoices[0]);
              if (type) {
                allInvoicetrigger({
                  type: type?.toLowerCase(),
                  invoiceNo: Number(invoices[0]),
                });
              }
            }
          }
        });
      setInvoice(invoices);
    }
  }, [isInvoiceSuccess, isInvoiceFetching]);

  useEffect(() => {
    if (isAllInvoiceSuccess && !isAllInvoiceFetching) {
      var data: any = [];
      setNewSampleCard(allInvoiceResult.data.data);
      let status = false;
      // allInvoiceResult && allInvoiceResult.data?.data?.map((invoice: any) => {
      //     if (id) {
      //         if (id == invoice?._id) {
      //             data.push(invoice?.invoiceNo + "-" + invoice?.cardNo)
      //             setSelectedInvoiceCard(invoice?.invoiceNo + "-" + invoice?.cardNo)
      //             setSelectedInvoice(invoice?.invoiceNo)
      //             console.log("all")
      //             status = true;
      //         }
      //     }

      // })
      allInvoiceResult &&
        allInvoiceResult.data?.data?.map((invoice: any) => {
          data.push(invoice?.cardNo);
          if (id && isSampleUpdate) {
            // If Updating SampleCard
            if (id === invoice?._id) {
              // To select the correct invoice-card number when editing
              setSelectedInvoiceCard(invoice?.cardNo);
              setSelectedInvoice(invoice?.invoiceNo);
              status = true;
            }
          } else {
            // If Adding SampleCard
            setSelectedInvoiceCard(data[0]);
            // setSelectedInvoice(data[0])
            status = true;
          }
        });
      if (!status) {
        navigate(`/samplecards?type=${type}`);
      }
      setInvoiceNo(data);
    }
  }, [isAllInvoiceSuccess, isAllInvoiceFetching]);

  // useEffect(() => {
  //     if (isCardNumberBSuccess && !isCardNumberBFetching) {
  //         var cardNumbers: any = []
  //         cardNumberBResult && cardNumberBResult.data?.data?.map((item: any) => {
  //             cardNumbers.push(item?.cardNoB)
  //             if (isSampleCopy) {
  //                 setSelectedCardNoB(cardNumbers[0])
  //             }
  //             else {
  //                 if (id) {
  //                     if (item?._id == id) {
  //                         setSelectedCardNoB(item?.cardNoB)
  //                     }
  //                 }
  //                 else {
  //                     setSelectedCardNoB(cardNumbers[0])
  //                 }
  //             }
  //         })
  //         setCardNoB(cardNumbers)
  //     }
  // }, [isCardNumberBSuccess, isCardNumberBFetching])

  const handleTypeChange = (e: any) => {
    if (e.target.value !== "Select Type") {
      setCardData({
        ...cardData,
        [e.target.name]: e.target.value,
      });
      setType(e.target.value);
      invoicetrigger({ type: e.target.value.toLowerCase() });
    } else {
      setType(null);
      setInvoiceNo([]);
    }
  };

  const handleInvoiceChange = (e: any) => {
    setIsSaved(false);
    newSampleCard.map((item: any) => {
      if (
        item.invoiceNo + "-" + item.cardNo ===
        selectedInvoice + "-" + e.target.value
      ) {
        setSelectedInvoiceCard(e.target.value);
        if (item._id) {
          navigate(`/samplecards/${item?._id}`);
          sampleTrigger(item?._id);
          // cardNumberBtrigger(item?._id)
        } else {
          navigate(`/samplecards/add?type=${type}`);
          // setCardNoB([1])
          // setSelectedCardNoB(1)
        }
      }
    });
  };

  const handleInvoices = (e: any) => {
    setSelectedInvoice(e.target.value);
    setIsSaved(false);
    setIsSampleUpdate(false);
    allInvoicetrigger({ type: type, invoiceNo: Number(e.target.value) });
  };
  // const handleCardNoBChanges = (e: any) => {
  //     setSelectedCardNoB(e.target.value)
  //     cardNumberBResult?.data?.data.map((item: any) => {
  //         if (item?.cardNoB == e.target.value) {
  //             if (item._id) {
  //                 navigate(`/samplecards/${item?._id}`)
  //                 sampleTrigger(item?._id)
  //             }
  //             else {
  //                 navigate(`/samplecards?type=${type}`)
  //                 sampleTrigger(id)
  //             }
  //         }
  //     })
  // }

  const handleItemChanges = (e: any) => {
    setItemData({
      ...itemData,
      [e.target.name]: e.target.value,
    });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: "",
    });
  };

  const handleSpacingChanges = (e: any) => {
    if (e.target.value.match("^[0-9=]*$") != null) {
      setSpacing({
        ...spacing,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleImageChanges = (e: any) => {
    setImageSize({
      ...imageSize,
      [e.target.name]: e.target.value,
    });
  };

  // To Calculate Total Days
  useEffect(() => {
    if (totalProd > 0 && cardData?.perDayProduction > 0) {
      setTdays(
        Math.ceil(Number(totalProd) / Number(cardData?.perDayProduction))
      );
    }
  }, [totalProd, cardData?.perDayProduction]);

  useEffect(() => {
    let total: any = 0;
    tableData?.forEach((item: any) => {
      total = total + Number(item?.production);
    });

    setTotalProd(total);
  }, [tableData]);
  const handleChanges = (e: any) => {
    setCardData({
      ...cardData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isSampleCopy) {
      setImageSize({
        left: cardData?.image?.left,
        right: cardData?.image?.right,
        top: cardData?.image?.top,
        bottom: cardData?.image?.bottom,
      });
      setItemData({
        srNo: "",
        area: "",
        itemId: "",
        frame: "",
        CC: "",
        head: "",
        stitch: "",
        production: 0,
        day: "",
      });
      setType(cardData?.type);
      if (cardData?.type && cardData?.invoiceNo) {
        invoicetrigger({ type: cardData?.type?.toLowerCase() });
      }
      setUserType({
        label: cardData?.workType,
        value: cardData?.workType,
      });
      cardData?.workType === "skechers"
        ? skechersEmployeeTrigger("skecher")
        : imageEmployeeTrigger("image");
      setSelectedParty({
        label: cardData?.party?.name,
        value: cardData?.party?._id,
      });
      setImages(cardData?.image?.URL);
      setSelectedCloth({
        label: cardData?.clothe?.name,
        value: cardData?.clothe?._id,
      });
      setSelectedClothParty({
        label: cardData?.clotheParty?.name,
        value: cardData?.clotheParty?._id,
      });
      if (cardData?.designer?.name && cardData?.designer?._id) {
        setSelectedPuncher({
          label: cardData?.puncher?.name,
          value: cardData?.puncher?._id,
        });
      } else {
        setSelectedDesigner({});
      }
      setSelectedPuncher({
        label: cardData?.puncher?.name,
        value: cardData?.puncher?._id,
      });
      setSelectedItem({});
      setIsUpdate(false);
      setSpacing({
        top: cardData?.spacing?.top,
        right: cardData?.spacing?.right,
        bottom: cardData?.spacing?.bottom,
      });
      setSelectedFoil({
        label: cardData?.work?.foilColor?.name,
        value: cardData?.work?.foilColor?._id,
      });
    } else if (isSampleUpdate) {
      setUserType({
        label: cardData?.workType,
        value: cardData?.workType,
      });
      setSelectedFoil({
        label: cardData?.work?.foilColor?.name,
        value: cardData?.work?.foilColor?._id,
      });

      cardData?.workType === "skechers"
        ? skechersEmployeeTrigger("skecher")
        : imageEmployeeTrigger("image");

      setImageSize({
        left: cardData?.image?.left,
        right: cardData?.image?.right,
        top: cardData?.image?.top,
        bottom: cardData?.image?.bottom,
      });
      setItemData({
        srNo: "",
        area: "",
        itemId: "",
        frame: "",
        CC: "",
        head: "",
        stitch: "",
        production: 0,
        day: "",
      });
      setType(cardData?.type);
      if (cardData?.type && cardData?.invoiceNo) {
        invoicetrigger({ type: cardData?.type?.toLowerCase() });
        allInvoicetrigger({
          type: cardData?.type?.toLowerCase(),
          invoiceNo: cardData?.invoiceNo,
        });
      }
      setSelectedParty({
        label: cardData?.party?.name,
        value: cardData?.party?._id,
      });
      setSelectedDesigner({
        label: cardData?.designer?.name,
        value: cardData?.designer?._id,
      });
      if (cardData?.designer?.name && cardData?.designer?._id) {
        setSelectedPuncher({
          label: cardData?.puncher?.name,
          value: cardData?.puncher?._id,
        });
      } else {
        setSelectedDesigner({});
      }
      setSelectedCloth({
        label: cardData?.clothe?.name,
        value: cardData?.clothe?._id,
      });
      setSelectedClothParty({
        label: cardData?.clotheParty?.name,
        value: cardData?.clotheParty?._id,
      });
      setImages(cardData?.image?.URL);
      setSelectedItem({});
      setIsUpdate(false);
      setSpacing({
        top: cardData?.spacing?.top,
        right: cardData?.spacing?.right,
        bottom: cardData?.spacing?.bottom,
      });
    } else {
      // To set Today's Date as Default when adding Sample Card
      setDefaultDate();
    }
  }, [cardData]);

  useEffect(() => {
    setItemData({
      ...itemData,
      production: Number(itemData?.frame) * Number(itemData?.stitch),
    });
  }, [itemData?.frame, itemData?.stitch]);

  const handlepostImage = (image: any) => {
    imageUpload(image[0], (url: any) => {
      setImages(url);
    });
  };

  const [imageCopy, setImageCopy]: any = useState([]);
  const imageDelete = (imageFile: any) => {
    setImageCopy([...imageCopy, imageFile]);
    setImages("");
    // s3.deleteObject({
    //     Bucket: bucketName,
    //     Key: imageFile?.replace("https://cnt-satv.s3.ap-south-1.amazonaws.com/", "")
    // }, function (err: any, data: any) {
    //     if (data)
    //         setImages("")
    //     return data
    // })
  };

  const deleteImageOnSaveUpdate = () => {
    if (imageCopy?.length > 0) {
      imageCopy?.map((image: any) => {
        s3.deleteObject({
          Bucket: bucketName,
          Key: image?.replace(
            "https://cnt-satv.s3.ap-south-1.amazonaws.com/",
            ""
          ),
        });
      });
      setImageCopy([]);
    }
  };

  const imageUpload = async (imageFile: any, callback: any) => {
    const rawBytes = uuid();
    const imageName =
      rawBytes.toString() + "." + imageFile?.file?.name?.split(".")[1];

    const uploadURL = await s3.getSignedUrlPromise("putObject", {
      Bucket: bucketName,
      Key: "Sample-cards/" + imageName,
      Expires: 60,
    });

    await fetch(uploadURL, {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: imageFile.file,
    });

    const imageUrl = uploadURL.split("?")[0];
    callback(imageUrl);
  };

  const openInvoicePreview = () => {
    setShowSamplePreview(true);
  };

  const [isUpdating, setIsUpdating]: any = useState(false);
  const [isSaving, setIsSaving]: any = useState(false);
  const [isSaved, setIsSaved]: any = useState(false);
  const updateData = () => {
    let hasError: boolean = false;
    let errorData = {
      party: "",
      designNo: "",
      needle: "",
      designer: "",
      user: "",
    };
    if (Object.keys(selectedParty)?.length === 0) {
      errorData["party"] = "Party is Required";
      hasError = true;
    } else {
      errorData["party"] = "";
    }
    if (Object.keys(userType)?.length === 0) {
      errorData["user"] = "WorkType is Required";
      hasError = true;
    } else {
      errorData["user"] = "";
    }
    if (Object.keys(selectedDesigner)?.length === 0) {
      errorData["designer"] = "Designer is Required";
      hasError = true;
    } else {
      errorData["designer"] = "";
    }
    if (!cardData?.designNo) {
      errorData["designNo"] = "Design No is Required";
      hasError = true;
    } else {
      errorData["designNo"] = "";
    }
    if (needleData?.length === 0) {
      errorData["needle"] = "Needle is Required";
      hasError = true;
    } else {
      errorData["needle"] = "";
    }
    setErrorMessage(errorData);
    if (!hasError) {
      let TPPM = 0;
      needleData?.forEach((item: any) => {
        TPPM += Number(item.PM);
      });
      let extraWork: any = {};
      extraWork = {
        foilId: selectedFoil?.value || "",
      };
      let finalData = {
        partyId: selectedParty.value,
        designNo: cardData?.designNo
          ? type == "dress"
            ? "D-" + cardData?.designNo?.replace("D-", "")
            : type == "saree"
            ? "S-" + cardData?.designNo?.replace("S-", "")
            : "L-" + cardData?.designNo?.replace("L-", "")
          : "",
        date: cardData?.date,
        TPPM: TPPM,
        challanNo: cardData?.challanNo,
        clotheId: selectedCloth.value,
        clothePartyId: selectedClothParty?.value,
        puncherId: selectedPuncher?.value,
        designerId: selectedDesigner?.value,
        CM: cardData?.CM,
        CC: cardData?.CC,
        MC: cardData?.MC,
        notes: cardData.notes,
        height: cardData?.height,
        totalProduction: Number(totalProd),
        perDayProduction: cardData?.perDayProduction || 0,
        totalDays: Number(tdays),
        workType: userType?.value == "skechers" ? "skechers" : "image",
        image: {
          URL: images,
          right: imageSize?.right,
          top: imageSize?.top,
          bottom: imageSize?.bottom,
        },
        spacing: {
          top: spacing?.top,
          bottom: spacing?.bottom,
          right: spacing?.right,
        },
        needlesHead: tableHead,
        fabricColors: needleData,
        clothes: tableData,
        work: extraWork,
      };
      setIsUpdating(true);
      updateSampleCard({
        payload: finalData,
        query: {
          scId: id,
        },
      }).then((data: any) => {
        setIsUpdating(false);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Sample Card Successfully Updated !!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          allSampleTrigger({
            page: 1,
            limit: 20,
          });
          deleteImageOnSaveUpdate();
        }
      });
    }
  };
  const addData = () => {
    let hasError: boolean = false;
    let errorData = {
      party: "",
      designNo: "",
      needle: "",
      designer: "",
      user: "",
    };

    if (Object.keys(selectedParty)?.length === 0) {
      errorData["party"] = "Party is Required";
      hasError = true;
    } else {
      errorData["party"] = "";
    }
    if (Object.keys(userType)?.length === 0) {
      errorData["user"] = "WorkType is Required";
      hasError = true;
    } else {
      errorData["user"] = "";
    }
    if (Object.keys(selectedDesigner)?.length === 0) {
      errorData["designer"] = "Designer is Required";
      hasError = true;
    } else {
      errorData["designer"] = "";
    }
    if (!cardData?.designNo) {
      errorData["designNo"] = "Design No is Required";
      hasError = true;
    } else {
      errorData["designNo"] = "";
    }
    if (needleData?.length === 0) {
      errorData["needle"] = "Needle is Required";
      hasError = true;
    } else {
      errorData["needle"] = "";
    }
    setErrorMessage(errorData);
    if (!hasError) {
      let TPPM = 0;
      needleData?.forEach((item: any) => {
        TPPM += Number(item.PM);
      });
      let extraWork: any = {};
      extraWork = {
        foilId: selectedFoil?.value || "",
      };
      let finalData = {
        partyId: selectedParty.value,
        invoiceNo: selectedInvoice,
        cardNo: selectedInvoiceCard,
        // cardNoB: selectedCardNoB,
        TPPM: TPPM,
        designNo: cardData?.designNo
          ? type == "dress"
            ? "D-" + cardData?.designNo?.replace("D-", "")
            : type == "saree"
            ? "S-" + cardData?.designNo?.replace("S-", "")
            : "L-" + cardData?.designNo?.replace("L-", "")
          : "",
        date: cardData?.date,
        challanNo: cardData?.challanNo,
        clotheId: selectedCloth.value,
        clothePartyId: selectedClothParty?.value,
        puncherId: selectedPuncher?.value,
        designerId: selectedDesigner?.value,
        CM: cardData?.CM,
        notes: cardData?.notes,
        height: cardData?.height,
        totalProduction: Number(totalProd),
        perDayProduction: cardData?.perDayProduction || 0,
        totalDays: Number(tdays),
        workType: userType?.value == "skechers" ? "skechers" : "image",
        image: {
          URL: images,
          right: imageSize?.right,
          top: imageSize?.top,
          bottom: imageSize?.bottom,
        },
        type: type,
        spacing: {
          top: spacing?.top,
          bottom: spacing?.bottom,
          right: spacing?.right,
        },
        CC: cardData?.CC,
        MC: cardData?.MC,
        needlesHead: tableHead,
        fabricColors: needleData,
        clothes: tableData,
        work: extraWork,
      };
      setIsSaving(true);
      addSampleCard(finalData).then((data: any) => {
        setSavedId(data?.data?.data?.insertedIds[0]);
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Great",
            text: "Sample Card Successfully Added!!",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          });
          setIsSampleCopy(false);
          localStorage.setItem("copy", "");
          allSampleTrigger({
            page: 1,
            limit: 20,
          });
          setIsSaved(true);
          deleteImageOnSaveUpdate();
        }
        setIsSaving(false);
      });
    }
  };

  //#region Getting paginated records
  useEffect(() => {
    if (isgetItemSuccess && !isgetItemFetching) {
      let item = getItemResult.data?.data?.item.map(({ _id, name }: any) => {
        return {
          label: name,
          value: _id,
        };
      });
      if (item) {
        setItem(item);
      }
    }
  }, [isgetItemSuccess, isgetItemFetching]);

  useEffect(() => {
    if (isgetFabricColorSuccess && !isgetFabricColorFetching) {
      const color = getFabricColorResult.data?.data.fabricColor?.map(
        ({ _id, colorName }: any) => {
          return {
            label: colorName,
            value: _id,
          };
        }
      );
      if (color) {
        setFabric(color);
      }
    }
  }, [isgetFabricColorSuccess, isgetFabricColorFetching]);

  useEffect(() => {
    if (isgetClothSuccess && !isgetClothFetching) {
      let cloth = getClothResult.data?.data?.clothe.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (cloth) {
        setCloth(cloth);
      }
    }
  }, [isgetClothSuccess, isgetClothFetching]);

  useEffect(() => {
    if (isgetClothPartySuccess && !isgetClothPartyFetching) {
      let clothParty = getClothPartyResult.data?.data?.clotheParty.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (clothParty) {
        setClothParty(clothParty);
      }
    }
  }, [isgetClothPartySuccess, isgetClothPartyFetching]);

  useEffect(() => {
    if (isgetPartyNameSuccess && !isgetPartyNameFetching) {
      let party = getPartyNameResult.data?.data?.party.map(
        ({ _id, name }: any) => {
          return {
            label: name,
            value: _id,
          };
        }
      );
      if (party) {
        setParty(party);
      }
    }
  }, [isgetPartyNameSuccess, isgetPartyNameFetching]);
  //#endregion

  return (
    <div className="m-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl bg-themeBgColor pl-28 bg-cover overflow-hidden">
      <div className="mx-6 mt-24 lg:mt-0 xl:mt-0 fixed inset-y-0 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 xl:left-28 top-0 right-0 xl:translate-x-0 overflow-auto">
        <div className="invisible lg:visible xl:visible sticky top-5 z-90">
          <nav className="absolute sm:top-0 md:top-0 lg:top-0 left-0 right-0 flex flex-wrap items-center mb-0 shadow-lg rounded-xl bg-white lg:flex-nowrap lg:justify-start">
            <div className="flex gap-5 w-full justify-between items-center">
              <div className="px-4 py-8 sm:py-8 md:py-4 text-white md:w-auto rounded-l-xl bg-themeColor">
                <span className="text-2xl md:text-xl">Sample Card</span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 w-2/5">
                <div className="mr-6 mb-2 md:mb-0">
                  <Select
                    id="type"
                    name="type"
                    htmlFor="type"
                    label="Type"
                    disabled={isSampleCopy ? true : false}
                    options={["Select Type", "dress", "saree", "lump"]}
                    onChange={(e: any) => handleTypeChange(e)}
                    value={type}
                  />
                </div>
                <div className="mr-2">
                  <Select
                    id="invoice"
                    name="invoice"
                    htmlFor="invoice"
                    label="Card No."
                    options={invoice}
                    onChange={(e: any) => handleInvoices(e)}
                    value={selectedInvoice}
                  />
                </div>
                <div className="mr-2">
                  <Select
                    id="invoiceno"
                    name="invoiceno"
                    htmlFor="invoiceno"
                    label="Card No. B"
                    options={invoiceNo}
                    onChange={(e: any) => handleInvoiceChange(e)}
                    value={selectedInvoiceCard}
                  />
                </div>
                {/* <div>
                                    <Select
                                        id="cardNoB" name="cardNoB" htmlFor="cardNoB"
                                        label="Card No. B"
                                        options={cardNoB}
                                        disabled
                                        onChange={(e: any) => handleCardNoBChanges(e)}
                                        value={selectedCardNoB}
                                    />
                                </div> */}
              </div>
              <div className="px-3 w-2/5 flex justify-end">
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  disabled={showSamplePreview}
                  onClick={openInvoicePreview}
                >
                  <i className="fa fa-download"></i>
                  <span className="ml-2">Print</span>
                </button>
                {isSampleUpdate ? (
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={isUpdating}
                    onClick={updateData}
                  >
                    {isUpdating ? (
                      <i className="fa fa-spinner animate-spin"></i>
                    ) : (
                      <i className="fa fa-save"></i>
                    )}
                    <span className="ml-2">Update</span>
                  </button>
                ) : null}
                {!isSaved && !isSampleUpdate ? (
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={isSaving}
                    onClick={addData}
                  >
                    {isSaving ? (
                      <i className="fa fa-spinner animate-spin"></i>
                    ) : (
                      <i className="fa fa-save"></i>
                    )}
                    <span className="ml-2">Save</span>
                  </button>
                ) : null}
                <button
                  className="px-5 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  onClick={() => {
                    navigate(
                      `/samplecarddashboard${type ? `?type=${type}` : ""}`
                    );
                  }}
                >
                  <i className="fa fa-close"></i>
                  <span className="ml-2">Cancel</span>
                </button>
              </div>
            </div>
          </nav>
        </div>

        <div className="mx-6 xl:mx-0 lg:mt-0 xl:mt-0 fixed inset-y-0 mb-4 antialiased transition-transform duration-200 border-0 shadow-xl max-w-full rounded-2xl left-0 top-24 right-0 xl:translate-x-0 overflow-auto">
          <div className="w-full overflow-auto">
            <div className="w-full h-full rounded-xl bg-white overflow-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 pt-4 px-2 lg:p-0 xl:p-0 gap-2 lg:hidden visible xl:hidden lg:invisible xl:invisible">
                <div className="mb-2">
                  <Select
                    id="type"
                    name="type"
                    htmlFor="type"
                    label="Type"
                    options={["Select Type", "dress", "saree", "lump"]}
                    onChange={(e: any) => handleTypeChange(e)}
                    value={type}
                  />
                </div>
                <div className="mt-2 md:mt-0">
                  <Select
                    id="invoice"
                    name="invoice"
                    htmlFor="invoice"
                    label="Card No."
                    disabled={isSampleCopy ? true : false}
                    options={invoice}
                    onChange={(e: any) => handleInvoices(e)}
                    value={selectedInvoice}
                  />
                </div>
                <div className="mt-2 md:mt-0">
                  <Select
                    id="invoiceno"
                    name="invoiceno"
                    htmlFor="invoiceno"
                    label="Card No. B"
                    options={invoiceNo}
                    onChange={(e: any) => handleInvoiceChange(e)}
                  />
                </div>
              </div>

              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-7 p-2 pt-3 pb-0">
                <div>
                  <div className={`relative rounded-md`}>
                    <CreatableSelect
                      name="partyName"
                      classNamePrefix="customSelect"
                      filterOption={createFilter(selectFilterConfig)}
                      className={`block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0  z-70`}
                      classNames={{
                        control: () =>
                          errorMessage?.party
                            ? "border-red-600 peer-focus:border-red-500"
                            : "border-gray-500 ",
                      }}
                      onChange={(e: any) => {
                        if (e) {
                          handleChange(e, "party");
                          setErrorMessage({
                            ...errorMessage,
                            party: "",
                          });
                        } else {
                          setSelectedParty({});
                        }
                      }}
                      // onInputChange={(e: any) => {
                      //     if (e.length >= 1) {
                      //         getPartyNameTrigger(e)
                      //     }
                      //     if (e.length == 0) {
                      //         let getAllPartyName = result.data?.data?.party.map(({ _id, name }: any) => {
                      //             return {
                      //                 label: name,
                      //                 value: _id,
                      //             };
                      //         });
                      //         if (getAllPartyName) {
                      //             setParty(getAllPartyName)
                      //         }
                      //     }
                      // }}
                      onCreateOption={(data: any) => {
                        setPartyNameAdd(data);
                        setShowAddParty(true);
                      }}
                      options={Party && Party}
                      value={selectedParty}
                    />
                    <label
                      htmlFor="partyName"
                      className={`absolute z-80 text-md duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.party ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      Party Name
                    </label>
                  </div>
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.party}
                  </span>
                </div>

                <div>
                  <div className="relative">
                    <div className="rounded-md">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">
                          {type === "dress"
                            ? "D - "
                            : type === "saree"
                            ? "S - "
                            : type === "lump"
                            ? "L - "
                            : ""}
                        </span>
                      </div>
                      <input
                        type="number"
                        name="designNo"
                        id="designNo"
                        className={`block w-full py-1.5 pl-8 sm:text-sm sm:leading-6 first-line:block px-2.5 h-10 text-sm text-gray-900 bg-transparent rounded-lg border border-1 appearance-none focus:outline-none focus:ring-0  peer ${
                          errorMessage?.designNo
                            ? "border-red-500 focus:border-red-500"
                            : "border-gray-500 focus:border-themeColor"
                        }`}
                        placeholder=""
                        autoComplete="off"
                        value={cardData?.designNo?.split?.("-")?.[1]}
                        onChange={(e: any) => {
                          handleChanges(e);
                          setErrorMessage({
                            ...errorMessage,
                            designNo: "",
                          });
                        }}
                      />
                    </div>
                    <label
                      className={`absolute capitalize text-sm ${
                        errorMessage?.designNo
                          ? "text-red-500"
                          : "text-gray-500"
                      } duration-300 transform -translate-y-4 scale-75 top-1.5 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-1`}
                    >
                      Design No
                    </label>
                  </div>
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.designNo}
                  </span>
                </div>
                <TextBox
                  tabIndex={-1}
                  id="date"
                  type="date"
                  name="date"
                  htmlFor="date"
                  label="Date"
                  placeholder=" "
                  value={cardData?.date}
                  onChange={(e: any) => handleChanges(e)}
                />
                <TextBox
                  id="challanNo"
                  type="number"
                  name="challanNo"
                  htmlFor="challanNo"
                  label="Challan No"
                  placeholder=" "
                  value={cardData?.challanNo}
                  onChange={(e: any) => handleChanges(e)}
                />
                <div className="z-90 ">
                  <div className="relative rounded-md">
                    <ReactSelect
                      className="basic-single block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                      classNames={{
                        control: () =>
                          errorMessage?.user
                            ? "border-red-600 peer-focus:border-red-500"
                            : "border-gray-500 ",
                      }}
                      classNamePrefix="select"
                      value={userType}
                      id="user"
                      name="user"
                      onChange={(e: any) => {
                        setUserType(e);
                        setSelectedPuncher({});
                        setErrorMessage({
                          ...errorMessage,
                          user: "",
                        });
                        if (e.value === "image") {
                          imageEmployeeTrigger("image");
                        } else {
                          skechersEmployeeTrigger("skecher");
                        }
                      }}
                      options={[
                        { value: "skechers", label: "Skechers" },
                        { value: "image", label: "Image" },
                        { value: "sample pcs", label: "Sample Pcs" },
                      ]}
                    />
                    <span className="text-xs text-red-500 text-left">
                      {errorMessage?.user}
                    </span>
                    <label
                      htmlFor="user"
                      className={`absolute z-80 text-md duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                        errorMessage?.user ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      Work Type
                    </label>
                  </div>
                </div>
                {userType.value === "image" ? (
                  <div className="relative rounded-md">
                    <ReactSelect
                      className="block z-50 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                      classNamePrefix="select"
                      value={selectedPuncher}
                      id="image"
                      name="image"
                      isDisabled
                      onChange={(e: any) => {
                        setSelectedPuncher(e);
                      }}
                      options={skechersEmployee}
                    />
                    <label
                      htmlFor="image"
                      className={`absolute z-60 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                    >
                      Image
                    </label>
                  </div>
                ) : (
                  <div className="relative rounded-md">
                    <ReactSelect
                      className="basic-single block z-70 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer"
                      classNamePrefix="select"
                      isDisabled={userType.value === "sample pcs"}
                      value={selectedPuncher}
                      id="skechers"
                      name="skechers"
                      onChange={(e: any) => {
                        setSelectedPuncher(e);
                      }}
                      options={skechersEmployee}
                    />
                    <label
                      htmlFor="skechers"
                      className={`absolute z-70 text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                    >
                      Skechers
                    </label>
                  </div>
                )}
                <div className="relative rounded-md">
                  <ReactSelect
                    classNamePrefix="select"
                    value={selectedDesigner}
                    id="designer"
                    name="designer"
                    options={employee}
                    className={`basic-single block z-30 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor peer `}
                    classNames={{
                      control: () =>
                        errorMessage?.designer
                          ? "border-red-600 peer-focus:border-red-500"
                          : "border-gray-500 ",
                    }}
                    onChange={(e: any) => {
                      if (e) {
                        handleChange(e, "designer");
                        setErrorMessage({
                          ...errorMessage,
                          designer: "",
                        });
                      } else {
                        setSelectedDesigner({});
                      }
                    }}
                  />
                  <span className="text-xs text-red-500 text-left">
                    {errorMessage?.designer}
                  </span>
                  <label
                    htmlFor="designer"
                    className={`absolute z-80 text-md duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                      errorMessage?.designer ? "text-red-500" : "text-gray-500"
                    }`}
                  >
                    Designer
                  </label>
                </div>
              </div>

              {/* Niddle Configaration */}
              <fieldset
                className={`border-2 m-2 p-2 rounded-2 ${
                  errorMessage?.needle ? "border-red-500" : "border-gray-400"
                }`}
              >
                <legend
                  className={`capitalize px-2 ${
                    errorMessage?.needle ? "text-red-500" : ""
                  }`}
                >
                  Niddle Configuration
                </legend>
                <div className="grid grid-cols-1 lg:grid-cols-6">
                  <div className="text-center">
                    <div className="">
                      <div>Serial No</div>
                      <div>
                        <h1>{srNo}</h1>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-1">
                      <div className="relative rounded-md">
                        <CreatableSelect
                          name="fabricColor"
                          id="fabricColor"
                          classNamePrefix="customSelect"
                          filterOption={createFilter(selectFilterConfig)}
                          className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                          onChange={(e: any) => {
                            if (e) {
                              handleChange(e, "fabricColor");
                              setErrorMessage({
                                ...errorMessage,
                                fabricColor: "",
                                needle: "",
                              });
                            } else {
                              setSelectedFabric({});
                            }
                          }}
                          classNames={{
                            control: () =>
                              errorMessage?.fabricColor
                                ? "border-red-600"
                                : "border-grey-300",
                          }}
                          // onInputChange={(e: any) => {
                          //     if (e.length >= 1) {
                          //         getFabricColorTrigger(e)
                          //     }
                          //     if (e.length == 0) {
                          //         let getAllFabric = fabricResult.data?.data?.fabricColor?.map(({ _id, colorName }: any) => {
                          //             return {
                          //                 label: colorName,
                          //                 value: _id,
                          //             };
                          //         });
                          //         if (getAllFabric) {
                          //             setFabric(getAllFabric)
                          //         }
                          //     }
                          // }}
                          onCreateOption={(data: any) => {
                            setFabricAdd(data);
                            setShowAddFabric(true);
                          }}
                          options={Fabric}
                          value={selectedFabric}
                        />
                        <label
                          htmlFor="item"
                          className={`absolute text-md ${
                            errorMessage?.fabricColor
                              ? "text-red-500"
                              : "text-gray-500"
                          } duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                        >
                          {" "}
                          Fabric Color
                        </label>
                        <span className="text-xs text-red-500 flex justify-start">
                          {errorMessage?.fabricColor}
                        </span>
                      </div>
                      {type == "saree" ? null : (
                        <div className="px-0 mt-2 md:mt-0 lg:mt-2 md:px-2 lg:px-0">
                          <TextBox
                            id="PM"
                            name="PM"
                            htmlFor="PM"
                            type="number"
                            label="Pcs/ Mtr"
                            placeholder=" "
                            value={PM ? PM : ""}
                            onChange={(e: any) => {
                              setPM(e.target.value);
                            }}
                          />
                        </div>
                      )}

                      <div className="flex mt-2">
                        <div className="px-0 md:px-2 lg:px-0">
                          <TextBox
                            id="totalNeedles"
                            name="totalNeedles"
                            htmlFor="totalNeedles"
                            type="number"
                            label="Total Needles"
                            placeholder=" "
                            value={totalNeedles}
                            disabled={needlesAdded}
                            maxValue={18}
                            onChange={(e: any) => {
                              if (e.target.value > 18) {
                                Swal.fire({
                                  toast: true,
                                  icon: "warning",
                                  title: "Can't add More than 18 Needles !",
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: ERROR_MESSAGE_TIMEOUT,
                                  timerProgressBar: true,
                                });
                              } else {
                                setTotalNeedles(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="lg:w-auto ml-1">
                          <button
                            className={`w-full px-4 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md ${
                              needlesAdded ? "bg-gray-400" : "bg-themeColor"
                            }  bg-150 hover:shadow-xs hover:-translate-y-px`}
                            onClick={addMultipleNeedles}
                            disabled={needlesAdded}
                          >
                            {" "}
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full lg:col-span-4 px-6 mt-2 lg:mt-0 overflow-visible z-50">
                    <Carousel
                      containerProps={{
                        style: {
                          width: "100%",
                          justifyContent: "space-between",
                          userSelect: "none",
                        },
                      }}
                      innerProps={{
                        style: {
                          overflow: "unset",
                          overflowX: "clip",
                          overflowY: "visible",
                        },
                      }}
                      activeSlideIndex={activeSlide}
                      onRequestChange={setActiveSlide}
                      itemsToShow={1}
                      itemsToScroll={1}
                      forwardBtnProps={{
                        children: (
                          <i className="fas fa-angle-double-right text-black text-3xl cursor-pointer"></i>
                        ),
                      }}
                      backwardBtnProps={{
                        children: (
                          <i
                            tabIndex={1}
                            className="fas fa-angle-double-left text-black text-3xl cursor-pointer"
                          ></i>
                        ),
                      }}
                      responsiveProps={[
                        { minWidth: 1700, itemsToShow: 5 },
                        { minWidth: 1500, maxWidth: 1700, itemsToShow: 4 },
                        { minWidth: 1024, maxWidth: 1500, itemsToShow: 3 },
                        { minWidth: 500, maxWidth: 1024, itemsToShow: 2 },
                      ]}
                      infinite={false}
                      speed={400}
                      easing="linear"
                    >
                      {components.map((item: any, index: number) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </Carousel>
                  </div>

                  <div className="flex justify-center lg:flex-col flex-row">
                    <div className="mr-2 mt-3 flex gap-2">
                      {components.length < 18 ? (
                        <div className="w-full">
                          <button
                            tabIndex={-1}
                            className="w-full px-2 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                            onClick={addNeedle}
                          >
                            N{components.length + 1}{" "}
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      ) : null}
                      {components.length > 0 ? (
                        <div className="w-full">
                          <button
                            tabIndex={-1}
                            className="w-full px-2 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                            onClick={removeNeedle}
                          >
                            N{components.length}{" "}
                            <i className="fas fa-minus" aria-hidden="true"></i>
                          </button>
                        </div>
                      ) : null}
                    </div>
                    {needleUpdate ? (
                      <div className="md:w-1/3 lg:w-auto mr-2 mt-3">
                        <button
                          className="w-full px-5 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                          onClick={updateRecord}
                        >
                          {" "}
                          Update <i className="fas fa-pen-nib"></i>
                        </button>
                      </div>
                    ) : (
                      <div className="md:w-1/3 lg:w-auto mr-2 mt-3">
                        <button
                          className="w-full px-5 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                          onClick={addRecord}
                        >
                          {" "}
                          Add <i className="fas fa-fill-drip"></i>
                        </button>
                      </div>
                    )}

                    <div className="md:w-1/3 lg:w-auto mr-2 mt-3">
                      <button
                        tabIndex={-1}
                        className="w-full px-5 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                        onClick={() => {
                          setNeedlesAdded(false);
                          setComponents([]);
                          setPM("");
                          setNeedleUpdate(false);
                          setSelectedFabric({});
                          setSrNo(needleData?.length + 1);
                          needleArray = [];
                        }}
                      >
                        Clear <i className="fas fa-eraser"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <span className="text-xs text-red-500 text-left">
                  {errorMessage?.needle}
                </span>
              </fieldset>

              {/* Niddle Table */}
              <div className="grid grid-cols-1 px-2">
                <div className="border border-gray-300 rounded-3 overflow-hidden">
                  <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="overflow-hidden">
                        <div className="p-0 overflow-hidden">
                          {needleData && needleData.length > 0 ? (
                            <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                              <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 font-bold text-gray-900"
                                    >
                                      Sr No.
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 font-bold text-gray-900"
                                    >
                                      Fabric Color
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 font-bold text-gray-900"
                                    >
                                      Pcs/ Mtr
                                    </th>
                                    {tableHead?.map(
                                      (needle: any, index: number) => {
                                        return (
                                          <th
                                            key={index}
                                            scope="col"
                                            className="px-6 font-bold text-gray-900"
                                          >
                                            <div className="text-center capitalize">
                                              {needle?.number}
                                              <div>
                                                {needle?.designPattern == "seq"
                                                  ? "sequence"
                                                  : needle?.designPattern}
                                              </div>
                                              <div>{needle?.threadType}</div>
                                              {/* <div>
                                                {needle?.totalWeight ? (
                                                  <>
                                                    {needle?.totalWeight}{" "}
                                                    <span>/</span>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {needle?.totalUsed
                                                  ? needle?.totalUsed
                                                  : ""}
                                              </div>
                                              <div>
                                                {needle?.totalWeight
                                                  ? Math.floor(
                                                      needle?.totalWeight
                                                    ) - needle?.totalUsed
                                                  : ""}
                                              </div> */}
                                            </div>
                                          </th>
                                        );
                                      }
                                    )}
                                    <th
                                      scope="col"
                                      className="px-6 font-bold text-center text-gray-900"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                  {needleData?.map(
                                    (fabric: any, index: number) => {
                                      return (
                                        <tr
                                          key={index}
                                          className="hover:bg-gray-50"
                                        >
                                          <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                            {index + 1}
                                          </td>
                                          <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                            {fabricState[fabric.fabricColorId]}
                                          </td>
                                          <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                            {fabric.PM}
                                          </td>
                                          {fabric?.needles?.map(
                                            (needle: any, index: number) => {
                                              return (
                                                <td
                                                  key={index}
                                                  className="gap-3 px-6 py-2 font-normal text-gray-900"
                                                >
                                                  {codes[needle.designCodeId1]}
                                                  {codes[
                                                    needle.designCodeId2
                                                  ] &&
                                                    ", " +
                                                      codes[
                                                        needle.designCodeId2
                                                      ]}
                                                </td>
                                              );
                                            }
                                          )}
                                          {/* Adding Empty cells */}
                                          {Array(
                                            tableHead.length -
                                              fabric.needles.length
                                          )
                                            .fill("-")
                                            .map((row: any, index: number) => {
                                              return (
                                                <td
                                                  key={index}
                                                  className="gap-3 px-6 py-2 font-normal text-gray-900"
                                                >
                                                  {row}
                                                </td>
                                              );
                                            })}
                                          <td>
                                            <div>
                                              <button
                                                className="m-2"
                                                type="button"
                                                onClick={() => {
                                                  setNeedlesAdded(true);
                                                  needleArray = [];
                                                  setSrNo(index + 1);
                                                  setSelectedNeedle(fabric);
                                                  setSelectedFabric({
                                                    value: fabric.fabricColorId,
                                                    label:
                                                      fabricState[
                                                        fabric.fabricColorId
                                                      ],
                                                  });
                                                  setPM(fabric.PM);
                                                  setNeedleUpdate(true);
                                                  setComponents([]);
                                                  const needlesData: any =
                                                    fabric.needles;
                                                  let headData: any = tableHead;
                                                  const componentRender = [];
                                                  for (
                                                    let i = 0;
                                                    i < needlesData.length;
                                                    i++
                                                  ) {
                                                    let obj1 = {
                                                      _id: needlesData[i]
                                                        .designCodeId1,
                                                      designCode:
                                                        codes[
                                                          needlesData[i]
                                                            .designCodeId1
                                                        ],
                                                    };

                                                    let obj2 = {
                                                      _id: needlesData[i]
                                                        .designCodeId2,
                                                      designCode:
                                                        codes[
                                                          needlesData[i]
                                                            .designCodeId2
                                                        ],
                                                    };
                                                    componentRender.push(
                                                      <Needles
                                                        number={needlesData[
                                                          i
                                                        ].number.replace(
                                                          "N",
                                                          ""
                                                        )}
                                                        test={test}
                                                        patt={
                                                          headData[i]
                                                            .designPattern
                                                        }
                                                        thr={
                                                          headData[i].threadType
                                                        }
                                                        totalWeight={
                                                          headData[i]
                                                            ?.totalWeight || ""
                                                        }
                                                        totalUsed={
                                                          headData[i]
                                                            ?.totalUsed || ""
                                                        }
                                                        totalWeight1={
                                                          headData[i]
                                                            ?.totalWeight1 || ""
                                                        }
                                                        totalUsed1={
                                                          headData[i]
                                                            ?.totalUsed1 || ""
                                                        }
                                                        code1={obj1}
                                                        code2={
                                                          needlesData[i]
                                                            ?.designCodeId2
                                                            ? obj2
                                                            : {}
                                                        }
                                                        disable={
                                                          needleData.length <= 1
                                                            ? false
                                                            : tableHead?.[i]
                                                            ? true
                                                            : false
                                                        }
                                                        twoCodes={
                                                          tableHead?.[i]
                                                            ?.threadType ==
                                                            "1+1" ||
                                                          tableHead?.[i]
                                                            ?.threadType ==
                                                            "2+2"
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                    );
                                                  }
                                                  setComponents([
                                                    ...componentRender,
                                                  ]);
                                                }}
                                              >
                                                <i className="fas fa-pen hover:text-green-600"></i>
                                              </button>
                                              <button
                                                className="m-2"
                                                onClick={() => {
                                                  Swal.fire({
                                                    title: "Are you sure?",
                                                    text: "Once deleted, you will not be able to recover this record!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor:
                                                      "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Delete",
                                                  }).then((willDelete) => {
                                                    if (
                                                      willDelete.isConfirmed
                                                    ) {
                                                      let newData =
                                                        needleData.filter(
                                                          (item: any) => {
                                                            return (
                                                              item._id !==
                                                              fabric._id
                                                            );
                                                          }
                                                        );
                                                      setNeedleData(newData);
                                                      if (
                                                        newData?.length == 0
                                                      ) {
                                                        setTableHead([]);
                                                        setNeedlesAdded(false);
                                                      } else {
                                                        if (
                                                          fabric.needles
                                                            .length ==
                                                          tableHead.length
                                                        ) {
                                                          let lengths: any = [];
                                                          newData?.map(
                                                            (item: any) => {
                                                              lengths.push(
                                                                item?.needles
                                                                  ?.length
                                                              );
                                                            }
                                                          );
                                                          let maxValue: any =
                                                            Math.max(
                                                              ...lengths
                                                            );
                                                          if (
                                                            maxValue ==
                                                            tableHead?.length
                                                          ) {
                                                            setTableHead(
                                                              tableHead
                                                            );
                                                          } else {
                                                            let newHead =
                                                              tableHead.slice(
                                                                0,
                                                                maxValue
                                                              );
                                                            setTableHead(
                                                              newHead
                                                            );
                                                          }
                                                        }
                                                      }
                                                      setSrNo(
                                                        needleData?.length
                                                      );
                                                      setComponents([]);
                                                      setPM("");
                                                      setSelectedFabric({});
                                                      setNeedleUpdate(false);
                                                      needleArray = [];
                                                    }
                                                  });
                                                }}
                                              >
                                                <i className="fas fa-trash hover:text-red-600"></i>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="text-center py-3 text-gray-600 font-bold">
                              No Niddles Found
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Cloths Configuration */}
              <div className="grid grid-cols-1 lg:grid-cols-5 xl:grid-cols-5">
                <div className="col-span-4">
                  <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 pl-2 pr-2 md:pr-0">
                    <div className="col-span-4">
                      <div>
                        <fieldset className="border-2 mt-2 rounded-2 border-gray-400 p-2">
                          <legend className="capitalize px-2">
                            Cloths Configuration
                          </legend>
                          <div className="grid gap-4 grid-cols-1 xl:grid-cols-5">
                            <div>
                              <div className="relative rounded-md">
                                <CreatableSelect
                                  name="cloth"
                                  classNamePrefix="customSelect"
                                  filterOption={createFilter(
                                    selectFilterConfig
                                  )}
                                  className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-themeColor"
                                  onChange={(e: any) => {
                                    if (e) {
                                      handleChange(e, "cloth");
                                    } else {
                                      setSelectedCloth({});
                                    }
                                  }}
                                  classNames={{
                                    control: () =>
                                      errorMessage?.cloth
                                        ? "border-red-600"
                                        : "border-gray-500",
                                  }}
                                  // onInputChange={(e: any) => {
                                  //     if (e.length >= 1) {
                                  //         getClothTrigger(e)
                                  //     }
                                  //     if (e.length == 0) {
                                  //         let getAllCloth = clothResult.data?.data?.clothe?.map(({ _id, name }: any) => {
                                  //             return {
                                  //                 label: name,
                                  //                 value: _id,
                                  //             };
                                  //         });
                                  //         if (getAllCloth) {
                                  //             setCloth(getAllCloth)
                                  //         }
                                  //     }
                                  // }}
                                  onCreateOption={(data: any) => {
                                    setClothAdd(data);
                                    setShowAddCloth(true);
                                  }}
                                  options={Cloth}
                                  value={selectedCloth}
                                />
                                <label
                                  htmlFor="cloth"
                                  className={`absolute text-md text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                                >
                                  {" "}
                                  Fabric
                                </label>
                                <span className="text-xs text-red-500 text-left">
                                  {errorMessage?.cloth}
                                </span>
                              </div>
                            </div>
                            <div>
                              <div className="relative rounded-md">
                                <CreatableSelect
                                  name="clothParty"
                                  classNamePrefix="customSelect"
                                  filterOption={createFilter(
                                    selectFilterConfig
                                  )}
                                  className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-themeColor"
                                  classNames={{
                                    control: () =>
                                      errorMessage?.clothParty
                                        ? "border-red-600"
                                        : "border-gray-500",
                                  }}
                                  onChange={(e: any) => {
                                    if (e) {
                                      handleChange(e, "clothParty");
                                    } else {
                                      setSelectedClothParty({});
                                    }
                                  }}
                                  // onInputChange={(e: any) => {
                                  //     if (e.length >= 1) {
                                  //         getClothPartyTrigger(e)
                                  //     }
                                  //     if (e.length == 0) {
                                  //         let getAllClothParty = clothPartyResult.data?.data?.clotheParty.map(({ _id, name }: any) => {
                                  //             return {
                                  //                 label: name,
                                  //                 value: _id,
                                  //             };
                                  //         });

                                  //         if (getAllClothParty) {
                                  //             setClothParty(getAllClothParty)
                                  //         }
                                  //     }
                                  // }}
                                  onCreateOption={(data: any) => {
                                    setClothPartyAdd(data);
                                    setShowAddClothParty(true);
                                  }}
                                  options={clothParty}
                                  value={selectedClothParty}
                                />
                                <label
                                  htmlFor="clothParty"
                                  className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4`}
                                >
                                  Fabric Party
                                </label>
                                <span className="text-xs text-red-500 text-left">
                                  {errorMessage?.clothParty}
                                </span>
                              </div>
                            </div>
                            <div>
                              <TextBox
                                htmlFor="CM"
                                id="CM"
                                name="CM"
                                label="Cut/ Mtr"
                                type="number"
                                placeholder=" "
                                value={cardData?.CM}
                                className={`px-0 ${
                                  errorMessage?.CM ? "border-red-500" : ""
                                }`}
                                onChange={(e: any) => handleChanges(e)}
                              />
                              <span className="text-xs text-red-500 text-left">
                                {errorMessage?.CM}
                              </span>
                            </div>
                            <div>
                              <TextBox
                                id="height"
                                name="height"
                                htmlFor="height"
                                label="Height"
                                placeholder=" "
                                type="number"
                                value={cardData?.height}
                                className={`px-0 ${
                                  errorMessage?.height ? "border-red-500" : ""
                                }`}
                                onChange={(e: any) => handleChanges(e)}
                              />
                              <span className="text-xs text-red-500 text-left">
                                {errorMessage?.height}
                              </span>
                            </div>
                            <div className="relative rounded-md">
                              <CreatableSelect
                                name="foilColor"
                                classNamePrefix="customSelect"
                                filterOption={createFilter(selectFilterConfig)}
                                className="block text-left w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
                                onChange={(e: any) => {
                                  if (e) {
                                    handleChange(e, "foilColor");
                                  } else {
                                    setSelectedFoil({});
                                  }
                                }}
                                isClearable={true}
                                // onInputChange={(e: any) => {
                                //     if (e.length >= 1) {
                                //         getFoilColorTrigger(e)
                                //     }
                                //     if (e.length == 0) {
                                //         let getAllFoil = foilResult?.data?.data?.foilColor?.map(({ _id, name }: any) => {
                                //             return {
                                //                 label: name,
                                //                 value: _id,
                                //             };
                                //         });
                                //         if (getAllFoil) {
                                //             setFoil(getAllFoil)
                                //         }
                                //     }
                                // }}
                                onCreateOption={(data: any) => {
                                  setFoilAdd(data);
                                  setShowAddFoil(true);
                                }}
                                options={Foil}
                                value={selectedFoil}
                                menuPlacement="top"
                              />
                              <label
                                htmlFor="foilColor"
                                className={`absolute text-md text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 capitalize`}
                              >
                                foil color
                              </label>
                            </div>
                            {/* Item Configuration */}
                            <fieldset className="border-2 rounded-2 border-gray-400 p-2 lg:col-span-5">
                              <legend className="capitalize px-2">
                                Item Configuration
                              </legend>
                              <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
                                <div>
                                  <TextBox
                                    id="area"
                                    name="area"
                                    htmlFor="area"
                                    label="Area"
                                    placeholder=" "
                                    hasError={errorMessage?.area}
                                    onChange={(e: any) => handleItemChanges(e)}
                                    value={itemData?.area || ""}
                                  />
                                  <span className="text-xs text-red-500 text-left">
                                    {errorMessage?.area}
                                  </span>
                                </div>
                                <div>
                                  <TextBox
                                    id="head"
                                    name="head"
                                    htmlFor="head"
                                    label="Head"
                                    placeholder=" "
                                    type="number"
                                    onChange={(e: any) => handleItemChanges(e)}
                                    hasError={errorMessage?.head}
                                    className={`px-0 ${
                                      errorMessage?.head
                                        ? "border-red-500 focus:border-red-500"
                                        : "focus:border-themeColor"
                                    }`}
                                    labelClassName={`${
                                      errorMessage?.head
                                        ? "text-red-500 peer-focus:text-red-500"
                                        : "text-gray-500"
                                    }`}
                                    value={itemData?.head || ""}
                                  />
                                  <span className="text-xs text-red-500 text-left">
                                    {errorMessage?.head}
                                  </span>
                                </div>
                                <div className="relative rounded-md">
                                  <CreatableSelect
                                    name="item"
                                    classNamePrefix="customSelect"
                                    filterOption={createFilter(
                                      selectFilterConfig
                                    )}
                                    className="block w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none   focus:outline-none focus:ring-0 "
                                    onChange={(e: any) => {
                                      if (e) {
                                        handleChange(e, "item");
                                        setErrorMessage({
                                          ...errorMessage,
                                          item: "",
                                        });
                                      } else {
                                        setSelectedItem({});
                                      }
                                    }}
                                    classNames={{
                                      control: () =>
                                        errorMessage?.item
                                          ? "border-red-500 focus:border-red-500"
                                          : "border-gray-500 focus:border-themeColor",
                                    }}
                                    // onInputChange={(e: any) => {
                                    //     if (e.length >= 1) {
                                    //         getItemTrigger(e)
                                    //     }
                                    //     if (e.length == 0) {
                                    //         let getAllItem = itemResult.data?.data?.item.map(({ _id, name }: any) => {
                                    //             return {
                                    //                 label: name,
                                    //                 value: _id,
                                    //             };
                                    //         });
                                    //         if (getAllItem) {
                                    //             setItem(getAllItem)
                                    //         }
                                    //     }
                                    // }}
                                    onCreateOption={(data: any) => {
                                      setItemAdd(data);
                                      setShowAddItem(true);
                                    }}
                                    options={Item}
                                    value={selectedItem}
                                  />
                                  <label
                                    htmlFor="item"
                                    className={`absolute text-md text-gray-500  duration-300 transform -translate-y-4 scale-75 top-1 left-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-themeColor peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 ${
                                      errorMessage?.item
                                        ? "text-red-500"
                                        : "text-gray-500"
                                    }`}
                                  >
                                    {" "}
                                    Item
                                  </label>
                                  <span className="text-xs text-red-500 text-left">
                                    {errorMessage?.item}
                                  </span>
                                </div>
                                <div>
                                  <TextBox
                                    id="stitch"
                                    name="stitch"
                                    htmlFor="stitch"
                                    label="Stitch"
                                    placeholder=" "
                                    type="number"
                                    className={`px-0 ${
                                      errorMessage?.stitch
                                        ? "border-red-500 focus:border-red-500"
                                        : "focus:border-themeColor"
                                    }`}
                                    hasError={errorMessage?.stitch}
                                    labelClassName={`${
                                      errorMessage?.stitch
                                        ? "text-red-500 peer-focus:text-red-500"
                                        : "text-gray-500"
                                    }`}
                                    onChange={(e: any) => handleItemChanges(e)}
                                    value={itemData?.stitch || ""}
                                  />
                                  <span className="text-xs text-red-500 text-left">
                                    {errorMessage?.stitch}
                                  </span>
                                </div>
                                <div>
                                  <TextBox
                                    id="frame"
                                    name="frame"
                                    htmlFor="frame"
                                    label="Frame"
                                    placeholder=" "
                                    type="number"
                                    className={`px-0 ${
                                      errorMessage?.frame
                                        ? "border-red-500 focus:border-red-500"
                                        : "focus:border-themeColor"
                                    }`}
                                    hasError={errorMessage?.frame}
                                    labelClassName={`${
                                      errorMessage?.frame
                                        ? "text-red-500 peer-focus:text-red-500"
                                        : "text-gray-500"
                                    }`}
                                    onChange={(e: any) => handleItemChanges(e)}
                                    value={itemData?.frame || ""}
                                  />
                                  <span className="text-xs text-red-500 text-left">
                                    {errorMessage?.frame}
                                  </span>
                                </div>
                                <div>
                                  <TextBox
                                    htmlFor="CC"
                                    className={`px-0 ${
                                      errorMessage?.CC
                                        ? "border-red-500 focus:border-red-500"
                                        : "focus:border-themeColor"
                                    }`}
                                    hasError={errorMessage?.CC}
                                    labelClassName={`${
                                      errorMessage?.frame
                                        ? "text-red-500 peer-focus:text-red-500"
                                        : "text-gray-500"
                                    }`}
                                    id="CC"
                                    name="CC"
                                    label="Cutter"
                                    placeholder=" "
                                    value={itemData?.CC || ""}
                                    type="text"
                                    onChange={(e: any) => handleItemChanges(e)}
                                  />
                                  <span className="text-xs text-red-500 text-left">
                                    {errorMessage?.CC}
                                  </span>
                                </div>
                                <TextBox
                                  id="production"
                                  name="production"
                                  htmlFor="production"
                                  label="Production"
                                  placeholder=" "
                                  disabled
                                  type="number"
                                  onChange={(e: any) => handleItemChanges(e)}
                                  value={
                                    Number(itemData?.frame) *
                                    Number(itemData?.stitch)
                                  }
                                />

                                <div className="col-span-1 md:col-span-1">
                                  <button
                                    className="w-full px-8 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                                    onClick={() => {
                                      if (isUpdate) {
                                        let day: any =
                                          cardData?.perDayProduction
                                            ? Math.ceil(
                                                Number(itemData?.production) /
                                                  Number(
                                                    cardData?.perDayProduction
                                                  )
                                              )
                                            : "0";

                                        var item: any = {
                                          srNo: itemData?.srNo,
                                          area: itemData?.area,
                                          itemId: selectedItem.value
                                            ? selectedItem.value
                                            : "",
                                          stitch: itemData?.stitch,
                                          head: itemData?.head,
                                          frame: itemData?.frame,
                                          CC: itemData?.CC,
                                          production: itemData?.production,
                                          day: day || "0",
                                        };
                                        let errorData = {
                                          item: "",
                                          area: "",
                                          stitch: "",
                                          head: "",
                                          frame: "",
                                          CC: "",
                                        };
                                        if (
                                          !itemData?.area ||
                                          itemData?.area == ""
                                        ) {
                                          errorData["area"] =
                                            "Area is Required";
                                        } else errorData["area"] = "";
                                        if (
                                          !itemData?.stitch ||
                                          itemData?.stitch == ""
                                        ) {
                                          errorData["stitch"] =
                                            "Stitch is Required";
                                        } else errorData["stitch"] = "";
                                        if (
                                          !itemData?.head ||
                                          itemData?.head == ""
                                        ) {
                                          errorData["head"] =
                                            "Head is Required";
                                        } else errorData["head"] = "";
                                        if (
                                          !itemData?.frame ||
                                          itemData?.frame == ""
                                        ) {
                                          errorData["frame"] =
                                            "Frame is Required";
                                        } else errorData["frame"] = "";
                                        if (
                                          !itemData?.CC ||
                                          itemData?.CC == ""
                                        ) {
                                          errorData["CC"] =
                                            "Cutter is Required";
                                        } else errorData["CC"] = "";
                                        if (
                                          Object.keys(selectedItem).length == 0
                                        ) {
                                          errorData["item"] =
                                            "Item is Required";
                                        } else errorData["item"] = "";
                                        setErrorMessage(errorData);
                                        const result: any =
                                          itemValidation(item);
                                        if (result === true) {
                                          const elementsIndex =
                                            tableData.findIndex(
                                              (element: any) =>
                                                element.srNo == itemData.srNo
                                            );
                                          let newArray = [...tableData];

                                          newArray[elementsIndex] = {
                                            ...newArray[elementsIndex],
                                            ...item,
                                          };
                                          setItemData({
                                            srNo: "",
                                            area: "",
                                            itemId: "",
                                            frame: "",
                                            head: "",
                                            stitch: "",
                                            production: 0,
                                            CC: "",
                                            day: "",
                                          });
                                          setSelectedItem({});
                                          setIsUpdate(false);
                                          setTableData(newArray);
                                        } else {
                                          Swal.fire({
                                            toast: true,
                                            icon: "error",
                                            title: result.errorMessage,
                                            position: "top-end",
                                            showConfirmButton: false,
                                            timer: ERROR_MESSAGE_TIMEOUT,
                                            timerProgressBar: true,
                                          });
                                        }
                                      } else {
                                        let day: any =
                                          cardData?.perDayProduction
                                            ? Math.ceil(
                                                Number(itemData?.production) /
                                                  Number(
                                                    cardData?.perDayProduction
                                                  )
                                              )
                                            : "0";
                                        var newCloth = {
                                          srNo: Math.floor(
                                            Math.random() * 4000000 + 1
                                          ),
                                          area: itemData?.area,
                                          itemId: selectedItem.value
                                            ? selectedItem.value
                                            : "",
                                          stitch: itemData?.stitch,
                                          head: itemData?.head,
                                          frame: itemData?.frame,
                                          CC: itemData?.CC,
                                          production: itemData?.production,
                                          day: day || "0",
                                        };
                                        let errorData = {
                                          item: "",
                                          area: "",
                                          stitch: "",
                                          head: "",
                                          frame: "",
                                          CC: "",
                                        };
                                        if (
                                          !itemData?.area ||
                                          itemData?.area == ""
                                        ) {
                                          errorData["area"] =
                                            "Area is Required";
                                        } else errorData["area"] = "";
                                        if (
                                          !itemData?.stitch ||
                                          itemData?.stitch == ""
                                        ) {
                                          errorData["stitch"] =
                                            "Stitch is Required";
                                        } else errorData["stitch"] = "";
                                        if (
                                          !itemData?.head ||
                                          itemData?.head == ""
                                        ) {
                                          errorData["head"] =
                                            "Head is Required";
                                        } else errorData["head"] = "";
                                        if (
                                          !itemData?.frame ||
                                          itemData?.frame == ""
                                        ) {
                                          errorData["frame"] =
                                            "Frame is Required";
                                        } else errorData["frame"] = "";
                                        if (
                                          !itemData?.CC ||
                                          itemData?.CC == ""
                                        ) {
                                          errorData["CC"] =
                                            "Cutter is Required";
                                        } else errorData["CC"] = "";
                                        if (
                                          Object.keys(selectedItem).length == 0
                                        ) {
                                          errorData["item"] =
                                            "Item is Required";
                                        } else errorData["item"] = "";
                                        setErrorMessage(errorData);
                                        const result: any =
                                          itemValidation(newCloth);
                                        if (result === true) {
                                          setTableData([
                                            ...tableData,
                                            newCloth,
                                          ]);

                                          setItemData({
                                            srNo: "",
                                            area: "",
                                            itemId: "",
                                            frame: "",
                                            head: "",
                                            stitch: "",
                                            production: 0,
                                            day: "",
                                            CC: "",
                                          });
                                          setSelectedItem({});
                                          setIsUpdate(false);
                                        } else {
                                          Swal.fire({
                                            toast: true,
                                            icon: "error",
                                            title: result.errorMessage,
                                            position: "top-end",
                                            showConfirmButton: false,
                                            timer: ERROR_MESSAGE_TIMEOUT,
                                            timerProgressBar: true,
                                          });
                                        }
                                      }
                                    }}
                                  >
                                    {isUpdate ? "Update" : "Add"}
                                  </button>
                                </div>
                              </div>
                            </fieldset>
                            {/*Items Table */}
                            <div className="col-span-1 lg:col-span-5">
                              <div className="border border-gray-300 rounded-3 overflow-hidden">
                                <div className="flex flex-col overflow-auto sm:px-6 lg:px-8">
                                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="overflow-hidden">
                                      <div className="p-0 overflow-hidden capitalize">
                                        {tableData && tableData?.length > 0 ? (
                                          <div className="max-h-80 overflow-auto rounded-lg border border-gray-200 shadow-md">
                                            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                              <thead className="bg-gray-50">
                                                <tr>
                                                  <th
                                                    scope="col"
                                                    className="px-6 py-2 font-bold text-gray-900"
                                                  >
                                                    area
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-gray-900"
                                                  >
                                                    item
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-gray-900"
                                                  >
                                                    stitch
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-gray-900"
                                                  >
                                                    head
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-gray-900"
                                                  >
                                                    frame
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-gray-900"
                                                  >
                                                    cutter
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-gray-900"
                                                  >
                                                    production
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-gray-900"
                                                  >
                                                    days
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    className="px-6 font-bold text-gray-900"
                                                  >
                                                    action
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody className="divide-y text-center divide-gray-100 border-t border-gray-100">
                                                {tableData?.map(
                                                  (
                                                    item: any,
                                                    index: number
                                                  ) => {
                                                    return (
                                                      <tr
                                                        key={index}
                                                        className="hover:bg-gray-50"
                                                      >
                                                        <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                          {item?.area}
                                                        </td>
                                                        <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                          {
                                                            itemState[
                                                              item?.itemId
                                                            ]
                                                          }
                                                        </td>
                                                        <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                          {item?.stitch}
                                                        </td>
                                                        <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                          {item?.head}
                                                        </td>
                                                        <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                          {item?.frame}
                                                        </td>
                                                        <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                          {item?.CC}
                                                        </td>
                                                        <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                          {item?.production}
                                                        </td>
                                                        <td className="gap-3 px-6 py-2 font-normal text-gray-900 text-left">
                                                          {item?.day}
                                                        </td>
                                                        <td>
                                                          <div>
                                                            <button
                                                              className="m-2"
                                                              type="button"
                                                              onClick={() => {
                                                                setItemData({
                                                                  srNo: item?.srNo,
                                                                  area: item?.area,
                                                                  itemId:
                                                                    item?.itemId,
                                                                  stitch:
                                                                    item?.stitch,
                                                                  head: item?.head,
                                                                  frame:
                                                                    item?.frame,
                                                                  CC: item?.CC,
                                                                  production:
                                                                    item?.production,
                                                                  day: item?.days,
                                                                });
                                                                setSelectedItem(
                                                                  {
                                                                    label:
                                                                      itemState[
                                                                        item
                                                                          .itemId
                                                                      ],
                                                                    value:
                                                                      item.itemId,
                                                                  }
                                                                );
                                                                setIsUpdate(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <i className="fas fa-pen hover:text-green-600"></i>
                                                            </button>
                                                            <button
                                                              className="m-2"
                                                              onClick={() => {
                                                                Swal.fire({
                                                                  title:
                                                                    "Are you sure?",
                                                                  text: "Once deleted, you will not be able to recover this record!",
                                                                  icon: "warning",
                                                                  showCancelButton:
                                                                    true,
                                                                  confirmButtonColor:
                                                                    "#3085d6",
                                                                  cancelButtonColor:
                                                                    "#d33",
                                                                  confirmButtonText:
                                                                    "Delete",
                                                                }).then(
                                                                  (
                                                                    willDelete
                                                                  ) => {
                                                                    if (
                                                                      willDelete.isConfirmed
                                                                    ) {
                                                                      let newData =
                                                                        tableData.filter(
                                                                          (
                                                                            card: any
                                                                          ) => {
                                                                            return (
                                                                              item.srNo !==
                                                                              card.srNo
                                                                            );
                                                                          }
                                                                        );

                                                                      setTableData(
                                                                        newData
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <i className="fas fa-trash hover:text-red-600"></i>
                                                            </button>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        ) : (
                                          <div className="text-center py-3 text-gray-600 font-bold">
                                            No Item Found
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>

                  {/* Production Configuration */}
                  <div className="pb-1">
                    <fieldset className="border-2 mt-2 rounded-2 border-gray-400 mx-2 p-2">
                      <legend className="capitalize px-2">
                        Production Configuration
                      </legend>
                      {/* <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
                                                <TextBox
                                                    htmlFor="totalProduction"
                                                    id="totalProduction"
                                                    name="totalProduction"
                                                    label="Total Production"
                                                    type="number"
                                                    placeholder=" "
                                                    disabled
                                                    value={totalProd}
                                                    onChange={(e: any) => handleChanges(e)} />
                                                <TextBox
                                                    htmlFor="perDayProduction" id="perDayProduction" name="perDayProduction" label="Per Day Production" type="number" placeholder=" " value={cardData?.perDayProduction}
                                                    onChange={(e: any) => handleChanges(e)} />
                                                <TextBox
                                                    id="totalDays" name="totalDays" htmlFor="totalDays" label="Total Days" placeholder=" "
                                                    value={tdays} type="number" disabled
                                                    onChange={(e: any) => handleChanges(e)} />
                                            </div> */}
                      <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
                        <TextBox
                          htmlFor="top"
                          id="top"
                          name="top"
                          label="Top Spacing"
                          placeholder=" "
                          value={spacing?.top}
                          type="text"
                          onChange={(e: any) => handleSpacingChanges(e)}
                        />
                        <TextBox
                          htmlFor="right"
                          id="right"
                          name="right"
                          label="Right Spacing"
                          placeholder=" "
                          value={spacing?.right}
                          type="text"
                          onChange={(e: any) => handleSpacingChanges(e)}
                        />
                        <TextBox
                          id="bottom"
                          name="bottom"
                          htmlFor="bottom"
                          label="Bottom Spacing"
                          placeholder=" "
                          value={spacing?.bottom}
                          type="text"
                          onChange={(e: any) => handleSpacingChanges(e)}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div className="pb-1">
                    <fieldset className="border-2 mt-2 rounded-2 border-gray-400 mx-2 p-2">
                      <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
                        <div>
                          <TextBox
                            htmlFor="notes"
                            id="notes"
                            name="notes"
                            label="notes"
                            placeholder=" "
                            value={cardData?.notes}
                            type="text"
                            onChange={(e: any) => handleChanges(e)}
                          />
                        </div>
                        {/* <TextBox
                          htmlFor="MC"
                          id="MC"
                          name="MC"
                          label="sequence"
                          placeholder=" "
                          value={cardData?.MC}
                          type="text"
                          onChange={(e: any) => handleChanges(e)}
                        /> */}
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className={`mt-4 px-2 col-span-3 md:col-span-1`}>
                  <ImageUploading
                    multiple={false}
                    onChange={(e: any) => handlepostImage(e)}
                    maxNumber={1}
                    acceptType={["png", "jpg", "jpeg"]}
                    maxFileSize={FILE_UPLOAD_SIZE}
                    dataURLKey="data_url"
                    value={images}
                  >
                    {({ onImageUpload, errors }) => {
                      return (
                        <div className="text-center">
                          {errors && (
                            <div>
                              {errors.acceptType && (
                                <span className="text-red-500">
                                  <i className="	fa fa-warning"></i>Your selected
                                  file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span className="text-red-500">
                                  <i className="fa fa-warning"></i>Selected file
                                  size exceed maxFileSize
                                </span>
                              )}
                            </div>
                          )}
                          {images ? (
                            <>
                              <div className="grid grid-cols-7 text-sm">
                                <div></div>
                                <div className="col-span-5 text-center">
                                  {imageSize?.top}
                                </div>
                                <div></div>
                                <div></div>
                                <div className="col-span-5 text-center">
                                  <div className="">
                                    <button
                                      className="mt-1 w-full h-full flex justify-center rounded-md"
                                      onClick={() => onImageUpload()}
                                    ></button>

                                    <div className="relative">
                                      <button
                                        className="absolute top-0 px-2 -right-7 bg-white rounded-lg"
                                        onClick={() => {
                                          imageDelete(images);
                                        }}
                                      >
                                        <i className="fas fa-times"></i>
                                      </button>
                                      <ProgressiveImage
                                        src={images}
                                        placeholder=""
                                      >
                                        {(src: any) => (
                                          <img
                                            className="object-contain max-h-80 w-full"
                                            src={src}
                                            alt="img"
                                          />
                                        )}
                                      </ProgressiveImage>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-center ml-1">
                                  {imageSize?.right}
                                </div>
                                <div></div>
                                <div className="col-span-5 text-center">
                                  {imageSize?.bottom}
                                </div>
                                <div></div>
                              </div>
                            </>
                          ) : (
                            <button
                              className="mt-1 h-full w-full flex justify-center rounded-md border-2 border-solid border-gray-300 px-3 py-10"
                              onClick={() => onImageUpload()}
                            >
                              <div className="w-full h-full flex justify-center items-center content-center flex-wrap space-y-1 text-center">
                                <img
                                  src="../../../assets/images/imageIcon.svg"
                                  alt="Image"
                                />

                                <div className="w-full text-sm text-gray-600">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                  >
                                    <span>Upload a file</span>
                                  </label>
                                  <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                  PNG, JPG Upto 1.5 MB
                                </p>
                              </div>
                            </button>
                          )}
                        </div>
                      );
                    }}
                  </ImageUploading>

                  <div className="grid grid-cols-2 mt-5">
                    <div className="col-span-2 mb-2">
                      <TextBox
                        id="top"
                        name="top"
                        label="top"
                        placeholder=" "
                        value={imageSize?.top}
                        onChange={(e: any) => handleImageChanges(e)}
                      />
                    </div>
                    <div className="col-span-2">
                      <TextBox
                        id="right"
                        name="right"
                        label="right"
                        placeholder=" "
                        value={imageSize?.right}
                        onChange={(e: any) => handleImageChanges(e)}
                      />
                    </div>
                    <div className="col-span-2 mt-2">
                      <TextBox
                        id="bottom"
                        name="bottom"
                        label="bottom"
                        placeholder=" "
                        value={imageSize?.bottom}
                        onChange={(e: any) => handleImageChanges(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap justify-between visible lg:invisible xl:invisible p-2 gap-2">
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    disabled={showSamplePreview}
                    onClick={openInvoicePreview}
                  >
                    <i className="fa fa-download"></i>
                    <span className="ml-2">Print</span>
                  </button>
                </div>
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  {isSampleUpdate ? (
                    <button
                      className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      disabled={isUpdating}
                      onClick={updateData}
                    >
                      {isUpdating ? (
                        <i className="fa fa-spinner animate-spin"></i>
                      ) : (
                        <i className="fa fa-save"></i>
                      )}
                      <span className="ml-2">Update</span>
                    </button>
                  ) : null}
                  {!isSaved && !isSampleUpdate ? (
                    <button
                      className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      disabled={isSaving}
                      onClick={addData}
                    >
                      {isSaving ? (
                        <i className="fa fa-spinner animate-spin"></i>
                      ) : (
                        <i className="fa fa-save"></i>
                      )}
                      <span className="ml-2">Save</span>
                    </button>
                  ) : null}
                </div>
                <div className="lg:p-0 xl:p-0 visible lg:invisible xl:invisible">
                  <button
                    className="px-5 mx-2 float-right py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    onClick={() => {
                      navigate(
                        `/samplecarddashboard${type ? `?type=${type}` : ""}`
                      );
                    }}
                  >
                    <i className="fa fa-times hover:rotate-180 duration-300"></i>
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Modal */}
            {showAddParty && (
              <PartyAdd
                setShowAddParty={setShowAddParty}
                showAddParty={showAddParty}
                setPartyNameAdd={setPartyNameAdd}
                partyNameAdd={partyNameAdd}
                getData={getData}
              />
            )}
            {showAddDesignCode && (
              <DesignCodeAdd
                setShowAddDesignCode={setShowAddDesignCode}
                showAddDesignCode={showAddDesignCode}
                setDesignCodeAdd={setDesignCodeAdd}
                designCodeAdd={designCodeAdd}
                getDesignCode={getDesignCode}
                codeType={designCodeType}
              />
            )}
            {showAddCloth && (
              <ClothAdd
                setShowAddCloth={setShowAddCloth}
                showAddCloth={showAddCloth}
                setClothAdd={setClothAdd}
                clothAdd={clothAdd}
                getClothData={getClothData}
              />
            )}
            {showAddClothParty && (
              <ClothPartyAdd
                setShowAddClothParty={setShowAddClothParty}
                showAddClothParty={showAddClothParty}
                setClothPartyAdd={setClothPartyAdd}
                clothPartyAdd={clothPartyAdd}
                getClothPartyData={getClothPartyData}
              />
            )}
            {showAddItem && (
              <ItemAdd
                setShowAddItem={setShowAddItem}
                showAddItem={showAddItem}
                setItemAdd={setItemAdd}
                itemAdd={itemAdd}
                getItemData={getItemData}
              />
            )}
            {showAddFabric && (
              <FabricAdd
                setShowAddFabric={setShowAddFabric}
                showAddFabric={showAddFabric}
                setFabricAdd={setFabricAdd}
                fabricAdd={fabricAdd}
                getFabricData={getFabricData}
              />
            )}
            {showAddFoil && (
              <FoilColorAdd
                setShowAddFoil={setShowAddFoil}
                showAddFoil={showAddFoil}
                setFoilAdd={setFoilAdd}
                foilAdd={foilAdd}
                getFoilData={getFoilData}
              />
            )}
            {/* Pdf Preview */}
            {showSamplePreview && (
              <SampleCardPreview
                showSamplePreview={showSamplePreview}
                setShowSamplePreview={setShowSamplePreview}
                id={isSampleUpdate ? id : savedId}
                pageName="Sample card"
              />
            )}
          </div>
        </div>
      </div>
      {isSampleFetching ? <Loader /> : null}
    </div>
  );
}
